import React, { FC, useEffect } from 'react';
import {
  useGetAccountCompanyInfo,
  useGetSpacesAction,
  useGetUnreadCountAction,
} from '@/hooks/redux';

const UserPreloader: FC = ({ children }) => {
  const getAccountCompanyInfo = useGetAccountCompanyInfo();
  const getUnreadNotifications = useGetUnreadCountAction();
  const getUserSpaces = useGetSpacesAction();

  useEffect(() => {
    getAccountCompanyInfo();
    getUnreadNotifications();
    getUserSpaces();
  }, []);

  return <>{children}</>;
};

export default UserPreloader;
