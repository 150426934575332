import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface DetailItemProps {
  label: string;
  value: any;
}

const DetailItem: FC<DetailItemProps> = ({ label, value }) => {
  const [t] = useTranslation('common');

  return (
    <div className="d-flex align-items-center justify-content-between mb-4">
      <span>{t(label)}</span>
      <span className="text-capitalize">{value || '-'}</span>
    </div>
  );
};

export default DetailItem;
