import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from '@mui/material';
import { FormikContextType } from 'formik';
import { DatePicker, Input, Select } from '@/components';
import { GENDER } from '@/resources/enums';
import {
  useAuthState,
  useCompanyState,
  useFindCompanyAction,
  useFindUserAction,
  useUserState,
} from '@/hooks/redux';
import { CompanyModel } from '@/resources/models';
import { useParams } from 'react-router';

interface UserFormProps {
  form: FormikContextType<any>;
  title?: string;
}

const UserForm: FC<UserFormProps> = ({
  form,
  title = 'common.contact_info',
}) => {
  const [t] = useTranslation('common');
  const { id: userId } = useParams<{ id: string }>();
  const { account } = useAuthState();
  const { user, finding: loadingUser } = useUserState();
  const findUserAction = useFindUserAction();
  const { company: companyState } = useCompanyState();
  const findCompanyAction = useFindCompanyAction();
  const [company, setCompany] = useState<CompanyModel>();

  useEffect(() => {
    if (userId) {
      if (!user && !loadingUser) {
        findUserAction(userId);
      }
    } else {
      if (account.company) {
        setCompany(account.company as CompanyModel);
      } else {
        findCompanyAction((account.company as CompanyModel).id);
      }
    }
  }, [userId, user, loadingUser]);

  useEffect(() => {
    if (userId && user && user.company) {
      setCompany(user.company as CompanyModel);
    }
  }, [user]);

  useEffect(() => {
    if (companyState) {
      setCompany(companyState);
    }
  }, [companyState]);

  const genderOptions = useMemo(() => {
    return [
      { label: t('common.male'), value: GENDER.MALE },
      { label: t('common.female'), value: GENDER.FEMALE },
      { label: t('common.other'), value: GENDER.OTHER },
    ];
  }, []);

  const unitOptions = useMemo(() => {
    return company
      ? company.units.map((u) => ({
          label: u.name,
          value: u.name,
        }))
      : [];
  }, [company]);

  return (
    <Box sx={{ mb: 10 }}>
      <Divider textAlign="left" variant="dialog" sx={{ mb: 8 }}>
        {t(title)}
      </Divider>

      <Grid container rowSpacing={8} columnSpacing={5}>
        <Grid item xs={12} sm={6}>
          <Input
            label={t('companies.first_name')}
            fullWidth
            {...form.getFieldProps('firstName')}
            errorText={
              form.errors.firstName && form.touched.firstName
                ? t(form.errors.firstName as string)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label={t('companies.last_name')}
            fullWidth
            {...form.getFieldProps('lastName')}
            errorText={
              form.errors.lastName && form.touched.lastName
                ? t(form.errors.lastName as string)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            label={t('common.gender')}
            fullWidth
            options={genderOptions}
            {...form.getFieldProps('gender')}
            errorText={
              form.errors.gender && form.touched.gender
                ? t(form.errors.gender as string)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            fullWidth
            label={t('common.birthday')}
            name="birthday"
            value={form.values.birthday}
            onChange={(value) => form.setFieldValue('birthday', value)}
            errorText={
              form.errors.birthday && form.touched.birthday
                ? (form.errors.birthday as string)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label={t('companies.email')}
            fullWidth
            {...form.getFieldProps('email')}
            errorText={
              form.errors.email && form.touched.email
                ? t(form.errors.email as string)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label={t('companies.mobile_phone')}
            type="number"
            fullWidth
            {...form.getFieldProps('mobilePhone')}
            errorText={
              form.errors.mobilePhone && form.touched.mobilePhone
                ? t(form.errors.mobilePhone as string)
                : ''
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            label={t('companies.unit')}
            fullWidth
            options={unitOptions}
            {...form.getFieldProps('unit')}
            errorText={
              form.errors.unit && form.touched.unit
                ? t(form.errors.unit as string)
                : ''
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserForm;
