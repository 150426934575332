import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete, Visibility } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { DemandModel } from '@/resources/models';
import { DEMAND_STATUS } from '@/resources/enums';
import { useRemoveDemandAction} from '@/hooks/redux';
import DemandDetailDialog from '@/pages/User/Activity/Demands/DemandDetailDialog';
import ConfirmDialog from '@/components/ConfimDialog';

interface DemandDetailButtonProps {
  demand: DemandModel;
  onRemove: () => void;
}

const DemandDetailButton: FC<DemandDetailButtonProps> = ({
  demand,
  onRemove,
}) => {
  const [t] = useTranslation('common');
  const [showDialog, setShowDialog] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeDemand = useRemoveDemandAction();

  const deleteDemand = async () => {
    setLoading(true);
    const res = await removeDemand(demand.id);
    setLoading(false);

    if (res) onRemove();
  };

  return (
    <>
      <Tooltip title={t('common.show_detail')}>
        <IconButton color="primary" onClick={() => setShowDialog(true)}>
          <Visibility />
        </IconButton>
      </Tooltip>

      <DemandDetailDialog
        demand={demand}
        open={showDialog}
        handleClose={() => setShowDialog(false)}
      />

      {
        demand.status === DEMAND_STATUS.PENDING && (
          <>
            <Tooltip title={t('common.remove')}>
              <IconButton color="error" disabled={loading} onClick={() => setShowRemoveConfirm(true)}>
                <Delete />
              </IconButton>
            </Tooltip>

            <ConfirmDialog
              open={showRemoveConfirm}
              handleClose={() => setShowRemoveConfirm(false)}
              onConfirm={deleteDemand}
              confirmTitle="session.delete_request"
              confirmText="session.delete_request_confirm"
            />
          </>
        )
      }
    </>
  )
};

export default DemandDetailButton;
