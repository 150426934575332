import { UserModel } from '@/resources/models/user.model';
import { AddressModel } from '@/resources/models/address.model';
import { SPACE_PRIVACY } from '@/resources/enums';

export class SpaceModel {
  id: string;
  name: string;
  description?: string;
  user: string | UserModel;
  address: string | AddressModel;
  accessibility: boolean;
  parking: boolean;
  childFriendly: boolean;
  petFriendly: boolean;
  privacy: SPACE_PRIVACY;

  constructor(init: any = {}) {
    const data = {
      id: '',
      name: '',
      user: '',
      address: '',
      accessibility: false,
      parking: false,
      childFriendly: false,
      petFriendly: false,
      privacy: SPACE_PRIVACY.DESK_SHARING,
      ...init,
    };

    this.id = data.id || data._id;
    this.name = data.name;
    this.accessibility = data.accessibility;
    this.parking = data.parking;
    this.childFriendly = data.childFriendly;
    this.petFriendly = data.petFriendly;
    this.privacy = data.privacy;

    if (typeof data.user === 'string') {
      this.user = data.user;
    } else {
      this.user = new UserModel(data.user);
    }

    if (typeof data.address === 'string') {
      this.address = data.address;
    } else {
      this.address = new AddressModel(data.address);
    }
  }
}
