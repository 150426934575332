import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Box, Typography } from '@mui/material';
import * as Yup from 'yup';
import { Button, Input } from '@/components';
import {
  useAuthState,
  useChangePasswordAction,
  useShowToastAction,
} from '@/hooks/redux';
import FormSaved from '@/parts/FormSaved';

const validationSchema = Yup.object().shape({
  password: Yup.string().required('validation.password_required'),
  newPassword: Yup.string()
    .required('validation.new_password_required')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'validation.password_invalid',
    ),
  passwordConfirm: Yup.string()
    .required('validation.password_confirm_required')
    .oneOf([Yup.ref('newPassword')], 'validation.password_confirm_unmatched'),
});

const ChangePassword = () => {
  const [t] = useTranslation('common');

  const showToast = useShowToastAction();
  const changePassword = useChangePasswordAction();
  const { loading } = useAuthState();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (values: any) => {
    const { password, newPassword } = values;

    const res = await changePassword(password, newPassword);
    if (res) {
      showToast(t('auth.password_updated'));
      setFormSubmitted(true);
    }
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      password: '',
      newPassword: '',
      passwordConfirm: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <Box>
        <Typography
          variant={'h1'}
          sx={{ color: (theme) => theme.colors.text_secondary }}
        >
          {t('app.change_password')}
        </Typography>
        <Box sx={{ mx: 'auto', maxWidth: '60vh' }}>
          {formSubmitted ? (
            <FormSaved />
          ) : (
            <form onSubmit={form.handleSubmit}>
              <Input
                fullWidth
                type="password"
                label={t('common.password')}
                placeholder={t('auth.password_placeholder')}
                sx={{ mb: 10 }}
                {...form.getFieldProps('password')}
                errorText={
                  form.errors.password && form.touched.password
                    ? t(form.errors.password as string)
                    : ''
                }
              />

              <Input
                fullWidth
                type="password"
                label={t('common.new_password')}
                placeholder={t('auth.password_placeholder')}
                sx={{ mb: 10 }}
                {...form.getFieldProps('newPassword')}
                errorText={
                  form.errors.newPassword && form.touched.newPassword
                    ? t(form.errors.newPassword as string)
                    : ''
                }
              />

              <Input
                fullWidth
                type="password"
                label={t('auth.confirm_password')}
                placeholder={t('auth.confirm_password')}
                sx={{ mb: 10 }}
                {...form.getFieldProps('passwordConfirm')}
                errorText={
                  form.errors.passwordConfirm && form.touched.passwordConfirm
                    ? t(form.errors.passwordConfirm as string)
                    : ''
                }
              />

              <div className="d-flex justify-content-center">
                <Button variant="blue" type="submit" loading={loading}>
                  {t('common.submit')}
                </Button>
              </div>
            </form>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default ChangePassword;
