export class UrbanAreaModel {
  id: string;
  fid: number;
  name: string;
  area: number;
  countryIso: string;
  countryName: string;
  urbanCentreIds: number[];
  urbanCentreCount: number;
  commuting: boolean;
  urbanCentreArea: number;
  geom?: string;

  constructor(init: any = {}) {
    const data = {
      ...init,
    };

    this.id = data._id || data.id;
    this.fid = data.fid;
    this.name = data.name;
    this.area = data.area;
    this.countryIso = data.countryIso;
    this.countryName = data.countryName;
    this.urbanCentreIds = data.urbanCentreIds;
    this.urbanCentreCount = data.urbanCentreCount;
    this.commuting = data.commuting;
    this.urbanCentreArea = data.urbanCentreArea;
    this.geom = data.geom;
  }
}
