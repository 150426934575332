import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

export const useSessionTimePeriodList = (startTime: string, endTime: string) => {
  const [t] = useTranslation('common');

  return [
    {
      label: t('common.session_date'),
      value: moment(startTime).format('DD/MM/YYYY')
    },
    {
      label: t('common.start_time'),
      value: moment(startTime).format('HH:mm')
    },
    {
      label: t('common.end_time'),
      value: moment(endTime).format('HH:mm')
    },
  ]
}
