import React, { ComponentType, FC } from 'react';
import { Route, RouteProps } from 'react-router';
import FullLayout from "../../layouts/FullLayout";

interface PrivateRouteProps extends RouteProps {
  hasLayout?: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({
  component,
  hasLayout= true,
  ...rest
}) => {
  const C = component as ComponentType;
  return (
    <Route
      {...rest}
      render={() =>
        hasLayout ?(
          <FullLayout>
            <C />
          </FullLayout>
        ) :(
          <C />
        )
      }
    />
  );
}

export default PrivateRoute;
