import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { ROUTES, USER_ACCOUNT_TABS } from '@/constants';
import Profile from '@/pages/User/Account/Profile';
import Spaces from '@/pages/User/Account/Spaces';

const accountTabs = [
  { title: 'app.my_profile', value: USER_ACCOUNT_TABS.PROFILE },
  { title: 'app.my_spaces', value: USER_ACCOUNT_TABS.SPACES },
]

const Account = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const { tab } = useParams<{ tab: string; }>();

  const activeTab = useMemo(() => {
    if (tab === USER_ACCOUNT_TABS.SPACES)
      return tab;

    return USER_ACCOUNT_TABS.PROFILE;
  }, [tab]);

  const onTabChange = (tab: USER_ACCOUNT_TABS) => {
    history.replace(`${ROUTES.USER.ACCOUNT.PREFIX}/${tab}`);
  }

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={(e, value: USER_ACCOUNT_TABS) => onTabChange(value)}
        TabIndicatorProps={{
          style: {display: 'none'}
        }}
      >
        {
          accountTabs.map((item) => (
            <Tab
              key={item.value}
              label={t(item.title)}
              value={item.value}
            />
          ))
        }
      </Tabs>

      <Box sx={{ pt: 15 }}>
        {
          activeTab === USER_ACCOUNT_TABS.PROFILE && (
            <Profile />
          )
        }

        {
          activeTab === USER_ACCOUNT_TABS.SPACES && (
            <Spaces />
          )
        }
      </Box>
    </div>
  );
};

export default Account;
