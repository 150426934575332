import React from 'react';
import { Button } from '@mui/material';
import { BannerBoxStyled, BannerQuestionStyled } from '@/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from '@/constants';

export const OfferSessionBanner = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  return (
    <BannerBoxStyled direction='left'>
      <BannerQuestionStyled sx={{mb: '30px'}}>
        {t('app.want_to_host_coremoting_session')}
      </BannerQuestionStyled>
      {/*<Box sx={{mt: '10px', mb: '30px'}}>*/}
      {/*  <Typography sx={{*/}
      {/*    fontFamily: 'Public Sans',*/}
      {/*    fontWeight: 600,*/}
      {/*    fontSize: '24px',*/}
      {/*    lineHeight: '41px'*/}
      {/*  }}>*/}
      {/*    {t('app.go_work_with_us')}*/}
      {/*  </Typography>*/}
      {/*  <Typography sx={{*/}
      {/*    fontFamily: 'Public Sans',*/}
      {/*    fontWeight: 300,*/}
      {/*    fontSize: '16px',*/}
      {/*    lineHeight: '20px'*/}
      {/*  }}>*/}
      {/*    {t('app.get_your_first_offer_sessions')}*/}
      {/*  </Typography>*/}
      {/*</Box>*/}
      <Button
        size='large'
        onClick={ () => history.push(ROUTES.USER.OFFER_SESSION.INDEX)}
      >
        {t('app.offers_sessions')}
      </Button>
    </BannerBoxStyled>
  );
}

export default OfferSessionBanner;
