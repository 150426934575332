import { GENDER, ROLE, USER_STATUS } from '@/resources/enums';
import { AddressModel } from '@/resources/models/address.model';
import { UnitModel } from '@/resources/models/unit.model';
import { CompanyModel } from '@/resources/models/company.model';
import { UrbanAreaModel } from '@/resources/models/urban-area.model';

export class UserModel {
  id: '';
  firstName: string;
  lastName: string;
  email: string;
  gender: GENDER;
  birthday: string;
  mobilePhone: string;
  status: USER_STATUS;
  roles: ROLE[];
  address: string | AddressModel;
  unit: string | UnitModel;
  urbanArea: string | UrbanAreaModel;
  company?: string | CompanyModel;
  createdAt: string;

  constructor(init: any = {}) {
    const data = {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      roles: [],
      address: '',
      gender: GENDER.MALE,
      mobilePhone: '',
      birthday: '',
      unit: '',
      urbanArea: '',
      status: USER_STATUS.ACTIVE,
      createdAt: '',
      ...init,
    };

    this.id = data.id || data._id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.email = data.email;
    this.gender = data.gender;
    this.mobilePhone = data.mobilePhone;
    this.birthday = data.birthday;
    this.roles = data.roles;
    this.status = data.status;
    this.createdAt = data.createdAt;

    if (typeof data.address === 'string') {
      this.address = data.address;
    } else {
      this.address = new AddressModel(data.address);
    }

    if (typeof data.unit === 'string') {
      this.unit = data.unit;
    } else {
      this.unit = new UnitModel(data.unit);
    }

    if (typeof data.urbanArea === 'string') {
      this.urbanArea = data.urbanArea;
    } else {
      this.urbanArea = new UrbanAreaModel(data.urbanArea);
    }

    if (data.company) {
      if (typeof data.company === 'string') {
        this.company = data.company;
      } else {
        this.company = new CompanyModel(data.company);
      }
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
