import React from 'react';
import { useSpinnerLoadingCount } from '@/hooks/redux';
import { Backdrop, CircularProgress } from '@mui/material';

export const LoadingSpinner = () => {
  const loadingCount = useSpinnerLoadingCount();

  return (
    <>
      {
        loadingCount > 0 && (
          <Backdrop
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )
      }
    </>
  )
}