import i18next from 'i18next';

import translationUS from '@/assets/resources/en-US.json';

const DEFAULT_LANGUAGE = 'en';

i18next.init({
  interpolation: { escapeValue: false },
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  resources: {
    en: {
      common: translationUS,
    },
  },
});

export const t = (key: string, options: any = {}) => {
  return (
    i18next.getResource(i18next.language, 'common', key, options) ||
    i18next.getResource(DEFAULT_LANGUAGE, 'common', key, options) ||
    key
  );
};

export default i18next;
