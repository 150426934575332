import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import moment, { Moment } from 'moment-timezone';

type DatePickerProps = {
  name?: string;
  label: string;
  sx?: SxProps;
  value: Moment | null;
  onChange: (value: Moment | null) => void;
  errorText?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  showErrorText?: boolean;
};

export const DatePicker: FC<DatePickerProps> = ({
  name,
  label,
  value,
  onChange,
  errorText,
  fullWidth,
  disabled,
  showErrorText = true,
}) => {
  const [t] = useTranslation('common');

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDatePicker
        label={label}
        value={value}
        onChange={(newValue: Moment) => {
          onChange(newValue);
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            error={!!errorText}
            helperText={showErrorText ? t(errorText) : ''}
            fullWidth={fullWidth}
            onChange={(e) => {
              onChange(moment(e.target.value));
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
