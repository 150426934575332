import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, Divider } from '@mui/material';
import { Dialog } from '@/components';
import { AddressModel, OfferModel, SpaceModel } from '@/resources/models';
import {
  Accessible as AccessibleIcon,
  EscalatorWarning,
  LocalParking as LocalParkingIcon,
  Pets,
} from '@mui/icons-material';
import DetailItem from '@/parts/DetailItem';
import {
  useAddressDetailList,
  usePartnerOptionDetailList,
  useSessionTimePeriodList,
} from '@/hooks/app';

interface OfferDetailDialogProps {
  offer: OfferModel;
  open: boolean;
  handleClose: () => void;
}

const OfferDetailDialog: FC<OfferDetailDialogProps> = ({
  offer,
  open,
  handleClose,
}) => {
  const [t] = useTranslation('common');

  const space = offer.space as SpaceModel;
  const address = space.address as AddressModel;

  const timePeriodList = useSessionTimePeriodList(
    offer.startTime,
    offer.endTime,
  );
  const addressList = useAddressDetailList(address);
  const guestOptions = usePartnerOptionDetailList(offer.guests);

  return (
    <Dialog open={open} onClose={handleClose} title={t('session.offer_detail')}>
      <DialogContent sx={{ py: 5 }}>
        <DetailItem
          key="common_status"
          label={t('common.status')}
          value={offer.status}
        />

        <Divider sx={{ mb: 3 }}>{t('app.space')}</Divider>

        <DetailItem
          key={`address-space`}
          label={t('space.space_name')}
          value={space?.name}
        />

        {addressList.map((item, index) => (
          <DetailItem
            key={`address-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}

        <Divider sx={{ mb: 3 }}>{t('common.characteristics')}</Divider>

        <div className="d-flex flex-wrap">
          {space.accessibility && (
            <div className="d-flex align-items-center mr-4 mb-4">
              <AccessibleIcon sx={{ mr: 1 }} />
              {t('space.accessibility')}
            </div>
          )}
          {space.parking && (
            <div className="d-flex align-items-center mr-4 mb-4">
              <LocalParkingIcon sx={{ mr: 1 }} />
              {t('space.parking')}
            </div>
          )}
          {space.childFriendly && (
            <div className="d-flex align-items-center mr-4 mb-4">
              <EscalatorWarning sx={{ mr: 1 }} />
              {t('space.child_friendly')}
            </div>
          )}
          {space.petFriendly && (
            <div className="d-flex align-items-center mr-4 mb-4">
              <Pets sx={{ mr: 1 }} />
              {t('space.pet_friendly')}
            </div>
          )}
        </div>

        <Divider sx={{ mb: 3 }}>{t('app.time')}</Divider>
        {timePeriodList.map((item, index) => (
          <DetailItem
            key={`time-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}

        <Divider sx={{ mb: 3 }}>{t('app.guests')}</Divider>
        {guestOptions.map((item, index) => (
          <DetailItem
            key={`guest-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default OfferDetailDialog;
