import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IconButton, Tooltip} from '@mui/material';
import {Block, Check} from '@mui/icons-material';
import {UserModel} from '@/resources/models';
import {useActiveUserAction, useAuthState, useBlockUserAction, useShowToastAction} from '@/hooks/redux';
import {ROLE, USER_STATUS} from '@/resources/enums';
import ConfirmDialog from '@/components/ConfimDialog';

interface ActiveUserButtonProps {
  user: UserModel;
}

const ActiveUserButton: FC<ActiveUserButtonProps> = ({
  user,
}) => {
  const [t] = useTranslation('common');
  const { currentRole } = useAuthState();

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(user.status);
  const [showConfirm, setShowConfirm] = useState(false);

  const showToast = useShowToastAction();
  const activeUser = useActiveUserAction();
  const blockUser = useBlockUserAction();

  const onToggleStatus = async () => {
    const action = status === USER_STATUS.ACTIVE ? blockUser : activeUser;

    setLoading(true);
    const res = await action(user.id);
    setLoading(false);

    if (res) {
      setStatus(status === USER_STATUS.ACTIVE ? USER_STATUS.BLOCKED : USER_STATUS.ACTIVE);

      if (status === USER_STATUS.ACTIVE) {
        showToast(t('user.user_blocked_message'));
      } else {
        showToast(t('user.user_activated_message'));
      }
    }
  };

  return (
    <>
      <Tooltip
        title={ status === USER_STATUS.ACTIVE ? t('companies.block_user') : t('companies.active_user') }
      >
        <IconButton
          color={ status === USER_STATUS.ACTIVE ? 'warning' : 'success' }
          onClick={() => setShowConfirm(true)}
          disabled={
            loading
            || (currentRole === ROLE.COMPANY && user.roles.includes(ROLE.COMPANY))
          }
        >
          {
            status === USER_STATUS.ACTIVE ? (
              <Block />
            ) : (
              <Check />
            )
          }
        </IconButton>
      </Tooltip>

      <ConfirmDialog
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        onConfirm={onToggleStatus}
        confirmTitle={status === USER_STATUS.ACTIVE ? 'companies.block_user' : 'companies.active_user' }
        confirmText={status === USER_STATUS.ACTIVE ? 'companies.block_user_confirm' : 'companies.active_user_confirm'}
      />
    </>
  )
};

export default ActiveUserButton;
