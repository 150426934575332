import React from 'react';
import {
  Alert as MuiAlert,
  AlertProps,
  IconButton,
  Snackbar,
} from '@mui/material';
import { useHideToastAction, useToastState } from '@/hooks/redux';
import { Close } from '@mui/icons-material';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = () => {
  const { show, type, content } = useToastState();
  const hideToast = useHideToastAction();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={8000}
      open={show}
      onClose={hideToast}
      sx={{ maxWidth: '400px' }}
    >
      <Alert
        onClose={hideToast}
        severity={type}
        sx={{ width: '100%' }}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={hideToast}
          >
            <Close
              fontSize="inherit"
              sx={{ color: (theme) => theme.colors.white }}
            />
          </IconButton>
        }
      >
        {content}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
