import React, { useState } from 'react';
import { Box, Grid, Popover, styled } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@/components';

const FilterButton = styled(Button)`
  padding: 5px 20px;
  font-size: 12px;
`;

const AddFilterButton = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={openMenu}>
        Add Filter
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0 0 10px 10px',
          },
        }}
      >
        <Box sx={{ p: 4, maxWidth: '300px' }}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item xs={6}>
              <FilterButton color="secondary">Date Range</FilterButton>
            </Grid>
            <Grid item xs={6}>
              <FilterButton color="secondary">Status</FilterButton>
            </Grid>
            <Grid item xs={6}>
              <FilterButton color="secondary">Urban Area</FilterButton>
            </Grid>
            <Grid item xs={6}>
              <FilterButton color="secondary">Unit</FilterButton>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </>
  );
};

export default AddFilterButton;
