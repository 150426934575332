export enum COMPANY_ACTIONS {
  CREATE_COMPANY_REQUEST = 'CREATE_COMPANY_REQUEST',
  CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR',
  GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST',
  GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS',
  GET_COMPANIES_ERROR = 'GET_COMPANIES_ERROR',
  DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST',
  DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_ERROR = 'DELETE_COMPANY_ERROR',
  GET_COMPANY_USERS_REQUEST = 'GET_COMPANY_USERS_REQUEST',
  GET_COMPANY_USERS_SUCCESS = 'GET_COMPANY_USERS_SUCCESS',
  GET_COMPANY_USERS_ERROR = 'GET_COMPANY_USERS_ERROR',
  GET_COMPANY_SESSIONS_REQUEST = 'GET_COMPANY_SESSIONS_REQUEST',
  GET_COMPANY_SESSIONS_SUCCESS = 'GET_COMPANY_SESSIONS_SUCCESS',
  GET_COMPANY_SESSIONS_ERROR = 'GET_COMPANY_SESSIONS_ERROR',
  FIND_COMPANY_REQUEST = 'FIND_COMPANY_REQUEST',
  FIND_COMPANY_SUCCESS = 'FIND_COMPANY_SUCCESS',
  FIND_COMPANY_ERROR = 'FIND_COMPANY_ERROR',
  UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST',
  UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR',
  GET_COMPANY_DETAIL_USERS_REQUEST = 'GET_COMPANY_DETAIL_USERS_REQUEST',
  GET_COMPANY_DETAIL_USERS_SUCCESS = 'GET_COMPANY_DETAIL_USERS_SUCCESS',
  GET_COMPANY_DETAIL_USERS_ERROR = 'GET_COMPANY_DETAIL_USERS_ERROR',
  DISABLE_COMPANY_REQUEST = 'DISABLE_COMPANY_REQUEST',
  DISABLE_COMPANY_SUCCESS = 'DISABLE_COMPANY_SUCCESS',
  DISABLE_COMPANY_ERROR = 'DISABLE_COMPANY_ERROR',
  CREATE_COMPANY_ADMIN_REQUEST = 'CREATE_COMPANY_ADMIN_REQUEST',
  CREATE_COMPANY_ADMIN_SUCCESS = 'CREATE_COMPANY_ADMIN_SUCCESS',
  CREATE_COMPANY_ADMIN_ERROR = 'CREATE_COMPANY_ADMIN_ERROR',
}
