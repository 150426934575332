import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { AddressModel } from '@/resources/models';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAP_KEY } from '@/constants';
import { Marker } from '@/parts/SpaceAddressForm';
import { useCommonState } from '@/hooks/redux';

export enum AddressType {
  USER_ADDRESS,
  SPACE_ADDRESS,
}

interface AddressPreviewProps {
  address: AddressModel;
  addressType?: AddressType;
}

const AddressPreview: FC<AddressPreviewProps> = ({ address, addressType }) => {
  const [t] = useTranslation('common');
  const { countries } = useCommonState();

  const getAddressLabel = () => {
    switch (addressType) {
      case AddressType.SPACE_ADDRESS:
        return t('common.space_address');
      case AddressType.USER_ADDRESS:
        return t('common.user_address');
      default:
        return t('common.address');
    }
  };

  const getCountryById = (id) => countries.find((c) => c.id === id);

  const { numberOfStreet, street, city, postalCode, country, lat, lng } =
    address;

  const countryModel =
    typeof country === 'string' ? getCountryById(country) : country;

  const inLineAddress = `${numberOfStreet}, ${street}, ${city}, ${postalCode}, ${
    countryModel ? countryModel.name : ''
  }`;
  const location = { lat, lng };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <strong>{getAddressLabel()}:</strong>
        <p>{inLineAddress}</p>
      </Grid>
      {address.description && (
        <Grid item xs={12} md={12}>
          <strong>{t('common.description')}:</strong>
          <p>{address.description}</p>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        {location && location.lat && location.lng && (
          <div style={{ height: '265px', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
              center={location}
              zoom={17}
              yesIWantToUseGoogleMapApiInternals
              options={{
                panControl: false,
              }}
            >
              <Marker lat={location.lat} lng={location.lng} />
            </GoogleMapReact>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default AddressPreview;
