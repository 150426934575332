import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete, Visibility } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { SessionModel } from '@/resources/models';
import { useRemoveSessionAction } from '@/hooks/redux';
import SessionDetailDialog from './SessionDetailDialog';
import ConfirmDialog from '@/components/ConfimDialog';

interface SessionDetailButtonProps {
  session: SessionModel;
  onRemove: () => void;
  canRemove?: boolean;
}

const SessionDetailButton: FC<SessionDetailButtonProps> = ({
  session,
  onRemove,
  canRemove = false,
}) => {
  const [t] = useTranslation('common');
  const [showDialog, setShowDialog] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeSession = useRemoveSessionAction();

  const deleteSession = async () => {
    setLoading(true);
    const res = await removeSession(session.id);
    setLoading(false);

    if (res) onRemove();
  };

  return (
    <>
      <Tooltip title={t('common.show_detail')}>
        <IconButton color="default" onClick={() => setShowDialog(true)}>
          <Visibility />
        </IconButton>
      </Tooltip>

      <SessionDetailDialog
        session={session}
        open={showDialog}
        handleClose={() => setShowDialog(false)}
      />

      {canRemove && (
        <>
          <Tooltip title={t('common.remove')}>
            <IconButton
              color="error"
              disabled={loading}
              onClick={() => setShowRemoveConfirm(true)}
            >
              <Delete />
            </IconButton>
          </Tooltip>

          <ConfirmDialog
            open={showRemoveConfirm}
            handleClose={() => setShowRemoveConfirm(false)}
            onConfirm={deleteSession}
            confirmTitle="session.delete_session"
            confirmText="session.delete_session_confirm"
          />
        </>
      )}
    </>
  );
};

export default SessionDetailButton;
