import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import { finishLoading, startLoading } from '@/redux/actions';

export const useSpinnerLoadingCount = () =>
  useSelector(
    ({ spinnerReducer: { loadingCount } }: RootState) => loadingCount,
  );

export const useStartLoadingDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(startLoading());
};

export const useFinishLoadingDispatch = () => {
  const dispatch = useDispatch();
  return () => dispatch(finishLoading());
};
