import { HttpService } from '@/service/http.service';
import { UserModel } from '@/resources/models';

export class AuthService {
  static login(email: string, password: string) {
    return HttpService.post('/auth/login', { email, password });
  }

  static getAccount() {
    return HttpService.get('/auth/me').then((data) => new UserModel(data));
  }

  static refreshToken(token: string) {
    return HttpService.post('/auth/refresh-token', { refreshToken: token });
  }

  static forgotPassword(email: string) {
    return HttpService.post('/auth/forgot-password', { email });
  }

  static resetPassword(password: string, token: string) {
    return HttpService.post('/auth/reset-password', { password, token });
  }

  static changePassword(password: string, newPassword: string) {
    return HttpService.post('/auth/change-password', { password, newPassword });
  }
}
