import React from 'react';
import { Typography } from '@mui/material';
import OfferSessionBanner from '@/parts/Sessions/Banners/OfferSessionBanner';
import RequestSessionBanner from '@/parts/Sessions/Banners/RequestSessionBanner';
import { useTranslation } from 'react-i18next';

const Sessions = () => {
  const [t] = useTranslation('common');

  return (
    <>
      <Typography
        variant={'h1'}
        sx={{color: theme => theme.colors.text_secondary}}
      >
        {t('app.sessions')}
      </Typography>
      <OfferSessionBanner />
      <RequestSessionBanner />
    </>
  );
};

export default Sessions;
