export enum SPACE_ACTIONS {
  GET_SPACES_REQUEST = 'GET_SPACES_REQUEST',
  GET_SPACES_SUCCESS = 'GET_SPACES_SUCCESS',
  GET_SPACES_ERROR = 'GET_SPACES_ERROR',
  DELETE_SPACE_REQUEST = 'DELETE_SPACE_REQUEST',
  DELETE_SPACE_SUCCESS = 'DELETE_SPACE_SUCCESS',
  DELETE_SPACE_ERROR = 'DELETE_SPACE_ERROR',
  CREATE_SPACE_REQUEST = 'CREATE_SPACE_REQUEST',
  CREATE_SPACE_SUCCESS = 'CREATE_SPACE_SUCCESS',
  CREATE_SPACE_ERROR = 'CREATE_SPACE_ERROR',
  UPDATE_SPACE_REQUEST = 'UPDATE_SPACE_REQUEST',
  UPDATE_SPACE_SUCCESS = 'UPDATE_SPACE_SUCCESS',
  UPDATE_SPACE_ERROR = 'UPDATE_SPACE_ERROR',
  FIND_SPACE_REQUEST = 'FIND_SPACE_REQUEST',
  FIND_SPACE_SUCCESS = 'FIND_SPACE_SUCCESS',
  FIND_SPACE_ERROR = 'FIND_SPACE_SUCCESS',
}
