import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { BackButton } from '@/components';
import PostUser from '@/parts/PostUser';
import { useFindUserAction, useUserState } from '@/hooks/redux';

const EditUser = () => {
  const [t] = useTranslation('common');
  const { id } = useParams<{ id: string }>();

  const { finding, user } = useUserState();
  const findUser = useFindUserAction();

  useEffect(() => {
    findUser(id);
  }, [id]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 15,
        }}
      >
        <Typography
          variant={'h1'}
          sx={{ color: (theme) => theme.colors.text_secondary }}
        >
          {t('user.edit_user')}
        </Typography>

        <BackButton />
      </Box>

      {finding ? (
        <Box>
          {new Array(10).fill(0).map((_, index) => (
            <Skeleton key={index} />
          ))}
        </Box>
      ) : user ? (
        <PostUser user={user} />
      ) : (
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '500px',
          }}
        >
          <Typography variant="body1" color="error">
            {t('user.not_found_user')}
          </Typography>
        </Paper>
      )}
    </div>
  );
};

export default EditUser;
