import React from 'react';
import { useAuthState } from '@/hooks/redux';
import AddressPreview from '@/parts/SpacePreviewForm/AddressPreview';
import { AddressModel } from '@/resources/models';
import moment from 'moment-timezone';
import UserPreview from '@/parts/UserPreview';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const [t] = useTranslation('common');
  const { account } = useAuthState();

  const { firstName, lastName, gender, email, unit, birthday, mobilePhone } =
    account;

  const user = {
    firstName,
    lastName,
    gender,
    email,
    unit,
    birthday: moment(birthday).toISOString(),
    mobilePhone,
  };

  return (
    <>
      <UserPreview user={user} />
      <Divider textAlign="left" variant="dialog" sx={{ mt: 7, mb: 5 }}>
        {t('common.user_address')}
      </Divider>
      <AddressPreview address={account.address as AddressModel} />
    </>
  );
};

export default Profile;
