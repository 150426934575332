import React, { FC, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useGetUserSessionsAction, useUserState } from '@/hooks/redux';
import { ObjectHelper } from '@/utils';
import SessionFilters from '@/parts/SessionFilters';
import SessionTable from '@/parts/Sessions/SessionTable';

const WorkingSessions: FC = () => {
  const searchSessions = useGetUserSessionsAction();
  const { loadingSessions, userSessions, sessionTotalCount } = useUserState();

  const [filter, setFilter] = useState({
    search: '',
    page: 0,
    perPage: 10,
  });

  const onChangeFilter = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  useEffect(() => {
    searchSessions(ObjectHelper.trimQuery(filter));
  }, [filter]);

  return (
    <div>
      <Box sx={{ mb: '10px' }}>
        <SessionFilters filter={filter} onChangeFilter={onChangeFilter} />
      </Box>

      <SessionTable
        sessions={userSessions}
        loading={loadingSessions}
        pagination={{
          total: sessionTotalCount,
          page: filter.page,
          perPage: filter.perPage,
          onPageChange: (page) => onChangeFilter('page', page)
        }}
        refresh={() => onChangeFilter('page', 0)}
        type="user"
      />
    </div>
  );
};

export default WorkingSessions;
