export enum SESSION_ACTIONS {
  GET_SESSIONS_REQUEST = 'GET_SESSIONS_REQUEST',
  GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS',
  GET_SESSIONS_ERROR = 'GET_SESSIONS_ERROR',
  REMOVE_SESSION_REQUEST = 'REMOVE_SESSION_REQUEST',
  REMOVE_SESSION_SUCCESS = 'REMOVE_SESSION_SUCCESS',
  REMOVE_SESSION_ERROR = 'REMOVE_SESSION_ERROR',
  COMPANY_SCHEDULED_SESSIONS_COUNT_REQUEST = 'COMPANY_SCHEDULED_SESSIONS_COUNT_REQUEST',
  COMPANY_SCHEDULED_SESSIONS_COUNT_SUCCESS = 'COMPANY_SCHEDULED_SESSIONS_COUNT_SUCCESS',
  COMPANY_SCHEDULED_SESSIONS_COUNT_ERROR = 'COMPANY_SCHEDULED_SESSIONS_COUNT_ERROR',
}
