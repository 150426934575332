import { CountryModel } from '@/resources/models/country.model';
import { UrbanAreaModel } from '@/resources/models/urban-area.model';
import { UrbanCentreModel } from '@/resources/models/urban-centre.model';

export class AddressModel {
  id: string;
  country: string | CountryModel;
  postalCode: string;
  city: string;
  urbanArea: string | UrbanAreaModel;
  urbanCentre: string | UrbanCentreModel;
  street: string;
  numberOfStreet: string;
  description: string;
  lat?: number;
  lng?: number;

  constructor(init: any = {}) {
    const data = {
      id: '',
      country: '',
      postalCode: '',
      city: '',
      urbanArea: '',
      street: '',
      numberOfStreet: '',
      description: '',
      ...init,
    };

    this.id = data.id || data._id;
    this.postalCode = data.postalCode;
    this.city = data.city;
    this.urbanArea = data.urbanArea;
    this.urbanCentre = data.urbanCentre;
    this.street = data.street;
    this.numberOfStreet = data.numberOfStreet;
    this.description = data.description;
    this.lat = data.lat;
    this.lng = data.lng;

    if (typeof data.country === 'string') {
      this.country = data.country;
    } else {
      this.country = new CountryModel(data.country);
    }

    if (typeof data.urbanArea === 'string') {
      this.urbanArea = data.urbanArea;
    } else {
      this.urbanArea = new UrbanAreaModel(data.urbanArea);
    }
  }
}
