import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { ADMIN_COMPANY_DETAIL_TABS, ROUTES } from '@/constants';
import CompanyUsers from '@/pages/Admin/Companies/CompanyUsers';
import EditCompany from "@/pages/Admin/Companies/EditCompany";

const companyDetailTabs = [
  { title: 'common.detail', value: ADMIN_COMPANY_DETAIL_TABS.DETAIL },
  { title: 'common.users', value: ADMIN_COMPANY_DETAIL_TABS.USERS }
];

const CompanyDetail = () => {
  const [t] = useTranslation('common');

  const history = useHistory();
  const { id, tab } = useParams<{ id: string; tab: string; }>();

  const activeTab = useMemo(() => {
    if (
      tab === ADMIN_COMPANY_DETAIL_TABS.USERS
    )
      return tab;

    return ADMIN_COMPANY_DETAIL_TABS.DETAIL;
  }, [tab]);

  const onTabChange = (tab: ADMIN_COMPANY_DETAIL_TABS) => {
    history.replace(`${ROUTES.ADMIN.COMPANIES.PREFIX}/${id}/${tab}`);
  };

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={(e, value: ADMIN_COMPANY_DETAIL_TABS) => onTabChange(value)}
        TabIndicatorProps={{
          style: {display: 'none'}
        }}
      >
        {
          companyDetailTabs.map((item) => (
            <Tab
              key={item.value}
              label={t(item.title)}
              value={item.value}
            />
          ))
        }
      </Tabs>

      <Box sx={{ pt: 15 }}>
        {
          activeTab === ADMIN_COMPANY_DETAIL_TABS.DETAIL && (
            <EditCompany />
          )
        }

        {
          activeTab === ADMIN_COMPANY_DETAIL_TABS.USERS && (
            <CompanyUsers />
          )
        }
      </Box>
    </div>
  );
};

export default CompanyDetail;
