import { AUTH_ACTIONS } from '../action-types';
import { UserModel } from '@/resources/models';
import { ROLE } from '@/resources/enums';

export interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export interface AuthState {
  tokens: Tokens | undefined;
  account: UserModel | null;
  currentRole: ROLE | undefined;

  loadingAccount: boolean;
  loading: boolean;
}

const initialState: AuthState = {
  tokens: undefined,
  account: null,
  currentRole: undefined,
  loadingAccount: true,
  loading: false,
};

const authReducer = (state: AuthState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case AUTH_ACTIONS.SET_TOKEN:
      return {
        ...state,
        tokens: payload.tokens,
      };

    case AUTH_ACTIONS.GET_ACCOUNT_REQUEST:
      return {
        ...state,
        loadingAccount: true,
      };

    case AUTH_ACTIONS.SET_ACCOUNT:
      return {
        ...state,
        account: payload.account,
        loadingAccount: false,
      };

    case AUTH_ACTIONS.GET_ACCOUNT_ERROR:
      return {
        ...state,
        loadingAccount: false,
      };

    case AUTH_ACTIONS.SET_ACCOUNT_LOADING:
      return {
        ...state,
        loadingAccount: payload.loading,
      };

    case AUTH_ACTIONS.SET_CURRENT_ROLE:
      return {
        ...state,
        currentRole: payload.role,
      };

    case AUTH_ACTIONS.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AUTH_ACTIONS.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case AUTH_ACTIONS.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case AUTH_ACTIONS.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AUTH_ACTIONS.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case AUTH_ACTIONS.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };

    case AUTH_ACTIONS.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AUTH_ACTIONS.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case AUTH_ACTIONS.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
