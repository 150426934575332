import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import {
  Accessible as AccessibleIcon,
  EscalatorWarning,
  LocalParking as LocalParkingIcon,
  Pets,
} from '@mui/icons-material';
import { FormikContextType } from 'formik';
import { usePrivacyOptions } from '@/hooks/app';
import { Input, Select } from '@/components';

interface UserPreferenceProps {
  form: FormikContextType<any>;
}

const UserPreference: FC<UserPreferenceProps> = ({ form }) => {
  const [t] = useTranslation('common');

  const { privacyOptions } = usePrivacyOptions();

  return (
    <Grid container rowSpacing={5} columnSpacing={5}>
      <Grid item xs={9}>
        {t('common.preference')}
      </Grid>
      <Grid item xs={3}>
        {t('common.mandatory')}
      </Grid>

      <Grid item xs={9}>
        <Input
          label={t('session.maximum_distance')}
          fullWidth
          {...form.getFieldProps('maximumDistance')}
          errorText={
            form.errors.maximumDistance && form.touched.maximumDistance
              ? (form.errors.maximumDistance as string)
              : ''
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Checkbox
          checked={form.values.maximumDistanceMandatory}
          onChange={(e) =>
            form.setFieldValue('maximumDistanceMandatory', e.target.checked)
          }
        />
      </Grid>

      <Grid item xs={9}>
        <Select
          label={t('space.privacy_request')}
          fullWidth
          options={privacyOptions}
          {...form.getFieldProps('privacy')}
          errorText={
            form.errors.privacy && form.touched.privacy
              ? (form.errors.privacy as string)
              : ''
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Checkbox
          checked={form.values.privacyMandatory}
          onChange={(e) =>
            form.setFieldValue('privacyMandatory', e.target.checked)
          }
        />
      </Grid>

      <Grid item xs={9}>
        <FormControlLabel
          control={
            <Checkbox
              checked={form.values.accessibility}
              onChange={(e) =>
                form.setFieldValue('accessibility', e.target.checked)
              }
            />
          }
          label={
            <div className="d-flex align-items-center">
              <AccessibleIcon sx={{ mr: 1 }} />
              {t('space.accessibility')}
            </div>
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Checkbox
          checked={form.values.accessibilityMandatory}
          onChange={(e) =>
            form.setFieldValue('accessibilityMandatory', e.target.checked)
          }
        />
      </Grid>

      <Grid item xs={9}>
        <FormControlLabel
          control={
            <Checkbox
              checked={form.values.parking}
              onChange={(e) => form.setFieldValue('parking', e.target.checked)}
            />
          }
          label={
            <div className="d-flex align-items-center">
              <LocalParkingIcon sx={{ mr: 1 }} />
              {t('space.parking')}
            </div>
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Checkbox
          checked={form.values.parkingMandatory}
          onChange={(e) =>
            form.setFieldValue('parkingMandatory', e.target.checked)
          }
        />
      </Grid>

      <Grid item xs={9}>
        <FormControlLabel
          control={
            <Checkbox
              checked={form.values.childFriendly}
              onChange={(e) =>
                form.setFieldValue('childFriendly', e.target.checked)
              }
            />
          }
          label={
            <div className="d-flex align-items-center">
              <EscalatorWarning sx={{ mr: 1 }} />
              {t('space.child_friendly')}
            </div>
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Checkbox
          checked={form.values.childFriendlyMandatory}
          onChange={(e) =>
            form.setFieldValue('childFriendlyMandatory', e.target.checked)
          }
        />
      </Grid>

      <Grid item xs={9}>
        <FormControlLabel
          control={
            <Checkbox
              checked={form.values.petFriendly}
              onChange={(e) =>
                form.setFieldValue('petFriendly', e.target.checked)
              }
            />
          }
          label={
            <div className="d-flex align-items-center">
              <Pets sx={{ mr: 1 }} />
              {t('space.pet_friendly')}
            </div>
          }
        />
      </Grid>
      <Grid item xs={3}>
        <Checkbox
          checked={form.values.petFriendlyMandatory}
          onChange={(e) =>
            form.setFieldValue('petFriendlyMandatory', e.target.checked)
          }
        />
      </Grid>
    </Grid>
  );
};

export default UserPreference;
