import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  activeUserAction,
  blockUserAction,
  createMultipleUsersAction,
  createUserAction,
  deleteUserAction,
  findUserAction,
  getUserCalendarDataAction,
  getUsersAction,
  searchUserSessionsAction,
  updateUserAction,
} from '@/redux/actions';
import { Dayjs } from 'dayjs';

export const useUserState = () =>
  useSelector(({ userReducer }: RootState) => userReducer);

export const useCreateUserAction = () => {
  const dispatch = useDispatch();
  return (data: any) => dispatch(createUserAction(data));
};

export const useCreateMultipleUsersAction = () => {
  const dispatch = useDispatch();
  return (data: any) => dispatch(createMultipleUsersAction(data));
};

export const useGetUsersAction = () => {
  const dispatch = useDispatch();
  return (query: any = {}) => dispatch(getUsersAction(query));
};

export const useDeleteUserAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(deleteUserAction(id));
};

export const useFindUserAction = () => {
  const dispatch = useDispatch();

  return (id: string) => dispatch(findUserAction(id));
};

export const useUpdateUserAction = () => {
  const dispatch = useDispatch();

  return (id: string, data: any) => dispatch(updateUserAction(id, data));
};

export const useGetUserSessionsAction = () => {
  const dispatch = useDispatch();

  return (query: any = {}) => dispatch(searchUserSessionsAction(query));
};

export const useActiveUserAction = () => {
  const dispatch = useDispatch();

  return (id: string) => dispatch(activeUserAction(id));
};

export const useBlockUserAction = () => {
  const dispatch = useDispatch();

  return (id: string) => dispatch(blockUserAction(id));
};

export const useGetUserCalendarDataAction = () => {
  const dispatch = useDispatch();
  return (date: Dayjs) => dispatch(getUserCalendarDataAction(date));
};
