import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@/components';
import { useCountryOptions, usePrivacyOptions } from '@/hooks/app';
import { useAuthState, useShowToastAction } from '@/hooks/redux';
import { AddressModel, CompanyModel, UrbanAreaModel } from '@/resources/models';
import SpaceAddressForm from '@/parts/SpaceAddressForm';
import UserPreference from '@/pages/User/RequestSession/RequestSpace/UserPreference';
import AddressPreview, {
  AddressType,
} from '@/parts/SpacePreviewForm/AddressPreview';
import { FormHelper } from '@/utils';

const validationSchema = Yup.object().shape({
  country: Yup.string().trim().required('validation.required_field'),
  postalCode: Yup.string().required('validation.required_field'),
  city: Yup.string().required('validation.required_field'),
  street: Yup.string().required('validation.required_field'),
  numberOfStreet: Yup.string().required('validation.required_field'),
  description: Yup.string(),
  maximumDistance: Yup.mixed(),
  maximumDistanceMandatory: Yup.boolean().required('validation.required_field'),
  privacy: Yup.string().required('validation.required_field'),
  privacyMandatory: Yup.boolean().required('validation.required_field'),
  accessibility: Yup.boolean().required('validation.required_field'),
  accessibilityMandatory: Yup.boolean().required('validation.required_field'),
  parking: Yup.boolean().required('validation.required_field'),
  parkingMandatory: Yup.boolean().required('validation.required_field'),
  childFriendly: Yup.boolean().required('validation.required_field'),
  childFriendlyMandatory: Yup.boolean().required('validation.required_field'),
  petFriendly: Yup.boolean().required('validation.required_field'),
  petFriendlyMandatory: Yup.boolean().required('validation.required_field'),
});

export enum ADDRESS_OPTION {
  MY_ADDRESS = 'MY_ADDRESS',
  OTHER_LOCATION = 'OTHER_LOCATION',
}

interface RequestSpaceProps {
  requestData: any;
  setRequestData: (data: any) => void;
  onNext: () => void;
}

const RequestSpace: FC<RequestSpaceProps> = ({
  requestData,
  setRequestData,
  onNext,
}) => {
  const [t] = useTranslation('common');
  const { defaultCountryId } = useCountryOptions();
  const { defaultPrivacy } = usePrivacyOptions();
  const { account } = useAuthState();
  const showToast = useShowToastAction();

  const userAddress = account?.address as AddressModel;

  const { address, spaceOptions } = requestData;

  const [location, setLocation] = useState({
    lat: address.lat,
    lng: address.lng,
  });

  const onChangeAddressOption = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value as ADDRESS_OPTION;
    setRequestData({
      ...requestData,
      addressOption: value,
    });
  };

  const handleSubmit = (values: any) => {
    const {
      country,
      postalCode,
      city,
      urbanArea,
      street,
      numberOfStreet,
      description,
      maximumDistance,
      maximumDistanceMandatory,
      privacy,
      privacyMandatory,
      accessibility,
      accessibilityMandatory,
      parking,
      parkingMandatory,
      childFriendly,
      childFriendlyMandatory,
      petFriendly,
      petFriendlyMandatory,
    } = values;

    if (
      country !==
      ((account.company as CompanyModel).address as AddressModel).country
    ) {
      showToast(
        t('common.please_use_country_corresponding_to_urban_area'),
        'error',
      );
      return;
    }

    const { lat, lng } = location;

    setRequestData({
      ...requestData,
      address: {
        ...requestData.address,
        country,
        postalCode,
        city,
        urbanArea,
        street,
        numberOfStreet,
        description,
        lat,
        lng,
      },
      spaceOptions: {
        ...requestData.spaceOptions,
        maximumDistance: {
          value: maximumDistance,
          mandatory: maximumDistanceMandatory,
        },
        privacy: { value: privacy, mandatory: privacyMandatory },
        accessibility: {
          value: accessibility,
          mandatory: accessibilityMandatory,
        },
        parking: { value: parking, mandatory: parkingMandatory },
        childFriendly: {
          value: childFriendly,
          mandatory: childFriendlyMandatory,
        },
        petFriendly: {
          value: petFriendly,
          mandatory: petFriendlyMandatory,
        },
      },
    });

    onNext();
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      country: address.country || defaultCountryId,
      postalCode: address.postalCode || '',
      city: address.city || '',
      street: address.street || '',
      numberOfStreet: address.numberOfStreet || '',
      description: address.description || '',
      maximumDistance: spaceOptions.maximumDistance.value || '',
      maximumDistanceMandatory: spaceOptions.maximumDistance.mandatory || false,
      privacy: spaceOptions.privacy.value || defaultPrivacy,
      privacyMandatory: spaceOptions.privacy.mandatory || false,
      accessibility: spaceOptions.accessibility.value || false,
      accessibilityMandatory: spaceOptions.accessibility.mandatory || false,
      parking: spaceOptions.parking.value || false,
      parkingMandatory: spaceOptions.parking.mandatory || false,
      childFriendly: spaceOptions.childFriendly.value || false,
      childFriendlyMandatory: spaceOptions.childFriendly.mandatory || false,
      petFriendly: spaceOptions.petFriendly.value || false,
      petFriendlyMandatory: spaceOptions.petFriendly.mandatory || false,
    },
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (requestData.addressOption === ADDRESS_OPTION.MY_ADDRESS) {
      form.setValues({
        ...form.values,
        country: userAddress.country,
        postalCode: userAddress.postalCode,
        city: userAddress.city,
        urbanArea: (userAddress.urbanArea as UrbanAreaModel)?.name || '',
        street: userAddress.street,
        numberOfStreet: userAddress.numberOfStreet,
        description: userAddress.description,
      });
      setLocation({ lat: userAddress.lat, lng: userAddress.lng });
    }
  }, [requestData.addressOption, userAddress]);

  return (
    <div>
      <Box sx={{ mx: 'auto' }}>
        <form
          onSubmit={form.handleSubmit}
          onKeyDown={FormHelper.onKeyDownPreventEnter}
        >
          <Box sx={{ mx: 'auto' }}>
            <Divider sx={{ mb: 3 }}>{t('common.address')}</Divider>

            <RadioGroup
              row
              sx={{ mb: 5 }}
              value={requestData.addressOption}
              onChange={onChangeAddressOption}
            >
              <FormControlLabel
                value={ADDRESS_OPTION.MY_ADDRESS}
                control={<Radio />}
                label={t('session.my_address')}
              />
              <FormControlLabel
                value={ADDRESS_OPTION.OTHER_LOCATION}
                control={<Radio />}
                label={t('session.other_location')}
              />
            </RadioGroup>

            {requestData.addressOption === ADDRESS_OPTION.MY_ADDRESS ? (
              <AddressPreview
                address={{ ...form.values, ...location } as AddressModel}
                addressType={AddressType.USER_ADDRESS}
              />
            ) : (
              <SpaceAddressForm
                form={form}
                location={location}
                setLocation={setLocation}
                isNew={true}
              />
            )}

            <Divider sx={{ my: 5 }}>{t('session.my_preferences')}</Divider>

            <UserPreference form={form} />
          </Box>

          <div className="d-flex align-items-center justify-content-center mt-15">
            <Button type="submit" variant="blue">
              {t('common.next')}
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default RequestSpace;
