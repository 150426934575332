export enum DEMAND_ACTIONS {
  CREATE_DEMAND_REQUEST = 'CREATE_DEMAND_REQUEST',
  CREATE_DEMAND_SUCCESS = 'CREATE_DEMAND_SUCCESS',
  CREATE_DEMAND_ERROR = 'CREATE_DEMAND_ERROR',
  GET_DEMANDS_REQUEST = 'GET_DEMANDS_REQUEST',
  GET_DEMANDS_SUCCESS = 'GET_DEMANDS_SUCCESS',
  GET_DEMANDS_ERROR = 'GET_DEMANDS_ERROR',
  GET_PENDING_DEMANDS_REQUEST = 'GET_PENDING_DEMANDS_REQUEST',
  GET_PENDING_DEMANDS_SUCCESS = 'GET_PENDING_DEMANDS_SUCCESS',
  GET_PENDING_DEMANDS_ERROR = 'GET_PENDING_DEMANDS_ERROR',
  REMOVE_DEMAND_REQUEST = 'REMOVE_DEMAND_REQUEST',
  REMOVE_DEMAND_SUCCESS = 'REMOVE_DEMAND_SUCCESS',
  REMOVE_DEMAND_ERROR = 'REMOVE_DEMAND_ERROR',
}
