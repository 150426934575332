import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  createDemandAction,
  getDemandsAction,
  getPendingDemandsAction,
  removeDemandAction,
} from '@/redux/actions';

export const useDemandState = () =>
  useSelector(({ demandReducer }: RootState) => demandReducer);

export const useCreateDemandAction = () => {
  const dispatch = useDispatch();
  return (data: any) => dispatch(createDemandAction(data));
};

export const useGetDemandsAction = () => {
  const dispatch = useDispatch();
  return (data: any = {}) => dispatch(getDemandsAction(data));
};

export const useGetPendingDemandCount = () => {
  const dispatch = useDispatch();
  return () => dispatch(getPendingDemandsAction());
};

export const useRemoveDemandAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(removeDemandAction(id));
};
