import React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  AccountCircle,
  Group as GroupIcon,
  Home as HomeIcon,
  OpenInNew,
  Receipt,
  ViewModule,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { COMPANY_ACCOUNT_TABS, ROUTES } from '@/constants';
import { MenuLink } from './styles';

const CompanyMenu = () => {
  const [t] = useTranslation('common');
  const location = useLocation();

  return (
    <Box sx={{ p: '10px 30px' }}>
      <MenuLink
        className={classnames({
          active: location.pathname === ROUTES.COMPANY.DASHBOARD,
        })}
        to={ROUTES.COMPANY.DASHBOARD}
      >
        <HomeIcon className="menu-icon" />
        <p className="menu-title">{t('app.home')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          active: location.pathname.includes(ROUTES.COMPANY.USERS.PREFIX),
        })}
        to={ROUTES.COMPANY.USERS.INDEX}
      >
        <GroupIcon className="menu-icon" />
        <p className="menu-title">{t('common.users')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          active: location.pathname === ROUTES.COMPANY.SESSIONS.INDEX,
        })}
        to={ROUTES.COMPANY.SESSIONS.INDEX}
      >
        <ViewModule className="menu-icon" />
        <p className="menu-title">{t('app.sessions')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          active: location.pathname === ROUTES.COMPANY.ACTIVITIES.INDEX,
        })}
        to={ROUTES.COMPANY.ACTIVITIES.INDEX}
      >
        <OpenInNew className="menu-icon" />
        <p className="menu-title">{t('app.activities')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          active: location.pathname === ROUTES.COMPANY.KPIS.INDEX,
        })}
        to={ROUTES.COMPANY.KPIS.INDEX}
      >
        <Receipt className="menu-icon" />
        <p className="menu-title">{t('app.kpis')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          active: location.pathname.includes(ROUTES.COMPANY.ACCOUNT.PREFIX),
        })}
        to={`${ROUTES.COMPANY.ACCOUNT.PREFIX}/${COMPANY_ACCOUNT_TABS.PROFILE}`}
      >
        <AccountCircle className="menu-icon" />
        <p className="menu-title">{t('app.account')}</p>
      </MenuLink>
    </Box>
  );
};

export default CompanyMenu;
