import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatisticBoxStyled } from './styles';
import { Apartment, ViewModule, Work } from '@mui/icons-material';
import colors from '@/themes/colors';
import {
  useDemandState,
  useGetCompanyScheduledSessionsCount,
  useGetPendingDemandCount,
  useGetPendingOfferCount,
  useOfferState,
  useSessionState,
} from '@/hooks/redux';

const CompanyStatistics = () => {
  const [t] = useTranslation('common');

  const { loadingPendingDemands, pendingDemandCount } = useDemandState();
  const { loadingPendingOffers, pendingOfferCount } = useOfferState();
  const { loadingCompanyScheduledSessions, companyScheduledSessionsCount } =
    useSessionState();

  const getPendingDemandsCount = useGetPendingDemandCount();
  const getPendingOffersCount = useGetPendingOfferCount();
  const getCompanyScheduledSessionsCount =
    useGetCompanyScheduledSessionsCount();

  useEffect(() => {
    getPendingDemandsCount();
    getPendingOffersCount();
    getCompanyScheduledSessionsCount();
  }, []);

  return (
    <Box
      sx={{
        mt: '100px',
      }}
    >
      <Typography sx={{}} variant="body1">
        {t('app.activity')}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          contentJustify: 'center',
        }}
      >
        <StatisticBoxStyled>
          <Work style={{ color: colors.btn_blue_bg, fontSize: '66px' }} />
          <div className="counter">
            <div className="counter-value">
              {loadingPendingOffers ? <CircularProgress /> : pendingOfferCount}
            </div>
            <div className="counter-label">{t('app.pending_offers')}</div>
          </div>
        </StatisticBoxStyled>
        <StatisticBoxStyled>
          <Apartment style={{ color: colors.btn_blue_bg, fontSize: '66px' }} />
          <div className="counter">
            <div className="counter-value">
              {loadingPendingDemands ? (
                <CircularProgress />
              ) : (
                pendingDemandCount
              )}
            </div>
            <div className="counter-label">{t('app.pending_requests')}</div>
          </div>
        </StatisticBoxStyled>
        <StatisticBoxStyled>
          <ViewModule style={{ color: colors.btn_blue_bg, fontSize: '66px' }} />
          <div className="counter">
            <div className="counter-value">
              {loadingCompanyScheduledSessions ? (
                <CircularProgress />
              ) : (
                companyScheduledSessionsCount
              )}
            </div>
            <div className="counter-label">{t('app.scheduled_sessions')}</div>
          </div>
        </StatisticBoxStyled>
      </Box>
    </Box>
  );
};

export default CompanyStatistics;
