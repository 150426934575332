import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box, Typography } from '@mui/material';
import { Button, SortOption } from '@/components';
import { ROUTES } from '@/constants';
import {
  useCompanyState,
  useSearchCompaniesAction,
} from '@/hooks/redux/company';
import { ObjectHelper } from '@/utils';
import CompanyFilters from '@/pages/Admin/Companies/CompanyFilters';
import CompanyTable from '@/pages/Admin/Companies/CompanyTable';

const Companies = () => {
  const history = useHistory();
  const [t] = useTranslation('common');
  const searchCompanies = useSearchCompaniesAction();
  const { loading, companies, totalCount } = useCompanyState();

  const [sortOption, setSortOption] = useState<SortOption>({
    order: 'desc',
    orderBy: 'createdAt',
  });
  const [filter, setFilter] = useState({
    search: '',
    page: 0,
    perPage: 10,
  });

  const onChangeFilter = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const reload = () => {
    onChangeFilter('page', 0);
  };

  useEffect(() => {
    const query = ObjectHelper.trimQuery({
      ...filter,
      ...sortOption,
    });

    searchCompanies(query);
  }, [filter, sortOption]);

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {t('companies.title')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 10,
        }}
      >
        <CompanyFilters filter={filter} onChangeFilter={onChangeFilter} />
        <Button onClick={() => history.push(ROUTES.ADMIN.COMPANIES.CREATE)}>
          {t('companies.add_new_company')}
        </Button>
      </Box>

      <CompanyTable
        companies={companies}
        reload={reload}
        loading={loading}
        sortOption={sortOption}
        setSortOption={setSortOption}
        pagination={{
          total: totalCount,
          page: filter.page,
          perPage: filter.perPage,
          onPageChange: (page) => onChangeFilter('page', page),
        }}
      />
    </div>
  );
};

export default Companies;
