import { CountryModel } from '@/resources/models';
import { COMMON_ACTIONS } from '@/redux/action-types';

export interface CommonState {
  countries: CountryModel[];
}

const initialState: CommonState = {
  countries: [],
};

const commonReducer = (state: CommonState = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case COMMON_ACTIONS.GET_COUNTRIES: {
      return {
        ...state,
        countries: payload.countries,
      };
    }

    default:
      return state;
  }
};

export default commonReducer;
