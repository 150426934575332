import React, { FC } from 'react';
import AddFilterButton from '@/pages/Company/Activities/ActivityFilters/AddFilterButton';
import SearchFilter from '@/parts/Filters/SearchFilter';

type FilterType = 'search' | 'select';

export interface FilterOption {
  type: FilterType;
  value: any;
  label?: string;
  placeholder?: string;
}

interface FiltersProps {
  filterOptions: FilterOption[];
  currentFilterOptions: FilterOption[];
  setCurrentFilterOptions: (value: FilterOption[]) => void;
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
  showFilterButton?: boolean;
}

const Filters: FC<FiltersProps> = ({
  filterOptions,
  currentFilterOptions,
  onChangeFilter,
  showFilterButton = true,
}) => {
  const renderFilter = (filter: FilterOption) => {
    switch (filter.type) {
      case 'search':
        return <SearchFilter filter={filter} onChangeFilter={onChangeFilter} />;

      default:
        return null;
    }
  };

  return (
    <div className="d-flex align-items-center">
      {filterOptions.map((item, index) => {
        return (
          <React.Fragment key={index}>{renderFilter(item)}</React.Fragment>
        );
      })}

      {showFilterButton && <AddFilterButton />}
    </div>
  );
};

export default Filters;
