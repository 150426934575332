import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@/components';
import { useCommonState, useUpdateCompanyAccount } from '@/hooks/redux';
import UrbanAreasForm from 'src/parts/PostCompany/UrbanAreaUnits/UrbanAreasForm';
import UnitsForm from '@/parts/PostCompany/UrbanAreaUnits/UnitsForm';
import { useUrbanAreasByCountry } from '@/hooks/app/useUrbanAreasByCountry';
import { useUrbanAreasOptions } from '@/hooks/app/useUrbanAreasOptions';

export const urbanAreaUnitValidation = {
  urbanAreas: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().trim().required('validation.required_field'),
    }),
  ),
  units: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().trim().required('validation.required_field'),
      // urbanAreas: Yup.array().of(
      //   Yup.object().shape({
      //     name: Yup.string().trim().required('validation.required_field'),
      //   }),
      // ),
    }),
  ),
};

const validationSchema = Yup.object().shape(urbanAreaUnitValidation);

interface UrbanAreaUnitsProps {
  onBack: () => void;
  onNext: () => void;
  companyData: any;
  setCompanyData: (value: any) => void;
}

const UrbanAreaUnits: FC<UrbanAreaUnitsProps> = ({
  onBack,
  onNext,
  companyData,
  setCompanyData,
}) => {
  const [t] = useTranslation('common');
  const { loading } = useUpdateCompanyAccount();
  const { countries } = useCommonState();

  const countryId =
    typeof companyData.address.country === 'string'
      ? companyData.address.country
      : companyData.address.country.id;
  const country = countries.find((c) => c.id === countryId);

  const city = companyData.address.city;

  const { urbanAreasByCountry } = useUrbanAreasByCountry(country.code);
  const { urbanAreasOptions } = useUrbanAreasOptions(urbanAreasByCountry);

  const onSubmit = async (values: any) => {
    setCompanyData({
      ...companyData,
      urbanAreas: values.urbanAreas,
      units: values.units,
    });
    onNext();
  };

  const getDefaultUrbanArea = () => {
    const DUA = urbanAreasOptions.find(
      (UA) => UA.label.toLowerCase() === city.toLowerCase(),
    );
    return { index: 0, id: DUA ? DUA.value : '' };
  };

  const form = useFormik({
    validationSchema,
    onSubmit,
    initialValues: {
      urbanAreas: companyData.urbanAreas.length
        ? companyData.urbanAreas
        : [getDefaultUrbanArea()],
      units: companyData.units,
    },
  });

  return (
    <Box sx={{ mx: 'auto', maxWidth: '900px' }}>
      <form onSubmit={form.handleSubmit}>
        <UrbanAreasForm form={form} urbanAreasOptions={urbanAreasOptions} />

        <UnitsForm form={form} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{ mr: 5 }}
            variant="outlined"
            onClick={onBack}
            type="button"
          >
            {t('common.back')}
          </Button>
          <Button type="submit" variant="blue" loading={loading}>
            {t('common.next')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UrbanAreaUnits;
