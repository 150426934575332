import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { CompanyModel } from '@/resources/models';
import CompanyDetailDialog from './CompanyDetailDialog';

interface CompanyDetailButtonProps {
  company: CompanyModel;
}

const CompanyDetailButton: FC<CompanyDetailButtonProps> = ({
  company,
 }) => {
  const [t] = useTranslation('common');
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Tooltip title={t('common.show_detail')} >
        <IconButton color="primary" onClick={() => setShowDialog(true)}>
          <Visibility />
        </IconButton>
      </Tooltip>

      <CompanyDetailDialog
        company={company}
        open={showDialog}
        handleClose={() => setShowDialog(false)}
      />
    </>
  );
};

export default CompanyDetailButton;
