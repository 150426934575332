import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Filters, { FilterOption } from '@/parts/Filters';

interface CompanyFiltersProps {
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
}

const CompanyFilters: FC<CompanyFiltersProps> = ({
  filter,
  onChangeFilter,
}) => {
  const [t] = useTranslation('common');

  const [currentFilterOptions, setCurrentFilterOptions] = useState<FilterOption[]>([
    { type: 'search', value: '', placeholder: t('companies.company_search_placeholder') }
  ]);

  const filterOptions = useMemo<FilterOption[]>(() => ([
    { type: 'search', value: '', placeholder: t('companies.company_search_placeholder') },
  ]), []);

  return (
    <div>
      <Filters
        filterOptions={filterOptions}
        currentFilterOptions={currentFilterOptions}
        setCurrentFilterOptions={setCurrentFilterOptions}
        filter={filter}
        onChangeFilter={onChangeFilter}
        showFilterButton={false}
      />
    </div>
  );
};

export default CompanyFilters;
