import React, { FC, useEffect, useState } from 'react';
import { useGetCountriesAction, useGetUrbanAreasAction } from '@/hooks/redux';
import { LoadingBox } from '@/components';

const Preloader: FC = ({ children }) => {
  const getCountries = useGetCountriesAction();
  const getUrbanAreas = useGetUrbanAreasAction();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([getCountries(), getUrbanAreas()]).then(() => {
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <LoadingBox />;
  }

  return <>{children}</>;
};

export default Preloader;
