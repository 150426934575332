import React from 'react';
import { Button } from '@mui/material';
import { BannerBoxStyled, BannerQuestionStyled } from '@/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from '@/constants';

export const AdminDashboardNewCompanyBanner = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  return (
    <BannerBoxStyled direction='left'>
      <BannerQuestionStyled sx={{mb: '30px'}}>
        {t('app.want_add_company')}
      </BannerQuestionStyled>
      <Button
        size='large'
        onClick={ () => history.push(ROUTES.ADMIN.COMPANIES.CREATE)}
      >
        {t('companies.add_new_company')}
      </Button>
    </BannerBoxStyled>
  );
}

export default AdminDashboardNewCompanyBanner;
