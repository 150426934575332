import {
  CompanyModel,
  UnitModel,
  UrbanAreaModel,
  UserModel,
} from '@/resources/models';
import { ACCOUNT_ACTIONS } from '@/redux/action-types';

export interface AccountState {
  company: CompanyModel | undefined;
  loadingCompanyInfo: boolean;
  companyUsers: UserModel[];
  companyUnits: UnitModel[];
  companyUrbanAreas: UrbanAreaModel[];

  updatingProfile: boolean;
}

const initialState: AccountState = {
  company: undefined,
  companyUsers: [],
  companyUnits: [],
  companyUrbanAreas: [],
  loadingCompanyInfo: false,

  updatingProfile: false,
};

const accountReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case ACCOUNT_ACTIONS.GET_ACCOUNT_COMPANY_INFO_REQUEST: {
      return {
        ...state,
        loadingCompanyInfo: true,
      };
    }

    case ACCOUNT_ACTIONS.GET_ACCOUNT_COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        loadingCompanyInfo: false,
        companyUsers: payload.users,
        companyUnits: payload.units,
        companyUrbanAreas: payload.urbanAreas,
      };
    }

    case ACCOUNT_ACTIONS.GET_ACCOUNT_COMPANY_INFO_ERROR: {
      return {
        ...state,
        loadingCompanyInfo: false,
      };
    }

    case ACCOUNT_ACTIONS.UPDATE_PROFILE_REQUEST: {
      return {
        ...state,
        updatingProfile: true,
      };
    }

    case ACCOUNT_ACTIONS.UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        updatingProfile: false,
      };
    }

    case ACCOUNT_ACTIONS.UPDATE_PROFILE_ERROR: {
      return {
        ...state,
        updatingProfile: false,
      };
    }

    default:
      return state;
  }
};

export default accountReducer;
