import React, { FC, useMemo, useState } from 'react';
import Filters, { FilterOption } from '@/parts/Filters';

interface NotificationFiltersProps {
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
}

const NotificationFilters: FC<NotificationFiltersProps> = ({
  filter,
  onChangeFilter,
}) => {
  const [currentFilterOptions, setCurrentFilterOptions] = useState<FilterOption[]>([
    { type: 'search', value: '', placeholder: 'Search for notifications' }
  ]);

  const filterOptions = useMemo<FilterOption[]>(() => ([
    { type: 'search', value: '' },
  ]), []);

  return (
    <div>
      <Filters
        filterOptions={filterOptions}
        currentFilterOptions={currentFilterOptions}
        setCurrentFilterOptions={setCurrentFilterOptions}
        filter={filter}
        onChangeFilter={onChangeFilter}
        showFilterButton={false}
      />
    </div>
  );
};

export default NotificationFilters;
