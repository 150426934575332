export enum OFFER_ACTIONS {
  CREATE_OFFER_REQUEST = 'CREATE_OFFER_REQUEST',
  CREATE_OFFER_SUCCESS = 'CREATE_OFFER_SUCCESS',
  CREATE_OFFER_ERROR = 'CREATE_OFFER_ERROR',
  GET_OFFERS_REQUEST = 'GET_OFFERS_REQUEST',
  GET_OFFERS_SUCCESS = 'GET_OFFERS_SUCCESS',
  GET_OFFERS_ERROR = 'GET_OFFERS_ERROR',
  GET_PENDING_OFFERS_REQUEST = 'GET_PENDING_OFFERS_REQUEST',
  GET_PENDING_OFFERS_SUCCESS = 'GET_PENDING_OFFERS_SUCCESS',
  GET_PENDING_OFFERS_ERROR = 'GET_PENDING_OFFERS_ERROR',
  REMOVE_OFFER_REQUEST = 'REMOVE_OFFER_REQUEST',
  REMOVE_OFFER_SUCCESS = 'REMOVE_OFFER_SUCCESS',
  REMOVE_OFFER_ERROR = 'REMOVE_OFFER_ERROR',
}
