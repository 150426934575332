import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent } from '@mui/material';
import { Dialog } from '@/components';
import { SpaceModel } from '@/resources/models';
import SpacePreview from '@/parts/SpacePreviewForm/SpacePreview';

interface SpaceDetailDialogProps {
  space: SpaceModel;
  open: boolean;
  handleClose: () => void;
}

const SpaceDetailDialog: FC<SpaceDetailDialogProps> = ({
  space,
  open,
  handleClose,
}) => {
  const [t] = useTranslation('common');

  return (
    <Dialog open={open} onClose={handleClose} title={t('space.space_detail')}>
      <DialogContent sx={{ py: 5 }}>
        <SpacePreview space={space} />
      </DialogContent>
    </Dialog>
  );
};

export default SpaceDetailDialog;
