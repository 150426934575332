import React from 'react';
import { Box, Grow } from '@mui/material';

const FormSaved = () => (
  <Grow in={true}>
    <Box
      sx={{
        backgroundImage: 'url(/assets/images/form-saved.png)',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '178px',
        height: '186px',
        maxWidth: 'calc(100vw - 25%)',
        maxHeight: 'calc(100vw - 25%)',
        mt: 12,
        mx: 'auto',
      }}
    ></Box>
  </Grow>
);

export default FormSaved;
