import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IconButton, Tooltip } from '@mui/material';
import { Edit } from '@mui/icons-material';
import {
  PaginationOptions,
  SortOption,
  Table,
  TableColumn,
} from '@/components';
import {
  AddressModel,
  UnitModel,
  UrbanAreaModel,
  UserModel,
} from '@/resources/models';
import { DateHelper } from '@/utils';
import { ROUTES } from '@/constants';
import { ROLE } from '@/resources/enums';
import RemoveUserButton from './RemoveUserButton';
import ActiveUserButton from './ActiveUserButton';
import UserDetailButton from './UserDetailButton';
import { useAuthState } from '@/hooks/redux';

export enum USER_TABLE_TYPE {
  ADMIN = 'ADMIN',
  COMPANY = 'COMPANY',
}

interface UserTableProps {
  users: UserModel[];
  onRemoveUser: () => void;
  loading?: boolean;
  pagination?: PaginationOptions;
  sortOption?: SortOption;
  setSortOption?: (value: SortOption) => void;
  type?: USER_TABLE_TYPE;
}

const UsersTable: FC<UserTableProps> = ({
  users,
  onRemoveUser,
  loading,
  type = USER_TABLE_TYPE.ADMIN,
  pagination,
  sortOption,
  setSortOption,
}) => {
  const history = useHistory();
  const [t] = useTranslation('common');
  const { currentRole } = useAuthState();

  const tableColumns: TableColumn[] = useMemo(() => {
    return [
      { title: 'common.name', dataIndex: 'name', sortable: true },
      { title: 'common.unit', dataIndex: 'unit' },
      {
        title: 'common.urban_area',
        dataIndex: 'address',
        render: (user: UserModel) => {
          const address = user.address as AddressModel;
          const urbanArea = address.urbanArea as UrbanAreaModel;

          return urbanArea?.name;
        },
      },
      { title: 'common.email', dataIndex: 'email' },
      ...(type === USER_TABLE_TYPE.ADMIN
        ? [{ title: 'common.roles', dataIndex: 'roles' }]
        : []),
      { title: 'common.mobile_phone', dataIndex: 'mobilePhone' },
      {
        title: 'common.gender',
        dataIndex: 'gender',
        render: (data: any) => (
          <span className="text-capitalize">{data.gender}</span>
        ),
      },
      { title: 'common.birthday', dataIndex: 'birthday' },
      { title: 'common.created_at', dataIndex: 'createdAt', sortable: true },
      {
        title: '',
        dataIndex: 'id',
        align: 'right',
        render: (user: any) => (
          <>
            <UserDetailButton user={user} />

            <Tooltip title={t('user.edit_user')}>
              <IconButton
                color="primary"
                onClick={() =>
                  history.push(`${ROUTES.COMPANY.USERS.PREFIX}/${user.id}`)
                }
                disabled={
                  currentRole === ROLE.COMPANY &&
                  user.roles.includes(ROLE.COMPANY)
                }
              >
                <Edit />
              </IconButton>
            </Tooltip>

            <ActiveUserButton user={user} />

            <RemoveUserButton user={user} onRemoveUser={onRemoveUser} />
          </>
        ),
      },
    ];
  }, [onRemoveUser, type]);

  const data = useMemo(() => {
    return users.map((user) => {
      const unit = user.unit as UnitModel;

      const roles = user.roles.map((item) => {
        if (item === ROLE.ADMIN) return 'Super Admin';
        if (item === ROLE.COMPANY) return 'Company Admin';
        return 'User';
      });

      return {
        ...user,
        name: user.fullName,
        unit: unit?.name,
        roles: roles.join(', '),
        birthday: DateHelper.formatDate(user.birthday, 'DD/MM/YYYY'),
        createdAt: DateHelper.formatDate(user.createdAt, 'DD/MM/YYYY'),
      };
    });
  }, [users]);

  return (
    <Table
      columns={tableColumns}
      data={data}
      loading={loading}
      pagination={pagination}
      sortOption={sortOption}
      setSortOption={setSortOption}
    />
  );
};

export default UsersTable;
