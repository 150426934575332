import React from 'react';
import { useTranslation } from 'react-i18next';
import { PoweredByStyled } from '@/parts/SidebarLeft/styles';
import { Box, Typography } from '@mui/material';
import NewLogo from '@/parts/NewLogo';
import colors from '@/themes/colors';

const PoweredBy = () => {
  const [t] = useTranslation('common');

  return (
    <PoweredByStyled>
      {t('app.powered_by')}:
      <Box sx={{ mt: '15px', display: 'flex', alignItems: 'center', gap: 1 }}>
        <NewLogo fillC={colors.text_grey} />
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: colors.text_grey,
            fontFamily: 'Stolzl',
            fontSize: '1em',
          }}
          variant="h5"
        >
          coremoting
        </Typography>
        {/*<Logo fill="" />*/}
      </Box>
    </PoweredByStyled>
  );
};

export default PoweredBy;
