import { createTheme } from '@mui/material';

import breakpoints from '@/themes/breakpoints';
import components from '@/themes/components';
import palette from '@/themes/palette';
import typography from '@/themes/typography';
import colors from '@/themes/colors';

const theme = createTheme({
  typography,
  breakpoints,
  colors,
  palette,
  components,
  spacing: (factor) => `${0.25 * factor}rem`,
});

export default theme;
