import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@/components';
import { COOKIE_CONSENT_NAME, ROUTES } from '@/constants';
import { useLoginAction } from '@/hooks/redux/auth';
import colors from '@/themes/colors';
import { LinkGreyStyled } from '@/pages/Auth/styles';
import { useCookies } from 'react-cookie';
import { useShowToastAction } from '@/hooks/redux';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('validation.email_invalid')
    .required('validation.email_required'),
  password: Yup.string().required('validation.password_required'),
});

const Login = () => {
  const [t] = useTranslation('common');
  const login = useLoginAction();
  const showToast = useShowToastAction();
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies([COOKIE_CONSENT_NAME]);

  const handleSubmit = async (values: any) => {
    if (cookies[COOKIE_CONSENT_NAME] !== true) {
      showToast(t('cookie_consent.need_accept_cookies_before'), 'error');
      return;
    }
    const { email, password } = values;

    setLoading(true);
    await login(email, password);
    setLoading(false);
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Typography variant="h4" color={colors.text} mb={2}>
        {t('auth.enter')}
      </Typography>
      <Typography variant="body1" color={colors.text} mb={4}>
        {t('auth.welcome_message')}
      </Typography>

      <form onSubmit={form.handleSubmit}>
        <Input
          fullWidth
          label={t('common.email')}
          placeholder={t('auth.email_placeholder')}
          sx={{ mb: 10 }}
          {...form.getFieldProps('email')}
          errorText={
            form.errors.email && form.touched.email
              ? t(form.errors.email as string)
              : ''
          }
        />

        <Input
          fullWidth
          type="password"
          label={t('common.password')}
          placeholder={t('auth.password_placeholder')}
          sx={{ mb: 1 }}
          {...form.getFieldProps('password')}
          errorText={
            form.errors.password && form.touched.password
              ? t(form.errors.password as string)
              : ''
          }
        />

        <div className="text-left mb-15">
          <LinkGreyStyled to={ROUTES.AUTH.FORGOT_PASSWORD}>
            {t('auth.forgot_password_question')}
          </LinkGreyStyled>
        </div>

        <Button fullWidth type="submit" variant="auth" loading={loading}>
          {t('auth.login')}
        </Button>
      </form>
    </>
  );
};

export default Login;
