import { DEMAND_ACTIONS } from '@/redux/action-types';
import { DemandModel } from '@/resources/models';

export interface DemandState {
  creating: boolean;

  loading: boolean;
  demands: DemandModel[];
  totalCount: number;

  loadingPendingDemands: boolean;
  pendingDemandCount: number;

  deleting: boolean;
}

const initialState: DemandState = {
  creating: false,

  loading: false,
  demands: [],
  totalCount: 0,

  loadingPendingDemands: false,
  pendingDemandCount: 0,

  deleting: false,
};

const demandReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case DEMAND_ACTIONS.CREATE_DEMAND_REQUEST: {
      return {
        ...state,
        creating: true,
      };
    }

    case DEMAND_ACTIONS.CREATE_DEMAND_SUCCESS: {
      return {
        ...state,
        creating: false,
      };
    }

    case DEMAND_ACTIONS.CREATE_DEMAND_ERROR: {
      return {
        ...state,
        creating: false,
      };
    }

    case DEMAND_ACTIONS.GET_DEMANDS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case DEMAND_ACTIONS.GET_DEMANDS_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalCount: payload.totalCount,
        demands: payload.demands,
      };
    }

    case DEMAND_ACTIONS.GET_DEMANDS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case DEMAND_ACTIONS.GET_PENDING_DEMANDS_REQUEST: {
      return {
        ...state,
        loadingPendingDemands: true,
      };
    }

    case DEMAND_ACTIONS.GET_PENDING_DEMANDS_SUCCESS: {
      return {
        ...state,
        pendingDemandCount: payload.count,
        loadingPendingDemands: false,
      };
    }

    case DEMAND_ACTIONS.GET_PENDING_DEMANDS_ERROR: {
      return {
        ...state,
        loadingPendingDemands: false,
      };
    }

    case DEMAND_ACTIONS.REMOVE_DEMAND_REQUEST: {
      return {
        ...state,
        deleting: true,
      };
    }

    case DEMAND_ACTIONS.REMOVE_DEMAND_SUCCESS: {
      return {
        ...state,
        deleting: false,
      };
    }

    case DEMAND_ACTIONS.REMOVE_DEMAND_ERROR: {
      return {
        ...state,
        deleting: false,
      };
    }

    default:
      return state;
  }
};

export default demandReducer;
