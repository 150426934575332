export class CountryModel {
  id: string;
  name: string;
  code: string;
  prefix: string;

  constructor(init: any = {}) {
    const data = {
      id: '',
      name: '',
      code: '',
      prefix: '',
      ...init,
    };

    this.id = data.id || data._id;
    this.name = data.name;
    this.code = data.code;
    this.prefix = data.prefix;
  }
}
