import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import OfferSpace from '@/pages/User/OfferSession/OfferSpace';
import OfferTime from '@/pages/User/OfferSession/OfferTime';
import OfferGuests from '@/pages/User/OfferSession/OfferGuests';
import {
  useAuthState,
  useCreateOfferAction,
  useFindCompanyAction,
  useGetPendingDemandCount,
  useGetSpacesAction,
  useShowToastAction,
  useSpaceState,
} from '@/hooks/redux';
import { SessionPeriod } from '@/resources/interfaces';
import { ObjectHelper } from '@/utils';
import { ROUTES, USER_ACTIVITY_TABS } from '@/constants';

export enum OFFER_SESSION_STEPS {
  SPACE,
  TIME,
  GUESTS,
}

const steps = [
  { title: 'app.space', step: OFFER_SESSION_STEPS.SPACE },
  { title: 'app.time', step: OFFER_SESSION_STEPS.TIME },
  { title: 'app.guests', step: OFFER_SESSION_STEPS.GUESTS },
];

const OfferSession = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  const getPendingDemandsCount = useGetPendingDemandCount();
  const createOffer = useCreateOfferAction();
  const showToast = useShowToastAction();
  const findCompany = useFindCompanyAction();
  const getSpacesAction = useGetSpacesAction();

  const { account } = useAuthState();
  const { spaces, loading: loadingSpaces } = useSpaceState();

  const [activeStep, setActiveStep] = useState(OFFER_SESSION_STEPS.SPACE);
  const [offerData, setOfferData] = useState({
    spaceId: '',
    periods: [
      {
        id: '1',
        date: moment(),
        startTime: moment(),
        endTime: moment(),
      },
    ],
    guests: {
      names: { value: '', mandatory: false },
      units: { value: '', mandatory: false },
      gender: { value: false, mandatory: false },
      aroundAge: { value: false, mandatory: false },
    },
  });

  const onOfferSession = async (data: any) => {
    ObjectHelper.convertSessionPeriod(data.periods as SessionPeriod[]);

    const res = (await createOffer(data)) as any;

    if (res) {
      if (res.scheduled) {
        showToast(t('session.session_created_by_offer'));
        history.push(
          `${ROUTES.USER.ACTIVITY.PREFIX}/${USER_ACTIVITY_TABS.WORKING}`,
        );
      } else {
        showToast(t('session.offer_created_message'));
        history.push(
          `${ROUTES.USER.ACTIVITY.PREFIX}/${USER_ACTIVITY_TABS.OFFERS}`,
        );
      }
    }
  };

  useEffect(() => {
    getPendingDemandsCount();
    findCompany(
      typeof account.company === 'string'
        ? account.company
        : account.company.id,
    );
    getSpacesAction();
  }, []);

  useEffect(() => {
    if (loadingSpaces === false && spaces.length === 0) {
      showToast(
        t('session.create_at_least_one_space_before_creating_offer'),
        'info',
      );
      history.push(ROUTES.USER.SPACES.CREATE);
    }
  }, [spaces, loadingSpaces]);

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {t('app.offers_sessions')}
      </Typography>

      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 10 }}>
        {steps.map((item) => (
          <Step key={item.step}>
            <StepLabel>{t(item.title)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box>
        {activeStep === OFFER_SESSION_STEPS.SPACE && (
          <OfferSpace
            offerData={offerData}
            setOfferData={setOfferData}
            onNext={() => setActiveStep(OFFER_SESSION_STEPS.TIME)}
          />
        )}

        {activeStep === OFFER_SESSION_STEPS.TIME && (
          <OfferTime
            offerData={offerData}
            setOfferData={setOfferData}
            onBack={() => setActiveStep(OFFER_SESSION_STEPS.SPACE)}
            onNext={() => setActiveStep(OFFER_SESSION_STEPS.GUESTS)}
          />
        )}

        {activeStep === OFFER_SESSION_STEPS.GUESTS && (
          <OfferGuests
            offerData={offerData}
            setOfferData={setOfferData}
            onBack={() => setActiveStep(OFFER_SESSION_STEPS.TIME)}
            onOffer={onOfferSession}
          />
        )}
      </Box>
    </div>
  );
};

export default OfferSession;
