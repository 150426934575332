import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Divider } from '@mui/material';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  AddressModel,
  CompanyModel,
  UnitModel,
  UrbanAreaModel,
  UserModel,
} from '@/resources/models';
import { Button } from '@/components';
import {
  useAccountState,
  useAuthState,
  useCompanyState,
  useCreateUserAction,
  useFindCompanyAction,
  useShowToastAction,
  useUpdateProfile,
  useUpdateUserAction,
  useUserState,
} from '@/hooks/redux';
import { FormHelper, ObjectHelper } from '@/utils';
import { ROUTES } from '@/constants';
import { GENDER, ROLE } from '@/resources/enums';
import { useCountryOptions } from '@/hooks/app';
import { addressFormValidation, userFormValidation } from '@/validations';
import UserForm from '@/parts/UserForm';
import SpaceAddressForm from '@/parts/SpaceAddressForm';

interface PostUserProps {
  user?: UserModel;
  profileForm?: boolean;
}

const PostUser: FC<PostUserProps> = ({ user, profileForm = false }) => {
  const [t] = useTranslation('common');

  const { currentRole } = useAuthState();

  const validationSchema = Yup.object().shape({
    ...addressFormValidation,
    ...userFormValidation,
    ...(currentRole === ROLE.COMPANY
      ? {
          roles: Yup.string().required('validation.required_field'),
        }
      : {}),
  });

  const address = user?.address as AddressModel;

  const history = useHistory();
  const { defaultCountryId } = useCountryOptions();
  const { creating, updating } = useUserState();
  const [companyFUAName, setCompanyFUAName] = useState<UrbanAreaModel>();

  const updateUser = useUpdateUserAction();
  const createUser = useCreateUserAction();

  const { updatingProfile } = useAccountState();
  const { account } = useAuthState();

  const showToast = useShowToastAction();
  const updateProfile = useUpdateProfile();

  const [location, setLocation] = useState({
    lat: address?.lat,
    lng: address?.lng,
  });

  const { company } = useCompanyState();
  const findCompany = useFindCompanyAction();

  useEffect(() => {
    if (user?.company) {
      const companyId =
        typeof user.company === 'string' ? user.company : user.company.id;
      findCompany(companyId);
    }
  }, [user]);

  useEffect(() => {
    if (account.company) {
      const company = account.company as CompanyModel;
      const FUA = company.urbanAreas[0] as UrbanAreaModel;
      setCompanyFUAName(FUA);
    } else if (company) {
      setCompanyFUAName(company.urbanAreas[0] as UrbanAreaModel);
    }
  }, [company, account]);

  useEffect(() => {
    if (form) {
      form.setFieldValue(
        'urbanArea',
        companyFUAName ? companyFUAName.name : '',
      );
    }
  }, [companyFUAName]);

  const handleSubmit = async (values: any) => {
    const {
      firstName,
      lastName,
      gender,
      email,
      birthday,
      mobilePhone,
      unit,
      country,
      postalCode,
      city,
      street,
      numberOfStreet,
      description,
      roles,
    } = values;

    if (!account.roles.includes(ROLE.ADMIN)) {
      if (
        country !==
        ((account.company as CompanyModel).address as AddressModel).country
      ) {
        showToast(
          t('common.please_use_country_corresponding_to_urban_area'),
          'error',
        );
        return;
      }
    }

    const { lat, lng } = location;

    const data = {
      user: {
        firstName,
        lastName,
        gender,
        email,
        unit,
        birthday: moment(birthday).toISOString(),
        mobilePhone: Number(mobilePhone),
        roles: Array.isArray(roles) ? roles : [roles],
      },
      address: {
        country,
        postalCode,
        city,
        street,
        numberOfStreet,
        description,
        lat,
        lng,
      },
    };

    if (profileForm) {
      const res = await updateProfile(ObjectHelper.trimQuery(data));
      if (res) {
        showToast(t('user.profile_updated_message'));
      }
    } else {
      const result = await (user
        ? updateUser(user.id, ObjectHelper.trimQuery(data))
        : createUser(ObjectHelper.trimQuery(data)));

      if (result) {
        const route =
          currentRole === ROLE.ADMIN
            ? ROUTES.ADMIN.COMPANIES.USERS.replace(
                ':id',
                typeof user.company === 'string'
                  ? user.company
                  : user.company.id,
              )
            : ROUTES.COMPANY.USERS.PREFIX;
        history.push(route);
      }
    }
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      firstName: user ? user.firstName : '',
      lastName: user ? user.lastName : '',
      gender: user ? user.gender : GENDER.MALE,
      email: user ? user.email : '',
      birthday: user ? moment(user.birthday) : null,
      mobilePhone: user ? user.mobilePhone : '',
      ...(currentRole === ROLE.ADMIN
        ? { roles: ROLE.COMPANY }
        : { roles: ROLE.USER }),
      postalCode: user ? address?.postalCode : '',
      city: user ? address?.city : '',
      street: user ? address?.street : '',
      numberOfStreet: user ? address?.numberOfStreet : '',
      description: user ? address?.description : '',
      unit: user ? (user.unit as UnitModel)?.name : '',
      country: user ? address?.country : defaultCountryId,
    },
    onSubmit: handleSubmit,
  });

  return (
    <form
      onSubmit={form.handleSubmit}
      onKeyDown={FormHelper.onKeyDownPreventEnter}
    >
      <UserForm form={form} />

      <Box>
        <Divider textAlign="left" variant="dialog" sx={{ my: 10 }}>
          {t('common.address')}
        </Divider>

        <SpaceAddressForm
          form={form}
          location={location}
          setLocation={setLocation}
          isNew={!user}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 10,
        }}
      >
        <Button
          variant="blue"
          type="submit"
          loading={creating || updating || updatingProfile}
        >
          {user ? t('common.update') : t('common.submit')}
        </Button>
      </Box>
    </form>
  );
};

export default PostUser;
