import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikContextType } from 'formik';
import { Box, Divider } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { Button } from '@/components';
import UnitItem from './UnitItem';
import UploadUnitButton from './UploadUnitButton';

interface UnitsFormProps {
  form: FormikContextType<any>;
}

const UnitsForm: FC<UnitsFormProps> = ({ form }) => {
  const [t] = useTranslation('common');

  const { errors } = form;
  const { units = [] } = form.values;

  const onChange = (id: string, value: any) => {
    const newUnits = units.map((item) => {
      if (item.id === id) return { ...item, ...value };

      return item;
    });

    form.setFieldValue('units', newUnits);
  };

  const addUnit = () => {
    form.setFieldValue('units', [
      ...units,
      {
        id: `${Math.random()}`,
        name: '',
        urbanAreas: [],
        // urbanAreas: [
        //   { id: `${Math.random()}`, name: '' }
        // ]
      },
    ]);
  };

  const addBulkUnits = (data: any[]) => {
    form.setFieldValue('units', [...units, ...data]);
  };

  const removeUnit = (id: string) => {
    const newUnits = units.filter((item) => item.id !== id);

    form.setFieldValue('units', newUnits);
  };

  return (
    <Box sx={{ p: 4, mb: 10 }}>
      <Divider textAlign="left" variant="dialog" sx={{ mb: 6 }}>
        {t('companies.unit')}
      </Divider>

      {units.map((item, index) => (
        <UnitItem
          key={item.id}
          unit={item}
          onChange={onChange}
          removeUnit={() => removeUnit(item.id)}
          allowRemove={units.length > 1}
          error={
            errors.units && errors.units.length > 0 ? errors.units[index] : {}
          }
        />
      ))}

      <div className="d-flex align-items-center justify-content-end">
        <Button
          sx={{ mr: 2 }}
          variant="link"
          startIcon={<AddIcon />}
          onClick={addUnit}
        >
          {t('companies.add_more_unit')}
        </Button>

        <UploadUnitButton uploadUnits={addBulkUnits} />
      </div>
    </Box>
  );
};

export default UnitsForm;
