import { useTranslation } from 'react-i18next';
import { PartnerOption } from '@/resources/interfaces';
import { NameHelper } from '@/utils';

export const usePartnerOptionDetailList = (option: PartnerOption) => {
  const [t] = useTranslation('common');

  return [
    {
      label: t('common.names'),
      value: !option.names.value ? t('session.no_preference') : `${option.names.value} (${option.names.mandatory ? t('common.mandatory') : t('common.optional')})`,
    },
    {
      label: t('common.unit'),
      value: !option.units.value ? t('session.no_preference') : `${option.units.value} (${option.units.mandatory ? t('common.mandatory') : t('common.optional')})`,
    },
    {
      label: t('common.gender'),
      value: `${NameHelper.getGenderName(option.gender.value)} ${option.gender.value === false ? '' : `(${option.gender.mandatory ? t('common.mandatory') : t('common.optional')})`}`,
    },
    {
      label: t('common.age'),
      value: `${NameHelper.getAgeOptionName(option.aroundAge.value)} ${option.aroundAge.value === false ? '' : `(${option.aroundAge.mandatory ? t('common.mandatory') : t('common.optional')})`}`,
    },
  ];
}
