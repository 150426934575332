import { SpaceModel } from '@/resources/models';
import { SPACE_ACTIONS } from '@/redux/action-types';

export interface SpaceState {
  spaces: SpaceModel[];
  loading: boolean;
  deleting: boolean;
  creating: boolean;
  updating: boolean;
  finding: boolean;
  space: SpaceModel | undefined;
}

const initialState: SpaceState = {
  spaces: [],
  loading: false,
  deleting: false,
  creating: false,
  updating: false,
  finding: false,
  space: undefined,
};

const spaceReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SPACE_ACTIONS.GET_SPACES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SPACE_ACTIONS.GET_SPACES_SUCCESS: {
      return {
        ...state,
        loading: false,
        spaces: payload.spaces,
      };
    }

    case SPACE_ACTIONS.GET_SPACES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case SPACE_ACTIONS.DELETE_SPACE_REQUEST: {
      return {
        ...state,
        deleting: true,
      };
    }

    case SPACE_ACTIONS.DELETE_SPACE_SUCCESS: {
      return {
        ...state,
        deleting: false,
        spaces: state.spaces.filter((item) => item.id !== payload.spaceId),
      };
    }

    case SPACE_ACTIONS.DELETE_SPACE_ERROR: {
      return {
        ...state,
        deleting: false,
      };
    }

    case SPACE_ACTIONS.CREATE_SPACE_REQUEST: {
      return {
        ...state,
        creating: true,
      };
    }

    case SPACE_ACTIONS.CREATE_SPACE_SUCCESS: {
      return {
        ...state,
        creating: false,
        spaces: [...state.spaces, payload.space],
      };
    }

    case SPACE_ACTIONS.CREATE_SPACE_ERROR: {
      return {
        ...state,
        creating: false,
      };
    }

    case SPACE_ACTIONS.FIND_SPACE_REQUEST: {
      return {
        ...state,
        finding: true,
      };
    }

    case SPACE_ACTIONS.FIND_SPACE_SUCCESS: {
      return {
        ...state,
        finding: false,
        space: payload.space,
      };
    }

    case SPACE_ACTIONS.FIND_SPACE_ERROR: {
      return {
        ...state,
        finding: false,
      };
    }

    case SPACE_ACTIONS.UPDATE_SPACE_REQUEST: {
      return {
        ...state,
        updating: true,
      };
    }

    case SPACE_ACTIONS.UPDATE_SPACE_SUCCESS: {
      return {
        ...state,
        updating: false,
      };
    }

    case SPACE_ACTIONS.UPDATE_SPACE_ERROR: {
      return {
        ...state,
        updating: false,
      };
    }

    default:
      return state;
  }
};

export default spaceReducer;
