import { Dispatch } from 'redux';
import { AUTH_ACTIONS, DESTROY_SESSION } from '@/redux/action-types';
import { AuthService } from '@/service';
import { Tokens } from '@/redux/reducers/auth.reducer';
import { UserModel } from '@/resources/models';
import { showToastAction } from '@/redux/actions/toast.actions';
import { ROLE } from '@/resources/enums';

export const setTokens = (tokens: Tokens) => ({
  type: AUTH_ACTIONS.SET_TOKEN,
  payload: { tokens },
});

export const clearTokensAndState = () => async (dispatch: Dispatch) => {
  dispatch({
    type: DESTROY_SESSION,
  });
  dispatch(
    setTokens({
      accessToken: null,
      refreshToken: null,
    }),
  );
};

export const setAccount = (account: UserModel) => ({
  type: AUTH_ACTIONS.SET_ACCOUNT,
  payload: { account },
});

export const setAccountLoading = (loading: boolean) => ({
  type: AUTH_ACTIONS.SET_ACCOUNT_LOADING,
  payload: { loading },
});

export const setCurrentRole = (role: ROLE) => ({
  type: AUTH_ACTIONS.SET_CURRENT_ROLE,
  payload: { role },
});

export const loginAction =
  (email: string, password: string) => async (dispatch: Dispatch) => {
    try {
      const res = await AuthService.login(email, password);
      dispatch(setTokens(res));
      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
    }
  };

export const getAccountAction = () => async (dispatch: Dispatch) => {
  dispatch({
    type: AUTH_ACTIONS.GET_ACCOUNT_REQUEST,
  });

  try {
    const res = await AuthService.getAccount();
    dispatch(setAccount(res));

    if (res.roles.includes(ROLE.ADMIN)) {
      dispatch(setCurrentRole(ROLE.ADMIN));
    } else if (res.roles.includes(ROLE.COMPANY)) {
      dispatch(setCurrentRole(ROLE.COMPANY));
    } else if (res.roles.includes(ROLE.USER)) {
      dispatch(setCurrentRole(ROLE.USER));
    }

    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch({
      type: AUTH_ACTIONS.GET_ACCOUNT_ERROR,
    });
    return false;
  }
};

const forgotPasswordRequest = () => ({
  type: AUTH_ACTIONS.FORGOT_PASSWORD_REQUEST,
});

const forgotPasswordSuccess = () => ({
  type: AUTH_ACTIONS.FORGOT_PASSWORD_SUCCESS,
});

const forgotPasswordError = () => ({
  type: AUTH_ACTIONS.FORGOT_PASSWORD_ERROR,
});

export const forgotPasswordAction =
  (email: string) => async (dispatch: Dispatch) => {
    dispatch(forgotPasswordRequest());

    try {
      await AuthService.forgotPassword(email);
      dispatch(forgotPasswordSuccess());
      return true;
    } catch (err) {
      showToastAction(err.message)(dispatch);
      dispatch(forgotPasswordError());
    }
  };

const resetPasswordRequest = () => ({
  type: AUTH_ACTIONS.RESET_PASSWORD_REQUEST,
});

const resetPasswordSuccess = () => ({
  type: AUTH_ACTIONS.RESET_PASSWORD_SUCCESS,
});

const resetPasswordError = () => ({
  type: AUTH_ACTIONS.RESET_PASSWORD_ERROR,
});

export const resetPasswordAction =
  (password: string, token: string) => async (dispatch: Dispatch) => {
    dispatch(resetPasswordRequest());

    try {
      await AuthService.resetPassword(password, token);
      dispatch(resetPasswordSuccess());
      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(resetPasswordError());
    }
  };

const changePasswordRequest = () => ({
  type: AUTH_ACTIONS.CHANGE_PASSWORD_REQUEST,
});

const changePasswordSuccess = () => ({
  type: AUTH_ACTIONS.CHANGE_PASSWORD_SUCCESS,
});

const changePasswordError = () => ({
  type: AUTH_ACTIONS.CHANGE_PASSWORD_ERROR,
});

export const changePasswordAction =
  (password: string, newPassword: string) => async (dispatch: Dispatch) => {
    dispatch(changePasswordRequest());

    try {
      await AuthService.changePassword(password, newPassword);
      dispatch(changePasswordSuccess());
      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(changePasswordError());
    }
  };
