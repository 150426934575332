import React, { FC } from 'react';
import {
  Box,
  Dialog as MuiDialog,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { SxProps } from '@mui/system';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  sx?: SxProps;
};

export const Dialog: FC<DialogProps> = ({
  open,
  onClose,
  sx,
  title,
  children,
}) => {
  return (
    <MuiDialog open={open} onClose={onClose} sx={sx}>
      <Box sx={{ position: 'relative' }}>
        <DialogTitle sx={{ py: 3 }}>{title}</DialogTitle>

        <IconButton
          size="small"
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '16px',
            color: (theme) => theme.colors.text_blue,
            '&:hover': {
              color: (theme) => theme.colors.blue,
            },
          }}
          onClick={onClose}
        >
          <Close sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      </Box>

      {children}
    </MuiDialog>
  );
};
