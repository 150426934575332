import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ComeInLater from '@/parts/ComeInLater';

const Calendar = () => {
  const [t] = useTranslation('common');

  return (
    <>
      <Typography
        variant={'h1'}
        sx={{color: theme => theme.colors.text_secondary}}
      >
        {`${t('app.my_calendar')} (${t('app.come_later')})`}
      </Typography>
      <ComeInLater />
    </>
  );
};

export default Calendar;
