import React, { FC, useEffect } from 'react';
import { Box, Hidden, IconButton, SwipeableDrawer } from '@mui/material';
import SidebarLeft from '@/parts/SidebarLeft';
import SidebarRight from '@/parts/SidebarRight';
import { MenuOpen } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useAuthState, useGetUnreadCountAction } from '@/hooks/redux';
import { ROLE } from '@/resources/enums';
import CookiePopup from '@/components/CookiePopup';

const FullLayout: FC = ({ children }) => {
  const history = useHistory();
  const getUnreadCountAction = useGetUnreadCountAction();
  const { currentRole } = useAuthState();

  const [openLeft, setOpenLeft] = React.useState(false);
  const [openRight, setOpenRight] = React.useState(false);

  const handleLeftOpen = () => {
    setOpenLeft(true);
  };

  const handleRightOpen = () => {
    setOpenRight(true);
  };

  useEffect(() => {
    return history.listen(() => {
      setOpenLeft(false);
      setOpenRight(false);

      if (currentRole === ROLE.USER) {
        getUnreadCountAction();
      }
    });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <Hidden only={['xs', 'sm', 'md']}>
        <SidebarLeft />
      </Hidden>
      <Hidden lgUp xlUp>
        <IconButton
          onClick={handleLeftOpen}
          edge="start"
          sx={{
            position: 'absolute',
            top: 15,
            left: 15,
            display: { lg: 'none' },
          }}
        >
          <MenuOpen />
        </IconButton>
        <SwipeableDrawer
          variant="temporary"
          anchor="left"
          open={openLeft}
          onOpen={() => {
            setOpenLeft(true);
          }}
          onClose={() => {
            setOpenLeft(false);
          }}
        >
          <SidebarLeft />
        </SwipeableDrawer>
      </Hidden>
      <Box
        sx={{
          flexGrow: 1,
          maxHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            py: 10,
            px: 10,
            bgcolor: (theme) => theme.colors.bg_grey,
            minHeight: '100vh',
          }}
        >
          {children}
        </Box>
      </Box>
      <Hidden only={['xs', 'sm', 'md']}>
        <SidebarRight />
      </Hidden>
      <Hidden lgUp>
        <IconButton
          onClick={handleRightOpen}
          edge="start"
          sx={{
            position: 'absolute',
            top: 15,
            right: 15,
            transform: 'rotate(180deg)',
            display: { lg: 'none' },
          }}
        >
          <MenuOpen />
        </IconButton>
        <SwipeableDrawer
          variant="temporary"
          anchor="right"
          open={openRight}
          onOpen={() => {
            setOpenRight(true);
          }}
          onClose={() => {
            setOpenRight(false);
          }}
        >
          <SidebarRight />
        </SwipeableDrawer>
      </Hidden>
      <CookiePopup />
    </Box>
  );
};

export default FullLayout;
