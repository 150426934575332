import { CompanyModel } from '@/resources/models/company.model';
import { UserModel } from '@/resources/models/user.model';
import { SpaceModel } from '@/resources/models/space.model';
import { GENDER, OFFER_STATUS } from '@/resources/enums';
import { PartnerOption } from '@/resources/interfaces';

export class OfferModel {
  id: string;
  company: string | CompanyModel;
  user: string | UserModel;
  space: string | SpaceModel;
  status: OFFER_STATUS;
  startTime: string;
  endTime: string;
  guests: PartnerOption;

  constructor(init: any = {}) {
    const data = {
      id: '',
      company: '',
      user: '',
      space: '',
      status: OFFER_STATUS.PENDING,
      startTime: '',
      endTime: '',
      guests: {
        name: '',
        units: '',
        gender: GENDER.MALE,
        aroundAge: false,
      },
      ...init,
    };

    this.id = data.id || data._id;
    this.status = data.status;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.guests = data.guests;

    if (typeof data.company === 'string') {
      this.company = data.company;
    } else {
      this.company = new CompanyModel(data.company);
    }

    if (typeof data.user === 'string') {
      this.user = data.user;
    } else {
      this.user = new UserModel(data.user);
    }

    if (typeof data.space === 'string') {
      this.space = data.space;
    } else {
      this.space = new SpaceModel(data.space);
    }
  }
}
