import { Dispatch } from 'redux';
import { OFFER_ACTIONS } from '@/redux/action-types';
import { OfferService } from '@/service';
import { showToastAction } from '@/redux/actions/toast.actions';
import { OfferModel } from '@/resources/models';

const createOfferRequest = () => ({
  type: OFFER_ACTIONS.CREATE_OFFER_REQUEST,
});

const createOfferSuccess = () => ({
  type: OFFER_ACTIONS.CREATE_OFFER_SUCCESS,
});

const createOfferError = () => ({
  type: OFFER_ACTIONS.CREATE_OFFER_ERROR,
});

export const createOfferAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(createOfferRequest());

  try {
    const res = await OfferService.create(data);
    dispatch(createOfferSuccess());
    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(createOfferError());
  }
};

const getOffersRequest = () => ({
  type: OFFER_ACTIONS.GET_OFFERS_REQUEST,
});

const getOffersSuccess = (offers: OfferModel[], totalCount: number) => ({
  type: OFFER_ACTIONS.GET_OFFERS_SUCCESS,
  payload: { offers, totalCount },
});

const getOffersError = () => ({
  type: OFFER_ACTIONS.GET_OFFERS_ERROR,
});

export const getOffersAction = (query: any) => async (dispatch: Dispatch) => {
  dispatch(getOffersRequest());

  try {
    const { data, totalCount } = await OfferService.search(query);
    dispatch(getOffersSuccess(data, totalCount));
    return { data, totalCount };
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(getOffersError());
  }
};

const getPendingOffersRequest = () => ({
  type: OFFER_ACTIONS.GET_PENDING_OFFERS_REQUEST,
});

const getPendingOffersSuccess = (count: number) => ({
  type: OFFER_ACTIONS.GET_PENDING_OFFERS_SUCCESS,
  payload: { count },
});

const getPendingOffersError = () => ({
  type: OFFER_ACTIONS.GET_PENDING_OFFERS_ERROR,
});

export const getPendingOffersAction = () => async (dispatch: Dispatch) => {
  dispatch(getPendingOffersRequest());

  try {
    const { count } = await OfferService.getHostPendingCount();
    dispatch(getPendingOffersSuccess(count));
    return count;
  } catch (err) {
    showToastAction(err.message, 'error');
    dispatch(getPendingOffersError());
  }
};

const removeOfferRequest = () => ({
  type: OFFER_ACTIONS.REMOVE_OFFER_REQUEST,
});

const removeOfferSuccess = () => ({
  type: OFFER_ACTIONS.REMOVE_OFFER_SUCCESS,
});

const removeOfferError = () => ({
  type: OFFER_ACTIONS.REMOVE_OFFER_ERROR,
});

export const removeOfferAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(removeOfferRequest());

  try {
    await OfferService.remove(id);
    showToastAction('Offer is removed successfully.')(dispatch);
    dispatch(removeOfferSuccess());
    return true;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(removeOfferError());
  }
};
