import { HttpService } from '@/service/http.service';
import { UrbanAreaModel } from '@/resources/models/urban-area.model';

export class UrbanAreaService {
  static getUrbanAreas() {
    return HttpService.get('/urban-areas').then((res) => {
      return res.map((item) => new UrbanAreaModel(item));
    });
  }
}
