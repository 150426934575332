import React from 'react';
import { Redirect, Switch } from 'react-router';
import { AUTH_ROUTES, ROUTES } from '@/constants';
import PrivateRoute from '@/routes/PrivateRoute';
import User from '@/pages/User';
import UserPreloader from '@/providers/UserPreloader';
import SocketProvider from '@/providers/SocketProvider';

export const UserRoutes = () => {
  return (
    <SocketProvider>
      <UserPreloader>
        <Switch>
          {
            AUTH_ROUTES.map((route) => (
              <Redirect
                key={route.path as string}
                path={route.path as string}
                to={ROUTES.COMPANY.PREFIX}
              />
            ))
          }

          <PrivateRoute path={ROUTES.COMPANY.PREFIX} component={User} />
        </Switch>
      </UserPreloader>
    </SocketProvider>
  )
};
