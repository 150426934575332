import { SessionPeriod } from '@/resources/interfaces';
import moment from 'moment-timezone';

export class ObjectHelper {
  static trimQuery(query: any) {
    if (!query || typeof query !== 'object') return query;

    if (Array.isArray(query)) {
      return query.map((item) => ObjectHelper.trimQuery(item));
    }

    const result: any = {};
    Object.keys(query).forEach((key: string) => {
      const value: any = query[key];
      if (
        value !== undefined &&
        value !== null &&
        value !== '' &&
        (!Array.isArray(value) || value.length > 0)
      ) {
        result[key] = ObjectHelper.trimQuery(value);
      }
    });
    return result;
  }

  static convertSessionPeriod(periods: SessionPeriod[]) {
    periods.forEach((item: SessionPeriod) => {
      item.date = moment(item.date).startOf('day').toISOString();

      const date = moment(item.date).format('YYYY-MM-DD');
      const startTime = moment(item.startTime).format('HH:mm');
      const endTime = moment(item.endTime).format('HH:mm');

      item.startTime = moment(`${date} ${startTime}`, [
        'YYYY-MM-DD HH:mm',
      ]).toISOString();
      item.endTime = moment(`${date} ${endTime}`, [
        'YYYY-MM-DD HH:mm',
      ]).toISOString();

      if (item.repeat?.endOn) {
        item.repeat.endOn = moment(item.repeat.endOn).toISOString();
      }
    });

    return periods;
  }
}
