import { ADMIN_ACTIONS } from '@/redux/action-types';

export interface AdminState {
  loadingAdminStatistics: boolean;
  usersStatistics: {
    totalCount: number;
    newCount: number;
    activeCount: number;
  };
}

const initialState: AdminState = {
  loadingAdminStatistics: false,
  usersStatistics: {
    totalCount: 0,
    newCount: 0,
    activeCount: 0,
  },
};

const adminReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_ACTIONS.GET_ADMIN_USERS_STATISTICS_REQUEST: {
      return {
        ...state,
        loadingAdminStatistics: true,
      };
    }

    case ADMIN_ACTIONS.GET_ADMIN_USERS_STATISTICS_SUCCESS: {
      return {
        ...state,
        usersStatistics: {
          totalCount: payload.totalCount,
          newCount: payload.newCount,
          activeCount: payload.activeCount,
        },
        loadingAdminStatistics: false,
      };
    }

    case ADMIN_ACTIONS.GET_ADMIN_USERS_STATISTICS_ERROR: {
      return {
        ...state,
        loadingSessions: false,
      };
    }

    default:
      return state;
  }
};

export default adminReducer;
