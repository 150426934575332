import { ListItem, styled } from '@mui/material';

export const NotificationsButtonStyled = styled('div')`
  height: 64px;
  background-color: ${(props) => props.theme.colors.bg_light_blue};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  margin: 75px 0 5px;
  border-radius: 20px;

  &:hover {
    background-color: ${(props) => props.theme.colors.bg_blue};
  }
`;

export const UserMenuStyled = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.theme.colors.bg_light_blue};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  padding: 0 8px;
  margin: 5px 0;
  border-radius: 20px;

  &:hover,
  &.isOpen {
    background-color: ${(props) => props.theme.colors.bg_blue};
  }
`;

export const UserMenuInfoStyled = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 64px;
`;

export const ListItemStyled = styled(ListItem)`
  padding: 2px 0;
  color: ${(props) => props.theme.colors.text_light_grey};
  &:hover {
    color: ${(props) => props.theme.colors.text};
  }
`;
