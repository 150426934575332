/* eslint-disable  @typescript-eslint/ban-types */

import axios, { AxiosRequestConfig, Method } from 'axios';
import { API_SERVER, ROUTES } from '@/constants';
import store, { history } from '@/redux/store';
import { debounce } from 'lodash';
import {
  finishLoading,
  startLoading,
  setTokens,
  clearTokensAndState,
} from '@/redux/actions';
import { AuthService } from '@/service/auth.service';

const http = axios.create({ baseURL: `${API_SERVER}/` });

let reqQueue: {
  resolve: Function;
  reject: Function;
}[] = [];

const httpResponseHandler = (response) => {
  return response.data;
};

const checkTokens = (response: any): void => {
  if (response?.headers['access-token']) {
    store.dispatch(
      setTokens({
        accessToken: response.headers['access-token'],
        refreshToken: response.headers['refresh-token'],
      }),
    );
  }
};

const _refreshTokens: Function = debounce((resolve, reject): Promise<any> => {
  const {
    authReducer: { tokens },
  } = store.getState();
  if (!tokens?.refreshToken) {
    return reject();
  }

  return AuthService.refreshToken(tokens.refreshToken)
    .then((response) => {
      store.dispatch(
        setTokens({
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        }),
      );
      resolve(response.data);
    })
    .catch((err) => reject(err));
}, 100);

const refreshTokens: Function = (resolve: Function, reject: Function): void => {
  reqQueue.push({ resolve, reject });
  _refreshTokens(
    (data: any) => {
      reqQueue.forEach((req) => {
        req.resolve(data);
      });
      reqQueue = [];
    },
    (err: any) => {
      if (reqQueue.length) {
        reqQueue[0].reject({
          ...err,
          message: 'Token is expired.',
        });
      }
      reqQueue = [];
    },
  );
};

const triggerLogout = () => {
  store.dispatch(clearTokensAndState());
  history.push(ROUTES.AUTH.LOGIN);
};

const httpErrorHandler = async (err: any, refresh = true): Promise<any> => {
  const response = err?.response;
  checkTokens(response);
  if (response?.status === 401) {
    if (refresh) {
      return new Promise((resolve, reject) => {
        return refreshTokens(resolve, reject);
      })
        .then((tokens: any) => {
          err.config.headers.Authorization = `Bearer ${tokens.accessToken}`;
          return http
            .request(err.config)
            .then(httpResponseHandler)
            .catch((error) => httpErrorHandler(error, false));
        })
        .catch(async () => {
          triggerLogout();
          return new Promise((resolve, reject) =>
            reject({ message: 'UnAuthorized!' }),
          );
        });
    }

    triggerLogout();
    return new Promise(() => ({}));
  }

  const data = response?.data;

  // if (response?.status === 403) {
  //   triggerLogout();
  //   return new Promise(() => {});
  // }

  throw new Error(data?.message || 'Network Error!');
};

export class HttpService {
  static get(
    url: string,
    params: any = {},
    headers: any = {},
    hasSpinner = false,
  ) {
    return HttpService.request('GET', url, { params, headers }, hasSpinner);
  }

  static post(
    url: string,
    body: any = {},
    headers: any = {},
    hasSpinner = false,
  ) {
    return HttpService.request(
      'POST',
      url,
      { data: body, headers },
      hasSpinner,
    );
  }

  static put(
    url: string,
    body: any = {},
    headers: any = {},
    hasSpinner = false,
  ) {
    return HttpService.request('PUT', url, { data: body, headers }, hasSpinner);
  }

  static patch(
    url: string,
    body: any = {},
    headers: any = {},
    hasSpinner = false,
  ) {
    return HttpService.request(
      'PATCH',
      url,
      { data: body, headers },
      hasSpinner,
    );
  }

  static delete(
    url: string,
    body: any = {},
    headers: any = {},
    hasSpinner = false,
  ) {
    return HttpService.request(
      'DELETE',
      url,
      { data: body, headers },
      hasSpinner,
    );
  }

  static request(
    method: Method,
    url: string,
    options: AxiosRequestConfig,
    hasSpinner = true,
  ) {
    if (hasSpinner) {
      store.dispatch(startLoading());
    }

    const {
      authReducer: { tokens },
    } = store.getState();

    if (tokens) {
      options.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }

    return http
      .request({
        ...options,
        method,
        url,
      })
      .then(httpResponseHandler)
      .catch(httpErrorHandler)
      .finally(() => {
        store.dispatch(finishLoading());
      });
  }
}
