import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  createSpaceAction,
  deleteSpaceAction,
  findSpaceAction,
  getSpacesAction,
  updateSpaceAction,
} from '@/redux/actions';

export const useSpaceState = () =>
  useSelector(({ spaceReducer }: RootState) => spaceReducer);

export const useGetSpacesAction = () => {
  const dispatch = useDispatch();
  return (filter: any = {}) => dispatch(getSpacesAction(filter));
};

export const useDeleteSpaceAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(deleteSpaceAction(id));
};

export const useCreateSpaceAction = () => {
  const dispatch = useDispatch();
  return (data: any) => dispatch(createSpaceAction(data));
};

export const useUpdateSpaceAction = () => {
  const dispatch = useDispatch();
  return (id: string, data: any) => dispatch(updateSpaceAction(id, data));
};

export const useFindSpaceAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(findSpaceAction(id));
};
