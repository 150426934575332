import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box } from '@mui/material';
import { useGetOffersAction, useOfferState } from '@/hooks/redux';
import SessionFilters, { SESSION_FILTER_TYPE } from '@/parts/SessionFilters';
import OffersTable from '@/pages/User/Activity/Offers/OffersTable';
import { ObjectHelper } from '@/utils';
import { Button } from '@/components';
import { ROUTES } from '@/constants';

const Offers: FC = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const searchOffers = useGetOffersAction();
  const { loading, offers, totalCount } = useOfferState();
  const [filter, setFilter] = useState({
    search: '',
    page: 0,
    perPage: 10,
  });

  const onChangeFilter = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const onRemove = () => {
    onChangeFilter('page', 0);
  }

  useEffect(() => {
    searchOffers(ObjectHelper.trimQuery(filter));
  }, [filter]);

  return (
    <div>

      <Box sx={{ mb: '10px', display: 'flex', justifyContent: 'space-between' }}>
        <SessionFilters
          filter={filter}
          onChangeFilter={onChangeFilter}
          type={SESSION_FILTER_TYPE.OFFER}
        />
        <Button
          onClick={() => history.push(ROUTES.USER.OFFER_SESSION.INDEX)}
        >
          {t('session.create_new_offer')}
        </Button>
      </Box>

      <OffersTable
        offers={offers}
        loading={loading}
        onRemove={onRemove}
        pagination={{
          total: totalCount,
          page: filter.page,
          perPage: filter.perPage,
          onPageChange: (page) => onChangeFilter('page', page)
        }}
      />
    </div>
  );
};

export default Offers;
