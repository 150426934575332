import { HttpService } from '@/service/http.service';
import { CountryModel } from '@/resources/models';

export class CountryService {
  static getCountries() {
    return HttpService.get('/countries').then((res) => {
      return res.map((item) => new CountryModel(item));
    });
  }
}
