export enum USER_ACTIONS {
  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR = 'CREATE_USER_ERROR',
  GET_USERS_REQUEST = 'GET_USERS_REQUEST',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_ERROR = 'GET_USERS_ERROR',
  DELETE_USER_REQUEST = 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR = 'DELETE_USER_ERROR',
  FIND_USER_REQUEST = 'FIND_USER_REQUEST',
  FIND_USER_SUCCESS = 'FIND_USER_SUCCESS',
  FIND_USER_ERROR = 'FIND_USER_ERROR',
  UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR = 'UPDATE_USER_ERROR',
  GET_USER_SESSIONS_REQUEST = 'GET_USER_SESSIONS_REQUEST',
  GET_USER_SESSIONS_SUCCESS = 'GET_USER_SESSIONS_SUCCESS',
  GET_USER_SESSIONS_ERROR = 'GET_USER_SESSIONS_ERROR',
  CREATE_MULTIPLE_USERS_REQUEST = 'CREATE_MULTIPLE_USERS_REQUEST',
  CREATE_MULTIPLE_USERS_SUCCESS = 'CREATE_MULTIPLE_USERS_SUCCESS',
  CREATE_MULTIPLE_USERS_ERROR = 'CREATE_MULTIPLE_USERS_ERROR',
  ACTIVE_USER_REQUEST = 'ACTIVE_USER_REQUEST',
  ACTIVE_USER_SUCCESS = 'ACTIVE_USER_SUCCESS',
  ACTIVE_USER_ERROR = 'ACTIVE_USER_ERROR',
  BLOCK_USER_REQUEST = 'BLOCK_USER_REQUEST',
  BLOCK_USER_SUCCESS = 'BLOCK_USER_SUCCESS',
  BLOCK_USER_ERROR = 'BLOCK_USER_ERROR',
  GET_ADMIN_USERS_STATISTICS_REQUEST = 'GET_ADMIN_USERS_STATISTICS_REQUEST',
  GET_ADMIN_USERS_STATISTICS_SUCCESS = 'GET_ADMIN_USERS_STATISTICS_SUCCESS',
  GET_ADMIN_USERS_STATISTICS_ERROR = 'GET_ADMIN_USERS_STATISTICS_ERROR',
  USER_CALENDAR_DATA_REQUEST = 'USER_CALENDAR_DATA_',
  USER_CALENDAR_DATA_SUCCESS = 'USER_CALENDAR_DATA_SUCCESS',
  USER_CALENDAR_DATA_ERROR = 'USER_CALENDAR_DATA_ERROR',
}
