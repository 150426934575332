import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from '@mui/material';
import { GENDER } from '@/resources/enums';
import { UnitModel } from '@/resources/models';
import { DateHelper } from '@/utils';

interface UserFormProps {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    gender: GENDER;
    birthday: string;
    mobilePhone: string;
    unit: string | UnitModel;
  };
}

const UserPreview: FC<UserFormProps> = ({ user }) => {
  const [t] = useTranslation('common');

  return (
    <Box sx={{ mb: 10 }}>
      <Divider textAlign="left" variant="dialog" sx={{ mb: 8 }}>
        {t('common.contact_info')}
      </Divider>

      <Grid container rowSpacing={8} columnSpacing={5}>
        <Grid item xs={12} sm={6}>
          <strong>{t('companies.first_name')}:</strong> {user.firstName}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>{t('companies.last_name')}:</strong> {user.lastName}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>{t('common.gender')}: </strong> {user.gender}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>{t('common.birthday')}: </strong>{' '}
          {DateHelper.formatDate(user.birthday, 'DD/MM/YYYY')}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>{t('companies.email')}: </strong> {user.email}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>{t('companies.mobile_phone')}: </strong>
          {user.mobilePhone}
        </Grid>
        <Grid item xs={12} sm={6}>
          <strong>{t('companies.unit')}: </strong>
          {typeof user.unit === 'string' ? user.unit : user.unit.name}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UserPreview;
