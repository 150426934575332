import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material'
import SessionTable from '@/parts/Sessions/SessionTable';
import SessionFilters from '@/parts/SessionFilters';
import { ObjectHelper } from '@/utils';
import { useGetSessionsAction, useSessionState } from '@/hooks/redux';

const Sessions = () => {
  const [t] = useTranslation('common');

  const { loading, totalCount, sessions } = useSessionState();
  const getSessions = useGetSessionsAction();

  const [filter, setFilter] = useState({
    search: '',
    company: [],
    page: 0,
    perPage: 10,
  });

  const onChangeFilter = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  useEffect(() => {
    getSessions(ObjectHelper.trimQuery(filter));
  }, [filter]);

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{color: theme => theme.colors.text_secondary}}
      >
        {t('session.title')}
      </Typography>

      <Box sx={{ mb: 10 }}>
        <SessionFilters filter={filter} onChangeFilter={onChangeFilter} />
      </Box>

      <SessionTable
        sessions={sessions}
        loading={loading}
        pagination={{
          total: totalCount,
          page: filter.page,
          perPage: filter.perPage,
          onPageChange: (page) => onChangeFilter('page', page)
        }}
        refresh={() => onChangeFilter('page', 0)}
      />
    </div>
  );
};

export default Sessions;
