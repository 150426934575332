import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Box } from '@mui/material';
import { Button } from '@/components';
import { ROUTES } from '@/constants';
import { useGetSpacesAction, useSpaceState } from '@/hooks/redux';
import SpaceTable from '@/pages/User/Account/Spaces/SpaceTable';
import SpaceFilters from '@/pages/User/Account/Spaces/SpaceFilters';

const Spaces = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const { loading, spaces } = useSpaceState();
  const getSpaces = useGetSpacesAction();

  const [filter, setFilter] = useState({
    search: '',
  });

  const onChangeFilter = (field: string, value: any) => {
    const newFilter = {
      ...filter,
      [field]: value,
    };

    setFilter(newFilter);
  };

  useEffect(() => {
    getSpaces(filter);
  }, [filter]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 10,
        }}
      >
        <SpaceFilters filter={filter} onChangeFilter={onChangeFilter} />
        <Button onClick={() => history.push(ROUTES.USER.SPACES.CREATE)}>
          {t('space.create_new_space')}
        </Button>
      </Box>

      <SpaceTable spaces={spaces} loading={loading} />
    </div>
  );
};

export default Spaces;
