import { Dispatch } from 'redux';
import { CountryService } from '@/service';
import { COMMON_ACTIONS } from '@/redux/action-types';
import { showToastAction } from '@/redux/actions/toast.actions';

export const getCountriesAction = () => async (dispatch: Dispatch) => {
  try {
    const res = await CountryService.getCountries();
    dispatch({
      type: COMMON_ACTIONS.GET_COUNTRIES,
      payload: { countries: res },
    });
    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
  }
};
