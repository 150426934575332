import React from 'react';
import EditUser from '@/parts/EditUser';

const EditUserPage = () => {
  return (
    <EditUser />
  )
};

export default EditUserPage;
