import React from 'react';
import { Box, Typography } from '@mui/material';
import ComeInLater from '@/parts/ComeInLater';
import { useTranslation } from 'react-i18next';

const Activities = () => {
  const [t] = useTranslation('common');

  // const [filter, setFilter] = useState({
  //   search: '',
  // });

  // const onChangeFilter = (field: string, value: any) => {
  //   setFilter({
  //     ...filter,
  //     [field]: value,
  //   });
  // };

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {`${t('activities.title')} (${t('app.come_later')})`}
      </Typography>

      <Box sx={{ mb: 10 }}>
        {/*<ActivityFilters filter={filter} onChangeFilter={onChangeFilter} />*/}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          mb: 6,
        }}
      >
        {/*<Button sx={{ px: 10 }}>*/}
        {/*  {t('common.export')}*/}
        {/*</Button>*/}
      </Box>

      {/*<ActivityTable />*/}
      <ComeInLater />
    </div>
  );
};

export default Activities;
