import { SessionModel } from '@/resources/models';
import { SESSION_ACTIONS } from '@/redux/action-types';

export interface SessionState {
  loading: boolean;
  sessions: SessionModel[];
  totalCount: number;
  deleting: boolean;
  loadingCompanyScheduledSessions: boolean;
  companyScheduledSessionsCount: number;
}

const initialState: SessionState = {
  loading: false,
  sessions: [],
  totalCount: 0,
  deleting: false,
  loadingCompanyScheduledSessions: false,
  companyScheduledSessionsCount: 0,
};

const sessionReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SESSION_ACTIONS.GET_SESSIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SESSION_ACTIONS.GET_SESSIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalCount: payload.totalCount,
        sessions: payload.sessions,
      };
    }

    case SESSION_ACTIONS.GET_SESSIONS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case SESSION_ACTIONS.REMOVE_SESSION_REQUEST: {
      return {
        ...state,
        deleting: true,
      };
    }

    case SESSION_ACTIONS.REMOVE_SESSION_SUCCESS: {
      return {
        ...state,
        deleting: false,
      };
    }

    case SESSION_ACTIONS.REMOVE_SESSION_ERROR: {
      return {
        ...state,
        deleting: false,
      };
    }

    case SESSION_ACTIONS.COMPANY_SCHEDULED_SESSIONS_COUNT_REQUEST: {
      return {
        ...state,
        loadingCompanyScheduledSessions: true,
      };
    }

    case SESSION_ACTIONS.COMPANY_SCHEDULED_SESSIONS_COUNT_SUCCESS: {
      return {
        ...state,
        loadingCompanyScheduledSessions: false,
        companyScheduledSessionsCount: payload.count,
      };
    }

    case SESSION_ACTIONS.COMPANY_SCHEDULED_SESSIONS_COUNT_ERROR: {
      return {
        ...state,
        loadingCompanyScheduledSessions: false,
      };
    }

    default:
      return state;
  }
};

export default sessionReducer;
