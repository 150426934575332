const baseStyles = `
  * {
    box-sizing: border-box;
  }
  
  :root {
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
        
  body {
    margin: 0;
    color: #0b1c34;
  }
  
  p, h1, h2, h3, h4, h5 {
    margin: 0;
  }
  
  a {
    color: #307cd8;
    text-decoration: none;
    
    &:hover {
      color: #283C5B;
    }
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 8px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
  
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .d-flex {
    display: flex;
  }
  
  .d-block {
    display: block;
  }
  
  .d-inline-flex {
    display: inline-flex;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .justify-content-center {
    justify-content: center;
  }
  
  .justify-content-end {
    justify-content: flex-end;
  }
  
  .justify-content-between {
    justify-content: space-between;
  }
  
  flex-wrap {
    flex-wrap: wrap;
  }
  
  .text-left {
    text-align: left;
  }
  
  .text-right {
    text-align: right;
  }
  
  .text-xs {
    font-size: 0.75rem;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-md {
    font-size: 1rem;
  }
  
  .text-lg {
    font-size: 1.125rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .text-2xl {
    font-size: 1.375rem;
  }
  
  .text-3xl {
    font-size: 1.5rem;
  }
  
  .text-4xl {
    font-size: 1.75rem;
  }
  
  .text-capitalize {
    text-transform: capitalize;
  }
  
  .text-uppercase {
    text-transform: uppercase;
  }
  
  .fw-bold {
    font-weight: bold;
  }
`;

let commonSpacings = '';
for (let i = 0; i < 40; i++) {
  commonSpacings =
    commonSpacings +
    `
    .mb-${i} {
      margin-bottom: ${i * 0.125}rem;
    }
    
    .mt-${i} {
      margin-top: ${i * 0.125}rem;
    }
    
    .mr-${i} {
      margin-right: ${i * 0.125}rem;
    }
    
    .ml-${i} {
      margin-left: ${i * 0.125}rem;
    }
    
    .mx-${i} {
      margin-left: ${i * 0.125}rem;
      margin-right: ${i * 0.125}rem;
    } 
    
    .my-${i} {
      margin-top: ${i * 0.125}rem;
      margin-bottom: ${i * 0.125}rem;
    }
    
    .m-${i} {
      margin-left: ${i * 0.125}rem;
      margin-right: ${i * 0.125}rem;
      margin-top: ${i * 0.125}rem;
      margin-bottom: ${i * 0.125}rem;
    } 
  `;
}

export default baseStyles + commonSpacings;
