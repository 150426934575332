import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  changePasswordAction,
  forgotPasswordAction,
  getAccountAction,
  loginAction,
  clearTokensAndState,
  resetPasswordAction,
  setAccount,
  setAccountLoading,
  setTokens,
} from '@/redux/actions';
import { Tokens } from '@/redux/reducers/auth.reducer';
import { UserModel } from '@/resources/models';

export const useAuthState = () =>
  useSelector(({ authReducer }: RootState) => authReducer);

export const useLoginAction = () => {
  const dispatch = useDispatch();
  return (email: string, password: string) =>
    dispatch(loginAction(email, password));
};

export const useSetTokenAction = () => {
  const dispatch = useDispatch();
  return (tokens: Tokens) => dispatch(setTokens(tokens));
};

export const useSetAccountAction = () => {
  const dispatch = useDispatch();
  return (account: UserModel) => dispatch(setAccount(account));
};

export const useGetAccountAction = () => {
  const dispatch = useDispatch();
  return () => dispatch(getAccountAction());
};

export const useLogout = () => {
  const dispatch = useDispatch();
  return () => dispatch(clearTokensAndState());
};

export const useSetAccountLoading = () => {
  const dispatch = useDispatch();
  return (loading: boolean) => dispatch(setAccountLoading(loading));
};

export const useForgotPasswordAction = () => {
  const dispatch = useDispatch();
  return (email: string) => dispatch(forgotPasswordAction(email));
};

export const useResetPasswordAction = () => {
  const dispatch = useDispatch();
  return (password: string, token: string) =>
    dispatch(resetPasswordAction(password, token));
};

export const useChangePasswordAction = () => {
  const dispatch = useDispatch();
  return (password: string, newPassword: string) =>
    dispatch(changePasswordAction(password, newPassword));
};
