import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid, IconButton } from '@mui/material';
import { FormikContextType } from 'formik';
import { DatePicker, Input, Select } from '@/components';
import { GENDER } from '@/resources/enums';
import { Close } from '@mui/icons-material';

interface CreateCompanyUsersFormProps {
  form: FormikContextType<any>;
  index: number;
  units: { name: string }[];
  allowRemove?: boolean;
  onRemove?: () => void;
}

const CreateCompanyUsersForm: FC<CreateCompanyUsersFormProps> = ({
  form,
  index,
  allowRemove,
  onRemove,
  units = [],
}) => {
  // const { currentRole } = useAuthState();
  const [t] = useTranslation('common');

  // const roleOptions = useMemo(() => {
  //   return [
  //     { label: t('common.company_admin'), value: ROLE.COMPANY },
  //     { label: t('common.user'), value: ROLE.USER },
  //   ];
  // }, []);

  const genderOptions = useMemo(() => {
    return [
      { label: t('common.male'), value: GENDER.MALE },
      { label: t('common.female'), value: GENDER.FEMALE },
      { label: t('common.other'), value: GENDER.OTHER },
    ];
  }, []);

  const unitOptions = useMemo(() => {
    return units.map((u) => ({ label: u.name, value: u.name }));
  }, [units]);

  const getFieldKey = (key: string): string => `users.${index}.${key}`;

  const getFieldError = (key: string): string => {
    const err =
      form.errors.users &&
      form.touched.users &&
      form.errors.users[index] &&
      form.touched.users[index] &&
      form.errors.users[index][key] &&
      form.touched.users[index][key]
        ? t(String(form.errors.users[index][key]))
        : '';
    return err;
  };

  return (
    <Box sx={{ mb: 10 }}>
      <Divider textAlign="left" variant="dialog" sx={{ mb: 8 }}>
        {allowRemove && (
          <div className="d-flex align-items-center justify-content-start">
            <IconButton
              onClick={onRemove}
              sx={{ '&:hover': { color: (theme) => theme.colors.btn_red_bg } }}
            >
              <Close />
            </IconButton>
            {t('companies.contact_person')}
          </div>
        )}
      </Divider>

      <Grid container rowSpacing={8} columnSpacing={5}>
        <Grid item xs={12} sm={6}>
          <Input
            label={t('companies.first_name')}
            fullWidth
            {...form.getFieldProps(getFieldKey('firstName'))}
            errorText={getFieldError('firstName')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label={t('companies.last_name')}
            fullWidth
            {...form.getFieldProps(getFieldKey('lastName'))}
            errorText={getFieldError('lastName')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            label={t('common.gender')}
            fullWidth
            options={genderOptions}
            {...form.getFieldProps(getFieldKey('gender'))}
            errorText={getFieldError('gender')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            fullWidth
            label={t('common.birthday')}
            name="birthday"
            value={form.values.users[index].birthday}
            onChange={(value) => {
              form.setFieldValue(getFieldKey('birthday'), value);
            }}
            errorText={getFieldError('birthday')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label={t('companies.email')}
            fullWidth
            {...form.getFieldProps(getFieldKey('email'))}
            errorText={getFieldError('email')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            label={t('companies.mobile_phone')}
            type="number"
            fullWidth
            {...form.getFieldProps(getFieldKey('mobilePhone'))}
            errorText={getFieldError('mobilePhone')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            label={t('common.unit')}
            fullWidth
            options={unitOptions}
            {...form.getFieldProps(getFieldKey('unit'))}
            errorText={getFieldError('unit')}
          />
        </Grid>
        {/*{currentRole === ROLE.ADMIN && (*/}
        {/*  <Grid item xs={12} sm={6}>*/}
        {/*    <Select*/}
        {/*      label={t('common.role')}*/}
        {/*      fullWidth*/}
        {/*      options={roleOptions}*/}
        {/*      multiple*/}
        {/*      {...form.getFieldProps(getFieldKey('roles'))}*/}
        {/*      errorText={getFieldError('roles')}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*)}*/}
      </Grid>
    </Box>
  );
};

export default CreateCompanyUsersForm;
