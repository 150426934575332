import { AddressModel, CountryModel, UrbanAreaModel } from '@/resources/models';
import { useTranslation } from 'react-i18next';

export const useAddressDetailList = (address: AddressModel) => {
  const [t] = useTranslation('common');
  const country = address.country as CountryModel;
  const urbanArea = address.urbanArea as UrbanAreaModel;

  return [
    {
      label: t('common.country'),
      value: country?.name,
    },
    {
      label: t('companies.postal_code'),
      value: address.postalCode,
    },
    {
      label: t('companies.city'),
      value: address.city,
    },
    {
      label: t('companies.street'),
      value: address.street,
    },
    {
      label: t('companies.number_street'),
      value: address.numberOfStreet,
    },
    {
      label: t('companies.urban_area'),
      value: urbanArea?.name,
    },
    {
      label: t('companies.lat'),
      value: address.lat,
    },
    {
      label: t('companies.lng'),
      value: address.lng,
    },
  ];
};
