import { HttpService } from '@/service/http.service';
import { SessionModel, UserModel } from '@/resources/models';
import { SearchApiResult } from '@/resources/interfaces';
import { Dayjs } from 'dayjs';

export class UserService {
  static search(query: any = {}): Promise<SearchApiResult<UserModel>> {
    return HttpService.get('/users', query).then(
      (res: SearchApiResult<any>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new UserModel(item)),
        };
      },
    );
  }

  static create(data: any) {
    return HttpService.post('/users', data);
  }

  static createMultipleUsers(data: any) {
    return HttpService.post('/users/multiple', data);
  }

  static update(id: string, data: any) {
    return HttpService.put(`/users/${id}`, data);
  }

  static find(id: string) {
    return HttpService.get(`/users/${id}`).then((res) => new UserModel(res));
  }

  static removeUser(id: string) {
    return HttpService.delete(`/users/${id}`);
  }

  static activeUser(id: string) {
    return HttpService.post(`/users/${id}/active`).then(
      (res) => new UserModel(res),
    );
  }

  static blockUser(id: string) {
    return HttpService.post(`/users/${id}/block`).then(
      (res) => new UserModel(res),
    );
  }

  static searchUserSessions(filter: any = {}) {
    return HttpService.get('/users/sessions', filter).then((res) => {
      const { data = [], totalCount } = res;

      return {
        data: data.map((item) => new SessionModel(item)),
        totalCount,
      };
    });
  }

  static getAdminUsersStatistics() {
    return HttpService.get('/users/admin-statistics');
  }

  static getUserCalendarDataAction(date: Dayjs) {
    return HttpService.get('/users/calendar', {
      date: date.format('YYYY-MM-DDTHH:mm:ss'),
    });
  }
}
