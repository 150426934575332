import React from 'react';
import { Route, Switch } from 'react-router';
import { ROUTES } from '@/constants';
import Dashboard from '@/pages/User/Dashboard';
import Account from '@/pages/User/Account';
import OfferSession from '@/pages/User/OfferSession';
import RequestSession from '@/pages/User/RequestSession';
import Activity from '@/pages/User/Activity';
import UserNotifications from 'src/pages/User/UserNotifications';
import Calendar from '@/pages/User/Calendar';
import CreateSpace from '@/pages/User/Account/CreateSpace';
import EditSpace from '@/pages/User/Account/EditSpace';
import ChangePassword from '@/parts/ChangePassword';
import Sessions from '@/pages/User/Sessions';

const User = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.USER.DASHBOARD} component={Dashboard} />
      <Route exact path={ROUTES.USER.OFFER_SESSION.INDEX} component={OfferSession} />
      <Route exact path={ROUTES.USER.REQUEST_SESSION.INDEX} component={RequestSession} />
      <Route exact path={ROUTES.USER.ACCOUNT.INDEX} component={Account} />
      <Route exact path={ROUTES.USER.ACTIVITY.INDEX} component={Activity} />
      <Route exact path={ROUTES.USER.SESSIONS.INDEX} component={Sessions} />
      <Route exact path={ROUTES.USER.NOTIFICATIONS.INDEX} component={UserNotifications} />
      <Route exact path={ROUTES.USER.CALENDAR.INDEX} component={Calendar} />
      <Route exact path={ROUTES.USER.SPACES.CREATE} component={CreateSpace} />
      <Route exact path={ROUTES.USER.SPACES.EDIT} component={EditSpace} />
      <Route exact path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
    </Switch>
  );
};

export default User;
