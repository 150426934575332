import React from 'react';
import { CompanyNewUserBanner } from 'src/pages/Company/Dashboard/CompanyNewUserBanner';
import { Typography } from '@mui/material';
import { useAuthState } from '@/hooks/redux';
import { CompanyModel } from '@/resources/models';
import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const [t] = useTranslation('common');
  const { account } = useAuthState();
  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {(account.company as CompanyModel).name} {t('app.dashboard')}
      </Typography>
      <CompanyNewUserBanner />
    </div>
  );
};

export default Dashboard;
