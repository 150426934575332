import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  createCompanyAction,
  createCompanyAdminAction,
  disableCompanyAction,
  findCompanyAction,
  getCompaniesAction,
  getCompanyDetailUsersAction,
  getCompanySessionsAction,
  getCompanyUsersAction,
  removeCompanyAction,
  updateCompanyAction,
} from '@/redux/actions';

export const useCompanyState = () =>
  useSelector(({ companyReducer }: RootState) => companyReducer);

export const useCreateCompanyAction = () => {
  const dispatch = useDispatch();
  return (data: any) => dispatch(createCompanyAction(data));
};

export const useCreateCompanyAdminAction = () => {
  const dispatch = useDispatch();
  return (id: string, data: any) =>
    dispatch(createCompanyAdminAction(id, data));
};

export const useSearchCompaniesAction = () => {
  const dispatch = useDispatch();
  return (filter: any = {}) => dispatch(getCompaniesAction(filter));
};

export const useRemoveCompanyAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(removeCompanyAction(id));
};

export const useDisableCompanyAction = () => {
  const dispatch = useDispatch();
  return (id: string, disabled: boolean) =>
    dispatch(disableCompanyAction(id, disabled));
};

export const useGetCompanyUsersAction = () => {
  const dispatch = useDispatch();
  return (query: any = {}) => dispatch(getCompanyUsersAction(query));
};

export const useGetCompanySessionsAction = () => {
  const dispatch = useDispatch();
  return (query: any = {}) => dispatch(getCompanySessionsAction(query));
};

export const useFindCompanyAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(findCompanyAction(id));
};

export const useUpdateCompanyAction = () => {
  const dispatch = useDispatch();
  return (id: string, data: any) => dispatch(updateCompanyAction(id, data));
};

export const useGetCompanyDetailUsersAction = () => {
  const dispatch = useDispatch();
  return (id: string, query: any = {}) =>
    dispatch(getCompanyDetailUsersAction(id, query));
};
