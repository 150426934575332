import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ROUTES } from '@/constants';
import { Button, Input } from '@/components';
import {
  useAuthState,
  useForgotPasswordAction,
  useShowToastAction,
} from '@/hooks/redux';
import colors from '@/themes/colors';
import { useHistory } from 'react-router';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('validation.email_invalid')
    .required('validation.email_required'),
});

const ForgotPassword: FC = () => {
  const [t] = useTranslation('common');
  const showToast = useShowToastAction();
  const history = useHistory();
  const forgotPassword = useForgotPasswordAction();
  const { loading } = useAuthState();

  const handleSubmit = async (values: any) => {
    const { email } = values;
    const res = await forgotPassword(email);

    if (res) {
      showToast(t('auth.email_sent_success'));
    }
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      email: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Typography variant="h4" color={colors.text} mb={2}>
        {t('auth.forgot_password')}
      </Typography>
      <Typography variant="body1" color={colors.text} mb={4}>
        {t('auth.enter_email_reset')}
      </Typography>

      <form onSubmit={form.handleSubmit}>
        <Input
          fullWidth
          label={t('common.email')}
          placeholder={t('auth.email_placeholder')}
          sx={{ mb: 8 }}
          {...form.getFieldProps('email')}
          errorText={
            form.errors.email && form.touched.email
              ? t(form.errors.email as string)
              : ''
          }
        />

        <Button fullWidth type="submit" variant="auth" loading={loading}>
          {t('common.send')}
        </Button>
      </form>

      <div className="flex-center mt-8 text-center">
        <Button
          fullWidth
          variant="authLight"
          onClick={() => {
            history.push(ROUTES.AUTH.LOGIN);
          }}
        >
          {t('auth.back_to_login')}
        </Button>
      </div>
    </>
  );
};

export default ForgotPassword;
