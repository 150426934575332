import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { ROUTES, USER_ACTIVITY_TABS } from '@/constants';
import WorkingSessions from '@/pages/User/Activity/WorkingSessions';
import Offers from '@/pages/User/Activity/Offers';
import Demands from '@/pages/User/Activity/Demands';

const sessionsTabs = [
  { title: 'app.working_sessions', value: USER_ACTIVITY_TABS.WORKING },
  { title: 'app.offers', value: USER_ACTIVITY_TABS.OFFERS },
  { title: 'app.requests', value: USER_ACTIVITY_TABS.DEMANDS },
];

const Activity = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const { tab } = useParams<{ tab: string }>();

  const activeTab = useMemo(() => {
    if (tab === USER_ACTIVITY_TABS.DEMANDS || tab === USER_ACTIVITY_TABS.OFFERS)
      return tab;

    return USER_ACTIVITY_TABS.WORKING;
  }, [tab]);

  const onTabChange = (tab: USER_ACTIVITY_TABS) => {
    history.replace(`${ROUTES.USER.ACTIVITY.PREFIX}/${tab}`);
  };

  return (
    <>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {t('app.my_activity')}
      </Typography>
      <Tabs
        value={activeTab}
        onChange={(e, value: USER_ACTIVITY_TABS) => onTabChange(value)}
        TabIndicatorProps={{
          style: { display: 'none' },
        }}
      >
        {sessionsTabs.map((item) => (
          <Tab key={item.value} label={t(item.title)} value={item.value} />
        ))}
      </Tabs>

      <Box sx={{ pt: 15 }}>
        {activeTab === USER_ACTIVITY_TABS.WORKING && <WorkingSessions />}

        {activeTab === USER_ACTIVITY_TABS.OFFERS && <Offers />}

        {activeTab === USER_ACTIVITY_TABS.DEMANDS && <Demands />}
      </Box>
    </>
  );
};

export default Activity;
