import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { OptionItem, Select } from '@/components';

interface UrbanAreaItemProps {
  urbanArea: { id: string; name: string };
  options: OptionItem[];
  onChange: (value: string) => void;
  removeUrbanArea: () => void;
  canRemove?: boolean;
  error?: any;
}

const UrbanAreaItem: FC<UrbanAreaItemProps> = ({
  urbanArea,
  options,
  onChange,
  removeUrbanArea,
  canRemove,
  error,
}) => {
  const [t] = useTranslation('common');

  return (
    <Grid container rowSpacing={8} columnSpacing={5} sx={{ mb: 5 }}>
      <Grid item xs={11} sm={9}>
        <Select
          label={t('common.urban_area_name')}
          fullWidth
          options={options}
          value={urbanArea.id}
          onChange={(e) => onChange(String(e.target.value))}
          errorText={error?.name ? error?.name : ''}
        />
      </Grid>

      {canRemove && (
        <Grid item xs={1}>
          <Tooltip title={t('companies.remove_urban_area')}>
            <IconButton color="error" onClick={removeUrbanArea}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default UrbanAreaItem;
