import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, Checkbox, Grid } from '@mui/material';
import { FormikContextType, FormikErrors, FormikTouched } from 'formik';
import { Input, Select } from '@/components';
import { GENDER, ROLE, USER_STATUS } from '@/resources/enums';
import { useAccountState, useAuthState } from '@/hooks/redux';
import { CompanyModel } from '@/resources/models';

const genderOptions = [
  { label: 'session.no_preference', value: false },
  { label: 'common.male', value: GENDER.MALE },
  { label: 'common.female', value: GENDER.FEMALE },
  { label: 'common.other', value: GENDER.OTHER },
];

const ageOptions = [
  { label: 'session.no_preference', value: false },
  { label: 'session.around_my_age', value: true },
];

interface SessionUserProps {
  form: FormikContextType<any>;
}

const SessionUsers: FC<SessionUserProps> = ({ form }) => {
  const [t] = useTranslation('common');
  const { companyUsers } = useAccountState();
  const { account } = useAuthState();

  const nameSuggestions = useMemo(() => {
    return companyUsers
      .filter((user) => {
        return (
          user.roles.includes(ROLE.USER) &&
          user.status === USER_STATUS.ACTIVE &&
          account.id !== user.id
        );
      })
      .map((user) => user.fullName);
  }, [companyUsers, account.id]);

  const unitSuggestions = useMemo(() => {
    return (account.company as CompanyModel).units.map((item) => item.name);
  }, [account]);

  return (
    <Box sx={{ p: 8 }}>
      <Grid container columnSpacing={10} rowSpacing={5}>
        <Grid item xs={9}>
          <span>{t('session.my_preferences')}</span>
        </Grid>
        <Grid item xs={3}>
          <span>{t('common.mandatory')}</span>
        </Grid>

        <Grid item xs={9}>
          <Autocomplete
            freeSolo
            disableClearable
            options={nameSuggestions}
            value={form.values.names.value}
            onChange={(e, value) => form.setFieldValue('names.value', value)}
            renderInput={(params) => (
              <Input
                {...params}
                label={t('common.names')}
                fullWidth
                {...form.getFieldProps('names.value')}
                errorText={
                  (form.errors.names as FormikErrors<any>)?.value &&
                  (form.touched.names as FormikTouched<any>)?.value
                    ? t(
                        (form.errors.names as FormikErrors<any>)
                          ?.value as string,
                      )
                    : ''
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={3} className="flex-center">
          <Checkbox
            checked={form.values.names.mandatory}
            onChange={(e) =>
              form.setFieldValue('names.mandatory', e.target.checked)
            }
          />
        </Grid>

        <Grid item xs={9}>
          <Autocomplete
            freeSolo
            disableClearable
            options={unitSuggestions}
            value={form.values.units.value}
            onChange={(e, value) => form.setFieldValue('units.value', value)}
            renderInput={(params) => (
              <Input
                {...params}
                label={t('common.units')}
                fullWidth
                {...form.getFieldProps('units.value')}
                errorText={
                  (form.errors.units as FormikErrors<any>)?.value &&
                  (form.touched.units as FormikTouched<any>)?.value
                    ? t(
                        (form.errors.units as FormikErrors<any>)
                          ?.value as string,
                      )
                    : ''
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={3} className="flex-center">
          <Checkbox
            checked={form.values.units.mandatory}
            onChange={(e) =>
              form.setFieldValue('units.mandatory', e.target.checked)
            }
          />
        </Grid>

        <Grid item xs={9}>
          <Select
            label={t('common.gender')}
            fullWidth
            options={genderOptions}
            {...form.getFieldProps('gender.value')}
            errorText={
              (form.errors.gender as FormikErrors<any>)?.value &&
              (form.touched.gender as FormikTouched<any>)?.value
                ? t((form.errors.gender as FormikErrors<any>)?.value as string)
                : ''
            }
          />
        </Grid>
        <Grid item xs={3} className="flex-center">
          <Checkbox
            checked={form.values.gender.mandatory}
            onChange={(e) =>
              form.setFieldValue('gender.mandatory', e.target.checked)
            }
          />
        </Grid>

        <Grid item xs={9}>
          <Select
            label={t('common.age')}
            fullWidth
            options={ageOptions}
            {...form.getFieldProps('aroundAge.value')}
            errorText={
              (form.errors.aroundAge as FormikErrors<any>)?.value &&
              (form.touched.aroundAge as FormikTouched<any>)?.value
                ? t(
                    (form.errors.aroundAge as FormikErrors<any>)
                      ?.value as string,
                  )
                : ''
            }
          />
        </Grid>
        <Grid item xs={3} className="flex-center">
          <Checkbox
            checked={form.values.aroundAge.mandatory}
            onChange={(e) =>
              form.setFieldValue('aroundAge.mandatory', e.target.checked)
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SessionUsers;
