import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { Check } from '@mui/icons-material';
import { useReadNotificationAction, useShowToastAction } from '@/hooks/redux';
import { NotificationModel } from '@/resources/models';

interface NotificationReadButtonProps {
  notification: NotificationModel;
}

const NotificationReadButton: FC<NotificationReadButtonProps> = ({
  notification
}) => {
  const [t] = useTranslation('common');
  const [loading, setLoading] = useState(false);
  const [read, setRead] = useState(notification.read);
  const showToast = useShowToastAction();

  const readNotification = useReadNotificationAction();

  const markAsRead = async () => {
    setLoading(true);
    const res = await readNotification(notification.id);
    setLoading(false);

    if (res) {
      showToast(t('notifications.mark_as_read_message'));
      setRead(true);
    }
  };

  return (
    <>
      {
        read ? (
          t('notifications.read')
        ) : (
          <Tooltip title={t('notifications.mark_as_read')}>
            <IconButton color="success" onClick={markAsRead} disabled={loading}>
              <Check />
            </IconButton>
          </Tooltip>
        )
      }
    </>
  );
};

export default NotificationReadButton;
