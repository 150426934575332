import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';
import moment from 'moment-timezone';
import { useCreateMultipleUsersAction, useUserState } from '@/hooks/redux';
import { Button } from '@/components';
import { ObjectHelper } from '@/utils';

interface UploadUsersButtonProps {
  refresh: () => void;
}

const UploadUsersButton: FC<UploadUsersButtonProps> = ({ refresh }) => {
  const [t] = useTranslation('common');

  const uploadUsers = useCreateMultipleUsersAction();
  const { creating } = useUserState();

  const onDrop = (files: File[]) => {
    if (files.length) {
      const xlsxReader = new FileReader();

      xlsxReader.onload = async (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const convertOptions = {};
        const result = XLSX.utils.sheet_to_json(worksheet, convertOptions);
        await createMultipleUsers(result);
      };

      xlsxReader.readAsArrayBuffer(files[0]);
    }
  };

  const createMultipleUsers = async (data: any[]) => {
    const users = data.map((item) => {
      const {
        firstName,
        lastName,
        gender,
        email,
        birthday,
        mobilePhone,
        unit,
        country,
        postalCode,
        city,
        urbanArea,
        street,
        numberOfStreet,
        description,
      } = item;

      return ObjectHelper.trimQuery({
        user: {
          firstName,
          lastName,
          email,
          unit,
          gender: gender.toLowerCase(),
          birthday: moment(birthday).toISOString(),
          mobilePhone: Number(mobilePhone),
        },
        address: {
          country,
          city,
          urbanArea: urbanArea,
          street,
          description,
          postalCode: String(postalCode),
          numberOfStreet: String(numberOfStreet),
        },
      });
    });

    await uploadUsers({ users });

    refresh();
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    multiple: false,
    disabled: creating,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} accept=".xlsx" />

      <Button loading={creating}>
        {t('company_account.users.upload_users')}
      </Button>
    </div>
  );
};

export default UploadUsersButton;
