import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { SpaceModel } from '@/resources/models';
import { useDeleteSpaceAction, useShowToastAction, useSpaceState } from '@/hooks/redux';
import ConfirmDialog from '@/components/ConfimDialog';

interface SpaceRemoveButtonProps {
  space: SpaceModel
}

const SpaceRemoveButton: FC<SpaceRemoveButtonProps> = ({
  space,
}) => {
  const [t] = useTranslation('common');
  const [showConfirm, setShowConfirm] = useState(false);
  const { deleting } = useSpaceState();
  const deleteSpace = useDeleteSpaceAction();
  const showToast = useShowToastAction();

  const onDelete = async () => {
    const res = await deleteSpace(space.id);

    if (res) {
      showToast(t('space.space_deleted_message'));
    }
  }

  return (
    <>
      <IconButton
        color="error"
        disabled={deleting}
        onClick={() => setShowConfirm(true)}
      >
        <Delete />
      </IconButton>

      <ConfirmDialog
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        onConfirm={onDelete}
        confirmTitle={t('space.delete_space')}
        confirmText={t('space.delete_space_confirm')}
      />
    </>
  )
};

export default SpaceRemoveButton;
