import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressModel, DemandModel } from '@/resources/models';
import { DialogContent, Divider } from '@mui/material';
import { Dialog } from '@/components';
import DetailItem from '@/parts/DetailItem';
import {
  useAddressDetailList,
  usePartnerOptionDetailList,
  useSessionTimePeriodList,
} from '@/hooks/app';
import { NameHelper } from '@/utils';
import { SPACE_PRIVACY } from '@/resources/enums';

interface DemandDetailDialogProps {
  demand: DemandModel;
  open: boolean;
  handleClose: () => void;
}

const DemandDetailDialog: FC<DemandDetailDialogProps> = ({
  demand,
  open,
  handleClose,
}) => {
  const [t] = useTranslation('common');

  const address = demand.address as AddressModel;
  const { spaceOptions } = demand;

  const addressList = useAddressDetailList(address);
  const timePeriodList = useSessionTimePeriodList(
    demand.startTime,
    demand.endTime,
  );
  const hostOptions = usePartnerOptionDetailList(demand.hosts);

  const preferenceList = [
    {
      label: t('session.maximum_distance'),
      value:
        spaceOptions.maximumDistance.value === undefined
          ? t('session.no_preference')
          : `${spaceOptions.maximumDistance.value} km (${
              spaceOptions.maximumDistance.mandatory
                ? t('common.mandatory')
                : t('common.optional')
            })`,
    },
    {
      label: t('session.privacy_request'),
      value: `${NameHelper.getPrivacyOptionName(spaceOptions.privacy.value)} ${
        spaceOptions.privacy.value === SPACE_PRIVACY.NO_PREFERENCE
          ? ''
          : `(${
              spaceOptions.privacy.mandatory
                ? t('common.mandatory')
                : t('common.optional')
            })`
      }`,
    },
    {
      label: t('session.accessibility'),
      value: `${
        spaceOptions.accessibility.value ? t('common.yes') : t('common.no')
      } (${
        spaceOptions.accessibility.mandatory
          ? t('common.mandatory')
          : t('common.optional')
      })`,
    },
    {
      label: t('session.parking'),
      value: `${
        spaceOptions.parking.value ? t('common.yes') : t('common.no')
      } (${
        spaceOptions.parking.mandatory
          ? t('common.mandatory')
          : t('common.optional')
      })`,
    },
    {
      label: t('session.child_friendly'),
      value: `${
        spaceOptions.childFriendly.value ? t('common.yes') : t('common.no')
      } (${
        spaceOptions.childFriendly.mandatory
          ? t('common.mandatory')
          : t('common.optional')
      })`,
    },
    {
      label: t('session.pet_friendly'),
      value: `${
        spaceOptions.petFriendly.value ? t('common.yes') : t('common.no')
      } (${
        spaceOptions.petFriendly.mandatory
          ? t('common.mandatory')
          : t('common.optional')
      })`,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('session.demand_detail')}
    >
      <DialogContent sx={{ py: 5 }}>
        <DetailItem
          key="common_status"
          label={t('common.status')}
          value={demand.status}
        />

        <Divider sx={{ mb: 3 }}>{t('common.address')}</Divider>
        {addressList.map((item, index) => (
          <DetailItem
            key={`address-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}

        <Divider sx={{ mb: 3 }}>{t('session.my_preferences')}</Divider>
        {preferenceList.map((item, index) => (
          <DetailItem
            key={`address-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}

        <Divider sx={{ mb: 3 }}>{t('app.time')}</Divider>
        {timePeriodList.map((item, index) => (
          <DetailItem
            key={`time-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}

        <Divider sx={{ mb: 3 }}>{t('app.hosts')}</Divider>
        {hostOptions.map((item, index) => (
          <DetailItem
            key={`host-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}
      </DialogContent>
    </Dialog>
  );
};

export default DemandDetailDialog;
