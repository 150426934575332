import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { Button } from '@/components';
import { userFormValidation } from '@/validations';
import { GENDER, ROLE } from '@/resources/enums';
import {
  useCompanyState,
  useCreateCompanyAction,
  useShowToastAction,
  useUpdateCompanyAccount,
} from '@/hooks/redux';
import { useHistory } from 'react-router';
import { FormHelper, ObjectHelper } from '@/utils';
import { ROUTES } from '@/constants';
import { Add as AddIcon } from '@mui/icons-material';
import CreateCompanyUsersForm from '@/parts/PostCompany/CreateCompanyUsersForm';

const validationSchema = Yup.object().shape({
  users: Yup.array().of(
    Yup.object().shape({
      ...userFormValidation,
      // roles: Yup.string().required('validation.required_field'),
    }),
  ),
});

interface ContactInfoProps {
  onBack: () => void;
  companyData: any;
}

const CompanyContactInfo: FC<ContactInfoProps> = ({ onBack, companyData }) => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const { creating } = useCompanyState();
  const { loading } = useUpdateCompanyAccount();
  const createCompany = useCreateCompanyAction();
  const showToast = useShowToastAction();

  const initUserData = {
    firstName: '',
    lastName: '',
    email: '',
    birthday: moment(),
    gender: GENDER.MALE,
    unit: '',
    mobilePhone: '',
    roles: ROLE.COMPANY,
  };

  const handleSubmit = async (values: any) => {
    const newData = {
      ...companyData,
      urbanAreas: companyData.urbanAreas.map((item) => item.id),
      users: values.users.map((u) => ({
        ...u,
        mobilePhone: Number(u.mobilePhone),
        birthday: moment(u.birthday).toISOString(),
        roles: Array.isArray(u.roles) ? u.roles : [u.roles],
      })),
    };

    const res = await createCompany(ObjectHelper.trimQuery(newData));
    if (res) {
      showToast(t('companies.create_company_success_message'));
      history.push(ROUTES.ADMIN.COMPANIES.INDEX);
    }
  };

  const addUser = () => {
    form.setValues({
      users: [
        ...form.values.users,
        {
          key: Math.random(),
          ...initUserData,
        },
      ],
    });
  };

  const removeUser = (key) => {
    form.setValues({
      users: form.values.users.filter((user) => user.key !== key),
    });
  };

  const form = useFormik({
    validationSchema,
    validateOnBlur: false,
    initialValues: {
      users: [
        ...companyData.users,
        ...(companyData.users.length > 1
          ? []
          : [
              {
                key: Math.random(),
                ...initUserData,
              },
            ]),
      ],
    },
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={{ mx: 'auto', maxWidth: '900px' }}>
      <form
        onSubmit={form.handleSubmit}
        onKeyDown={FormHelper.onKeyDownPreventEnter}
      >
        {form.values.users.map((user, index) => (
          <CreateCompanyUsersForm
            key={`user-form-${user.key}`}
            form={form}
            index={index}
            units={companyData.units}
            allowRemove={form.values.users.length > 1}
            onRemove={() => {
              removeUser(user.key);
            }}
          />
        ))}

        <div className="d-flex align-items-center justify-content-end">
          <Button
            sx={{ mr: 2 }}
            variant="link"
            startIcon={<AddIcon />}
            onClick={addUser}
          >
            {t('companies.add_more_contact_person')}
          </Button>
        </div>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{ mr: 5 }}
            variant="outlined"
            onClick={onBack}
            type="button"
          >
            {t('common.back')}
          </Button>
          <Button type="submit" variant="blue" loading={creating || loading}>
            {t('common.submit')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CompanyContactInfo;
