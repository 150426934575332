import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Order, TableColumn } from '@/components';

interface TableHeaderProps {
  columns: TableColumn[];
  orderBy?: string;
  order?: Order;
  onSortChange: (property: string) => void;
}

const TableHeader: FC<TableHeaderProps> = ({
  columns,
  order,
  orderBy,
  onSortChange,
}) => {
  const [t] = useTranslation('common');

  return (
    <TableHead>
      <TableRow>
        {
          columns.map((column, index) => {
            return (
              <TableCell
                key={index}
                align={column.align}
                sortDirection={orderBy === column.dataIndex ? order : false}
              >
                {
                  column.sortable ? (
                    <TableSortLabel
                      active={orderBy === column.dataIndex}
                      direction={orderBy === column.dataIndex ? order : 'asc'}
                      onClick={() => onSortChange(column.dataIndex)}
                    >
                      {t(column.title)}
                    </TableSortLabel>
                  ) : t(column.title)
                }
              </TableCell>
            );
          })
        }
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
