import { Moment } from 'moment-timezone';

export enum END_TYPE {
  AFTER = 'after_occurrence',
  ON = 'on_date',
}

export enum REPEAT_UNIT {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
}

export interface RepeatOption {
  amount: number;
  unit?: REPEAT_UNIT;
  endType: END_TYPE;
  endOn?: string;
  endAfter?: number;
}

export interface SessionPeriod {
  id: string;
  date: Moment | string;
  startTime: Moment | string;
  endTime: Moment | string;
  repeat?: RepeatOption;
}
