import { Dispatch } from 'redux';
import { NOTIFICATION_ACTIONS } from '@/redux/action-types';
import { NotificationModel } from '@/resources/models';
import { NotificationService } from '@/service';
import { showToastAction } from '@/redux/actions';

const getNotificationsRequest = () => ({
  type: NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_REQUEST,
});

const getNotificationsSuccess = (
  notifications: NotificationModel[],
  totalCount: number,
) => ({
  type: NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_SUCCESS,
  payload: { notifications, totalCount },
});

const getNotificationsError = () => ({
  type: NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_ERROR,
});

export const getNotificationsAction =
  (query: any = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(getNotificationsRequest());

    try {
      const { data, totalCount } = await NotificationService.search(query);
      dispatch(getNotificationsSuccess(data, totalCount));
      return { data, totalCount };
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(getNotificationsError());
    }
  };

export const getUnreadCountAction = () => async (dispatch: Dispatch) => {
  try {
    const totalCount = await NotificationService.getUnreadCount();
    dispatch({
      type: NOTIFICATION_ACTIONS.SET_UNREAD_COUNT,
      payload: { unreadCount: totalCount },
    });
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
  }
};

export const addUnreadCountAction = () => (dispatch: Dispatch) => {
  dispatch({
    type: NOTIFICATION_ACTIONS.ADD_UNREAD_COUNT,
  });
};

export const readNotificationAction =
  (id: string) => async (dispatch: Dispatch) => {
    try {
      await NotificationService.markAsRead(id);
      dispatch({
        type: NOTIFICATION_ACTIONS.MARK_NOTIFICATION_READ,
        payload: { id },
      });

      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
    }
  };
