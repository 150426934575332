import React from 'react';
import { Badge, Box } from '@mui/material';
import { ROUTES } from '@/constants';
import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useNotificationState } from '@/hooks/redux';
import { useTranslation } from 'react-i18next';
import { NotificationsButtonStyled } from '@/parts/SidebarRight/styles';
import { grey } from '@mui/material/colors';

const Notifications = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const { unreadCount } = useNotificationState();

  return (
    <NotificationsButtonStyled
      onClick={() => history.push(ROUTES.USER.NOTIFICATIONS.INDEX)}
    >
      <NotificationsIcon fontSize="large" style={{ color: grey[500] }} />
      <Box>{t('common.notifications')}</Box>
      <Badge
        sx={{
          marginLeft: 'auto',
          marginRight: '15px',
        }}
        badgeContent={unreadCount}
        color="error"
        // variant="red"
      />
    </NotificationsButtonStyled>
  );
};

export default Notifications;
