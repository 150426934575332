import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const LinkGreyStyled = styled(Link)`
  color: ${props => props.theme.colors.text_grey};
  font-size: 14px;
  font-family: 'Montserrat', 'Public Sans', sans-serif;
  font-weight: 500;
  "&:hover": {
    color: ${props => props.theme.colors.text_secondary};
  } 
}
`;
