import React, { FC, useEffect } from 'react';
import { useGetAdminUsersStatisticsAction } from '@/hooks/redux';

const AdminPreloader: FC = ({ children }) => {
  const getAdminUsersStatistics = useGetAdminUsersStatisticsAction();

  useEffect(() => {
    getAdminUsersStatistics();
  }, []);

  return <>{children}</>;
};

export default AdminPreloader;
