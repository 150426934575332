import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { DateRange, Edit, Settings } from '@mui/icons-material';
import { ADMIN_COMPANY_DETAIL_TABS, ROUTES } from '@/constants';
import { AddressModel, CompanyModel, CountryModel } from '@/resources/models';
import {
  PaginationOptions,
  SortOption,
  Table,
  TableColumn,
} from '@/components';
import { DateHelper } from '@/utils';
import RemoveCompanyButton from './RemoveCompanyButton';
import CompanyDetailButton from './CompanyDetailButton';
import DisableCompanyButton from '@/pages/Admin/Companies/CompanyTable/DisableCompanyButton';

interface CompanyTableProps {
  companies: CompanyModel[];
  loading?: boolean;
  pagination?: PaginationOptions;
  reload: () => void;
  sortOption?: SortOption;
  setSortOption?: (value: SortOption) => void;
}

const CompanyTable: FC<CompanyTableProps> = ({
  companies,
  loading,
  pagination,
  reload,
  sortOption,
  setSortOption,
}) => {
  const [t] = useTranslation('common');
  const history = useHistory();

  const tableColumns: TableColumn[] = [
    { title: 'common.name', dataIndex: 'name', sortable: true },
    { title: 'common.country', dataIndex: 'country' },
    { title: 'common.urban_areas', dataIndex: 'urbanAreas' },
    { title: 'common.units', dataIndex: 'units' },
    { title: 'common.users', dataIndex: 'users', align: 'right' },
    { title: 'common.sessions', dataIndex: 'sessions', align: 'right' },
    {
      title: 'common.created_at',
      dataIndex: 'createdAt',
      align: 'right',
      sortable: true,
    },
    {
      title: '',
      dataIndex: '',
      align: 'right',
      render: (company: any) => (
        <>
          <CompanyDetailButton company={company} />

          <Tooltip title={t('companies.edit_company')}>
            <IconButton
              color="primary"
              onClick={() =>
                history.push(
                  `${ROUTES.ADMIN.COMPANIES.PREFIX}/${company.id}/${ADMIN_COMPANY_DETAIL_TABS.DETAIL}`,
                )
              }
            >
              <Edit />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('companies.company_calendar')}>
            <IconButton
              color="secondary"
              onClick={() =>
                history.push(
                  `${ROUTES.ADMIN.COMPANIES.PREFIX}/${company.id}/calendar`,
                )
              }
            >
              <DateRange />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('companies.company_rules')}>
            <IconButton
              color="secondary"
              onClick={() =>
                history.push(
                  `${ROUTES.ADMIN.COMPANIES.PREFIX}/${company.id}/rules`,
                )
              }
            >
              <Settings />
            </IconButton>
          </Tooltip>

          <DisableCompanyButton
            id={company.id}
            disabled={company.disabled}
            onDisableCompany={reload}
          />
          {company.disabled && (
            <RemoveCompanyButton id={company.id} onRemoveCompany={reload} />
          )}
        </>
      ),
    },
  ];

  const data = useMemo(() => {
    return companies.map((company) => {
      const address = company.address as AddressModel;

      return {
        ...company,
        urbanAreas: company.urbanAreas.length,
        units: company.units.length,
        country: (address?.country as CountryModel)?.name,
        createdAt: DateHelper.formatDate(company.createdAt, 'DD/MM/YYYY'),
      };
    });
  }, [companies]);

  return (
    <Table
      columns={tableColumns}
      data={data}
      loading={loading}
      sortOption={sortOption}
      setSortOption={setSortOption}
      pagination={pagination}
    />
  );
};

export default CompanyTable;
