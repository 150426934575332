import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Badge, Box, Tooltip, Typography } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import './styles.scss';
import { useGetUserCalendarDataAction, useUserState } from '@/hooks/redux';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import colors from '@/themes/colors';
import { useHistory } from 'react-router';
import { ROUTES, USER_ACTIVITY_TABS } from '@/constants';

dayjs.extend(dayOfYear);

const CalendarSidebar = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  const getUserCalendarDataAction = useGetUserCalendarDataAction();
  const { calendarData, loadingCalendarData } = useUserState();
  const initialValue = dayjs(new Date());

  const handleMonthChange = async (date: Dayjs) => {
    await getUserCalendarDataAction(date);
  };

  const getDayBadge = (day: Dayjs) => {
    const currentDayNumber = day.dayOfYear();
    const todayItems = calendarData.filter((item) => {
      const startDayNumber = dayjs(item.item.startTime).dayOfYear();
      const endDayNumber = dayjs(item.item.endTime).dayOfYear();

      return (
        startDayNumber <= currentDayNumber && endDayNumber >= currentDayNumber
      );
    });

    const sessions = todayItems.filter((i) => i.type === 'session');
    const offers = todayItems.filter((i) => i.type === 'offer');
    const requests = todayItems.filter((i) => i.type === 'request');

    let bgColor = colors.btn_red_bg;
    const tooltipTexts: string[] = [];
    if (sessions.length > 0) {
      bgColor = colors.btn_red_bg;
      tooltipTexts.push(`${t('app.sessions')}: ${sessions.length}`);
    } else if (offers.length > 0) {
      bgColor = colors.dark_blue;
      tooltipTexts.push(`${t('app.offers_sessions')}: ${offers.length}`);
    } else if (requests.length > 0) {
      bgColor = colors.blue;
      tooltipTexts.push(`${t('app.request_sessions')}: ${requests.length}`);
    }

    return todayItems.length > 0 ? (
      <Tooltip title={tooltipTexts.join('\n\r')}>
        <Box
          sx={{
            backgroundColor: bgColor,
            color: (theme) => theme.colors.white,
            width: '12px',
            height: '12px',
            borderRadius: '12px',
            fontSize: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
      </Tooltip>
    ) : (
      ''
    );
  };

  const Day = (props: PickersDayProps<Dayjs>) => {
    const { day, outsideCurrentMonth, ...other } = props;

    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={outsideCurrentMonth ? '' : getDayBadge(day)}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          onClick={() =>
            history.push(
              `${ROUTES.USER.ACTIVITY.PREFIX}/${USER_ACTIVITY_TABS.WORKING}`,
            )
          }
        />
      </Badge>
    );
  };

  React.useEffect(() => {
    handleMonthChange(initialValue);
  }, []);

  return (
    <Box
      sx={{
        mb: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          readOnly
          views={['day']}
          sx={{
            fontSize: '0.5rem',
          }}
          slots={{
            day: Day,
          }}
          onMonthChange={handleMonthChange}
          loading={loadingCalendarData}
          renderLoading={() => <DayCalendarSkeleton />}
          defaultValue={initialValue}
          value={null}
        />
      </LocalizationProvider>
      <Box sx={{ fontFamily: 'Public Sans', w: '150px', m: 'auto' }}>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: '14px',
          }}
        >
          <FiberManualRecord
            fontSize="small"
            sx={{ color: (theme) => theme.colors.btn_red_bg, mr: '10px' }}
          />
          {t('app.sessions')}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: '14px',
          }}
        >
          <FiberManualRecord
            fontSize="small"
            sx={{ color: (theme) => theme.colors.dark_blue, mr: '10px' }}
          />
          {t('app.offers_sessions')}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: '14px',
          }}
        >
          <FiberManualRecord
            fontSize="small"
            sx={{ color: (theme) => theme.colors.blue, mr: '10px' }}
          />
          {t('app.request_sessions')}
        </Typography>
      </Box>
    </Box>
  );
};

export default CalendarSidebar;
