import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { BackButton } from '@/components';
import PostUser from '@/parts/PostUser';

const AddUser = () => {
  const [t] = useTranslation('common');

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 15
        }}
      >
        <Typography
          variant={'h1'}
          sx={{color: theme => theme.colors.text_secondary}}
        >
          {t('user.add_user')}
        </Typography>

        <BackButton />
      </Box>

      <PostUser />
    </div>
  );
};

export default AddUser;
