import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Box,
  ListItemIcon,
  IconButton,
  Typography,
  ListItemText,
  List,
} from '@mui/material';
import { Edit, ExpandLess, ExpandMore, Logout } from '@mui/icons-material';
import { useAuthState, useLogout } from '@/hooks/redux';
import { Avatar } from '@/components';
import { ROUTES } from '@/constants';
import {
  ListItemStyled,
  UserMenuInfoStyled,
  UserMenuStyled,
} from '@/parts/SidebarRight/styles';

const AccountMenu = () => {
  const [t] = useTranslation('common');
  const { account } = useAuthState();
  const history = useHistory();
  const logout = useLogout();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return history.listen(() => {
      setIsOpen(false);
    });
  }, []);

  const switchMenu = () => {
    setIsOpen(!isOpen);
  };

  const doLogout = () => {
    logout();
    history.push(ROUTES.AUTH.LOGIN);
  };

  return (
    <UserMenuStyled
      onClick={switchMenu}
      className={isOpen ? 'isOpen' : ''}
      sx={{ userSelect: 'none' }}
    >
      <UserMenuInfoStyled>
        <IconButton size="small" sx={{ ml: 2 }}>
          <Avatar name={account.fullName} size="2.5rem" round />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            color: (theme) => theme.colors.text,
            textWrap: 'nowrap',
            overflow: 'hidden',
          }}
        >
          <Typography
            sx={{
              fontWeight: '700',
              overflow: 'hidden',
              maxWidth: '150px',
              whiteSpace: 'nowrap',
            }}
            variant="body3"
          >
            {account.fullName}
          </Typography>
          <Typography sx={{ fontWeight: '400' }} variant="body3">
            {/*{NameHelper.getRoleName(currentRole)} */}
            {account.email}
          </Typography>
        </Box>
        {!isOpen && <ExpandMore sx={{ ml: 'auto' }} />}
        {isOpen && <ExpandLess sx={{ ml: 'auto' }} />}
      </UserMenuInfoStyled>
      {isOpen && (
        <List component="nav" sx={{ width: '100%', m: '0 0 10px 25px', p: 0 }}>
          <ListItemStyled onClick={() => history.push(ROUTES.CHANGE_PASSWORD)}>
            <ListItemIcon sx={{ minWidth: '22px', color: 'inherit' }}>
              <Edit style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontSize: '12px' }}>
                {t('app.change_password')}
              </Typography>
            </ListItemText>
          </ListItemStyled>
          <ListItemStyled onClick={doLogout}>
            <ListItemIcon sx={{ minWidth: '22px', color: 'inherit' }}>
              <Logout style={{ fontSize: '16px' }} />
            </ListItemIcon>
            <ListItemText>
              <Typography sx={{ fontSize: '12px' }}>
                {t('auth.logout')}
              </Typography>
            </ListItemText>
          </ListItemStyled>
        </List>
      )}
    </UserMenuStyled>
  );
};

export default AccountMenu;
