import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { UserModel } from '@/resources/models';
import UserDetailDialog from './UserDetailDialog';

interface UserDetailButtonProps {
  user: UserModel;
}

const UserDetailButton: FC<UserDetailButtonProps> = ({
  user,
}) => {
  const [t] = useTranslation('common');
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Tooltip title={t('common.show_detail')} >
        <IconButton color="primary" onClick={() => setShowDialog(true)}>
          <Visibility />
        </IconButton>
      </Tooltip>

      <UserDetailDialog
        user={user}
        open={showDialog}
        handleClose={() => setShowDialog(false)}
      />
    </>
  )
};

export default UserDetailButton;
