import React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  AccountCircle, Apartment,
  Home as HomeIcon, Receipt,
  ViewModule,
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { ROUTES } from '@/constants';
import { MenuLink } from './styles';

const AdminMenu = () => {
  const [t] = useTranslation('common');
  const location = useLocation();

  return (
    <Box sx={{ p: '10px 30px' }}>
      <MenuLink
        className={classnames({
          'active': location.pathname === ROUTES.ADMIN.DASHBOARD
        })}
        to={ROUTES.ADMIN.DASHBOARD}
      >
        <HomeIcon className="menu-icon" />
        <p className="menu-title">{t('app.home')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          'active': location.pathname === ROUTES.ADMIN.COMPANIES.INDEX
        })}
        to={ROUTES.ADMIN.COMPANIES.INDEX}
      >
        <Apartment className="menu-icon" />
        <p className="menu-title">{t('app.companies')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          'active': location.pathname === ROUTES.ADMIN.SESSIONS.INDEX
        })}
        to={ROUTES.ADMIN.SESSIONS.INDEX}
      >
        <ViewModule className="menu-icon" />
        <p className="menu-title">{t('app.sessions')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          'active': location.pathname === ROUTES.ADMIN.KPIS.INDEX
        })}
        to={ROUTES.ADMIN.KPIS.INDEX}
      >
        <Receipt className="menu-icon" />
        <p className="menu-title">{t('app.kpis')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          'active': location.pathname === ROUTES.ADMIN.CUSTOMIZATIONS.INDEX
        })}
        to={ROUTES.ADMIN.CUSTOMIZATIONS.INDEX}
      >
        <AccountCircle className="menu-icon" />
        <p className="menu-title">{t('app.settings')}</p>
      </MenuLink>
    </Box>
  );
};

export default AdminMenu;
