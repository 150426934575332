import { useDispatch, useSelector } from 'react-redux';
import { getAdminStatisticsAction } from '@/redux/actions';
import { RootState } from '@/redux/reducers';

export const useAdminState = () =>
  useSelector(({ adminReducer }: RootState) => adminReducer);

export const useGetAdminUsersStatisticsAction = () => {
  const dispatch = useDispatch();

  return () => dispatch(getAdminStatisticsAction());
};
