import { CompanyModel } from '@/resources/models/company.model';
import { UrbanAreaModel } from '@/resources/models/urban-area.model';

export class UnitModel {
  id: string;
  name: string;
  company: string | CompanyModel;
  urbanAreas: UrbanAreaModel[] | string[];

  constructor(init: any = {}) {
    const data = {
      id: '',
      name: '',
      company: '',
      urbanAreas: [],
      ...init,
    };

    this.id = data.id || data._id;
    this.name = data.name;

    this.company =
      typeof data.company === 'string'
        ? data.company
        : new CompanyModel(data.company);

    this.urbanAreas = data.urbanAreas.map((item) => {
      if (typeof item === 'string') return item;
      return new UrbanAreaModel(item);
    });
  }
}
