import React, { FC, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { FilterOption} from '@/parts/Filters/index';
import {Search} from "@/components";
import colors from '@/themes/colors';

interface SearchFilterProps {
  filter: FilterOption;
  onChangeFilter: (field: string, value: any) => void;
}

const SearchFilter: FC<SearchFilterProps> = ({
  filter,
  onChangeFilter,
}) => {
  const [value, setValue] = useState(filter.value);

  const onDebouncedFilterChange = useMemo(() => debounce(onChangeFilter, 500), [
    onChangeFilter
  ]);

  const onSearchChange = (value: string) => {
    setValue(value);
    onDebouncedFilterChange('search', value);
  };

  return (
    <Search
      placeholder={filter.placeholder}
      sx={{
        mr: 4,
        '& fieldset': {
          borderRadius: 5,
          borderColor: colors.btn_grey_border,
        },
        '& .Mui-focused fieldset': {
          borderColor: `${colors.text} !important`,
        }
      }}
      value={value}
      onChange={(e) => onSearchChange(e.target.value)}
    />
  )
};

export default SearchFilter;
