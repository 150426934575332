import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { uniqueId } from 'lodash';

export type OptionItem = {
  label: string;
  value: any;
};

type SelectProps = {
  options: OptionItem[];
  errorText?: string;
} & MuiSelectProps &
  SxProps;

export const Select: FC<SelectProps> = ({
  fullWidth,
  sx,
  label,
  errorText,
  options,
  ...props
}) => {
  const [t] = useTranslation('common');

  const selectUniqueId = uniqueId('select-');

  if (props.multiple && !props.value) {
    props.value = [];
  }

  return (
    <FormControl sx={sx} fullWidth={fullWidth} error={!!errorText}>
      <InputLabel id={selectUniqueId}>
        {typeof label === 'string' ? t(label) : label}
      </InputLabel>
      <MuiSelect
        label={typeof label === 'string' ? t(label) : label}
        labelId={selectUniqueId}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '300px',
            },
          },
        }}
        {...props}
      >
        {options.map((item) => (
          <MenuItem value={item.value} key={item.value}>
            {t(item.label)}
          </MenuItem>
        ))}
      </MuiSelect>
      <FormHelperText>{t(errorText)}</FormHelperText>
    </FormControl>
  );
};
