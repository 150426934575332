import { useMemo } from 'react';
import { useCommonState } from '@/hooks/redux';

export const useCountryOptions = () => {
  const { countries } = useCommonState();

  const defaultCountryId = useMemo(() => {
    return countries.find((item) => item.code === 'US')?.id;
  }, [countries]);

  const countryOptions = useMemo(() => {
    return countries.map((item) => ({
      label: item.name,
      value: item.id,
    }))
  }, [countries]);

  return { countryOptions, defaultCountryId }
}