import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  color: ${props => props.theme.colors.text};
  padding: 8px 12px;
  border-radius: 8px;
  
  &.active {
    background: ${props => props.theme.colors.bg_blue};
    color: ${props => props.theme.colors.text_blue};
    box-shadow: 1.1px 2.7px 3.8px -1.2px rgba(187, 187, 187, 0.26), 0.5px 1.3px 1.8px -0.6px rgba(187, 187, 187, 0.18), 0.3px 0.8px 1.1px rgba(187, 187, 187, 0.11);
  }
  
  &:hover {
    color: ${props => props.theme.colors.text_blue};
  }
  
  .menu-icon {
    width: 20px;
    height: 30px;
    margin-right: 8px;
  }
  
  .menu-title {
    font-family: 'Public Sans', 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`

export const CopyrightStyled = styled('div')`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: ${props => props.theme.colors.text_light_grey};
  padding: 15px 12px;  
`;

export const PoweredByStyled = styled('div')`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 125px;
  padding: 22px;
  margin: auto;
  background: ${props => props.theme.colors.bg_light_blue};
  border-radius: 20px;
  
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: ${props => props.theme.colors.text_grey};
`;
