import * as React from 'react';

declare module '@mui/material' {
  interface Theme {
    colors: {
      primary: React.CSSProperties['color'];
      secondary: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      bg_grey: React.CSSProperties['color'];
      bg_light_blue: React.CSSProperties['color'];
      bg_blue: React.CSSProperties['color'];
      btn_red_bg: React.CSSProperties['color'];
      btn_light_blue_bg: React.CSSProperties['color'];
      btn_blue_bg: React.CSSProperties['color'];
      btn_blue_lighter_bg: React.CSSProperties['color'];
      btn_grey_border: React.CSSProperties['color'];
      btn_grey_text: React.CSSProperties['color'];
      input_bg: React.CSSProperties['color'];
      light: React.CSSProperties['color'];
      dark: React.CSSProperties['color'];
      text: React.CSSProperties['color'];
      text_secondary: React.CSSProperties['color'];
      text_blue: React.CSSProperties['color'];
      text_light_grey: React.CSSProperties['color'];
      text_grey: React.CSSProperties['color'];
      dark_blue: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      light_blue: React.CSSProperties['color'];
      light_grey: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    colors: {
      primary: React.CSSProperties['color'];
      secondary: React.CSSProperties['color'];
      white: React.CSSProperties['color'];
      green: React.CSSProperties['color'];
      bg_grey: React.CSSProperties['color'];
      bg_light_blue: React.CSSProperties['color'];
      bg_blue: React.CSSProperties['color'];
      btn_red_bg: React.CSSProperties['color'];
      btn_light_blue_bg: React.CSSProperties['color'];
      btn_blue_bg: React.CSSProperties['color'];
      btn_blue_lighter_bg: React.CSSProperties['color'];
      btn_grey_border: React.CSSProperties['color'];
      btn_grey_text: React.CSSProperties['color'];
      input_bg: React.CSSProperties['color'];
      light: React.CSSProperties['color'];
      dark: React.CSSProperties['color'];
      text: React.CSSProperties['color'];
      text_secondary: React.CSSProperties['color'];
      text_blue: React.CSSProperties['color'];
      text_light_grey: React.CSSProperties['color'];
      text_grey: React.CSSProperties['color'];
      dark_blue: React.CSSProperties['color'];
      blue: React.CSSProperties['color'];
      light_blue: React.CSSProperties['color'];
      light_grey: React.CSSProperties['color'];
    };
  }
}

const colors = {
  primary: '#054DA7',
  secondary: '#ffffff',
  white: '#ffffff',
  green: '#00d12d',
  dark_blue: '#072859',
  blue: '#054DA7',
  light_blue: '#2196F3',
  light: '#DDF1FF',
  dark: '#00153C',
  light_grey: '#D9D9D9',

  bg_grey: '#F3F4FC',
  bg_light_blue: '#F4FAFF',
  bg_blue: '#DDF1FF',

  btn_red_bg: '#FA5255',
  btn_light_blue_bg: '#ADDBFF',
  btn_blue_bg: '#096BDE',
  btn_blue_lighter_bg: '#3990FF',
  btn_grey_border: '#DDDEE0',
  btn_grey_text: '#81848F',

  input_bg: '#EEEFF0',

  text: '#272930',
  text_secondary: '#646872',
  text_grey: '#6A6A6A',
  text_light_grey: '#757575',
  text_blue: '#096BDE',
};

export default colors;
