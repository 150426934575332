import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export const LoadingBox = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh'
      }}
      className="flex-center"
    >
      <CircularProgress
        sx={{
          color: 'primary.main',
        }}
        size={80}
        thickness={6}
      />
    </Box>
  )
};