import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useRemoveCompanyAction } from '@/hooks/redux/company';
import ConfirmDialog from '@/components/ConfimDialog';

interface RemoveCompanyButtonProps {
  id: string;
  onRemoveCompany: () => void;
}

const RemoveCompanyButton: FC<RemoveCompanyButtonProps> = ({
  id,
  onRemoveCompany,
}) => {
  const [t] = useTranslation('common');
  const removeCompany = useRemoveCompanyAction();

  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { account } = useAuthState();
  //
  // useEffect(() => {
  //   if (account) {
  //     AuthService.login(account.email, '12345678').then((r) => console.log(r));
  //   }
  // }, [account]);

  const onDelete = async () => {
    setLoading(true);
    const res = await removeCompany(id);

    if (res) {
      onRemoveCompany();
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip title={t('companies.delete_company')}>
        <IconButton
          color="error"
          disabled={loading}
          onClick={() => setShowConfirm(true)}
        >
          <Delete />
        </IconButton>
      </Tooltip>

      <ConfirmDialog
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        onConfirm={onDelete}
        confirmTitle="companies.delete_company"
        confirmText="companies.delete_company_confirm"
      />
    </>
  );
};

export default RemoveCompanyButton;
