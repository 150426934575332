import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, IconButton } from '@mui/material';
import { Cancel, ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Moment } from 'moment-timezone';
import { DatePicker, MoreButton, TimePicker } from '@/components';
import { SessionPeriod } from '@/resources/interfaces';
import TimeOptionDialog from '@/parts/SessionTime/TimeOptionDialog';

interface TimeItemProps {
  period: SessionPeriod;
  onPeriodChange: (id: string, field: string, value: any) => void;
  canRemove?: boolean;
  onRemove: () => void;
  error?: any;
  touched?: any;
}

const TimeItem: FC<TimeItemProps> = ({
  period,
  onPeriodChange,
  canRemove = true,
  onRemove,
  error = {},
}) => {
  const [t] = useTranslation('common');
  const [showOptionDialog, setShowOptionDialog] = useState(false);
  const { date, startTime, endTime } = period;

  const onChange = (field: string, value: any) => {
    onPeriodChange(period.id, field, value);
  };

  return (
    <Box>
      <Grid container columnSpacing={4} rowSpacing={3} sx={{ mb: 7 }}>
        <Grid item sm={3}>
          <DatePicker
            label={t('common.date')}
            value={date as Moment}
            onChange={(value) => onChange('date', value)}
            errorText={error.date ? error.date : ''}
          />
        </Grid>
        <Grid item sm={3}>
          <TimePicker
            label={t('common.start_time')}
            value={startTime as Moment}
            onChange={(value) => onChange('startTime', value)}
            errorText={error.startTime ? error.startTime : ''}
          />
        </Grid>
        <Grid item sm={3}>
          <TimePicker
            label={t('common.end_time')}
            value={endTime as Moment}
            onChange={(value) => onChange('endTime', value)}
            errorText={error.endTime ? error.endTime : ''}
          />
        </Grid>
        <Grid item sm={2}>
          <MoreButton onClick={() => setShowOptionDialog(true)}>
            <span>{t('common.options')}</span>

            <ChevronRightIcon />
          </MoreButton>
        </Grid>

        {canRemove && (
          <Grid item sm={1}>
            <IconButton color="error" onClick={onRemove}>
              <Cancel />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <TimeOptionDialog
        open={showOptionDialog}
        handleClose={() => setShowOptionDialog(false)}
        repeatOption={period.repeat}
        setRepeatOption={(value) => onChange('repeat', value)}
      />
    </Box>
  );
};

export default TimeItem;
