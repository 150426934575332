import React, { FC } from 'react';
import { Box, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { DateRange, LocalizationProvider } from '@mui/lab';
import MuiDateRangePicker from '@mui/lab/DateRangePicker';

import AdapterMoment from '@mui/lab/AdapterMoment';

type DateRangePickerProps = Record<string, unknown> & SxProps;

export const DateRangePicker: FC<DateRangePickerProps> = ({ ...props }) => {
  const [value, setValue] = React.useState<DateRange<Date>>([null, null]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDateRangePicker
        startText="From"
        endText="To"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
};
