import { SessionModel, UserModel } from '@/resources/models';
import { USER_ACTIONS } from '@/redux/action-types';
import { USER_STATUS } from '@/resources/enums';

export interface UserCalendarDataItem {
  id: string;
  startTime: Date;
  endTime: Date;
}

export interface UserCalendarData {
  type: 'offer' | 'session' | 'request';
  item: UserCalendarDataItem;
}

export interface UsersState {
  users: UserModel[];
  totalCount: number;
  loading: boolean;

  user: UserModel | undefined;
  finding: boolean;

  creating: boolean;
  deleting: boolean;
  updating: boolean;

  loadingCalendarData: boolean;
  calendarData: UserCalendarData[];

  userSessions: SessionModel[];
  sessionTotalCount: number;
  loadingSessions: boolean;
}

const initialState: UsersState = {
  users: [],
  totalCount: 0,
  loading: false,

  user: undefined,
  finding: false,

  creating: false,
  deleting: false,
  updating: false,

  loadingCalendarData: false,
  calendarData: [],

  userSessions: [],
  sessionTotalCount: 0,
  loadingSessions: false,
};

const userReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTIONS.GET_USERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case USER_ACTIONS.GET_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        users: payload.users,
        totalCount: payload.totalCount,
      };
    }

    case USER_ACTIONS.GET_USERS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case USER_ACTIONS.CREATE_MULTIPLE_USERS_REQUEST:
    case USER_ACTIONS.CREATE_USER_REQUEST: {
      return {
        ...state,
        creating: true,
      };
    }

    case USER_ACTIONS.CREATE_MULTIPLE_USERS_SUCCESS:
    case USER_ACTIONS.CREATE_USER_SUCCESS: {
      return {
        ...state,
        creating: false,
      };
    }

    case USER_ACTIONS.CREATE_MULTIPLE_USERS_ERROR:
    case USER_ACTIONS.CREATE_USER_ERROR: {
      return {
        ...state,
        creating: false,
      };
    }

    case USER_ACTIONS.DELETE_USER_REQUEST: {
      return {
        ...state,
        deleting: true,
      };
    }

    case USER_ACTIONS.DELETE_USER_SUCCESS: {
      return {
        ...state,
        deleting: false,
      };
    }

    case USER_ACTIONS.DELETE_USER_ERROR: {
      return {
        ...state,
        deleting: false,
      };
    }

    case USER_ACTIONS.FIND_USER_REQUEST: {
      return {
        ...state,
        finding: true,
      };
    }

    case USER_ACTIONS.FIND_USER_SUCCESS: {
      return {
        ...state,
        finding: false,
        user: payload.user,
      };
    }

    case USER_ACTIONS.FIND_USER_ERROR: {
      return {
        ...state,
        finding: false,
      };
    }

    case USER_ACTIONS.UPDATE_USER_REQUEST: {
      return {
        ...state,
        updating: true,
      };
    }

    case USER_ACTIONS.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        updating: false,
      };
    }

    case USER_ACTIONS.UPDATE_USER_ERROR: {
      return {
        ...state,
        updating: false,
      };
    }

    case USER_ACTIONS.GET_USER_SESSIONS_REQUEST: {
      return {
        ...state,
        loadingSessions: true,
      };
    }

    case USER_ACTIONS.GET_USER_SESSIONS_SUCCESS: {
      return {
        ...state,
        userSessions: payload.userSessions,
        sessionTotalCount: payload.sessionTotalCount,
        loadingSessions: false,
      };
    }

    case USER_ACTIONS.GET_USER_SESSIONS_ERROR: {
      return {
        ...state,
        loadingSessions: false,
      };
    }

    case USER_ACTIONS.ACTIVE_USER_SUCCESS: {
      return {
        ...state,
        users: state.users.map((item) => {
          if (item.id === payload.userId) {
            return new UserModel({ ...item, status: USER_STATUS.ACTIVE });
          }

          return item;
        }),
      };
    }

    case USER_ACTIONS.BLOCK_USER_SUCCESS: {
      return {
        ...state,
        users: state.users.map((item) => {
          if (item.id === payload.userId) {
            return new UserModel({ ...item, status: USER_STATUS.BLOCKED });
          }

          return item;
        }),
      };
    }

    case USER_ACTIONS.GET_ADMIN_USERS_STATISTICS_REQUEST: {
      return {
        ...state,
        loadingAdminStatistics: true,
      };
    }

    case USER_ACTIONS.GET_ADMIN_USERS_STATISTICS_SUCCESS: {
      return {
        ...state,
        totalCount: payload.totalCount,
        newCount: payload.newCount,
        activeCount: payload.activeCount,
        loadingAdminStatistics: false,
      };
    }

    case USER_ACTIONS.GET_ADMIN_USERS_STATISTICS_ERROR: {
      return {
        ...state,
        loadingAdminStatistics: false,
      };
    }

    case USER_ACTIONS.USER_CALENDAR_DATA_REQUEST: {
      return {
        ...state,
        loadingCalendarData: true,
      };
    }

    case USER_ACTIONS.USER_CALENDAR_DATA_SUCCESS: {
      return {
        ...state,
        calendarData: payload.calendarData,
        loadingCalendarData: false,
      };
    }

    case USER_ACTIONS.USER_CALENDAR_DATA_ERROR: {
      return {
        ...state,
        loadingCalendarData: false,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
