import { GENDER, ROLE, SPACE_PRIVACY } from '@/resources/enums';
import { t } from '@/providers/LanguageProvider/i18n';

export class NameHelper {
  static getRoleName(value: ROLE) {
    if (value === ROLE.ADMIN) return t('common.admin');

    if (value === ROLE.COMPANY) return t('common.company_admin');

    if (value === ROLE.USER) return t('common.user');

    return t('common.unknown');
  }

  static getGenderName(value: any) {
    if (!value) return t('session.no_preference');
    if (value === GENDER.MALE) return t('common.male');
    if (value === GENDER.FEMALE) return t('common.female');
    if (value === GENDER.OTHER) return t('common.other');

    return value;
  }

  static getAgeOptionName(value: boolean) {
    if (value) return t('session.around_my_age');

    return t('session.no_preference');
  }

  static getPrivacyOptionName(value: string) {
    if (value === SPACE_PRIVACY.NO_PREFERENCE)
      return t('session.no_preference');

    if (value === SPACE_PRIVACY.OWN_ROOM) return t('space.own_room');

    if (value === SPACE_PRIVACY.OWNED_DESK) return t('space.owned_desk');

    if (value === SPACE_PRIVACY.DESK_SHARING) return t('space.desk_sharing');

    return value;
  }
}
