import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, Divider } from '@mui/material';
import { Dialog } from '@/components';
import {AddressModel, CompanyModel} from '@/resources/models';
import DetailItem from "@/parts/DetailItem";
import {useAddressDetailList} from "@/hooks/app";

interface CompanyDetailDialogProps {
  company: CompanyModel;
  open: boolean;
  handleClose: () => void;
}

const CompanyDetailDialog: FC<CompanyDetailDialogProps> = ({
  company,
  open,
  handleClose,
}) => {
  const [t] = useTranslation('common');

  const address = company.address as AddressModel;

  const addressList = useAddressDetailList(address);

  const contents = [
    {
      title: 'companies.name',
      value: company.name,
    },
    {
      title: 'common.users',
      value: `${company.users}`,
    },
    {
      title: 'common.sessions',
      value: `${company.sessions}`,
    },
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('companies.company_detail')}
    >
      <DialogContent sx={{ py: 5 }}>
        {
          contents.map((item, index) => (
            <DetailItem key={index} label={item.title} value={item.value} />
          ))
        }

        <Divider sx={{ mb: 3 }}>{t('common.address')}</Divider>

        {
          addressList.map((item, index) => (
            <DetailItem key={`address-${index}`} label={item.label} value={item.value} />
          ))
        }

      </DialogContent>
    </Dialog>
  );
};

export default CompanyDetailDialog;
