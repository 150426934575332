import { HttpService } from '@/service/http.service';
import { SpaceModel } from '@/resources/models';
import { SearchApiResult } from '@/resources/interfaces';

export class SpaceService {
  static search(filter: any = {}): Promise<SearchApiResult<SpaceModel>> {
    return HttpService.get('/spaces', filter).then((res) => {
      const { data = [], totalCount } = res;

      return {
        data: data.map((item) => new SpaceModel(item)),
        totalCount,
      };
    });
  }

  static createSpace(data: any): Promise<SpaceModel> {
    return HttpService.post('/spaces', data).then((res) => new SpaceModel(res));
  }

  static findSpace(id: string): Promise<SpaceModel> {
    return HttpService.get(`/spaces/${id}`).then((res) => new SpaceModel(res));
  }

  static deleteSpace(id: string) {
    return HttpService.delete(`/spaces/${id}`);
  }

  static updateSpace(id: string, data: any): Promise<SpaceModel> {
    return HttpService.put(`/spaces/${id}`, data).then(
      (res) => new SpaceModel(res),
    );
  }
}
