import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button } from '@/components';
import SessionTime from '@/parts/SessionTime';
import { sessionTimePeriodForm } from '@/validations';
import moment from 'moment';
import { FormHelper } from '@/utils';

const validationSchema = Yup.object().shape(sessionTimePeriodForm);

interface OfferTimeProps {
  offerData: any;
  setOfferData: (values: any) => void;
  onBack: () => void;
  onNext: () => void;
}

const OfferTime: FC<OfferTimeProps> = ({
  onBack,
  onNext,
  offerData,
  setOfferData,
}) => {
  const [t] = useTranslation('common');

  const onSubmit = (values: any) => {
    setOfferData({
      ...offerData,
      ...values,
    });
    onNext();
  };

  const form = useFormik({
    validationSchema,
    onSubmit,
    initialValues: {
      periods: offerData.periods.map((item) => ({
        ...item,
        date: typeof item.date === 'string' ? moment(item.date) : item.date,
      })),
    },
  });

  return (
    <Box sx={{ mx: 'auto', maxWidth: '800px' }}>
      <form
        onSubmit={form.handleSubmit}
        onKeyDown={FormHelper.onKeyDownPreventEnter}
      >
        <SessionTime form={form} />

        <div className="d-flex align-items-center justify-content-center mt-15">
          <Button variant="outlined" onClick={onBack} sx={{ mr: '10px' }}>
            {t('common.back')}
          </Button>
          <Button type="submit" variant="blue">
            {t('common.next')}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default OfferTime;
