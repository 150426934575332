import React, { FC } from 'react';
import { useDropzone } from 'react-dropzone';
import XLSX from 'xlsx';
import { UnitItemItem } from './UnitItem';

interface UploadUnitButtonProps {
  uploadUnits: (data: UnitItemItem[]) => void;
}

const UploadUnitButton: FC<UploadUnitButtonProps> = ({ uploadUnits }) => {
  const addUnits = (data: any) => {
    const units = data.map((item) => {
      return {
        id: `${Math.random()}`,
        name: item.unit,
        // urbanAreas: [
        //   { id: `${Math.random()}`, name: item.urbanArea }
        // ]
      };
    });

    uploadUnits(units);
  };

  const onDrop = (files: File[]) => {
    if (files.length) {
      const xlsxReader = new FileReader();

      xlsxReader.onload = (e) => {
        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        const convertOptions = {};
        const result = XLSX.utils.sheet_to_json(worksheet, convertOptions);

        addUnits(result);
      };

      xlsxReader.readAsArrayBuffer(files[0]);
    }
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop,
    multiple: false,
    disabled: false,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} accept=".xlsx, .csv" />

      {/*<Button startIcon={<UploadIcon />}>{t('companies.upload_units')}</Button>*/}
    </div>
  );
};

export default UploadUnitButton;
