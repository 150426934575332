import { SPINNER_ACTIONS } from '@/redux/action-types';

export interface SpinnerState {
  loadingCount: number;
}

const initialState: SpinnerState = {
  loadingCount: 0,
};

const spinnerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SPINNER_ACTIONS.START_LOADING:
      return {
        ...state,
        loadingCount: state.loadingCount + 1,
      };

    case SPINNER_ACTIONS.FINISH_LOADING:
      return {
        ...state,
        loadingCount: Math.max(state.loadingCount - 1, 0),
      };

    default:
      return state;
  }
};

export default spinnerReducer;
