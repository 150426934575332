import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { BackButton, Button, DatePicker, Input, Select } from '@/components';
import { useHistory, useParams } from 'react-router';
import moment from 'moment-timezone';
import { GENDER, ROLE } from '@/resources/enums';
import { FormHelper, ObjectHelper } from '@/utils';
import { ROUTES } from '@/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { userFormValidation } from '@/validations';
import {
  useCompanyState,
  useCreateCompanyAdminAction,
  useFindCompanyAction,
  useShowToastAction,
} from '@/hooks/redux';

const validationSchema = Yup.object().shape(userFormValidation);

const AddCompanyAdmin = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const { id: companyId } = useParams<{ id: string }>();
  const showToast = useShowToastAction();
  const createAdmin = useCreateCompanyAdminAction();
  const { loading, creatingAdmin } = useCompanyState();

  const { company } = useCompanyState();
  const findCompanyAction = useFindCompanyAction();

  useEffect(() => {
    findCompanyAction(companyId);
  }, [companyId]);

  const handleSubmit = async (values: any) => {
    const newData = {
      ...values,
      mobilePhone: Number(values.mobilePhone),
      birthday: moment(values.birthday).toISOString(),
      roles: [ROLE.COMPANY],
    };

    const res = await createAdmin(companyId, ObjectHelper.trimQuery(newData));
    if (res) {
      showToast(t('companies.create_company_admin_success_message'));
      history.push(ROUTES.ADMIN.COMPANIES.USERS.replace(':id', companyId));
    }
  };

  const unitOptions = useMemo(() => {
    return company
      ? company.units.map((u) => ({ label: u.name, value: u.name }))
      : [];
  }, [company]);

  const genderOptions = useMemo(() => {
    return [
      { label: t('common.male'), value: GENDER.MALE },
      { label: t('common.female'), value: GENDER.FEMALE },
      { label: t('common.other'), value: GENDER.OTHER },
    ];
  }, []);

  const form = useFormik({
    validationSchema,
    validateOnBlur: false,
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      birthday: moment(),
      gender: GENDER.MALE,
      unit: '',
      mobilePhone: '',
      companyId,
    },
    onSubmit: handleSubmit,
  });

  const getFieldError = (key: string): string =>
    form.errors[key] && form.touched[key] ? t(form.errors[key] as string) : '';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 15,
        }}
      >
        <Typography
          variant={'h1'}
          sx={{ color: (theme) => theme.colors.text_secondary }}
        >
          {`${company?.name}: ${t('companies.add_company_admin')}`}
        </Typography>

        <BackButton />
      </Box>

      <Box sx={{ mx: 'auto', maxWidth: '900px' }}>
        <form
          onSubmit={form.handleSubmit}
          onKeyDown={FormHelper.onKeyDownPreventEnter}
        >
          <Grid container rowSpacing={8} columnSpacing={5}>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('companies.first_name')}
                fullWidth
                {...form.getFieldProps('firstName')}
                errorText={getFieldError('firstName')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('companies.last_name')}
                fullWidth
                {...form.getFieldProps('lastName')}
                errorText={getFieldError('lastName')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                label={t('common.gender')}
                fullWidth
                options={genderOptions}
                {...form.getFieldProps('gender')}
                errorText={getFieldError('gender')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                fullWidth
                label={t('common.birthday')}
                name="birthday"
                value={form.values.birthday}
                onChange={(value) => form.setFieldValue('birthday', value)}
                errorText={getFieldError('birthday')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('companies.email')}
                fullWidth
                {...form.getFieldProps('email')}
                errorText={getFieldError('email')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('companies.mobile_phone')}
                type="number"
                fullWidth
                {...form.getFieldProps('mobilePhone')}
                errorText={getFieldError('mobilePhone')}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                label={t('common.unit')}
                fullWidth
                options={unitOptions}
                {...form.getFieldProps('unit')}
                errorText={getFieldError('unit')}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 10,
            }}
          >
            <Button
              sx={{ mr: 5 }}
              variant="outlined"
              onClick={() =>
                history.push(
                  ROUTES.ADMIN.COMPANIES.USERS.replace(':id', companyId),
                )
              }
              type="button"
            >
              {t('common.back')}
            </Button>
            <Button
              type="submit"
              variant="blue"
              loading={creatingAdmin || loading}
            >
              {t('common.submit')}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default AddCompanyAdmin;
