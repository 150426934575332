import { CompanyModel, SessionModel, UserModel } from '@/resources/models';
import { COMPANY_ACTIONS } from '@/redux/action-types';

export interface CompaniesState {
  companies: CompanyModel[];
  totalCount: number;
  loading: boolean;
  creating: boolean;
  deleting: boolean;
  updating: boolean;
  disabling: boolean;
  creatingAdmin: boolean;

  company: CompanyModel | undefined;
  finding: boolean;

  companyUsers: UserModel[];
  userTotalCount: number;
  loadingUsers: boolean;

  companySessions: SessionModel[];
  sessionTotalCount: number;
  loadingSessions: boolean;
}

const initialState: CompaniesState = {
  companies: [],
  totalCount: 0,
  loading: false,
  creating: false,
  deleting: false,
  updating: false,
  disabling: false,
  creatingAdmin: false,

  company: undefined,
  finding: false,

  companyUsers: [],
  userTotalCount: 0,
  loadingUsers: false,

  companySessions: [],
  sessionTotalCount: 0,
  loadingSessions: false,
};

const companyReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case COMPANY_ACTIONS.CREATE_COMPANY_REQUEST: {
      return {
        ...state,
        creating: true,
      };
    }

    case COMPANY_ACTIONS.CREATE_COMPANY_SUCCESS: {
      return {
        ...state,
        creating: false,
      };
    }

    case COMPANY_ACTIONS.CREATE_COMPANY_ERROR: {
      return {
        ...state,
        creating: false,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalCount: payload.totalCount,
        companies: payload.companies,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANIES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case COMPANY_ACTIONS.DELETE_COMPANY_REQUEST: {
      return {
        ...state,
        deleting: true,
      };
    }

    case COMPANY_ACTIONS.DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        deleting: false,
      };
    }

    case COMPANY_ACTIONS.DELETE_COMPANY_ERROR: {
      return {
        ...state,
        deleting: false,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANY_DETAIL_USERS_REQUEST:
    case COMPANY_ACTIONS.GET_COMPANY_USERS_REQUEST: {
      return {
        ...state,
        loadingUsers: true,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANY_DETAIL_USERS_SUCCESS:
    case COMPANY_ACTIONS.GET_COMPANY_USERS_SUCCESS: {
      return {
        ...state,
        loadingUsers: false,
        userTotalCount: payload.userTotalCount,
        companyUsers: payload.companyUsers,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANY_DETAIL_USERS_ERROR:
    case COMPANY_ACTIONS.GET_COMPANY_USERS_ERROR: {
      return {
        ...state,
        loadingUsers: false,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANY_SESSIONS_REQUEST: {
      return {
        ...state,
        loadingSessions: true,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANY_SESSIONS_SUCCESS: {
      return {
        ...state,
        loadingSessions: false,
        companySessions: payload.companySessions,
        sessionTotalCount: payload.sessionTotalCount,
      };
    }

    case COMPANY_ACTIONS.GET_COMPANY_SESSIONS_ERROR: {
      return {
        ...state,
        loadingSessions: false,
      };
    }

    case COMPANY_ACTIONS.FIND_COMPANY_REQUEST: {
      return {
        ...state,
        finding: true,
      };
    }

    case COMPANY_ACTIONS.FIND_COMPANY_SUCCESS: {
      return {
        ...state,
        finding: false,
        company: payload.company,
      };
    }

    case COMPANY_ACTIONS.FIND_COMPANY_ERROR: {
      return {
        ...state,
        finding: false,
      };
    }

    case COMPANY_ACTIONS.UPDATE_COMPANY_REQUEST: {
      return {
        ...state,
        updating: true,
      };
    }

    case COMPANY_ACTIONS.UPDATE_COMPANY_SUCCESS: {
      return {
        ...state,
        updating: false,
      };
    }

    case COMPANY_ACTIONS.UPDATE_COMPANY_ERROR: {
      return {
        ...state,
        updating: false,
      };
    }

    case COMPANY_ACTIONS.DISABLE_COMPANY_REQUEST: {
      return {
        ...state,
        disabling: true,
      };
    }

    case COMPANY_ACTIONS.DISABLE_COMPANY_SUCCESS: {
      return {
        ...state,
        disabling: false,
      };
    }

    case COMPANY_ACTIONS.DISABLE_COMPANY_ERROR: {
      return {
        ...state,
        disabling: false,
      };
    }

    case COMPANY_ACTIONS.CREATE_COMPANY_ADMIN_REQUEST: {
      return {
        ...state,
        creatingAdmin: true,
      };
    }

    case COMPANY_ACTIONS.CREATE_COMPANY_ADMIN_SUCCESS: {
      return {
        ...state,
        creatingAdmin: false,
      };
    }

    case COMPANY_ACTIONS.CREATE_COMPANY_ADMIN_ERROR: {
      return {
        ...state,
        creatingAdmin: false,
      };
    }

    default:
      return state;
  }
};

export default companyReducer;
