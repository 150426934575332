import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import {
  useAuthState,
  useFindSpaceAction,
  useShowToastAction,
  useSpaceState,
  useUpdateSpaceAction,
} from '@/hooks/redux';
import { BackButton, Button } from '@/components';
import SpaceForm from '@/parts/SpaceForm';
import { AddressModel, CompanyModel, CountryModel } from '@/resources/models';
import { SPACE_PRIVACY } from '@/resources/enums';
import { FormHelper, ObjectHelper } from '@/utils';
import { ROUTES, USER_ACCOUNT_TABS } from '@/constants';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('validation.required_field'),
  country: Yup.string().trim().required('validation.required_field'),
  privacy: Yup.string().trim().required('validation.required_field'),
  postalCode: Yup.string().trim().required('validation.required_field'),
  city: Yup.string().trim().required('validation.required_field'),
  street: Yup.string().trim().required('validation.required_field'),
  numberOfStreet: Yup.string().trim().required('validation.required_field'),
});

const EditSpace = () => {
  const [t] = useTranslation('common');
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { finding, space, updating } = useSpaceState();
  const findSpace = useFindSpaceAction();
  const updateSpace = useUpdateSpaceAction();
  const showToast = useShowToastAction();
  const { account } = useAuthState();

  const adr = space?.address as AddressModel;
  const [address, setAddress] = useState<AddressModel>(adr);
  const [country, setCountry] = useState<CountryModel>(
    adr?.country as CountryModel,
  );

  const [location, setLocation] = useState<{ lat: number; lng: number }>();

  useEffect(() => {
    findSpace(id);
  }, [id]);

  useEffect(() => {
    if (space) {
      const spAddress = space?.address as AddressModel;
      setAddress(spAddress);
      setCountry(spAddress?.country as CountryModel);
      // setUrbanArea(spAddress?.urbanArea as UrbanAreaModel);

      const { lat, lng } = spAddress;
      if (lat && lng) {
        setLocation({ lat, lng });
      }
    }
  }, [space]);

  const onSubmit = async (values: any) => {
    const {
      country,
      postalCode,
      city,
      street,
      numberOfStreet,
      description,
      ...rest
    } = values;

    if (
      country !==
      ((account.company as CompanyModel).address as AddressModel).country
    ) {
      showToast(
        t('common.please_use_country_corresponding_to_urban_area'),
        'error',
      );
      return;
    }

    const data = {
      ...rest,
      address: {
        country,
        postalCode,
        city,
        street,
        numberOfStreet,
        description,
        ...location,
      },
    };

    const res = await updateSpace(id, ObjectHelper.trimQuery(data));

    if (res) {
      showToast(t('space.space_updated_message'));
      history.push(`${ROUTES.USER.ACCOUNT.PREFIX}/${USER_ACCOUNT_TABS.SPACES}`);
    }
  };

  const form = useFormik({
    enableReinitialize: true,
    validationSchema,
    onSubmit,
    initialValues: {
      name: space ? space.name : '',
      country: space && country ? country?.id : '',
      postalCode: space && address ? address?.postalCode : '',
      city: space && address ? address?.city : '',
      street: space && address ? address.street : '',
      numberOfStreet: space && address ? address.numberOfStreet : '',
      description: space && address ? address.description : '',
      privacy: space ? space.privacy : SPACE_PRIVACY.OWNED_DESK,
      accessibility: space ? space.accessibility : false,
      parking: space ? space.parking : false,
      childFriendly: space ? space.childFriendly : false,
      petFriendly: space ? space.petFriendly : false,
    },
  });

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 15,
        }}
      >
        <Typography
          variant={'h1'}
          sx={{ color: (theme) => theme.colors.text_secondary }}
        >
          {t('space.edit_space')}
        </Typography>

        <BackButton />
      </Box>

      {finding ? (
        <Box>
          {new Array(10).fill(0).map((_, index) => (
            <Skeleton key={index} />
          ))}
        </Box>
      ) : space ? (
        <form
          onSubmit={form.handleSubmit}
          onKeyDown={FormHelper.onKeyDownPreventEnter}
        >
          <Box sx={{ mx: 'auto', maxWidth: '600px' }}>
            <SpaceForm
              form={form}
              location={location}
              setLocation={setLocation}
              isNew={false}
            />

            <div className="d-flex justify-content-center mt-20">
              <Button variant="blue" type="submit" loading={updating}>
                {t('common.submit')}
              </Button>
            </div>
          </Box>
        </form>
      ) : (
        <Paper
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '500px',
          }}
        >
          <Typography variant="body1" color="error">
            {t('space.not_found_space')}
          </Typography>
        </Paper>
      )}
    </div>
  );
};

export default EditSpace;
