import { HttpService } from '@/service/http.service';
import { DemandModel } from '@/resources/models';
import { SearchApiResult } from '@/resources/interfaces';

export class DemandService {
  static create(data: any): Promise<DemandModel> {
    return HttpService.post('/demands', data);
  }

  static search(filter: any = {}): Promise<SearchApiResult<DemandModel>> {
    return HttpService.get('/demands', filter).then((res) => {
      const { data = [], totalCount } = res;

      return {
        data: data.map((item) => new DemandModel(item)),
        totalCount,
      };
    });
  }

  static getGuestPendingCount() {
    return HttpService.get('/demands/pending-guests');
  }

  static remove(id: string) {
    return HttpService.delete(`/demands/${id}`);
  }
}
