import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import { getUrbanAreasAction } from '@/redux/actions';

export const useUrbanAreasState = () =>
  useSelector(({ urbanAreasReducer }: RootState) => urbanAreasReducer);

export const useGetUrbanAreasAction = () => {
  const dispatch = useDispatch();
  return () => dispatch(getUrbanAreasAction());
};
