import React from 'react';
import { Redirect, Switch } from 'react-router';
import { AUTH_ROUTES, ROUTES } from '@/constants';
import PrivateRoute from '@/routes/PrivateRoute';
import Admin from '@/pages/Admin';
import AdminPreloader from '@/providers/AdminPreloader';

export const AdminRoutes = () => {
  return (
    <AdminPreloader>
      <Switch>
        {AUTH_ROUTES.map((route) => (
          <Redirect
            key={route.path as string}
            path={route.path as string}
            to={ROUTES.ADMIN.PREFIX}
          />
        ))}

        <PrivateRoute path={ROUTES.ADMIN.PREFIX} component={Admin} />
      </Switch>
    </AdminPreloader>
  );
};
