import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent } from '@mui/material';
import { Dialog } from '@/components';
import { AddressModel } from '@/resources/models';
import { useAddressDetailList } from '@/hooks/app';
import DetailItem from '@/parts/DetailItem';


interface AddressDetailDialogProps {
  address: AddressModel;
  open: boolean;
  handleClose: () => void;
}

const AddressDetailDialog: FC<AddressDetailDialogProps> = ({
  address,
  open,
  handleClose,
}) => {
  const [t] = useTranslation('common');

  const addressList = useAddressDetailList(address);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t('common.address')}
    >
      <DialogContent sx={{ py: 5 }}>
        {
          addressList.map((item, index) => (
            <DetailItem key={`address-${index}`} label={item.label} value={item.value} />
          ))
        }
      </DialogContent>

    </Dialog>
  );
};

export default AddressDetailDialog;
