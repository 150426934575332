import React from 'react';
import { Route, Switch } from 'react-router';
import { ROUTES } from '@/constants';
import Dashboard from '@/pages/Admin/Dashboard';
import Companies from '@/pages/Admin/Companies';
import Sessions from '@/pages/Admin/Sessions';
import KPI from '@/pages/Admin/KPI';
import Customizations from '@/pages/Admin/Customizations';
import AddCompany from '@/pages/Admin/Companies/AddCompany';
import CompanyCalendar from '@/pages/Admin/Companies/CompanyCalendar';
import CompanyRules from '@/pages/Admin/Companies/CompanyRules';
import CompanyDetail from '@/pages/Admin/Companies/CompanyDetail';
import EditUser from '@/pages/Admin/EditUser';
import ChangePassword from '@/parts/ChangePassword';
import AddCompanyAdmin from '@/pages/Admin/Companies/AddCompanyAdmin';

const Admin = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.ADMIN.DASHBOARD} component={Dashboard} />
      <Route
        exact
        path={ROUTES.ADMIN.COMPANIES.CREATE}
        component={AddCompany}
      />
      <Route
        exact
        path={ROUTES.ADMIN.COMPANIES.CALENDAR}
        component={CompanyCalendar}
      />
      <Route
        exact
        path={ROUTES.ADMIN.COMPANIES.RULES}
        component={CompanyRules}
      />
      <Route
        exact
        path={ROUTES.ADMIN.COMPANIES.ADD_ADMIN}
        component={AddCompanyAdmin}
      />
      <Route
        exact
        path={ROUTES.ADMIN.COMPANIES.DETAIL}
        component={CompanyDetail}
      />
      <Route exact path={ROUTES.ADMIN.COMPANIES.INDEX} component={Companies} />
      <Route exact path={ROUTES.ADMIN.SESSIONS.INDEX} component={Sessions} />
      <Route exact path={ROUTES.ADMIN.USERS.EDIT} component={EditUser} />
      <Route exact path={ROUTES.ADMIN.KPIS.INDEX} component={KPI} />
      <Route
        exact
        path={ROUTES.ADMIN.CUSTOMIZATIONS.INDEX}
        component={Customizations}
      />
      <Route exact path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
    </Switch>
  );
};

export default Admin;
