import { Dispatch } from 'redux';
import { SPACE_ACTIONS } from '@/redux/action-types';
import { SpaceModel } from '@/resources/models';
import { showToastAction } from '@/redux/actions/toast.actions';
import { SpaceService } from '@/service';
import { ObjectHelper } from '@/utils';

const getSpacesRequest = () => ({
  type: SPACE_ACTIONS.GET_SPACES_REQUEST,
});

const getSpacesSuccess = (spaces: SpaceModel[]) => ({
  type: SPACE_ACTIONS.GET_SPACES_SUCCESS,
  payload: { spaces },
});

const getSpacesError = () => ({
  type: SPACE_ACTIONS.GET_SPACES_ERROR,
});

export const getSpacesAction =
  (filter: any = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(getSpacesRequest());

    try {
      const res = await SpaceService.search(ObjectHelper.trimQuery(filter));
      dispatch(getSpacesSuccess(res.data));
      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(getSpacesError());
    }
  };

const deleteSpaceRequest = () => ({
  type: SPACE_ACTIONS.DELETE_SPACE_REQUEST,
});

const deleteSpaceSuccess = (spaceId: string) => ({
  type: SPACE_ACTIONS.DELETE_SPACE_SUCCESS,
  payload: { spaceId },
});

const deleteSpaceError = () => ({
  type: SPACE_ACTIONS.DELETE_SPACE_ERROR,
});

export const deleteSpaceAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(deleteSpaceRequest());

  try {
    await SpaceService.deleteSpace(id);
    dispatch(deleteSpaceSuccess(id));
    return true;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(deleteSpaceError());
  }
};

const createSpaceRequest = () => ({
  type: SPACE_ACTIONS.CREATE_SPACE_REQUEST,
});

const createSpaceSuccess = (space: SpaceModel) => ({
  type: SPACE_ACTIONS.CREATE_SPACE_SUCCESS,
  payload: { space },
});

const createSpaceError = () => ({
  type: SPACE_ACTIONS.CREATE_SPACE_ERROR,
});

export const createSpaceAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(createSpaceRequest());

  try {
    const res = await SpaceService.createSpace(data);
    dispatch(createSpaceSuccess(res));
    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(createSpaceError());
  }
};

const updateSpaceRequest = () => ({
  type: SPACE_ACTIONS.UPDATE_SPACE_REQUEST,
});

const updateSpaceSuccess = (space: SpaceModel) => ({
  type: SPACE_ACTIONS.UPDATE_SPACE_SUCCESS,
  payload: { space },
});

const updateSpaceError = () => ({
  type: SPACE_ACTIONS.UPDATE_SPACE_ERROR,
});

export const updateSpaceAction =
  (id: string, data: any) => async (dispatch: Dispatch) => {
    dispatch(updateSpaceRequest());

    try {
      const res = await SpaceService.updateSpace(id, data);
      dispatch(updateSpaceSuccess(res));
      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(updateSpaceError());
    }
  };

const findSpaceRequest = () => ({
  type: SPACE_ACTIONS.FIND_SPACE_REQUEST,
});

const findSpaceSuccess = (space: SpaceModel) => ({
  type: SPACE_ACTIONS.FIND_SPACE_SUCCESS,
  payload: { space },
});

const findSpaceError = () => ({
  type: SPACE_ACTIONS.FIND_SPACE_ERROR,
});

export const findSpaceAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(findSpaceRequest());

  try {
    const res = await SpaceService.findSpace(id);
    dispatch(findSpaceSuccess(res));
    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(findSpaceError());
  }
};
