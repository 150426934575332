import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Stepper, Step, StepLabel } from '@mui/material';
import {
  AddressModel,
  CompanyModel,
  UnitModel,
  UrbanAreaModel,
} from '@/resources/models';
import { useCountryOptions } from '@/hooks/app';
import CompanyInfo from './CompanyInfo';
import CompanyContactInfo from './ContactInfo';
import UrbanAreaUnits from './UrbanAreaUnits';
import AccountContactInfo from '@/parts/PostCompany/AccountContactInfo';

export enum POST_COMPANY_STEP {
  COMPANY_INFO = 0,
  URBAN_AREA_UNIT,
  CONTACT_INFO,
}

const companySteps = [
  {
    title: 'companies.company_information',
    step: POST_COMPANY_STEP.COMPANY_INFO,
  },
  {
    title: 'companies.urban_area_units',
    step: POST_COMPANY_STEP.URBAN_AREA_UNIT,
  },
  {
    title: 'companies.contact_information',
    step: POST_COMPANY_STEP.CONTACT_INFO,
  },
];

interface PostCompanyProps {
  company?: CompanyModel;
  companyAccountForm?: boolean;
}

const PostCompany: FC<PostCompanyProps> = ({ company, companyAccountForm }) => {
  const [t] = useTranslation('common');
  const { defaultCountryId } = useCountryOptions();
  const [activeStep, setActiveStep] = useState(POST_COMPANY_STEP.COMPANY_INFO);

  const address = company?.address as AddressModel;
  const urbanAreas = company?.urbanAreas as UrbanAreaModel[];

  const units = company?.units as UnitModel[];

  const [companyData, setCompanyData] = useState({
    name: company ? company.name : '',
    address: {
      country: company ? address.country : defaultCountryId,
      postalCode: company ? address.postalCode : '',
      city: company ? address.city : '',
      street: company ? address.street : '',
      numberOfStreet: company ? address.numberOfStreet : '',
      description: company ? address.description : '',
      lat: address?.lat,
      lng: address?.lng,
    },
    users: [],
    urbanAreas: company ? urbanAreas : [],
    units: company
      ? units
      : [
          {
            id: 'unit-1',
            name: '',
            urbanAreas: [],
          },
        ],
  });

  const history = useHistory();

  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 10 }}>
        {companySteps.map((item) => (
          <Step key={item.step}>
            <StepLabel>{t(item.title)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {activeStep === POST_COMPANY_STEP.COMPANY_INFO && (
        <CompanyInfo
          onNext={() => setActiveStep(POST_COMPANY_STEP.URBAN_AREA_UNIT)}
          onBack={() => history.go(-1)}
          companyData={companyData}
          setCompanyData={setCompanyData}
        />
      )}

      {activeStep === POST_COMPANY_STEP.URBAN_AREA_UNIT && (
        <UrbanAreaUnits
          onNext={() => setActiveStep(POST_COMPANY_STEP.CONTACT_INFO)}
          onBack={() => setActiveStep(POST_COMPANY_STEP.COMPANY_INFO)}
          companyData={companyData}
          setCompanyData={setCompanyData}
        />
      )}

      {activeStep === POST_COMPANY_STEP.CONTACT_INFO &&
        (companyAccountForm ? (
          <AccountContactInfo
            onBack={() => setActiveStep(POST_COMPANY_STEP.URBAN_AREA_UNIT)}
            companyData={companyData}
          />
        ) : (
          <CompanyContactInfo
            onBack={() => setActiveStep(POST_COMPANY_STEP.URBAN_AREA_UNIT)}
            companyData={companyData}
          />
        ))}
    </div>
  );
};

export default PostCompany;
