import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, Divider } from '@mui/material';
import { AddressModel, UserModel } from '@/resources/models';
import { Dialog } from '@/components';
import { useAddressDetailList } from '@/hooks/app';
import DetailItem from '@/parts/DetailItem';

interface UserDetailDialogProps {
  user: UserModel;
  open: boolean;
  handleClose: () => void;
}

const UserDetailDialog: FC<UserDetailDialogProps> = ({
  user,
  open,
  handleClose,
}) => {
  const [t] = useTranslation('common');

  const address = user.address as AddressModel;

  const addressList = useAddressDetailList(address);

  const contents = [
    {
      title: 'common.name',
      value: `${user.firstName} ${user.lastName}`,
    },
    {
      title: 'common.email',
      value: user.email,
    },
    {
      title: 'common.gender',
      value: user.gender,
    },
    {
      title: 'common.birthday',
      value: user.birthday,
    },
    {
      title: 'common.roles',
      value: user.roles,
    },
    {
      title: 'companies.mobile_phone',
      value: user.mobilePhone,
    },
    {
      title: 'common.unit',
      value: user.unit,
    },
  ];

  return (
    <Dialog open={open} onClose={handleClose} title={t('user.user_detail')}>
      <DialogContent sx={{ py: 5 }}>
        {contents.map((item, index) => (
          <DetailItem key={index} label={item.title} value={item.value} />
        ))}
        {Boolean(address) && (
          <>
            <Divider sx={{ mb: 3 }}>{t('common.address')}</Divider>

            {addressList.map((item, index) => (
              <DetailItem
                key={`address-${index}`}
                label={item.label}
                value={item.value}
              />
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UserDetailDialog;
