export enum AUTH_ACTIONS {
  SET_TOKEN = 'SET_TOKEN',
  SET_ACCOUNT = 'SET_ACCOUNT',
  GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR',
  SET_ACCOUNT_LOADING = 'SET_ACCOUNT_LOADING',
  SET_CURRENT_ROLE = 'SET_CURRENT_ROLE',
  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR',
  RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR',
  CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR',
}
