import { OFFER_ACTIONS } from '@/redux/action-types';
import { OfferModel } from '@/resources/models';

export interface OfferState {
  creating: boolean;
  loading: boolean;
  offers: OfferModel[];
  totalCount: number;
  loadingPendingOffers: boolean;
  pendingOfferCount: number;
  deleting: boolean;
}

const initialState: OfferState = {
  creating: false,
  loading: false,
  offers: [],
  totalCount: 0,
  loadingPendingOffers: false,
  pendingOfferCount: 0,
  deleting: false,
};

const offerReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case OFFER_ACTIONS.CREATE_OFFER_REQUEST: {
      return {
        ...state,
        creating: true,
      };
    }

    case OFFER_ACTIONS.CREATE_OFFER_SUCCESS: {
      return {
        ...state,
        creating: false,
      };
    }

    case OFFER_ACTIONS.CREATE_OFFER_ERROR: {
      return {
        ...state,
        creating: false,
      };
    }

    case OFFER_ACTIONS.GET_OFFERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case OFFER_ACTIONS.GET_OFFERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalCount: payload.totalCount,
        offers: payload.offers,
      };
    }

    case OFFER_ACTIONS.GET_OFFERS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case OFFER_ACTIONS.GET_PENDING_OFFERS_REQUEST: {
      return {
        ...state,
        loadingPendingOffers: true,
      };
    }

    case OFFER_ACTIONS.GET_PENDING_OFFERS_SUCCESS: {
      return {
        ...state,
        loadingPendingOffers: false,
        pendingOfferCount: payload.count,
      };
    }

    case OFFER_ACTIONS.GET_PENDING_OFFERS_ERROR: {
      return {
        ...state,
        loadingPendingOffers: false,
      };
    }

    case OFFER_ACTIONS.REMOVE_OFFER_REQUEST: {
      return {
        ...state,
        deleting: true,
      };
    }

    case OFFER_ACTIONS.REMOVE_OFFER_SUCCESS: {
      return {
        ...state,
        deleting: false,
      };
    }

    case OFFER_ACTIONS.REMOVE_OFFER_ERROR: {
      return {
        ...state,
        deleting: false,
      };
    }

    default:
      return state;
  }
};

export default offerReducer;
