import React, { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  Slide,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import OfferSessionBanner from '../../../parts/Sessions/Banners/OfferSessionBanner';
import RequestSessionBanner from '../../../parts/Sessions/Banners/RequestSessionBanner';
import {
  Apartment,
  ArrowBack,
  ArrowForward,
  ViewModule,
  Work,
} from '@mui/icons-material';
import { StatisticBoxStyled } from '@/parts/SidebarRight/AdminStatistics/styles';
import colors from '@/themes/colors';
import {
  useDemandState,
  useGetPendingDemandCount,
  useGetPendingOfferCount,
  useGetSessionsAction,
  useOfferState,
  useSessionState,
} from '@/hooks/redux';

const Dashboard = () => {
  const [t] = useTranslation('common');

  const [bannerToShow, setBannerToShow] = useState(1);

  const changeBanner = () => {
    setBannerToShow(bannerToShow === 1 ? 2 : 1);
  };

  const { loadingPendingDemands, pendingDemandCount } = useDemandState();
  const { loadingPendingOffers, pendingOfferCount } = useOfferState();
  const { loading: sessionsLoading, totalCount: sessionsCount } =
    useSessionState();
  const getPendingDemandsCount = useGetPendingDemandCount();
  const getPendingOffersCount = useGetPendingOfferCount();
  const getSessionsAction = useGetSessionsAction();
  useEffect(() => {
    getPendingDemandsCount();
    getPendingOffersCount();
    getSessionsAction();
  }, []);

  return (
    <>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {t('app.dashboard')}
      </Typography>

      <Box
        sx={{
          height: 'min-content',
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <IconButton
            onClick={changeBanner}
            sx={{
              borderRadius: '8px',
              border: '1px solid #ADDBFF',
              '&:hover': { background: '#DDF1FF' },
            }}
          >
            <ArrowBack />
          </IconButton>
          <IconButton
            onClick={changeBanner}
            sx={{
              borderRadius: '8px',
              border: '1px solid #ADDBFF',
              '&:hover': { background: '#DDF1FF' },
            }}
          >
            <ArrowForward />
          </IconButton>
        </Box>
        {bannerToShow === 1 && (
          <Slide
            direction="left"
            in={bannerToShow === 1}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <OfferSessionBanner />
            </div>
          </Slide>
        )}
        {bannerToShow === 2 && (
          <Slide
            direction="right"
            in={bannerToShow === 2}
            mountOnEnter
            unmountOnExit
          >
            <div>
              <RequestSessionBanner />
            </div>
          </Slide>
        )}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <StatisticBoxStyled sx={{ width: '32%' }}>
          <ViewModule style={{ color: colors.btn_blue_bg, fontSize: '66px' }} />
          <div className="counter">
            <div className="counter-value">
              {sessionsLoading ? <CircularProgress /> : sessionsCount}
            </div>
            <div className="counter-label">{t('app.scheduled_sessions')}</div>
          </div>
        </StatisticBoxStyled>
        <StatisticBoxStyled sx={{ width: '32%' }}>
          <Work style={{ color: colors.btn_blue_bg, fontSize: '66px' }} />
          <div className="counter">
            <div className="counter-value">
              {loadingPendingOffers ? <CircularProgress /> : pendingOfferCount}
            </div>
            <div className="counter-label">{t('app.pending_offers')}</div>
          </div>
        </StatisticBoxStyled>
        <StatisticBoxStyled sx={{ width: '32%' }}>
          <Apartment style={{ color: colors.btn_blue_bg, fontSize: '66px' }} />
          <div className="counter">
            <div className="counter-value">
              {loadingPendingDemands ? (
                <CircularProgress />
              ) : (
                pendingDemandCount
              )}
            </div>
            <div className="counter-label">{t('app.pending_requests')}</div>
          </div>
        </StatisticBoxStyled>
      </Box>
    </>
  );
};

export default Dashboard;
