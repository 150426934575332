import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete, Visibility } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { OfferModel } from '@/resources/models';
import { OFFER_STATUS } from '@/resources/enums';
import { useRemoveOfferAction } from '@/hooks/redux';
import OfferDetailDialog from '@/pages/User/Activity/Offers/OfferDetailDialog';
import ConfirmDialog from '@/components/ConfimDialog';

interface OfferDetailButtonProps {
  offer: OfferModel;
  onRemove: () => void;
}

const OfferDetailButton: FC<OfferDetailButtonProps> = ({
  offer,
  onRemove,
}) => {
  const [t] = useTranslation('common');
  const [showDialog, setShowDialog] = useState(false);
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeOffer = useRemoveOfferAction();

  const deleteOffer = async () => {
    setLoading(true);
    const res = await removeOffer(offer.id);
    setLoading(false);

    if (res) onRemove();
  };

  return (
    <>
      <>
        <Tooltip title={t('common.show_detail')}>
          <IconButton color="primary" onClick={() => setShowDialog(true)}>
            <Visibility />
          </IconButton>
        </Tooltip>

        <OfferDetailDialog
          offer={offer}
          open={showDialog}
          handleClose={() => setShowDialog(false)}
        />
      </>

      {
        offer.status === OFFER_STATUS.PENDING && (
          <>
            <Tooltip title={t('common.remove')}>
              <IconButton color="error" disabled={loading} onClick={() => setShowRemoveConfirm(true)}>
                <Delete />
              </IconButton>
            </Tooltip>

            <ConfirmDialog
              open={showRemoveConfirm}
              handleClose={() => setShowRemoveConfirm(false)}
              onConfirm={deleteOffer}
              confirmTitle="session.delete_offer"
              confirmText="session.delete_offer_confirm"
            />
          </>
        )
      }
    </>
  );
};

export default OfferDetailButton;
