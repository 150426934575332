import colors from './colors';

declare module '@mui/material' {
  interface Palette {
    dark: Palette['primary'];
  }
  interface PaletteOptions {
    dark: PaletteOptions['primary'];
  }
}

const palette = {
  primary: {
    main: colors.primary,
    contrastText: colors.secondary,
    text: colors.text,
  },
  secondary: {
    main: colors.secondary,
  },
  dark: {
    main: colors.dark,
    contrastText: colors.white,
  },
};

export default palette;
