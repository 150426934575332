import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Chip,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import { BackButton, Input, Select } from '@/components';
import { TIME_UNIT } from '@/resources/enums';

const CompanyRules = () => {
  const [t] = useTranslation('common');
  const [units, setUnits] = useState({
    first: [TIME_UNIT.WEEK],
    second: [TIME_UNIT.WEEK],
  })

  const handleUnitChange = (field: string, event: SelectChangeEvent) => {
    const { target: { value } } = event;
    setUnits({
      ...units,
      [field]: typeof value === 'string' ? value.split(',') : value
    })
  }

  const onRemoveUnitChip = (field: string, value: any) => {
    setUnits({
      ...units,
      [field]: units[field].filter((item) => item !== value),
    });
  };

  const timeUnitOptions = useMemo(() => {
    return [
      { label: t('common.week'), value: TIME_UNIT.WEEK },
      { label: t('common.month'), value: TIME_UNIT.MONTH },
      { label: t('common.year'), value: TIME_UNIT.YEAR },
    ]
  }, []);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 15
        }}
      >
        <Typography
          variant={'h1'}
          sx={{color: theme => theme.colors.text_secondary}}
        >
          {t('companies.company_rules')}
        </Typography>

        <BackButton />
      </Box>

      <Paper sx={{ p: 8, mb: 10 }}>
        <Box
          sx={{
            flexWrap: 'wrap',
            mb: 12
          }}
          className="flex-center"
        >
          <Input
            sx={{ minWidth: { md: '200px' } }}
            label={t('companies.max_sessions')}
            type="number"
          />
          <Typography variant="body1" sx={{ mx: 5 }}>
            {t('common.number_per')}
          </Typography>
          <Select
            sx={{
              minWidth: { md: '200px' }
            }}
            defaultValue={TIME_UNIT.MONTH}
            placeholder={t('common.time_unit')}
            options={timeUnitOptions}
          />
          <Typography variant="body1" sx={{ ml: 5 }}>
            {t('companies.and_user')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <FormControlLabel
            control={<Checkbox />}
            sx={{ mb: 5 }}
            label={t('companies.same_unit_only')}
          />

          <Typography
            variant="body1"
            sx={{ fontWeight: 'bold', textTransform: "uppercase", mb: 5, }}
          >
            {t('common.or')}
          </Typography>

          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Select
                sx={{ minWidth: { md: '200px' }, mb: 5 }}
                multiple
                placeholder={t('common.time_unit')}
                options={timeUnitOptions}
                value={units.first}
                onChange={(e: SelectChangeEvent) => handleUnitChange('first', e)}
              />

              <Stack direction="column" spacing={3}>
                {
                  units.first.map((item) => (
                    <Chip
                      key={item}
                      sx={{ justifyContent: 'space-between' }}
                      label={item}
                      color="primary"
                      variant="outlined"
                      onDelete={() => onRemoveUnitChip('first', item)}
                    />
                  ))
                }
              </Stack>
            </Box>

            <Typography variant="body1" sx={{ mx: 5, pt: 2 }}>
              {t('common.with')}
            </Typography>

            <Box sx={{ flexGrow: 1 }}>
              <Select
                sx={{ minWidth: { md: '200px' }, mb: 5 }}
                multiple
                placeholder={t('common.time_unit')}
                options={timeUnitOptions}
                value={units.second}
                onChange={(e: SelectChangeEvent) => handleUnitChange('second', e)}
              />

              <Stack direction="column" spacing={3}>
                {
                  units.second.map((item) => (
                    <Chip
                      key={item}
                      sx={{ justifyContent: 'space-between' }}
                      label={item}
                      color="primary"
                      variant="outlined"
                      onDelete={() => onRemoveUnitChip('second', item)}
                    />
                  ))
                }
              </Stack>
            </Box>
          </Box>
        </Box>
      </Paper>
    </div>
  );
};

export default CompanyRules;
