import { AddressModel } from '@/resources/models/address.model';
import { UnitModel } from '@/resources/models/unit.model';
import { UrbanAreaModel } from '@/resources/models/urban-area.model';

export class CompanyModel {
  id: string;
  name: string;
  address: string | AddressModel;
  urbanAreas: string[] | UrbanAreaModel[];
  units: string[] | UnitModel[];
  users: number;
  sessions: number;
  createdAt: string;
  deleted: boolean;
  disabled: boolean;

  constructor(init: any = {}) {
    const data = {
      id: '',
      name: '',
      address: '',
      units: [],
      urbanAreas: [],
      users: 0,
      sessions: 0,
      createdAt: '',
      deleted: false,
      disabled: false,
      ...init,
    };

    this.id = data.id || data._id;
    this.name = data.name;
    this.users = data.users;
    this.sessions = data.sessions;
    this.createdAt = data.createdAt;
    this.deleted = data.deleted;
    this.disabled = data.disabled;

    if (typeof data.address === 'string') {
      this.address = data.address;
    } else {
      this.address = new AddressModel(data.address);
    }

    this.units = data.units.map((item) => {
      return typeof item === 'string' ? item : new UnitModel(item);
    });

    this.urbanAreas = data.urbanAreas.map((item) => {
      return typeof item === 'string' ? item : new UrbanAreaModel(item);
    });
  }
}
