import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@/components';
import { useOfferState } from '@/hooks/redux';
import SessionUsers from '@/parts/SessionUsers';
import { sessionPartnerFormValidation } from '@/validations';
import { FormHelper } from '@/utils';

const validationSchema = Yup.object().shape(sessionPartnerFormValidation);

interface OfferGuestsProps {
  onBack: () => void;
  onOffer: (data: any) => void;
  offerData: any;
  setOfferData: (value: any) => void;
}

const OfferGuests: FC<OfferGuestsProps> = ({
  onBack,
  onOffer,
  offerData,
  setOfferData,
}) => {
  const [t] = useTranslation('common');
  const { creating } = useOfferState();

  const onSubmit = (values: any) => {
    const newOfferData = {
      ...offerData,
      guests: values,
    };

    setOfferData(newOfferData);
    onOffer(newOfferData);
  };

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: offerData.guests,
  });

  return (
    <Box sx={{ mx: 'auto', maxWidth: '600px' }}>
      <form
        onSubmit={form.handleSubmit}
        onKeyDown={FormHelper.onKeyDownPreventEnter}
      >
        <SessionUsers form={form} />

        <div className="d-flex align-items-center justify-content-center mt-15">
          <Button variant="outlined" onClick={onBack} sx={{ mr: '10px' }}>
            {t('common.back')}
          </Button>
          <Button variant="blue" type="submit" loading={creating}>
            {t('common.submit')}
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default OfferGuests;
