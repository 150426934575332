import React, { FC, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import AddressDetailDialog from '@/parts/AddressDetailDialog';
import { AddressModel } from '@/resources/models';
import {useTranslation} from "react-i18next";

interface AddressDetailButtonProps {
  address: AddressModel;
}

const AddressDetailButton: FC<AddressDetailButtonProps> = ({
  address,
}) => {
  const [t] = useTranslation('common');
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Tooltip title={t('common.show_detail')}>
        <IconButton color="secondary" onClick={() => setShowDialog(true)}>
          <Visibility />
        </IconButton>
      </Tooltip>

      <AddressDetailDialog
        address={address}
        open={showDialog}
        handleClose={() => setShowDialog(false)}
      />
    </>
  )
};

export default AddressDetailButton;
