import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { BannerBoxStyled, BannerQuestionStyled } from '@/components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ROUTES } from '@/constants';
import UploadUsersButton from '@/pages/Company/Users/UploadUsersButton';

export const CompanyNewUserBanner = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  return (
    <BannerBoxStyled direction="left">
      <BannerQuestionStyled>{t('app.need_to_add_users')}</BannerQuestionStyled>
      <Box sx={{ mt: '10px', mb: '30px' }}>
        <Typography
          sx={{
            fontFamily: 'Public Sans',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '41px',
          }}
        >
          {t('app.users_maybe_added_manually_or_bulk_upload')}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Button
          size="large"
          onClick={() => history.push(ROUTES.COMPANY.USERS.CREATE)}
          sx={{ mr: 2 }}
        >
          {t('user.add_user')}
        </Button>
        <UploadUsersButton
          refresh={() => {
            history.push(ROUTES.COMPANY.USERS.INDEX);
          }}
        />
      </Box>
    </BannerBoxStyled>
  );
};

export default CompanyNewUserBanner;
