import React, { FC, ReactNode } from 'react';
import {
  Box,
  Paper,
  TableBody,
  TableContainer,
  Table as MuiTable,
  TableRow,
  TableCell,
  TablePagination,
  Skeleton,
} from '@mui/material';
import { get } from 'lodash';
import TableHeader from './TableHeader';

export interface TableColumn {
  title: string;
  dataIndex: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  render?: (data?: any) => ReactNode;
  sortable?: boolean;
}

export interface PaginationOptions {
  total: number;
  page: number;
  perPage: number;
  onPageChange: (page: number) => void;
}

export type Order = 'asc' | 'desc';

export interface SortOption {
  orderBy: string;
  order: Order;
}

interface TableProps {
  columns: TableColumn[];
  data: any[];
  pagination?: PaginationOptions;
  sortOption?: SortOption;
  setSortOption?: (value: SortOption) => void;
  loading?: boolean;
}

export const Table: FC<TableProps> = ({
  columns,
  data,
  pagination,
  sortOption = {},
  setSortOption,
  loading,
}) => {
  const getCellData = (data: any, column: TableColumn) => {
    if (column.render) return column.render(data);

    if (
      get(data, column.dataIndex) !== undefined
      || get(data, column.dataIndex) !== null
    ) return get(data, column.dataIndex);

    return null;
  };

  const onSortChange = (property: string) => {
    const isAsc = sortOption.orderBy === property && sortOption.order === 'asc';

    if (setSortOption) {
      setSortOption({
        order: isAsc ? 'desc' : 'asc',
        orderBy: property,
      });
    }
  }

  return (
    <Paper>
      <TableContainer>
        <MuiTable sx={{ minWidth: 650 }}>
          <TableHeader
            columns={columns}
            order={sortOption?.order}
            orderBy={sortOption?.orderBy}
            onSortChange={onSortChange}
          />

          <TableBody>
            {
              loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                    <Skeleton height={30} />
                  </TableCell>
                </TableRow>
              ) : (
                <>

                  {
                    data.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={columns.length}>
                          <Box className="flex-center" sx={{ height: 100 }}>
                            No Data
                          </Box>
                        </TableCell>
                      </TableRow>
                    ) : (
                      data.map((item) => (
                        <TableRow
                          key={item.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: 36 }}
                        >
                          {
                            columns.map((column, index) => (
                              <TableCell key={index} align={column.align}>
                                {getCellData(item, column)}
                              </TableCell>
                            ))
                          }
                        </TableRow>
                      ))
                    )
                  }
                </>
              )
            }
          </TableBody>
        </MuiTable>
      </TableContainer>

      {
        pagination && (
          <TablePagination
            component="div"
            count={pagination.total}
            page={pagination.page}
            onPageChange={(e, page) => pagination.onPageChange(page)}
            rowsPerPage={pagination.perPage}
            rowsPerPageOptions={[pagination.perPage]}
            sx={{
              '& .MuiTablePagination-select': {
                pb: 0,
              },
            }}
          />
        )
      }
    </Paper>
  );
};
