import React from 'react';
import { Box } from '@mui/material';
import { useAuthState } from '@/hooks/redux';
import { ROLE } from '@/resources/enums';
import AccountMenu from '@/parts/SidebarRight/AccountMenu';
import { useTranslation } from 'react-i18next';
import Notifications from '@/parts/SidebarRight/Notifications';
import CalendarSidebar from '@/parts/SidebarRight/CalendarSidebar';
import { Button } from '@/components';
import { ROUTES } from '@/constants';
import { useHistory } from 'react-router';
import CompanyStatistics from '@/parts/SidebarRight/CompanyStatistics';
import AdminStatistics from '@/parts/SidebarRight/AdminStatistics';

const SidebarRight = () => {
  const { currentRole } = useAuthState();
  const [t] = useTranslation('common');
  const history = useHistory();

  return (
    <Box
      sx={{
        width: '300px',
        minWidth: '300px',
        height: '100vh',
        padding: '25px',
        bgcolor: (theme) => theme.colors.white,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: { xs: 'block' },
          position: 'relative',
        }}
      >
        <AccountMenu />
        {currentRole === ROLE.USER && <Notifications />}
      </Box>

      <Box>
        {currentRole === ROLE.ADMIN && <AdminStatistics />}

        {currentRole === ROLE.COMPANY && <CompanyStatistics />}

        {currentRole === ROLE.USER && (
          <>
            <CalendarSidebar />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                size="large"
                sx={{ mb: '10px', width: '170px' }}
                onClick={() => history.push(ROUTES.USER.OFFER_SESSION.INDEX)}
              >
                {t('app.offers_sessions')}
              </Button>
              <Button
                size="large"
                sx={{ width: '170px' }}
                onClick={() => history.push(ROUTES.USER.REQUEST_SESSION.INDEX)}
              >
                {t('app.request_sessions')}
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SidebarRight;
