import React from 'react';
import { Route, Switch } from 'react-router';
import { ROUTES } from '@/constants';
import Dashboard from '@/pages/Company/Dashboard';
import Sessions from '@/pages/Company/Sessions';
import Activities from '@/pages/Company/Activities';
import KPI from '@/pages/Company/KPI';
import Account from '@/pages/Company/Account';
import AddUser from '@/pages/Company/AddUser';
import EditUser from '@/pages/Company/EditUser';
import Users from '@/pages/Company/Users';
import ChangePassword from '@/parts/ChangePassword';

const Company = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.COMPANY.DASHBOARD} component={Dashboard} />
      <Route exact path={ROUTES.COMPANY.SESSIONS.INDEX} component={Sessions} />
      <Route exact path={ROUTES.COMPANY.ACTIVITIES.INDEX} component={Activities} />
      <Route exact path={ROUTES.COMPANY.KPIS.INDEX} component={KPI} />
      <Route exact path={ROUTES.COMPANY.ACCOUNT.DETAIL} component={Account} />
      <Route exact path={ROUTES.COMPANY.USERS.CREATE} component={AddUser} />
      <Route exact path={ROUTES.COMPANY.USERS.EDIT} component={EditUser} />
      <Route exact path={ROUTES.COMPANY.USERS.INDEX} component={Users} />
      <Route exact path={ROUTES.CHANGE_PASSWORD} component={ChangePassword} />
    </Switch>
  );
};

export default Company;
