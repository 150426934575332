import React from 'react';
import { Redirect, Switch } from 'react-router';
import { AUTH_ROUTES, ROUTES } from '@/constants';
import PrivateRoute from '@/routes/PrivateRoute';
import Company from '@/pages/Company';

export const CompanyRoutes = () => {
  return (
    <Switch>
      {
        AUTH_ROUTES.map((route) => (
          <Redirect
            key={route.path as string}
            path={route.path as string}
            to={ROUTES.COMPANY.PREFIX}
          />
        ))
      }

      <PrivateRoute path={ROUTES.COMPANY.PREFIX} component={Company} />
    </Switch>
  )
};
