import * as Yup from 'yup';

export const userFormValidation = {
  firstName: Yup.string().trim().required('validation.required_field'),
  lastName: Yup.string().trim().required('validation.required_field'),
  email: Yup.string()
    .required('validation.email_required')
    .email('validation.email_invalid'),
  gender: Yup.string().trim().required('validation.required_field'),
  unit: Yup.string().trim().required('validation.required_field'),
  birthday: Yup.object().nullable().required('validation.required_field'),
  mobilePhone: Yup.number().required('validation.required_field'),
};
