import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';
import { Button } from '@/components';
import { userFormValidation } from '@/validations';
import { ROLE } from '@/resources/enums';
import {
  useAuthState,
  useCompanyState,
  useShowToastAction,
  useUpdateCompanyAccount,
} from '@/hooks/redux';
import { UnitModel } from '@/resources/models';
import UserForm from '@/parts/UserForm';
import { FormHelper, ObjectHelper } from '@/utils';
import { ROUTES } from '@/constants';
import { useHistory } from 'react-router';

const validationSchema = Yup.array().of(
  Yup.object().shape({
    ...userFormValidation,
    // roles: Yup.array()
    //   .required('validation.required_field')
    //   .min(1, 'validation.required_field'),
  }),
);

interface ContactInfoProps {
  onBack: () => void;
  companyData: any;
}

const AccountContactInfo: FC<ContactInfoProps> = ({ onBack, companyData }) => {
  const [t] = useTranslation('common');
  const { creating } = useCompanyState();
  const { loading, updateCompanyAccount } = useUpdateCompanyAccount();
  const showToast = useShowToastAction();
  const history = useHistory();

  const { account } = useAuthState();

  const handleSubmit = async (values: any) => {
    const urbanAreas = companyData.urbanAreas.map((item) => item.id);
    const units = companyData.units.map((item) => {
      return {
        name: item.name,
        urbanAreas: [], //item.urbanAreas.map((item) => item.id),
      };
    });
    const newData = {
      ...companyData,
      urbanAreas,
      units,
      users: [
        {
          ...values,
          mobilePhone: Number(values.mobilePhone),
          birthday: moment(values.birthday).toISOString(),
          roles: Array.isArray(values.roles) ? values.roles : [values.roles],
        },
      ],
    };

    const res = await updateCompanyAccount(ObjectHelper.trimQuery(newData));
    if (res) {
      showToast(t('user.profile_updated_message'));
      history.push(ROUTES.COMPANY.DASHBOARD);
    }
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      firstName: account?.firstName,
      lastName: account?.lastName,
      email: account?.email,
      birthday: moment(account?.birthday),
      gender: account.gender,
      unit: (account?.unit as UnitModel)?.name,
      mobilePhone: account.mobilePhone,
      roles: ROLE.COMPANY,
    },
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={{ mx: 'auto', maxWidth: '900px' }}>
      <form
        onSubmit={form.handleSubmit}
        onKeyDown={FormHelper.onKeyDownPreventEnter}
      >
        <UserForm form={form} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{ mr: 5 }}
            variant="outlined"
            onClick={onBack}
            type="button"
          >
            {t('common.back')}
          </Button>
          <Button type="submit" variant="blue" loading={creating || loading}>
            {t('common.submit')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AccountContactInfo;
