import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Divider, Grid } from '@mui/material';
import { Button, Input } from '@/components';
import { addressFormValidation } from '@/validations';
import SpaceAddressForm from '@/parts/SpaceAddressForm';
import { FormHelper } from '@/utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('validation.required_field'),
  ...addressFormValidation,
});

interface CompanyInfoProps {
  onNext: () => void;
  onBack: () => void;
  companyData: any;
  setCompanyData: (value: any) => void;
}

const CompanyInfo: FC<CompanyInfoProps> = ({
  onBack,
  onNext,
  companyData,
  setCompanyData,
}) => {
  const [t] = useTranslation('common');
  const [location, setLocation] = useState({
    lat: companyData.address?.lat,
    lng: companyData.address?.lng,
  });

  const handleSubmit = async (values: any) => {
    const {
      name,
      country,
      postalCode,
      city,
      street,
      numberOfStreet,
      description,
    } = values;
    const { lat, lng } = location;

    setCompanyData({
      ...companyData,
      name,
      address: {
        ...companyData.address,
        country,
        postalCode,
        city,
        street,
        numberOfStreet,
        description,
        lat,
        lng,
      },
    });

    onNext();
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      name: companyData.name,
      country: companyData.address.country,
      postalCode: companyData.address.postalCode,
      city: companyData.address.city,
      street: companyData.address.street,
      numberOfStreet: companyData.address.numberOfStreet,
      description: companyData.address.description,
    },
    onSubmit: handleSubmit,
  });

  return (
    <Box sx={{ mx: 'auto', maxWidth: '900px' }}>
      <form
        onSubmit={form.handleSubmit}
        onKeyDown={FormHelper.onKeyDownPreventEnter}
      >
        <Box sx={{ p: 4, pb: 0, mb: 0 }}>
          <Grid container rowSpacing={8} columnSpacing={5}>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('companies.company_name')}
                fullWidth
                {...form.getFieldProps('name')}
                errorText={
                  form.errors.name && form.touched.name
                    ? t(form.errors.name as string)
                    : ''
                }
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ p: 4, mb: 5 }}>
          <Divider textAlign="left" variant="dialog" sx={{ mb: 5 }}>
            {t('companies.company_address')}
          </Divider>
          <SpaceAddressForm
            form={form}
            location={location}
            setLocation={setLocation}
            isNew={true}
            isCompany={true}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{ mr: 5 }}
            variant="outlined"
            onClick={onBack}
            type="button"
          >
            {t('common.back')}
          </Button>
          <Button variant="blue" type="submit">
            {t('common.next')}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default CompanyInfo;
