import React from 'react';
import { Redirect, Switch } from 'react-router';
import { ROUTES } from '@/constants';
import AuthRoute from '@/routes/AuthRoute';
import Login from '@/pages/Auth/Login';
import ForgotPassword from '@/pages/Auth/ForgotPassword';
import ResetPassword from '@/pages/Auth/ResetPassword';

export const UnauthenticatedRoutes = () => {
  return (
    <Switch>
      <Redirect exact path={ROUTES.AUTH.PREFIX} to={ROUTES.AUTH.LOGIN} />

      <AuthRoute key={ROUTES.AUTH.LOGIN} path={ROUTES.AUTH.LOGIN} component={Login} />
      <AuthRoute key={ROUTES.AUTH.FORGOT_PASSWORD} path={ROUTES.AUTH.FORGOT_PASSWORD} component={ForgotPassword} />
      <AuthRoute key={ROUTES.AUTH.RESET_PASSWORD} path={ROUTES.AUTH.RESET_PASSWORD} component={ResetPassword} />

      <Redirect to={ROUTES.AUTH.LOGIN} />
    </Switch>
  )
};
