import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, Dialog, Input } from '@/components';
import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { END_TYPE, REPEAT_UNIT, RepeatOption } from '@/resources/interfaces';

const repeatOptions = [
  { label: 'common.days', value: REPEAT_UNIT.DAYS },
  { label: 'common.weeks', value: REPEAT_UNIT.WEEKS },
  { label: 'common.months', value: REPEAT_UNIT.MONTHS },
];

interface AddressDialogProps {
  repeatOption?: RepeatOption;
  setRepeatOption: (value?: RepeatOption) => void;
  open: boolean;
  handleClose: () => void;
}

const TimeOptionDialog: FC<AddressDialogProps> = ({
  open,
  handleClose,
  repeatOption,
  setRepeatOption,
}) => {
  const [t] = useTranslation('common');

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      unit: Yup.string().required('validation.required_field'),
      amount: Yup.number()
        .required('validation.required_field')
        .min(1, t('validation.minimum_invalid', { value: 1 })),
      endType: Yup.string().required('validation.required_field'),
      endOn: Yup.object()
        .nullable()
        .when('endType', {
          is: END_TYPE.ON,
          then: Yup.object().nullable().required('validation.required_field'),
        }),
      endAfter: Yup.number().when('endType', {
        is: END_TYPE.AFTER,
        then: Yup.number()
          .required('validation.required_field')
          .min(1, t('validation.minimum_invalid', { value: 1 })),
      }),
    });
  }, []);

  const onSubmit = (values: any) => {
    setRepeatOption(values);
    handleClose();
  };

  const form = useFormik({
    onSubmit,
    validationSchema,
    initialValues: {
      unit: repeatOption ? repeatOption.unit : REPEAT_UNIT.DAYS,
      amount: repeatOption ? repeatOption.amount : 1,
      endType: repeatOption ? repeatOption.endType : END_TYPE.ON,
      endOn: repeatOption ? repeatOption.endOn : null,
      endAfter: repeatOption ? repeatOption.endAfter : 1,
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} title={t('common.options')}>
      <form onSubmit={form.handleSubmit}>
        <DialogContent sx={{ py: 5 }}>
          <Divider variant="dialog" textAlign="left" sx={{ mb: 5 }}>
            {t('session.repeats_every')}
          </Divider>
          <div className="d-flex align-items-center justify-content-center">
            <Input
              sx={{ mr: 5, maxWidth: '130px' }}
              type="number"
              {...form.getFieldProps('amount')}
              errorText={
                form.errors.amount && form.errors.amount
                  ? (form.errors.amount as string)
                  : ''
              }
            />

            <RadioGroup {...form.getFieldProps('unit')}>
              {repeatOptions.map((item) => (
                <FormControlLabel
                  key={item.value}
                  sx={{ mb: 0 }}
                  value={item.value}
                  control={<Radio size="small" />}
                  label={t(item.label)}
                />
              ))}
            </RadioGroup>
          </div>

          <Divider variant="dialog" textAlign="left" sx={{ mt: 7, mb: 5 }}>
            {t('common.ends')}
          </Divider>

          <div className="d-flex align-items-start justify-content-center">
            <RadioGroup {...form.getFieldProps('endType')}>
              <div className="d-flex align-items-start mb-10">
                <Box sx={{ maxWidth: '132px', mr: 4 }}>
                  <DatePicker
                    disabled={form.values.endType === END_TYPE.AFTER}
                    label={t('common.date')}
                    name="endOn"
                    value={form.values.endOn}
                    onChange={(value) => form.setFieldValue('endOn', value)}
                    showErrorText={false}
                    errorText={
                      form.errors.endOn && form.touched.endOn
                        ? (form.errors.endOn as string)
                        : ''
                    }
                  />
                </Box>
                <FormControlLabel
                  sx={{ mb: 0, minWidth: '80px' }}
                  value={END_TYPE.ON}
                  control={<Radio size="small" />}
                  label={t('common.on')}
                />
              </div>

              <div className="d-flex align-items-center">
                <span style={{ fontSize: '14px', marginRight: '5px' }}>
                  {t('common.after')}
                </span>
                <Box sx={{ maxWidth: '95px', mr: 4 }}>
                  <Input
                    type="number"
                    disabled={form.values.endType === END_TYPE.ON}
                    {...form.getFieldProps('endAfter')}
                    showErrorText={false}
                    errorText={
                      form.errors.endAfter && form.errors.endAfter
                        ? (form.errors.endAfter as string)
                        : ''
                    }
                  />
                </Box>
                <FormControlLabel
                  sx={{ mb: 0, minWidth: '80px' }}
                  value={END_TYPE.AFTER}
                  control={<Radio size="small" />}
                  label={t('common.occurrences')}
                />
              </div>
            </RadioGroup>
          </div>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            {t('common.cancel')}
          </Button>
          <Button type="submit" variant="blue">
            {t('common.ok')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TimeOptionDialog;
