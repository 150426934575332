import { alpha, Components } from '@mui/material';
import baseStyles from '@/themes/base';
import colors from '@/themes/colors';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    blue: true;
    link: true;
    auth: true;
    authLight: true;
  }
}

declare module '@mui/material/Divider' {
  interface DividerPropsVariantOverrides {
    dialog: true;
  }
}

const components: Components = {
  MuiCssBaseline: {
    styleOverrides: baseStyles,
  },

  MuiPaper: {
    defaultProps: {
      elevation: 2,
    },
  },

  MuiCard: {
    defaultProps: {
      sx: {
        borderRadius: 2,
      },
    },
  },

  MuiTabs: {
    styleOverrides: {
      flexContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        margin: '0 5px',
        borderRadius: '8px',
        backgroundColor: colors.btn_light_blue_bg,
        color: colors.white,
        width: '200px',
        '&:hover': {
          backgroundColor: colors.btn_blue_bg,
        },
        '&.Mui-selected': {
          cursor: 'default',
          backgroundColor: colors.btn_blue_bg,
          color: `${colors.white} !important`,
          border: 'none !important',
        },
      },
      textColorPrimary: colors.white,
      textColorSecondary: colors.white,
    },
  },

  MuiTableContainer: {
    styleOverrides: {
      root: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
      },
    },
  },

  MuiTableRow: {
    styleOverrides: {
      root: {
        background: colors.white,
        borderBottom: `2px solid ${colors.bg_blue}`,
        '&:last-child': {
          border: 'none',
        },
      },
      head: {
        '&:nth-of-type(2n + 1)': {
          background: colors.bg_light_blue,
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        border: 'none',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '15px',
        padding: '10px 5px 15px',
        textAlign: 'center',
      },
      head: {
        fontWeight: '700',
      },
      body: {
        fontWeight: 200,
        fontSize: '12px',
        lineHeight: '15px',
        padding: 0,
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        background: colors.bg_light_blue,
      },
      displayedRows: {
        color: colors.text,
        fontWeight: 200,
        lineHeight: '15px',
      },
      actions: {
        button: {
          color: colors.blue,
          ':disabled': {
            color: colors.text_light_grey,
          },
        },
      },
    },
  },

  MuiButton: {
    defaultProps: {
      size: 'large',
      variant: 'contained',
      disableElevation: true,
      style: {
        textTransform: 'none',
      },
    },
    variants: [
      {
        props: {
          variant: 'outlined',
        },
        style: {
          backgroundColor: 'transparent',
          color: colors.btn_grey_text,
          borderRadius: '16px',
          border: `1px solid ${colors.btn_grey_border}`,
          '&:hover': {
            backgroundColor: 'transparent',
            color: colors.text,
            border: `1px solid ${colors.text_secondary}`,
          },
        },
      },
      {
        props: {
          variant: 'blue',
        },
        style: {
          backgroundColor: colors.light,
          color: colors.primary,
          borderRadius: '16px',
          border: `1px solid ${colors.light}`,
          minWidth: '120px',
          '&:hover': {
            backgroundColor: colors.btn_light_blue_bg,
            border: `1px solid ${colors.btn_light_blue_bg}`,
            color: colors.primary,
          },
        },
      },
      {
        props: {
          variant: 'auth',
        },
        style: {
          backgroundColor: colors.btn_blue_bg,
          color: colors.white,
          borderRadius: '5px',
          width: '100%',
          border: 'none',
          '&:hover': {
            opacity: '0.5',
            backgroundColor: colors.btn_blue_bg,
            color: colors.white,
          },
        },
      },
      {
        props: {
          variant: 'authLight',
        },
        style: {
          backgroundColor: colors.btn_blue_lighter_bg,
          color: colors.white,
          borderRadius: '5px',
          width: '100%',
          border: 'none',
          '&:hover': {
            opacity: '0.5',
            backgroundColor: colors.btn_blue_lighter_bg,
            color: colors.white,
          },
        },
      },
      {
        props: {
          variant: 'link',
        },
        style: {
          backgroundColor: 'transparent',
          color: colors.text,
          border: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            color: colors.primary,
            border: 'none',
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        backgroundColor: colors.btn_red_bg,
        color: colors.white,
        fontSize: '14px',
        lineHeight: '28px',
        padding: '2px 12px',
        borderRadius: '20px',
        border: 'none',
        minWidth: '120px',
        '&:hover': {
          border: 'none',
          backgroundColor: colors.btn_red_bg,
          opacity: 0.7,
        },
      },
    },
  },

  MuiIconButton: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        color: colors.blue,
        '&:hover': {
          color: colors.light_blue,
        },
      },
      colorError: {
        color: colors.blue,
        '&:hover': {
          color: colors.btn_red_bg,
        },
      },
    },
  },

  MuiCheckbox: {
    defaultProps: {
      size: 'small',
    },
  },

  MuiSelect: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      select: {
        fontSize: '12px',
        padding: '6px 15px',
      },
    },
  },

  MuiTextField: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        '& .MuiInputBase-formControl': {
          fontSize: '12px',
        },
      },
    },
  },

  MuiOutlinedInput: {
    defaultProps: {
      sx: {
        '& fieldset': {
          border: (theme) =>
            `1px solid ${alpha(theme.palette.primary.main, 0.4)}`,
          transition: '0.4s',
        },

        '&.Mui-focused': {
          transition: '0.4s',
        },
      },
    },
  },

  MuiIcon: {
    styleOverrides: {
      fontSizeLarge: '48px',
    },
  },

  MuiBadge: {
    styleOverrides: {
      colorPrimary: 'transparent',
      colorError: colors.btn_red_bg,
    },
  },

  MuiStepIcon: {
    styleOverrides: {
      root: {
        color: colors.light_grey,
        '&.Mui-active, &.Mui-completed': {
          color: colors.btn_blue_bg,
        },
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      label: {
        fontFamily: 'Montserrat',
        color: colors.light_grey,
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '160%',
        marginTop: '2px !important',
        '&.Mui-active, &.Mui-completed': {
          color: colors.btn_blue_bg,
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        left: 'calc(-50% + 12px)',
        right: 'calc(50% + 12px)',
        '&.Mui-active .MuiStepConnector-line': {
          borderColor: colors.btn_blue_bg,
        },
        '&.Mui-completed .MuiStepConnector-line': {
          borderColor: colors.btn_blue_bg,
        },
      },
      line: {
        borderColor: colors.light_grey,
        borderTopWidth: '2px',
        borderTopStyle: 'dashed',
      },
    },
  },

  MuiFormControlLabel: {
    styleOverrides: {
      label: {
        fontSize: '14px',
      },
    },
  },

  MuiRadio: {
    styleOverrides: {
      root: {
        fontSize: '14px',
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      input: {
        backgroundColor: colors.input_bg,
      },
      formControl: {
        backgroundColor: colors.input_bg,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: '12px',
        transform: 'translate(14px, 10px) scale(1)',
      },
      shrink: {
        transform: 'translate(14px, -9px) scale(0.75)',
      },
    },
  },

  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: '20px',
        backgroundColor: colors.bg_grey,
        width: '600px',
        minWidth: '600px',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        backgroundColor: colors.bg_light_blue,
        color: colors.text_blue,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: 'center',
        paddingBottom: '20px',
      },
    },
  },

  MuiDivider: {
    variants: [
      {
        props: {
          variant: 'dialog',
        },
        style: {
          color: colors.text,
          fontWeight: 600,
          '&:after': {
            borderTopColor: colors.text,
          },
          '&:before': {
            display: 'none',
          },
        },
      },
    ],
  },
};

export default components;
