import { HttpService } from '@/service/http.service';
import { NotificationModel } from '@/resources/models';

export class NotificationService {
  static search(filter: any = {}) {
    return HttpService.get('/notifications', filter).then((res) => {
      const { data = [], totalCount } = res;

      return {
        data: data.map((item) => new NotificationModel(item)),
        totalCount,
      };
    });
  }

  static getUnreadCount() {
    return HttpService.get('/notifications/unread', {}, {}, true).then(
      (res) => {
        const { totalCount = 0 } = res;
        return totalCount;
      },
    );
  }

  static markAsRead(id: string) {
    return HttpService.patch(`/notifications/${id}/read`);
  }
}
