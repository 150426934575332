import * as Yup from 'yup';

export const sessionPartnerFormValidation = {
  names: Yup.object().shape({
    value: Yup.string(),
    mandatory: Yup.boolean(),
  }),
  units: Yup.object().shape({
    value: Yup.string(),
    mandatory: Yup.boolean(),
  }),
  gender: Yup.object().shape({
    value: Yup.mixed(),
    mandatory: Yup.boolean(),
  }),
  aroundAge: Yup.object().shape({
    value: Yup.string().required('validation.required_field'),
    mandatory: Yup.boolean(),
  }),
};
