import { UserModel } from '@/resources/models/user.model';
import { SessionModel } from '@/resources/models/session.model';
import { NOTIFICATION_TYPE } from '@/resources/enums';

export class NotificationModel {
  id: string;
  user: string | UserModel;
  session: string | SessionModel;
  type: NOTIFICATION_TYPE;
  read: boolean;
  createdAt: string;

  constructor(init: any = {}) {
    const data = {
      id: '',
      user: '',
      session: '',
      type: NOTIFICATION_TYPE.SESSION_CREATED,
      read: false,
      createdAt: '',
      ...init,
    };

    this.id = data.id || data._id;
    this.type = data.type;
    this.read = data.read;
    this.createdAt = data.createdAt;

    if (typeof data.user === 'string') {
      this.user = data.user;
    } else {
      this.user = new UserModel(data.user);
    }

    if (typeof data.session === 'string') {
      this.session = data.session;
    } else {
      this.session = new SessionModel(data.session);
    }
  }

  get title() {
    if (this.type === NOTIFICATION_TYPE.SESSION_CREATED)
      return 'You have a new session!';

    if (this.type === NOTIFICATION_TYPE.SESSION_CANCELLED)
      return 'Your session has been canceled!';

    if (this.type === NOTIFICATION_TYPE.SESSION_OVERFLOWED)
      return 'You have reached the limit';

    if (this.type === NOTIFICATION_TYPE.OFFER_CREATED)
      return 'You have a new session offer!';

    if (this.type === NOTIFICATION_TYPE.DEMAND_CREATED)
      return 'You have a new session request!';

    return '';
  }
}
