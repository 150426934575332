import { Box, styled } from '@mui/material';

export const StatisticBoxStyled = styled(Box)`
  border-radius: 28px;
  background: ${props => props.theme.colors.bg_light_blue};
  padding: 28px 24px;
  color: ${props => props.theme.colors.text};
  font-family: 'Public Sans', sans-serif;
  margin-top: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  .counter {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 20px;
    
    .counter-value {
      font-size: 36px;
      line-height: 45px;
      font-weight: 500;
    }
    .counter-label {
      font-size: 12px;
    }
  }
  
  
`;
