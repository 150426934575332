import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  createOfferAction,
  getOffersAction,
  getPendingOffersAction,
  removeOfferAction,
} from '@/redux/actions';

export const useOfferState = () =>
  useSelector(({ offerReducer }: RootState) => offerReducer);

export const useCreateOfferAction = () => {
  const dispatch = useDispatch();
  return (data: any) => dispatch(createOfferAction(data));
};

export const useGetOffersAction = () => {
  const dispatch = useDispatch();
  return (filters: any = {}) => dispatch(getOffersAction(filters));
};

export const useGetPendingOfferCount = () => {
  const dispatch = useDispatch();
  return () => dispatch(getPendingOffersAction());
};

export const useRemoveOfferAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(removeOfferAction(id));
};
