import React, { useEffect } from 'react';
import PostCompany from '@/parts/PostCompany';
import {
  useAuthState,
  useCompanyState,
  useFindCompanyAction,
} from '@/hooks/redux';
import { CompanyModel } from '@/resources/models';

const Profile = () => {
  const { account } = useAuthState();
  const { company, finding } = useCompanyState();
  const findCompany = useFindCompanyAction();

  useEffect(() => {
    findCompany((account.company as CompanyModel).id);
  }, [(account.company as CompanyModel).id]);

  return (
    <div>
      {!finding && <PostCompany company={company} companyAccountForm />}
    </div>
  );
};

export default Profile;
