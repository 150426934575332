import React, { memo, useMemo } from 'react';
import { useAuthState } from '@/hooks/redux/auth';
import {
  AdminRoutes,
  CompanyRoutes,
  UnauthenticatedRoutes,
  UserRoutes
} from '@/routes';
import ThemeProvider from '@/providers/ThemeProvider';
import LanguageProvider from '@/providers/LanguageProvider';
import AuthProvider from '@/providers/AuthProvider';
import Preloader from '@/providers/Preloader';
import Toast from '@/parts/Toast';
import { LoadingSpinner } from '@/components';
import { ROLE } from '@/resources/enums';

const App = memo(() => {
  const { account, currentRole } = useAuthState();

  const authenticatedRoutes = useMemo(() => {
    if (currentRole === ROLE.ADMIN)
      return <AdminRoutes />;

    if (currentRole === ROLE.COMPANY)
      return <CompanyRoutes />;

    if (currentRole === ROLE.USER)
      return <UserRoutes />

    return null;
  }, [currentRole]);

  return (
    <ThemeProvider>
      <LanguageProvider>
        <Toast />
        <LoadingSpinner />

        <AuthProvider>
          {
            !account ? (
              <UnauthenticatedRoutes />
            ) : (
              <Preloader>
                {authenticatedRoutes}
              </Preloader>
            )
          }
        </AuthProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
});

export default App;
