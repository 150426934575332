import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Box } from '@mui/material';
import { FormikContextType } from 'formik';
import UrbanAreaItem from '@/parts/PostCompany/UrbanAreaUnits/UrbanAreasForm/UrbanAreaItem';
import { OptionItem } from '@/components';

interface UrbanAreasFormProps {
  form: FormikContextType<any>;
  urbanAreasOptions: OptionItem[];
}

const UrbanAreasForm: FC<UrbanAreasFormProps> = ({
  form,
  urbanAreasOptions,
}) => {
  const [t] = useTranslation('common');

  const { errors } = form;
  const { urbanAreas } = form.values;

  const onUrbanAreaChange = (index: string, id: string) => {
    form.setFieldValue(
      'urbanAreas',
      urbanAreas.map((item) => {
        if (item.index === index) return { ...item, id };

        return item;
      }),
    );
  };

  // const addUrbanArea = () => {
  //   form.setFieldValue('urbanAreas', [
  //     ...urbanAreas,
  //     {
  //       id: `${Math.random()}`,
  //       name: '',
  //     },
  //   ]);
  // };

  const removeUrbanArea = (index: string) => {
    form.setFieldValue(
      'urbanAreas',
      urbanAreas.filter((item) => item.index !== index),
    );
  };

  return (
    <Box sx={{ p: 4 }}>
      <Divider textAlign="left" variant="dialog" sx={{ mb: 6 }}>
        {t('companies.urban_area')}
      </Divider>

      {urbanAreas.map((item, index) => (
        <UrbanAreaItem
          key={item.index}
          urbanArea={item}
          options={urbanAreasOptions}
          onChange={(value) => onUrbanAreaChange(item.index, value)}
          removeUrbanArea={() => removeUrbanArea(item.index)}
          error={
            errors.urbanAreas && errors.urbanAreas.length > 0
              ? errors.urbanAreas[index]
              : {}
          }
          canRemove={index !== 0}
        />
      ))}

      {/*<div className="d-flex align-items-center justify-content-end">*/}
      {/*  <Button variant="link"  startIcon={<AddIcon />} onClick={addUrbanArea}>*/}
      {/*    {t('companies.add_more_urban_area')}*/}
      {/*  </Button>*/}
      {/*</div>*/}
    </Box>
  );
};

export default UrbanAreasForm;
