import { useMemo } from 'react';
import { useUrbanAreasState } from '@/hooks/redux';
import { UrbanAreaModel } from '@/resources/models';

export const useUrbanAreasOptions = (areas?: UrbanAreaModel[]) => {
  const { urbanAreas } = useUrbanAreasState();

  const values = areas && areas.length ? areas : urbanAreas;

  const urbanAreasOptions = useMemo(() => {
    return values
      .map((item) => ({
        label: item.name,
        value: item.id,
      }))
      .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
  }, [values]);

  return { urbanAreasOptions };
};
