export class FormHelper {
  static onKeyDownPreventEnter(keyEvent: React.KeyboardEvent<HTMLFormElement>) {
    if ((keyEvent.key || keyEvent.key) === 'Enter') {
      keyEvent.preventDefault();
    }
  }

  static callOnEnter(cb: (e: React.KeyboardEvent<HTMLInputElement>) => void) {
    return (keyEvent: React.KeyboardEvent<HTMLInputElement>) => {
      if ((keyEvent.key || keyEvent.key) === 'Enter') {
        keyEvent.preventDefault();
        cb(keyEvent);
      }
    };
  }
}
