import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { PaginationOptions, Table, TableColumn } from '@/components';
import { AddressModel, DemandModel, UrbanAreaModel } from '@/resources/models';
import DemandDetailButton from '@/pages/User/Activity/Demands/DemandDetailButton';

interface DemandsTableProps {
  demands: DemandModel[];
  loading?: boolean;
  pagination?: PaginationOptions;
  onRemove: () => void;
}

const DemandsTable: FC<DemandsTableProps> = ({
  demands,
  loading,
  pagination,
  onRemove,
}) => {
  const [t] = useTranslation('common');

  const data = useMemo(() => {
    return demands.map((item) => {
      const address = item.address as AddressModel;
      const urbanArea = address.urbanArea as UrbanAreaModel;

      return {
        ...item,
        role: t('common.guest'),
        demandDate: moment(item.startTime).format('DD/MM/YYYY'),
        demandStartTime: moment(item.startTime).format('HH:mm'),
        demandEndTime: moment(item.endTime).format('HH:mm'),
        demandAddress: urbanArea.name,
      };
    });
  }, [demands]);

  const columns = useMemo<TableColumn[]>(() => {
    return [
      { title: 'common.session_date', dataIndex: 'demandDate' },
      { title: 'common.start_time', dataIndex: 'demandStartTime' },
      { title: 'common.end_time', dataIndex: 'demandEndTime' },
      { title: 'common.role', dataIndex: 'role' },
      {
        title: 'common.status',
        dataIndex: 'status',
        render: (data: any) => (
          <span className="text-capitalize">{data.status}</span>
        ),
      },
      { title: 'common.address', dataIndex: 'demandAddress' },
      {
        title: '',
        dataIndex: 'address',
        align: 'right',
        render: (demand: DemandModel) => (
          <DemandDetailButton demand={demand} onRemove={onRemove} />
        ),
      },
    ];
  }, [onRemove]);

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      pagination={pagination}
    />
  );
};

export default DemandsTable;
