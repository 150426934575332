import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { OfferModel, SpaceModel } from '@/resources/models';
import { PaginationOptions, Table, TableColumn } from '@/components';
import OfferDetailButton from '@/pages/User/Activity/Offers/OfferDetailButton';

interface OfferTableProps {
  offers: OfferModel[];
  loading?: boolean;
  pagination?: PaginationOptions;
  onRemove: () => void;
}

const OffersTable: FC<OfferTableProps> = ({
  offers,
  loading,
  pagination,
  onRemove,
}) => {
  const [t] = useTranslation('common');

  const data = useMemo(() => {
    return offers.map((item) => {
      return {
        ...item,
        role: t('common.host'),
        sessionDate: moment(item.startTime).format('DD/MM/YYYY'),
        sessionStartTime: moment(item.startTime).format('HH:mm'),
        sessionEndTime: moment(item.endTime).format('HH:mm'),
        sessionAddress: (item.space as SpaceModel).name,
      }
    })
  }, [offers]);

  const columns = useMemo<TableColumn[]>(() => {
    return [
      { title: 'common.session_date', dataIndex: 'sessionDate' },
      { title: 'common.start_time', dataIndex: 'sessionStartTime' },
      { title: 'common.end_time', dataIndex: 'sessionEndTime' },
      { title: 'common.role', dataIndex: 'role' },
      {
        title: 'common.status',
        dataIndex: 'status',
        render: (data: any) => (
          <span className="text-capitalize">{data.status}</span>
        )
      },
      { title: 'common.address', dataIndex: 'sessionAddress' },
      {
        title: 'common.actions',
        dataIndex: 'address',
        align: 'right',
        render: (offer: OfferModel) => (
          <OfferDetailButton offer={offer} onRemove={onRemove} />
        )
      },
    ]
  }, [onRemove]);

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
      pagination={pagination}
    />
  );
};

export default OffersTable;
