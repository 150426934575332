import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetNotificationsAction, useNotificationState } from '@/hooks/redux';
import NotificationFilters from '@/pages/User/UserNotifications/NotificationFilters';
import NotificationTable from '@/pages/User/UserNotifications/NotificationTable';
import {ObjectHelper} from "@/utils";

const UserNotifications = () => {
  const [t] = useTranslation('common');
  const searchNotifications = useGetNotificationsAction();
  const { notifications, loading, totalCount } = useNotificationState();

  const [filter, setFilter] = useState({
    search: '',
    page: 0,
    perPage: 20,
  });

  const onChangeFilter = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  useEffect(() => {
    searchNotifications(ObjectHelper.trimQuery(filter));
  }, [filter]);

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{color: theme => theme.colors.text_secondary}}
      >
        {t('notifications.title')}
      </Typography>

      <Box sx={{ mb: 10 }}>
        <NotificationFilters filter={filter} onChangeFilter={onChangeFilter} />
      </Box>

      <NotificationTable
        notifications={notifications}
        loading={loading}
        pagination={{
          total: totalCount,
          page: filter.page,
          perPage: filter.perPage,
          onPageChange: (page) => onChangeFilter('page', page)
        }}
      />
    </div>
  );
};

export default UserNotifications;
