import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Input } from '@/components';

export interface UnitItemItem {
  id: string;
  name: string;
  // urbanAreas: { id: string; name: string }[];
}

interface UnitItemProps {
  unit: UnitItemItem;
  onChange: (id: string, value: any) => void;
  removeUnit: () => void;
  allowRemove: boolean;
  error?: any;
}

const UnitItem: FC<UnitItemProps> = ({
  unit,
  onChange,
  removeUnit,
  allowRemove = false,
  error = {},
}) => {
  const [t] = useTranslation('common');

  const {
    name: errorName,
    // urbanAreas: errorUrbanAreas = []
  } = error;
  // const { urbanAreas } = unit;

  const onChangeName = (value: string) => {
    const newUnit = { ...unit, name: value };
    onChange(unit.id, newUnit);
  };

  // const onUrbanAreaChange = (id: string, value) => {
  //   const newAreas = urbanAreas.map((item) => {
  //     if (item.id === id) return { ...item, name: value };
  //     return item;
  //   });
  //
  //   const newUnit = { ...unit, urbanAreas: newAreas };
  //   onChange(unit.id, newUnit);
  // };

  // const addUrbanArea = () => {
  //   const newUnit = {
  //     ...unit,
  //     urbanAreas: [...unit.urbanAreas, { id: `${Math.random()}`, name: '' }],
  //   };
  //
  //   onChange(unit.id, newUnit);
  // };

  // const removeUrbanArea = (id: string) => {
  //   const newAreas = urbanAreas.filter((item) => item.id !== id);
  //   const newUnit = { ...unit, urbanAreas: newAreas };
  //   onChange(unit.id, newUnit);
  // };

  return (
    <Grid container rowSpacing={8} columnSpacing={5} sx={{ height: '100px' }}>
      <Grid item xs={12} sm={5}>
        <Input
          label={t('companies.unit')}
          fullWidth
          value={unit.name}
          onChange={(e) => onChangeName(e.target.value)}
          errorText={errorName || ''}
        />
      </Grid>

      {/*<Grid item xs={12} sm={6}>*/}
      {/*  {urbanAreas.map((item, index) => (*/}
      {/*    <UnitUrbanAreaItem*/}
      {/*      key={item.id}*/}
      {/*      urbanArea={item}*/}
      {/*      onChange={(value) => onUrbanAreaChange(item.id, value)}*/}
      {/*      removeUrbanArea={() => removeUrbanArea(item.id)}*/}
      {/*      canRemove={index !== 0}*/}
      {/*      canAdd={index === urbanAreas.length - 1}*/}
      {/*      addUrbanArea={addUrbanArea}*/}
      {/*      error={errorUrbanAreas[index] || {}}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</Grid>*/}

      <Grid item xs={1}>
        {allowRemove && (
          <Tooltip title={t('companies.remove_unit')}>
            <IconButton color="error" onClick={removeUnit}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default UnitItem;
