import React, { FC } from 'react';
import ReactAvatar from 'react-avatar';
import classnames from 'classnames';

interface AvatarProps {
  src?: string;
  name?: string;
  size?: string;
  round?: boolean;
  maxInitials?: number;
  className?: string;
  textSizeRatio?: number;
  onClick?: () => void;
}

export const Avatar: FC<AvatarProps> = ({
  src,
  name,
  size = "md",
  round,
  maxInitials,
  textSizeRatio,
  className,
  ...props
}) => {
  return (
    <div className={classnames(className)}>
      <ReactAvatar
        src={src}
        name={name}
        size={size}
        round={round}
        maxInitials={maxInitials}
        textSizeRatio={textSizeRatio}
        {...props}
      />
    </div>
  );
};