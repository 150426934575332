import { Dispatch } from 'redux';
import { DEMAND_ACTIONS } from '@/redux/action-types';
import { DemandService } from '@/service';
import { showToastAction } from '@/redux/actions/toast.actions';
import { DemandModel } from '@/resources/models';

const createDemandRequest = () => ({
  type: DEMAND_ACTIONS.CREATE_DEMAND_REQUEST,
});

const createDemandSuccess = () => ({
  type: DEMAND_ACTIONS.CREATE_DEMAND_SUCCESS,
});

const createDemandError = () => ({
  type: DEMAND_ACTIONS.CREATE_DEMAND_ERROR,
});

export const createDemandAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(createDemandRequest());

  try {
    const res = await DemandService.create(data);
    dispatch(createDemandSuccess());
    showToastAction('Sessions are requested successfully.')(dispatch);
    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(createDemandError());
  }
};

const getDemandsRequest = () => ({
  type: DEMAND_ACTIONS.GET_DEMANDS_REQUEST,
});

const getDemandsSuccess = (demands: DemandModel[], totalCount: number) => ({
  type: DEMAND_ACTIONS.GET_DEMANDS_SUCCESS,
  payload: { demands, totalCount },
});

const getDemandsError = () => ({
  type: DEMAND_ACTIONS.GET_DEMANDS_ERROR,
});

export const getDemandsAction = (query: any) => async (dispatch: Dispatch) => {
  dispatch(getDemandsRequest());

  try {
    const { data, totalCount } = await DemandService.search(query);
    dispatch(getDemandsSuccess(data, totalCount));
    return { data, totalCount };
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(getDemandsError());
  }
};

const getPendingDemandsRequest = () => ({
  type: DEMAND_ACTIONS.GET_PENDING_DEMANDS_REQUEST,
});

const getPendingDemandsSuccess = (count: number) => ({
  type: DEMAND_ACTIONS.GET_PENDING_DEMANDS_SUCCESS,
  payload: { count },
});

const getPendingDemandsError = () => ({
  type: DEMAND_ACTIONS.GET_PENDING_DEMANDS_ERROR,
});

export const getPendingDemandsAction = () => async (dispatch: Dispatch) => {
  dispatch(getPendingDemandsRequest());

  try {
    const { count } = await DemandService.getGuestPendingCount();
    dispatch(getPendingDemandsSuccess(count));
    return count;
  } catch (err) {
    dispatch(getPendingDemandsError());
  }
};

const removeDemandRequest = () => ({
  type: DEMAND_ACTIONS.REMOVE_DEMAND_REQUEST,
});

const removeDemandSuccess = () => ({
  type: DEMAND_ACTIONS.REMOVE_DEMAND_SUCCESS,
});

const removeDemandError = () => ({
  type: DEMAND_ACTIONS.REMOVE_DEMAND_ERROR,
});

export const removeDemandAction =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(removeDemandRequest());

    try {
      await DemandService.remove(id);
      showToastAction('Demand is removed successfully.')(dispatch);
      dispatch(removeDemandSuccess());
      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(removeDemandError());
    }
  };
