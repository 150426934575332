declare module '@mui/material' {
  interface TypographyVariants {
    body3: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
  }
}

const typography = {
  fontFamily: 'Public Sans, Montserrat, sans-serif',
  h1: {
    fontWeight: 'bold',
    fontSize: '36px',
    marginBottom: '30px',
  },
  h2: {
    fontWeight: 'bold',
  },
  h3: {
    fontWeight: 'bold',
  },
  h4: {
    fontWeight: 'bold',
  },
  h5: {
    fontWeight: 'bold',
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
  body3: {
    fontSize: '0.75rem',
  },
};

export default typography;
