import { HttpService } from '@/service/http.service';
import { UnitModel, UrbanAreaModel, UserModel } from '@/resources/models';

export class AccountService {
  static getAccountCompanyInfo() {
    return HttpService.get('/account/company-info', {}, {}, true).then(
      (data) => {
        const { users = [], units = [], urbanAreas = [] } = data;

        return {
          users: users.map((item) => new UserModel(item)),
          units: units.map((item) => new UnitModel(item)),
          urbanAreas: urbanAreas.map((item) => new UrbanAreaModel(item)),
        };
      },
    );
  }

  static updateProfile(data: any) {
    return HttpService.put(`/account/profile`, data).then(
      (data) => new UserModel(data),
    );
  }

  static updateCompanyProfile(data: any) {
    return HttpService.put('/account/company-profile', data).then(
      (data) => new UserModel(data),
    );
  }
}
