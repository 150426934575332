import React from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import {
  Home as HomeIcon,
  ViewModule,
  CalendarToday,
  OpenInNew,
  AccountCircle
} from '@mui/icons-material';
import { Box } from '@mui/material';
import { ROUTES, USER_ACCOUNT_TABS, USER_ACTIVITY_TABS } from '@/constants';
import { MenuLink } from './styles';

const UserMenu = () => {
  const [t] = useTranslation('common');
  const location = useLocation();

  return (
    <Box sx={{ p: '10px 30px' }}>
      <MenuLink
        className={classnames({
          'active': location.pathname === ROUTES.USER.DASHBOARD
        })}
        to={ROUTES.USER.DASHBOARD}
      >
        <HomeIcon className="menu-icon" />
        <p className="menu-title">{t('app.home')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          'active': location.pathname.includes(ROUTES.USER.SESSIONS.PREFIX)
        })}
        to={ROUTES.USER.SESSIONS.INDEX}
      >
        <ViewModule className="menu-icon" />
        <p className="menu-title">{t('app.sessions')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          'active': location.pathname === ROUTES.USER.CALENDAR.INDEX
        })}
        to={ROUTES.USER.CALENDAR.INDEX}
      >
        <CalendarToday className="menu-icon" />
        <p className="menu-title">{t('app.my_calendar')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          'active': location.pathname.includes(ROUTES.USER.ACTIVITY.PREFIX)
        })}
        to={`${ROUTES.USER.ACTIVITY.PREFIX}/${USER_ACTIVITY_TABS.WORKING}`}
      >
        <OpenInNew className="menu-icon" />
        <p className="menu-title">{t('app.my_activity')}</p>
      </MenuLink>
      <MenuLink
        className={classnames({
          'active': location.pathname.includes(ROUTES.USER.ACCOUNT.PREFIX)
        })}
        to={`${ROUTES.USER.ACCOUNT.PREFIX}/${USER_ACCOUNT_TABS.PROFILE}`}
      >
        <AccountCircle className="menu-icon" />
        <p className="menu-title">{t('app.account')}</p>
      </MenuLink>
    </Box>
  );
};

export default UserMenu;
