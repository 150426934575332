import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Box, Typography } from '@mui/material';
import { Button, SortOption } from '@/components';
import { ObjectHelper } from '@/utils';
import UserFilters from '@/pages/Company/Users/UserFilters';
import UsersTable, { USER_TABLE_TYPE } from '@/pages/Company/Users/UsersTable';
import { useCompanyState, useGetCompanyDetailUsersAction } from '@/hooks/redux';
import { ROUTES } from '@/constants';

const CompanyUsers = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const { loadingUsers, companyUsers, userTotalCount } = useCompanyState();
  const searchUsers = useGetCompanyDetailUsersAction();

  const [sortOption, setSortOption] = useState<SortOption>({
    order: 'desc',
    orderBy: 'createdAt',
  });
  const [filter, setFilter] = useState({
    search: '',
    page: 0,
    perPage: 10,
  });

  const onChangeFilter = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const onRemoveUser = () => {
    onChangeFilter('page', 0);
  };

  useEffect(() => {
    const query = ObjectHelper.trimQuery({
      ...filter,
      ...sortOption,
    });

    searchUsers(id, query);
  }, [id, filter, sortOption]);

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {t('common.users')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 10,
        }}
      >
        <UserFilters filter={filter} onChangeFilter={onChangeFilter} />
        <Button
          onClick={() =>
            history.push(ROUTES.ADMIN.COMPANIES.ADD_ADMIN.replace(':id', id))
          }
        >
          {t('companies.add_company_admin')}
        </Button>
      </Box>

      <UsersTable
        type={USER_TABLE_TYPE.ADMIN}
        users={companyUsers}
        loading={loadingUsers}
        onRemoveUser={onRemoveUser}
        sortOption={sortOption}
        setSortOption={setSortOption}
        pagination={{
          total: userTotalCount,
          page: filter.page,
          perPage: filter.perPage,
          onPageChange: (page) => onChangeFilter('page', page),
        }}
      />
    </div>
  );
};

export default CompanyUsers;
