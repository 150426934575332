import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  getCompanyScheduledSessionsCountAction,
  getSessionsAction,
  removeSessionAction,
} from '@/redux/actions';

export const useSessionState = () =>
  useSelector(({ sessionReducer }: RootState) => sessionReducer);

export const useGetSessionsAction = () => {
  const dispatch = useDispatch();
  return (data: any = {}) => dispatch(getSessionsAction(data));
};

export const useRemoveSessionAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(removeSessionAction(id));
};

export const useGetCompanyScheduledSessionsCount = () => {
  const dispatch = useDispatch();
  return () => dispatch(getCompanyScheduledSessionsCountAction());
};
