import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import {
  LocalizationProvider,
  TimePicker as MuiTimePicker,
} from '@mui/lab';
import AdapterMoment from '@mui/lab/AdapterMoment';
import { Moment } from 'moment-timezone';

type TimePickerProps = {
  label: string;
  value: Moment | null;
  onChange: (value: Moment | null) => void;
  errorText?: string;
} & SxProps

export const TimePicker: FC<TimePickerProps> = ({
  label,
  value,
  onChange,
  errorText,
}) => {
  const [t] = useTranslation('common');

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiTimePicker
        label={label}
        value={value}
        onChange={(newValue) => {
          onChange(newValue);
        }}
        ampm={false}
        renderInput={(params) => (
          <TextField
            {...params}
            error={!!errorText}
            helperText={t(errorText)}
          />
        )}
      />
    </LocalizationProvider>
  );
}
