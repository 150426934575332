import * as Yup from 'yup';
import { Moment } from 'moment-timezone';

export const sessionTimePeriodForm = {
  periods: Yup.array().of(
    Yup.object().shape({
      date: Yup.object().nullable().required('validation.required_field'),
      startTime: Yup.object().nullable().required('validation.required_field'),
      endTime: Yup.object()
        .nullable()
        .required('validation.required_field')
        .test(
          'minDate',
          'End time should be later than start time',
          function (value) {
            const startTime = this.resolve(Yup.ref('startTime'));

            return !(value as unknown as Moment).isBefore(startTime);
          },
        ),
    }),
  ),
};
