import { Button } from '@/components';
import { CookieConsent } from 'react-cookie-consent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { COOKIE_CONSENT_NAME } from '@/constants';
import { useCookies } from 'react-cookie';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';

const CookiePopup = () => {
  const { t } = useTranslation('common');
  const [cookies, setCookies] = useCookies([COOKIE_CONSENT_NAME]);
  const handleAccept = () => {
    setCookies(COOKIE_CONSENT_NAME, true);
  };

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText={t('cookie_consent.accept')}
        declineButtonText={t('cookie_consent.decline')}
        cookieName={COOKIE_CONSENT_NAME}
        style={{
          background: '#fff',
          color: '#333',
          borderTop: '1px solid #096BDE',
          boxShadow: '0px 2px 20px #096BDE',
        }}
        ButtonComponent={Button}
        buttonStyle={{
          background: '#096BDE',
          color: '#fff',
          fontWeight: 600,
          fontSize: '13px',
        }}
        declineButtonStyle={{
          background: 'rgba(193, 42, 42, 0.65)',
        }}
        onDecline={() => {
          setCookies(COOKIE_CONSENT_NAME, false);
        }}
        flipButtons
        enableDeclineButton
      >
        {t('cookie_consent.message')}{' '}
        <a href="https://coremoting.com/privacy-policy/" target="_blank">
          {t('cookie_consent.privacy_policy')}
        </a>
      </CookieConsent>

      {cookies[COOKIE_CONSENT_NAME] === false && (
        <AppBar
          position="fixed"
          color="secondary"
          sx={{ top: 'auto', bottom: 0 }}
        >
          <Toolbar>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1" color="inherit" component="div">
                {t('cookie_consent.rejected_message')}
              </Typography>
              <Button variant="outlined" onClick={handleAccept}>
                {t('cookie_consent.accept')}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default CookiePopup;
