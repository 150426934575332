import * as Yup from 'yup';

export const addressFormValidation = {
  country: Yup.string().trim().required('validation.required_field'),
  postalCode: Yup.string().required('validation.required_field'),
  city: Yup.string().required('validation.required_field'),
  street: Yup.string().required('validation.required_field'),
  numberOfStreet: Yup.string().required('validation.required_field'),
  description: Yup.string(),
};
