import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DoDisturbOff, DoDisturbOn } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useDisableCompanyAction } from '@/hooks/redux/company';
import ConfirmDialog from '@/components/ConfimDialog';

interface DisableCompanyButtonProps {
  id: string;
  disabled: boolean;
  onDisableCompany: () => void;
}

const DisableCompanyButton: FC<DisableCompanyButtonProps> = ({
  id,
  disabled,
  onDisableCompany,
}) => {
  const [t] = useTranslation('common');
  const disableCompany = useDisableCompanyAction();

  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDisable = async () => {
    setLoading(true);
    const res = await disableCompany(id, !disabled);

    if (res) {
      onDisableCompany();
    }
    setLoading(false);
  };

  return (
    <>
      <Tooltip
        title={t(
          disabled ? 'companies.enable_company' : 'companies.disable_company',
        )}
      >
        <IconButton
          color={disabled ? 'info' : 'error'}
          disabled={loading}
          onClick={() => setShowConfirm(true)}
        >
          {disabled ? <DoDisturbOff /> : <DoDisturbOn />}
        </IconButton>
      </Tooltip>

      <ConfirmDialog
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        onConfirm={onDisable}
        confirmTitle={
          disabled ? 'companies.enable_company' : 'companies.disable_company'
        }
        confirmText={
          disabled
            ? 'companies.enable_company_confirm'
            : 'companies.disable_company_confirm'
        }
      />
    </>
  );
};

export default DisableCompanyButton;
