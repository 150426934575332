import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components';
import { Add as AddIcon } from '@mui/icons-material';
import { FormikContextType, FormikTouched } from 'formik';
import moment from 'moment-timezone';
import TimeItem from '@/parts/SessionTime/TimeItem';

interface SessionTimeProps {
  form: FormikContextType<any>;
}

const SessionTime: FC<SessionTimeProps> = ({ form }) => {
  const [t] = useTranslation('common');

  const { errors, touched } = form;
  const { periods = [] } = form.values;

  const addPeriod = () => {
    form.setFieldValue('periods', [
      ...periods,
      {
        id: `${Math.random()}`,
        date: moment(),
        startTime: moment(),
        endTime: moment(),
      },
    ]);
  };

  const onPeriodChange = (id: string, field: string, value: any) => {
    const newPeriods = periods.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [field]: value,
        };
      }
      return item;
    });

    form.setFieldValue('periods', newPeriods);
  };

  const onRemovePeriod = (id: string) => {
    const newPeriods = periods.filter((item) => item.id !== id);
    form.setFieldValue('periods', newPeriods);
  };

  return (
    <>
      {periods.map((period, index) => (
        <TimeItem
          key={index}
          period={period}
          onPeriodChange={onPeriodChange}
          canRemove={index !== 0}
          onRemove={() => onRemovePeriod(period.id)}
          error={
            errors.periods && errors.periods.length > 0
              ? errors.periods[index]
              : {}
          }
          touched={
            touched.periods &&
            (touched.periods as FormikTouched<any>[]).length > 0
              ? touched.periods[index]
              : {}
          }
        />
      ))}

      <div className="d-flex align-items-center justify-content-end">
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={addPeriod}
          sx={{ mr: 5 }}
        >
          {t('session.add_more_time')}
        </Button>
      </div>
    </>
  );
};

export default SessionTime;
