import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  addUnreadCountAction,
  getNotificationsAction,
  getUnreadCountAction,
  readNotificationAction,
} from '@/redux/actions';

export const useNotificationState = () =>
  useSelector(({ notificationReducer }: RootState) => notificationReducer);

export const useGetNotificationsAction = () => {
  const dispatch = useDispatch();
  return (data: any = {}) => dispatch(getNotificationsAction(data));
};

export const useGetUnreadCountAction = () => {
  const dispatch = useDispatch();
  return () => dispatch(getUnreadCountAction());
};

export const useAddUnreadCountAction = () => {
  const dispatch = useDispatch();
  return () => dispatch(addUnreadCountAction());
};

export const useReadNotificationAction = () => {
  const dispatch = useDispatch();
  return (id: string) => dispatch(readNotificationAction(id));
};
