import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@mui/material';
import { COMPANY_ACCOUNT_TABS, ROUTES } from '@/constants';
import Profile from '@/pages/Company/Account/Profile';
import Rules from '@/pages/Company/Account/Rules';

const accountTabs = [
  { title: 'company_account.profile.title', value: COMPANY_ACCOUNT_TABS.PROFILE },
  { title: 'company_account.rules.title', value: COMPANY_ACCOUNT_TABS.RULES },
]

const Account = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const { tab } = useParams<{ tab: string; }>();

  const activeTab = useMemo(() => {
    if (tab === COMPANY_ACCOUNT_TABS.RULES)
      return tab;

    return COMPANY_ACCOUNT_TABS.PROFILE;
  }, [tab]);

  const onTabChange = (tab: COMPANY_ACCOUNT_TABS) => {
    history.replace(`${ROUTES.COMPANY.ACCOUNT.PREFIX}/${tab}`);
  }

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={(e, value: COMPANY_ACCOUNT_TABS) => onTabChange(value)}
        TabIndicatorProps={{
          style: {display: 'none'}
        }}
      >
        {
          accountTabs.map((item) => (
            <Tab
              key={item.value}
              label={t(item.title)}
              value={item.value}
            />
          ))
        }
      </Tabs>

      <Box sx={{ pt: 15 }}>
        {
          activeTab === COMPANY_ACCOUNT_TABS.PROFILE && (
            <Profile />
          )
        }

        {
          activeTab === COMPANY_ACCOUNT_TABS.RULES && (
            <Rules />
          )
        }
      </Box>
    </div>
  );
};

export default Account;
