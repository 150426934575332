import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@/components';
import SpacePreviewForm from 'src/parts/SpacePreviewForm';
import { FormHelper } from '@/utils';

const validationSchema = Yup.object().shape({
  spaceId: Yup.string().trim().required('validation.required_field'),
});

interface OfferSpaceProps {
  offerData: any;
  setOfferData: (values: any) => void;
  onNext: () => void;
}

const OfferSpace: FC<OfferSpaceProps> = ({
  onNext,
  offerData,
  setOfferData,
}) => {
  const [t] = useTranslation('common');

  const onSubmit = (values: any) => {
    const { spaceId } = values;

    setOfferData({
      ...offerData,
      spaceId,
    });

    onNext();
  };

  const form = useFormik({
    enableReinitialize: true,
    validationSchema,
    onSubmit,
    initialValues: {
      spaceId: offerData.spaceId || '',
    },
  });

  return (
    <div>
      <Box sx={{ mx: 'auto' }}>
        <form
          onSubmit={form.handleSubmit}
          onKeyDown={FormHelper.onKeyDownPreventEnter}
        >
          <SpacePreviewForm form={form} />
          <div className="d-flex align-items-center justify-content-center mt-15">
            <Button type="submit" variant="blue">
              {t('common.next')}
            </Button>
          </div>
        </form>
      </Box>
    </div>
  );
};

export default OfferSpace;
