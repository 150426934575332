import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useHistory } from 'react-router';
import queryString from 'query-string';
import { Typography } from '@mui/material';
import { Button, Input } from '@/components';
import { ROUTES } from '@/constants';
import {
  useAuthState,
  useResetPasswordAction,
  useShowToastAction,
} from '@/hooks/redux';
import colors from '@/themes/colors';

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required('validation.password_required')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'validation.password_invalid',
    ),
  passwordConfirm: Yup.string()
    .required('validation.password_confirm_required')
    .oneOf([Yup.ref('password')], 'validation.password_confirm_unmatched'),
});

const ResetPassword = () => {
  const [t] = useTranslation('common');
  const history = useHistory();
  const showToast = useShowToastAction();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { token = '' } = queryParams;

  const resetPassword = useResetPasswordAction();
  const { loading } = useAuthState();

  const handleSubmit = async (values: any) => {
    const { password } = values;

    if (token) {
      const res = await resetPassword(password, token as string);

      if (res) {
        showToast(t('auth.password_changed_success'));
        history.push(ROUTES.AUTH.LOGIN);
      }
    }
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      password: '',
      passwordConfirm: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Typography variant="h4" color={colors.text} mb={2}>
        {t('auth.reset_password')}
      </Typography>
      <Typography variant="body1" color={colors.text} mb={4}>
        {t('auth.enter_password_reset')}
      </Typography>

      <form onSubmit={form.handleSubmit}>
        <Input
          fullWidth
          type="password"
          label={t('common.password')}
          placeholder={t('auth.password_placeholder')}
          sx={{ mb: 10 }}
          {...form.getFieldProps('password')}
          errorText={
            form.errors.password && form.touched.password
              ? t(form.errors.password as string)
              : ''
          }
        />

        <Input
          fullWidth
          type="password"
          label={t('auth.confirm_password')}
          placeholder={t('auth.confirm_password')}
          sx={{ mb: 10 }}
          {...form.getFieldProps('passwordConfirm')}
          errorText={
            form.errors.passwordConfirm && form.touched.passwordConfirm
              ? t(form.errors.passwordConfirm as string)
              : ''
          }
        />

        <Button fullWidth type="submit" variant="auth" loading={loading}>
          {t('common.submit')}
        </Button>
      </form>

      <div className="flex-center mt-8 text-center">
        <Button
          fullWidth
          variant="authLight"
          onClick={() => {
            history.push(ROUTES.AUTH.LOGIN);
          }}
        >
          {t('auth.back_to_login')}
        </Button>
      </div>
    </>
  );
};

export default ResetPassword;
