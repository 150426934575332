import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import { getCountriesAction } from '@/redux/actions';

export const useCommonState = () =>
  useSelector(({ commonReducer }: RootState) => commonReducer);

export const useGetCountriesAction = () => {
  const dispatch = useDispatch();
  return () => dispatch(getCountriesAction());
};
