import { styled, Box } from '@mui/material';

export const MoreButton = styled('div')`
  font-weight: 400;
  font-size: 11.3231px;
  line-height: 17px;
  width: 100%;
  height: 34px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.palette.primary.main};
  
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

export const BannerBoxStyled = styled(Box)<{
  direction: 'left'|'right'
}>`
  display: flex;
  flex-direction: column;
  align-items: ${ props => props.direction === 'left' ? 'flex-start' : 'flex-end' };
  justify-content: center;
  padding: 15px 25px;
  margin-bottom: 10px;
  
  background-color: ${props => props.theme.colors.bg_light_blue};
  border-radius: 28px;  
  user-select: none;
`

export const BannerQuestionStyled = styled(Box)`
  padding: 10px 15px;
  width: 60%;
  border-radius: 28px;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 45px;
  color: ${props => props.theme.colors.text};
  background-color: ${props => props.theme.colors.bg_blue};
`
