import { useMemo } from 'react';
import { useUrbanAreasState } from '@/hooks/redux';

export const useUrbanAreasByCountry = (country: string) => {
  const { urbanAreas } = useUrbanAreasState();

  const urbanAreasByCountry = useMemo(() => {
    return urbanAreas.filter(
      (item) =>
        item.countryIso.toLowerCase() === country.toLowerCase() ||
        item.countryName.toLowerCase() === country.toLowerCase(),
    );
  }, [urbanAreas]);

  return { urbanAreasByCountry };
};
