import { Dispatch } from 'redux';
import { ADMIN_ACTIONS } from '@/redux/action-types';
import { UserService } from '@/service';

const getAdminUsersStatisticsRequest = () => ({
  type: ADMIN_ACTIONS.GET_ADMIN_USERS_STATISTICS_REQUEST,
});

const getAdminUsersStatisticsSuccess = (
  totalCount: number,
  newCount: number,
  activeCount: number,
) => ({
  type: ADMIN_ACTIONS.GET_ADMIN_USERS_STATISTICS_SUCCESS,
  payload: { totalCount, newCount, activeCount },
});

const getAdminUsersStatisticsError = () => ({
  type: ADMIN_ACTIONS.GET_ADMIN_USERS_STATISTICS_ERROR,
});

export const getAdminStatisticsAction = () => async (dispatch: Dispatch) => {
  dispatch(getAdminUsersStatisticsRequest());

  try {
    const res = await UserService.getAdminUsersStatistics();
    const { totalCount, newCount, activeCount } = res;

    dispatch(getAdminUsersStatisticsSuccess(totalCount, newCount, activeCount));
    return res;
  } catch (err) {
    dispatch(getAdminUsersStatisticsError());
  }
};
