import { HttpService } from '@/service/http.service';
import { SessionModel } from '@/resources/models';

export class SessionService {
  static search(filter: any = {}) {
    return HttpService.get('/sessions', filter).then((res) => {
      const { data = [], totalCount } = res;

      return {
        data: data.map((item) => new SessionModel(item)),
        totalCount,
      };
    });
  }

  static remove(id: string) {
    return HttpService.delete(`/sessions/${id}`);
  }

  static getCompanyScheduledSessionsCount() {
    return HttpService.get('/sessions/company-scheduled-count');
  }
}
