import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogContent, Divider } from '@mui/material';
import { Dialog } from '@/components';
import { AddressModel, SpaceModel } from '@/resources/models';
import { DateHelper } from '@/utils';
import DetailItem from '@/parts/DetailItem';
import { useAddressDetailList } from '@/hooks/app';

interface SessionDetailDialogProps {
  session: any;
  open: boolean;
  handleClose: () => void;
}

const SessionDetailDialog: FC<SessionDetailDialogProps> = ({
  session,
  open,
  handleClose,
}) => {
  const [t] = useTranslation('common');

  const space = session.space as SpaceModel;
  const address = space.address as AddressModel;

  const addressList = useAddressDetailList(address);

  const contents = [
    {
      title: 'common.status',
      value: session.status.toLowerCase(),
    },
    {
      title: 'common.match_date',
      value: DateHelper.formatDate(session.createdAt, 'DD/MM/YYYY'),
    },
    {
      title: 'common.score',
      value: session.score,
    },
    {
      title: 'common.session_date',
      value: session.sessionDate,
    },
    {
      title: 'common.start_time',
      value: session.startTime,
    },
    {
      title: 'common.end_time',
      value: session.endTime,
    },
    {
      title: 'common.host',
      value: session.host,
    },
    {
      title: 'common.guest',
      value: session.guest,
    },
    {
      title: 'common.distance',
      value: `${Number(session.distance).toFixed(2)}Km`,
    },
  ];

  return (
    <Dialog
      key={`session-detail-dialog-${session.id}`}
      open={open}
      onClose={handleClose}
      title={t('session.session_detail')}
    >
      <DialogContent sx={{ py: 5 }}>
        {contents.map((item, index) => (
          <DetailItem
            key={`${session.id}-contents-${index}`}
            label={item.title}
            value={item.value}
          />
        ))}

        <Divider key={`${session.id}-space_divider`} sx={{ mb: 3 }}>
          {t('app.space')}
        </Divider>
        <DetailItem
          key={`${session.id}-space_name`}
          label={t('space.space_name')}
          value={space.name}
        />
        {addressList.map((item, index) => (
          <DetailItem
            key={`${session.id}-address_list-${index}`}
            label={item.label}
            value={item.value}
          />
        ))}

        {session.distanceInfo && (
          <>
            {Object.keys(session.distanceInfo).map((key) => (
              <div key={`${session.id}-session.distanceInfo-${key}`}>
                {session.distanceInfo[key].status === 'OK' && (
                  <>
                    <Divider
                      key={`${session.id}-travel_by-${key}`}
                      sx={{ mb: 3 }}
                    >{`${t('app.travel_by')}: ${key}`}</Divider>
                    {session.distanceInfo[key].distance && (
                      <DetailItem
                        key={`${session.id}-distance-${key}`}
                        label={'common.distance'}
                        value={session.distanceInfo[key].distance.text}
                      />
                    )}
                    {session.distanceInfo[key].duration && (
                      <DetailItem
                        key={`${session.id}-duration-${key}`}
                        label={'common.duration'}
                        value={session.distanceInfo[key].duration.text}
                      />
                    )}
                    {session.distanceInfo[key].duration_in_traffic && (
                      <DetailItem
                        key={`${session.id}-duration-in-traffic-${key}`}
                        label={'common.duration_in_traffic'}
                        value={
                          session.distanceInfo[key].duration_in_traffic.text
                        }
                      />
                    )}
                    {session.distanceInfo[key].fare && (
                      <DetailItem
                        key={`${session.id}-fare-${key}`}
                        label={'common.fare'}
                        value={session.distanceInfo[key].fare.text}
                      />
                    )}
                  </>
                )}
              </div>
            ))}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SessionDetailDialog;
