import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Grid } from '@mui/material';
import {
  Accessible as AccessibleIcon,
  EscalatorWarning,
  LocalParking as LocalParkingIcon,
  Pets,
} from '@mui/icons-material';
import { AddressModel, SpaceModel } from '@/resources/models';
import AddressPreview, {
  AddressType,
} from '@/parts/SpacePreviewForm/AddressPreview';

interface SpacePreviewProps {
  space: SpaceModel;
}

const SpacePreview: FC<SpacePreviewProps> = ({ space }) => {
  const [t] = useTranslation('common');

  return (
    <Box sx={{ mt: 10 }}>
      <AddressPreview
        address={space.address as AddressModel}
        addressType={AddressType.SPACE_ADDRESS}
      />
      <Divider sx={{ mt: 7, mb: 5 }}>{t('common.characteristics')}</Divider>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <strong>{t('space.privacy_offer')}:</strong>
          <p>{t(`space.${space.privacy}`)}</p>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} sx={{ ml: '10px' }}>
            {space.accessibility && (
              <Grid item xs={6}>
                <div className="d-flex align-items-center">
                  <AccessibleIcon sx={{ mr: '5px' }} />
                  {t('space.accessibility')}
                </div>
              </Grid>
            )}

            {space.parking && (
              <Grid item xs={6}>
                <div className="d-flex align-items-center">
                  <LocalParkingIcon sx={{ mr: '5px' }} />
                  {t('space.parking')}
                </div>
              </Grid>
            )}

            {space.childFriendly && (
              <Grid item xs={6}>
                <div className="d-flex align-items-center">
                  <EscalatorWarning sx={{ mr: '5px' }} />
                  {t('space.child_friendly')}
                </div>
              </Grid>
            )}

            {space.petFriendly && (
              <Grid item xs={6}>
                <div className="d-flex align-items-center">
                  <Pets sx={{ mr: '5px' }} />
                  {t('space.pet_friendly')}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SpacePreview;
