import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ROUTES } from '@/constants';
import { Button, SortOption } from '@/components';
import {
  useCompanyState,
  useGetCompanyUsersAction,
} from '@/hooks/redux/company';
import { ObjectHelper } from '@/utils';
import UsersTable from '@/pages/Company/Users/UsersTable';
import UserFilters from '@/pages/Company/Users/UserFilters';
import UploadUsersButton from '@/pages/Company/Users/UploadUsersButton';

const Users = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  const { loadingUsers, companyUsers, userTotalCount } = useCompanyState();
  const getCompanyUsers = useGetCompanyUsersAction();

  const [sortOption, setSortOption] = useState<SortOption>({
    order: 'desc',
    orderBy: 'createdAt',
  });
  const [filter, setFilter] = useState({
    search: '',
    page: 0,
    perPage: 10,
  });

  const onChangeFilter = (field: string, value: any) => {
    setFilter({
      ...filter,
      [field]: value,
    });
  };

  const onRemoveUser = () => {
    onChangeFilter('page', 0);
  };

  useEffect(() => {
    const query = ObjectHelper.trimQuery({
      ...filter,
      ...sortOption,
    });

    getCompanyUsers(query);
  }, [filter, sortOption]);

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {t('common.users')}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <UserFilters filter={filter} onChangeFilter={onChangeFilter} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{ mr: 5 }}
            onClick={() => history.push(ROUTES.COMPANY.USERS.CREATE)}
          >
            {t('company_account.users.add_user')}
          </Button>

          <UploadUsersButton refresh={() => onChangeFilter('page', 0)} />
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          mt: 1,
          mb: 10,
          color: (theme) => theme.colors.text_grey,
          fontSize: '12px',
        }}
      >
        {`${t('companies.allowed_users_only_from_urban_area')}`}
      </Box>

      <UsersTable
        users={companyUsers}
        loading={loadingUsers}
        onRemoveUser={onRemoveUser}
        sortOption={sortOption}
        setSortOption={setSortOption}
        pagination={{
          total: userTotalCount,
          page: filter.page,
          perPage: filter.perPage,
          onPageChange: (page) => onChangeFilter('page', page),
        }}
      />
    </div>
  );
};

export default Users;
