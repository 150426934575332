import React from 'react';
import { useTranslation } from 'react-i18next';
import { CopyrightStyled } from '@/parts/SidebarLeft/styles';

const Copyright = () => {
  const [t] = useTranslation('common');

  return (
    <CopyrightStyled>
      {t('app.copyright', {
        year: (new Date()).getFullYear(),
      })}
    </CopyrightStyled>
  );
};

export default Copyright;
