import React, { FC, useMemo } from 'react';
import { PaginationOptions, Table, TableColumn } from '@/components';
import {
  AddressModel,
  SessionModel,
  SpaceModel,
  UrbanAreaModel,
  UserModel,
} from '@/resources/models';
import { DateHelper } from '@/utils';
import SessionDetailButton from './SessionDetailButton';

interface SessionTableProps {
  sessions: SessionModel[];
  loading?: boolean;
  pagination?: PaginationOptions;
  type?: 'admin' | 'company' | 'user';
  refresh: () => void;
}

const SessionTable: FC<SessionTableProps> = ({
  sessions,
  loading,
  pagination,
  type = 'company',
  refresh,
}) => {
  const tableColumns = useMemo(() => {
    const columns: TableColumn[] = [
      { title: 'common.urban_area', dataIndex: 'urbanArea' },
      { title: 'common.unit', dataIndex: 'unit' },
      { title: 'common.status', dataIndex: 'status' },
      { title: 'common.match_date', dataIndex: 'matchDate' },
      { title: 'common.score', dataIndex: 'score' },
      { title: 'common.session_date', dataIndex: 'sessionDate' },
      { title: 'common.start_time', dataIndex: 'startTime' },
      { title: 'common.end_time', dataIndex: 'endTime' },
      { title: 'common.address', dataIndex: 'address' },
      { title: 'common.distance', dataIndex: 'distanceKm' },
      { title: 'app.space', dataIndex: 'spaceName' },
      { title: 'common.host', dataIndex: 'host' },
      { title: 'common.guest', dataIndex: 'guest' },
      {
        title: '',
        dataIndex: 'address',
        align: 'right',
        render: (session: any) => {
          return (
            <SessionDetailButton
              session={session}
              onRemove={refresh}
              canRemove={type === 'user'}
            />
          );
        },
      },
    ];

    if (type === 'admin') {
      return [{ title: 'common.company', dataIndex: 'company' }, ...columns];
    }

    return columns;
  }, [type]);

  const data = useMemo(() => {
    return sessions.map((item: SessionModel) => {
      const space = item.space as SpaceModel;
      const address = space.address as AddressModel;

      return {
        ...item,
        spaceName: space.name,
        score: Number(item.score).toFixed(2),
        urbanArea: (address.urbanArea as UrbanAreaModel)?.name,
        matchDate: DateHelper.formatDate(item.startTime, 'DD/MM/YYYY'),
        sessionDate: DateHelper.formatDate(item.startTime, 'DD/MM/YYYY'),
        startTime: DateHelper.formatDate(item.startTime, 'HH:mm'),
        endTime: DateHelper.formatDate(item.endTime, 'HH:mm'),
        host: (item.host as UserModel).fullName,
        guest: (item.guest as UserModel).fullName,
        distanceKm: item.distance
          ? `${Number(item.distance).toFixed(2)}Km`
          : '-',
      };
    });
  }, [sessions]);

  return (
    <Table
      columns={tableColumns}
      data={data}
      loading={loading}
      pagination={pagination}
    />
  );
};

export default SessionTable;
