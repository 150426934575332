import React, { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Edit } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Table, TableColumn } from '@/components';
import { SpaceModel } from '@/resources/models';
import SpaceDetailButton from './SpaceDetailButton';
import SpaceRemoveButton from './SpaceRemoveButton';
import { ROUTES } from '@/constants';

interface SpaceTableProps {
  spaces: SpaceModel[];
  loading?: boolean;
}

const SpaceTable: FC<SpaceTableProps> = ({
  spaces,
  loading,
}) => {
  const history = useHistory();

  const columns: TableColumn[] = [
    { title: 'common.name', dataIndex: 'name' },
    { title: 'common.country', dataIndex: 'address.country.name' },
    { title: 'companies.city', dataIndex: 'address.city' },
    { title: 'companies.street', dataIndex: 'address.street' },
    { title: 'companies.number_street', dataIndex: 'address.numberOfStreet' },
    {
      title: 'common.actions',
      dataIndex: 'actions',
      align: 'right',
      render: (space: SpaceModel) => {
        return (
          <div className="d-flex align-items-center justify-content-end">
            <SpaceDetailButton space={space} />

            <IconButton
              color="primary"
              onClick={() => history.push(`${ROUTES.USER.SPACES.PREFIX}/${space.id}`)}
            >
              <Edit />
            </IconButton>

            <SpaceRemoveButton space={space} />
          </div>
        )
      }
    },
  ];

  const data = useMemo(() => {
    return spaces;
  }, [spaces]);

  return (
    <Table
      columns={columns}
      data={data}
      loading={loading}
    />
  )
}

export default SpaceTable;
