import { Dispatch } from 'redux';
import { ACCOUNT_ACTIONS } from '@/redux/action-types';
import { AccountService } from '@/service';
import { UnitModel, UrbanAreaModel, UserModel } from '@/resources/models';
import { showToastAction } from '@/redux/actions/toast.actions';
import { setAccount } from '@/redux/actions/auth.actions';

const getAccountCompanyInfoRequest = () => ({
  type: ACCOUNT_ACTIONS.GET_ACCOUNT_COMPANY_INFO_REQUEST,
});

const getAccountCompanyInfoSuccess = (
  users: UserModel[],
  units: UnitModel[],
  urbanAreas: UrbanAreaModel[],
) => ({
  type: ACCOUNT_ACTIONS.GET_ACCOUNT_COMPANY_INFO_SUCCESS,
  payload: { urbanAreas, users, units },
});

const getAccountCompanyInfoError = () => ({
  type: ACCOUNT_ACTIONS.GET_ACCOUNT_COMPANY_INFO_ERROR,
});

export const getAccountCompanyInfoAction = () => async (dispatch: Dispatch) => {
  dispatch(getAccountCompanyInfoRequest());

  try {
    const res = await AccountService.getAccountCompanyInfo();
    const { users, urbanAreas, units } = res;

    dispatch(getAccountCompanyInfoSuccess(users, units, urbanAreas));
    return res;
  } catch (err) {
    dispatch(getAccountCompanyInfoError());
  }
};

const updateProfileRequest = () => ({
  type: ACCOUNT_ACTIONS.UPDATE_PROFILE_REQUEST,
});

const updateProfileSuccess = () => ({
  type: ACCOUNT_ACTIONS.UPDATE_PROFILE_SUCCESS,
});

const updateProfileError = () => ({
  type: ACCOUNT_ACTIONS.UPDATE_PROFILE_ERROR,
});

export const updateProfileAction =
  (data: any) => async (dispatch: Dispatch) => {
    dispatch(updateProfileRequest());

    try {
      const user = await AccountService.updateProfile(data);

      dispatch(updateProfileSuccess());
      dispatch(setAccount(user));

      return user;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(updateProfileError());
    }
  };
