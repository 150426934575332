import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { AddressModel, NotificationModel, SessionModel, SpaceModel, UserModel } from '@/resources/models';
import { PaginationOptions, Table, TableColumn } from '@/components';
import { DateHelper } from '@/utils';
import AddressDetailButton from '@/parts/AddressDetailButton';
import NotificationReadButton from "@/pages/User/UserNotifications/NotificationReadButton";

const tableColumns: TableColumn[] = [
  { title: 'app.time', dataIndex: 'time' },
  { title: 'common.notification', dataIndex: 'notification' },
  { title: 'common.session_date', dataIndex: 'sessionDate' },
  { title: 'common.start_time', dataIndex: 'startTime' },
  { title: 'common.end_time', dataIndex: 'endTime' },
  { title: 'common.role', dataIndex: 'role' },
  { title: 'common.partner', dataIndex: 'partner' },
  {
    title: 'common.address',
    dataIndex: 'sessionAddress',
    render: (notification: NotificationModel) => {
      const session = notification.session as SessionModel;

      if (session) {
        const space = session.space as SpaceModel;
        const address = space.address as AddressModel;

        return (
          <Box
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            {space?.name}

            <AddressDetailButton address={address} />
          </Box>
        );
      }

      return '-';
    }
  },
  {
    title: '',
    dataIndex: 'notification',
    render: (notification: NotificationModel) => (
      <NotificationReadButton notification={notification} />
    )
  }
]

interface NotificationTableProps {
  notifications: NotificationModel[];
  loading?: boolean;
  pagination?: PaginationOptions;
}

const NotificationTable: FC<NotificationTableProps> = ({
  notifications,
  loading,
  pagination
}) => {
  const [t] = useTranslation('common');

  const data = useMemo(() => {
    return notifications.map((item) => {
      const session = item.session as SessionModel;
      const host = session.host as UserModel;
      const guest = session.guest as UserModel;

      return {
        ...item,
        time: DateHelper.formatDate(item.createdAt, 'DD/MM/YYYY HH:mm'),
        notification: item.title,
        sessionDate: DateHelper.formatDate(session.startTime, 'DD/MM/YYYY'),
        startTime: DateHelper.formatDate(session.startTime, 'hh:mm'),
        endTime: DateHelper.formatDate(session.endTime, 'hh:mm'),
        role: host ? (host.id === item.user ? t('common.host') : t('common.guest')) : '-',
        partner: host && guest ? (host.id === item.user ? guest.fullName : host.fullName) : '-',
      }
    });
  }, [notifications]);

  return (
    <Table
      columns={tableColumns}
      data={data}
      loading={loading}
      pagination={pagination}
    />
  )
};

export default NotificationTable;
