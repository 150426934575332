export const ROUTES = {
  HOME: '/',
  AUTH: {
    PREFIX: '/',
    LOGIN: '/login',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',
  },
  ADMIN: {
    PREFIX: '/',
    DASHBOARD: '/',
    COMPANIES: {
      PREFIX: '/companies',
      INDEX: '/companies',
      CREATE: '/companies/create',
      CALENDAR: '/companies/:id/calendar',
      RULES: '/companies/:id/rules',
      ADD_ADMIN: '/companies/:id/add-admin',
      DETAIL: '/companies/:id/:tab',
      EDIT: '/companies/:id/edit',
      USERS: '/companies/:id/users',
    },
    SESSIONS: {
      PREFIX: '/sessions',
      INDEX: '/sessions',
    },
    USERS: {
      PREFIX: '/users',
      EDIT: '/users/:id',
    },
    KPIS: {
      PREFIX: '/kpis',
      INDEX: '/kpis',
    },
    CUSTOMIZATIONS: {
      PREFIX: '/customizations',
      INDEX: '/customizations',
    },
  },
  COMPANY: {
    PREFIX: '/',
    DASHBOARD: '/',
    SESSIONS: {
      PREFIX: '/sessions',
      INDEX: '/sessions',
    },
    ACTIVITIES: {
      PREFIX: '/activities',
      INDEX: '/activities',
    },
    KPIS: {
      PREFIX: '/kpis',
      INDEX: '/kpis',
    },
    ACCOUNT: {
      PREFIX: '/account',
      INDEX: '/account',
      DETAIL: '/account/:tab',
    },
    USERS: {
      PREFIX: '/users',
      INDEX: '/users',
      CREATE: '/users/create',
      EDIT: '/users/:id',
    },
  },
  USER: {
    PREFIX: '/',
    DASHBOARD: '/',
    NOTIFICATIONS: {
      PREFIX: '/notifications',
      INDEX: '/notifications',
    },
    SESSIONS: {
      PREFIX: '/sessions',
      INDEX: '/sessions',
    },
    OFFER_SESSION: {
      PREFIX: '/sessions',
      INDEX: '/sessions/offer-session',
    },
    REQUEST_SESSION: {
      PREFIX: '/sessions',
      INDEX: '/sessions/request-session',
    },
    ACTIVITY: {
      PREFIX: '/activity',
      INDEX: '/activity/:tab',
    },
    CALENDAR: {
      PREFIX: '/calendar',
      INDEX: '/calendar',
    },
    ACCOUNT: {
      PREFIX: '/account',
      INDEX: '/account/:tab',
    },
    SPACES: {
      PREFIX: '/spaces',
      CREATE: '/spaces/create',
      EDIT: '/spaces/:id',
    },
  },

  SERVER_ERROR: '/server-error',
  CHANGE_PASSWORD: '/change-password',
};

export const AUTH_ROUTES = [
  { path: ROUTES.AUTH.LOGIN },
  { path: ROUTES.AUTH.FORGOT_PASSWORD },
  { path: ROUTES.AUTH.RESET_PASSWORD },
];

export enum COMPANY_ACCOUNT_TABS {
  PROFILE = 'profile',
  USERS = 'users',
  RULES = 'rules',
}

export enum USER_ACCOUNT_TABS {
  PROFILE = 'profile',
  SPACES = 'spaces',
}

export enum USER_ACTIVITY_TABS {
  WORKING = 'working',
  OFFERS = 'offers',
  DEMANDS = 'demands',
}

export enum ADMIN_COMPANY_DETAIL_TABS {
  DETAIL = 'detail',
  USERS = 'users',
}
