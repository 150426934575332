import React from 'react';
import { Button } from '@mui/material';
import { ArrowBackIos as BackIcon } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

export const BackButton = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  return (
    <Button
      sx={{ px: 5 }}
      onClick={() => history.go(-1)}
      startIcon={<BackIcon fontSize="small" />}
      variant="outlined"
    >
      {t('common.back')}
    </Button>
  );
};
