import { useTranslation } from 'react-i18next';
import { SPACE_PRIVACY } from '@/resources/enums';

export const usePrivacyOptions = () => {
  const [t] = useTranslation('common');

  const privacyOptions = [
    { label: t('session.no_preference'), value: SPACE_PRIVACY.NO_PREFERENCE },
    { label: t('space.owned_desk'), value: SPACE_PRIVACY.OWNED_DESK },
    { label: t('space.own_room'), value: SPACE_PRIVACY.OWN_ROOM },
    { label: t('space.desk_sharing'), value: SPACE_PRIVACY.DESK_SHARING },
  ];

  return { privacyOptions, defaultPrivacy: SPACE_PRIVACY.NO_PREFERENCE };
};
