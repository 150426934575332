import React, { FC } from 'react';
import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SxProps } from '@mui/system';

export type SearchProps = {
  className?: string;
} & TextFieldProps & SxProps

export const Search: FC<SearchProps> = ({
  className = '',
  type = 'text',
...props
}) => {

  return (
    <TextField
      type="text"
      InputProps={{
        startAdornment: <InputAdornment position="start">
          <SearchIcon style={{fontSize: '20px'}}/>
        </InputAdornment>,
      }}
      {...props}
    />
  )
};
