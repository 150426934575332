import React, { FC } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';

const LanguageProvider: FC = ({ children }) => {
  return (
    <I18nextProvider i18n={i18next}>
      {children}
    </I18nextProvider>
  );
};

export default LanguageProvider;
