import { AddressModel } from '@/resources/models/address.model';
import { CompanyModel } from '@/resources/models/company.model';
import { UserModel } from '@/resources/models/user.model';
import { PartnerOption, SpaceOption } from '@/resources/interfaces';
import { DEMAND_STATUS, GENDER } from '@/resources/enums';

export class DemandModel {
  id: string;
  company: string | CompanyModel;
  user: string | UserModel;
  address: string | AddressModel;
  startTime: string;
  endTime: string;
  status: DEMAND_STATUS;
  hosts: PartnerOption;
  spaceOptions: SpaceOption;

  constructor(init: any = {}) {
    const data = {
      id: '',
      company: '',
      user: '',
      address: '',
      startTime: '',
      endTime: '',
      status: DEMAND_STATUS.PENDING,
      hosts: {
        name: '',
        units: '',
        gender: GENDER.MALE,
        aroundAge: false,
      },
      spaceOptions: {},
      ...init,
    };

    this.id = data.id || data._id;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.hosts = data.hosts;
    this.status = data.status;
    this.spaceOptions = data.spaceOptions;

    if (typeof data.company === 'string') {
      this.company = data.company;
    } else {
      this.company = new CompanyModel(data.company);
    }

    if (typeof data.user === 'string') {
      this.user = data.user;
    } else {
      this.user = new UserModel(data.user);
    }

    if (typeof data.address === 'string') {
      this.address = data.address;
    } else {
      this.address = new AddressModel(data.address);
    }
  }
}
