import React, { FC, useMemo, useState } from 'react';
import Filters, { FilterOption } from '@/parts/Filters';
import {useTranslation} from "react-i18next";

export enum SESSION_FILTER_TYPE {
  SESSION = 'SESSION',
  OFFER = 'OFFER',
  DEMAND = 'DEMAND',
}

interface SessionFiltersProps {
  filter: any;
  onChangeFilter: (field: string, value: any) => void;
  type?: SESSION_FILTER_TYPE;
}

const SessionFilters: FC<SessionFiltersProps> = ({
  filter,
  onChangeFilter,
  type = SESSION_FILTER_TYPE.SESSION,
}) => {
  const [t] = useTranslation('common');

  const [currentFilterOptions, setCurrentFilterOptions] = useState<FilterOption[]>([
    { type: 'search', value: '', placeholder: 'Search by name' }
  ]);

   const filterOptions = useMemo<FilterOption[]>(() => ([
    {
      type: 'search',
      value: '',
      placeholder:
        type === SESSION_FILTER_TYPE.OFFER
          ? t('session.offer_search_placeholder')
          : type === SESSION_FILTER_TYPE.DEMAND
            ? t('session.demand_search_placeholder')
            : 'Search by name'
    },
  ]), [type]);

  return (
    <div>
      <Filters
        filterOptions={filterOptions}
        currentFilterOptions={currentFilterOptions}
        setCurrentFilterOptions={setCurrentFilterOptions}
        filter={filter}
        onChangeFilter={onChangeFilter}
        showFilterButton={false}
      />
    </div>
  );
};

export default SessionFilters;
