import React, {useState} from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  RadioGroup,
  Radio,
  SelectChangeEvent,
} from '@mui/material';
import { Button, DateRangePicker, Input, Select } from '@/components';

const repeatOptions = [
  { label: 'Each Year', value: 'each-year' },
  { label: 'Each Monday', value: 'each-monday' },
  { label: 'Each month the first monday', value: 'each-month-first-monday' },
];

const AddCalendar = () => {
  const [repeat, setRepeat] = useState(repeatOptions[0].value);
  
  return (
    <Paper sx={{ p: 8, mb: 10 }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 8 }}
      >
        <DateRangePicker />

        <Select
          size="small"
          sx={{
            minWidth: '300px',
            mx: 10
          }}
          label="Repeat"
          options={repeatOptions}
          value={repeat}
          onChange={(e: SelectChangeEvent) => setRepeat(e.target.value)}
        />

        <FormControlLabel control={<Checkbox defaultChecked />} label="Block weekends" />
      </Box>

      <Box
        sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
      >
        <FormControl component="fieldset" sx={{ mr: 6 }}>
          <RadioGroup row aria-label="gender" name="row-radio-buttons-group">
            <FormControlLabel value="female" control={<Radio />} label="Holiday" />
            <FormControlLabel value="male" control={<Radio />} label="Blocked day" />
          </RadioGroup>
        </FormControl>

        <Input label="Label" sx={{ flexGrow: 1 }} />

        <Button sx={{ ml: 6 }}>Add New</Button>
      </Box>
    </Paper>
  )
};

export default AddCalendar;
