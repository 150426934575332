import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAuthState } from '@/hooks/redux';
import { ROLE } from '@/resources/enums';
import AdminMenu from '@/parts/SidebarLeft/AdminMenu';
import CompanyMenu from '@/parts/SidebarLeft/CompanyMenu';
import UserMenu from '@/parts/SidebarLeft/UserMenu';
import Copyright from '@/parts/SidebarLeft/Copyright';
import PoweredBy from '@/parts/SidebarLeft/PoweredBy';
import NewLogo from '@/parts/NewLogo';
import colors from '@/themes/colors';

const SidebarLeft = () => {
  const { currentRole } = useAuthState();

  return (
    <Box
      sx={{
        width: '300px',
        minWidth: '300px',
        height: '100vh',
        bgcolor: (theme) => theme.colors.white,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          height: '70px',
          mb: 10,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
        className="flex-center"
      >
        <NewLogo fillC={colors.btn_blue_bg} size={50} />
        <Typography
          sx={{
            textTransform: 'uppercase',
            color: colors.btn_blue_bg,
            fontFamily: 'Stolzl',
            fontSize: '1em',
          }}
          variant="h5"
        >
          coremoting
        </Typography>
        {/*<Logo />*/}
      </Box>

      {currentRole === ROLE.ADMIN && <AdminMenu />}

      {currentRole === ROLE.COMPANY && <CompanyMenu />}

      {currentRole === ROLE.USER && <UserMenu />}
      <Box
        sx={{
          marginTop: 'auto',
        }}
      >
        <PoweredBy />
        <Copyright />
      </Box>
    </Box>
  );
};

export default SidebarLeft;
