import * as React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

const rows = [
  {
    id: 1,
    from: '01/03/2022',
    to: '04/03/2022',
    time: '04:33',
    label: 'Label Description 1',
  },
  {
    id: 2,
    from: '01/03/2022',
    to: '04/03/2022',
    time: '04:33',
    label: 'Label Description 2',
  },
  {
    id: 3,
    from: '01/03/2022',
    to: '04/03/2022',
    time: '04:33',
    label: 'Label Description 3',
  },
];

const CalendarList = () => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>From</TableCell>
          <TableCell>To</TableCell>
          <TableCell>Time</TableCell>
          <TableCell>Label</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>{row.from}</TableCell>
            <TableCell>{row.to}</TableCell>
            <TableCell>{row.time}</TableCell>
            <TableCell>{row.label}</TableCell>
            <TableCell align="right">
              <IconButton color="error">
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default CalendarList;
