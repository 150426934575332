export const API_SERVER = process.env.REACT_APP_API_SERVER;
export const ACCESS_TOKEN_KEY =
  process.env.REACT_APP_ACCESS_TOKEN || 'access-token';
export const REFRESH_TOKEN_KEY =
  process.env.REACT_APP_REFRESH_TOKEN || 'refresh-token';
export const SOCKET_SERVER = process.env.SOCKET_SERVER;
export const GOOGLE_MAP_KEY =
  process.env.GOOGLE_MAP_KEY || 'AIzaSyCdcyKvWUXhZCh8IiO6oZtIWPhtNtXJ6iA';

export const COOKIE_CONSENT_NAME =
  process.env.COOKIE_CONSENT_NAME || 'cookie-consent';
