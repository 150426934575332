import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Divider, Grid } from '@mui/material';
import {
  Accessible as AccessibleIcon,
  EscalatorWarning,
  LocalParking as LocalParkingIcon,
  Pets,
} from '@mui/icons-material';
import { FormikContextType } from 'formik';
import { Input, Select } from '@/components';
import { usePrivacyOptions } from '@/hooks/app';
import SpaceAddressForm from '@/parts/SpaceAddressForm';

interface SpaceFormProps {
  form: FormikContextType<any>;
  location: any;
  setLocation: (value: any) => void;
  isNew: boolean;
}

const SpaceForm: FC<SpaceFormProps> = ({
  form,
  location,
  setLocation,
  isNew,
}) => {
  const [t] = useTranslation('common');
  const { privacyOptions } = usePrivacyOptions();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Input
            label={t('space.space_name')}
            fullWidth
            {...form.getFieldProps('name')}
            errorText={
              form.errors.name && form.touched.name
                ? t(form.errors.name as string)
                : ''
            }
          />
        </Grid>
      </Grid>

      <Divider sx={{ mt: 7, mb: 5 }}>{t('common.space_address')}</Divider>
      <SpaceAddressForm
        form={form}
        location={location}
        setLocation={setLocation}
        isNew={isNew}
      />

      <Divider sx={{ mt: 7, mb: 5 }}>{t('common.characteristics')}</Divider>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Select
              label={t('space.privacy_offer')}
              fullWidth
              options={privacyOptions}
              {...form.getFieldProps('privacy')}
              errorText={
                form.errors.privacy && form.touched.privacy
                  ? t(form.errors.privacy as string)
                  : ''
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2} sx={{ ml: '10px' }}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.values.accessibility}
                    onChange={(e) =>
                      form.setFieldValue('accessibility', e.target.checked)
                    }
                  />
                }
                label={
                  <div className="d-flex align-items-center">
                    <AccessibleIcon sx={{ mr: '5px' }} />
                    {t('space.accessibility')}
                  </div>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.values.parking}
                    onChange={(e) =>
                      form.setFieldValue('parking', e.target.checked)
                    }
                  />
                }
                label={
                  <div className="d-flex align-items-center">
                    <LocalParkingIcon sx={{ mr: '5px' }} />
                    {t('space.parking')}
                  </div>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.values.childFriendly}
                    onChange={(e) =>
                      form.setFieldValue('childFriendly', e.target.checked)
                    }
                  />
                }
                label={
                  <div className="d-flex align-items-center">
                    <EscalatorWarning sx={{ mr: '5px' }} />
                    {t('space.child_friendly')}
                  </div>
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.values.petFriendly}
                    onChange={(e) =>
                      form.setFieldValue('petFriendly', e.target.checked)
                    }
                  />
                }
                label={
                  <div className="d-flex align-items-center">
                    <Pets sx={{ mr: '5px' }} />
                    {t('space.pet_friendly')}
                  </div>
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SpaceForm;
