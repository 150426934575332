import { Dispatch } from 'redux';
import { USER_ACTIONS } from '@/redux/action-types';
import { showToastAction } from '@/redux/actions/toast.actions';
import { UserService } from '@/service';
import { SessionModel, UserModel } from '@/resources/models';
import { Dayjs } from 'dayjs';
import { UserCalendarData } from '@/redux/reducers/user.reducer';

const createUserRequest = () => ({
  type: USER_ACTIONS.CREATE_USER_REQUEST,
});

const createUserSuccess = () => ({
  type: USER_ACTIONS.CREATE_USER_SUCCESS,
});

const createUserError = () => ({
  type: USER_ACTIONS.CREATE_USER_ERROR,
});

export const createUserAction = (data: any) => async (dispatch: Dispatch) => {
  dispatch(createUserRequest());

  try {
    const res = await UserService.create(data);
    showToastAction('User is created successfully.')(dispatch);
    dispatch(createUserSuccess());

    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(createUserError());
  }
};

const searchUsersRequest = () => ({
  type: USER_ACTIONS.GET_USERS_REQUEST,
});

const searchUsersSuccess = (users: UserModel[], totalCount: number) => ({
  type: USER_ACTIONS.GET_USERS_SUCCESS,
  payload: { users, totalCount },
});

const searchUsersError = () => ({
  type: USER_ACTIONS.GET_USERS_ERROR,
});

export const getUsersAction = (query: any) => async (dispatch: Dispatch) => {
  dispatch(searchUsersRequest());

  try {
    const res = await UserService.search(query);
    dispatch(searchUsersSuccess(res.data, res.totalCount));

    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(searchUsersError());
  }
};

const deleteUserRequest = () => ({
  type: USER_ACTIONS.DELETE_USER_REQUEST,
});

const deleteUserSuccess = () => ({
  type: USER_ACTIONS.DELETE_USER_SUCCESS,
});

const deleteUserError = () => ({
  type: USER_ACTIONS.DELETE_USER_ERROR,
});

export const deleteUserAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(deleteUserRequest());

  try {
    await UserService.removeUser(id);
    dispatch(deleteUserSuccess());

    return true;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(deleteUserError());
  }
};

const findUserRequest = () => ({
  type: USER_ACTIONS.FIND_USER_REQUEST,
});

const findUserSuccess = (user: UserModel) => ({
  type: USER_ACTIONS.FIND_USER_SUCCESS,
  payload: { user },
});

const findUserError = () => ({
  type: USER_ACTIONS.FIND_USER_ERROR,
});

export const findUserAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(findUserRequest());

  try {
    const user = await UserService.find(id);
    dispatch(findUserSuccess(user));

    return user;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(findUserError());
  }
};

const updateUserRequest = () => ({
  type: USER_ACTIONS.UPDATE_USER_REQUEST,
});

const updateUserSuccess = () => ({
  type: USER_ACTIONS.UPDATE_USER_SUCCESS,
});

const updateUserError = () => ({
  type: USER_ACTIONS.UPDATE_USER_ERROR,
});

export const updateUserAction =
  (id: string, data: any) => async (dispatch: Dispatch) => {
    dispatch(updateUserRequest());

    try {
      await UserService.update(id, data);
      showToastAction('The user is updated successfully.')(dispatch);
      dispatch(updateUserSuccess());
      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(updateUserError());
    }
  };

const searchUserSessionsRequest = () => ({
  type: USER_ACTIONS.GET_USER_SESSIONS_REQUEST,
});

const searchUserSessionsSuccess = (
  userSessions: SessionModel[],
  sessionTotalCount: number,
) => ({
  type: USER_ACTIONS.GET_USER_SESSIONS_SUCCESS,
  payload: { userSessions, sessionTotalCount },
});

const searchUserSessionsError = () => ({
  type: USER_ACTIONS.GET_USER_SESSIONS_ERROR,
});

export const searchUserSessionsAction =
  (query: any = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(searchUserSessionsRequest());

    try {
      const { data, totalCount } = await UserService.searchUserSessions(query);
      dispatch(searchUserSessionsSuccess(data, totalCount));
      return { data, totalCount };
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(searchUserSessionsError());
    }
  };

const createMultipleUsersRequest = () => ({
  type: USER_ACTIONS.CREATE_MULTIPLE_USERS_REQUEST,
});

const createMultipleUsersSuccess = () => ({
  type: USER_ACTIONS.CREATE_MULTIPLE_USERS_SUCCESS,
});

const createMultipleUsersError = () => ({
  type: USER_ACTIONS.CREATE_MULTIPLE_USERS_ERROR,
});

export const createMultipleUsersAction =
  (data: any) => async (dispatch: Dispatch) => {
    dispatch(createMultipleUsersRequest());

    try {
      const res = await UserService.createMultipleUsers(data);
      showToastAction(res.message)(dispatch);
      dispatch(createMultipleUsersSuccess());

      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(createMultipleUsersError());
    }
  };

const activeUserRequest = () => ({
  type: USER_ACTIONS.ACTIVE_USER_REQUEST,
});

const activeUserSuccess = (userId: string) => ({
  type: USER_ACTIONS.ACTIVE_USER_SUCCESS,
  payload: { userId },
});

const activeUserError = () => ({
  type: USER_ACTIONS.ACTIVE_USER_ERROR,
});

export const activeUserAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(activeUserRequest());

  try {
    const res = await UserService.activeUser(id);
    dispatch(activeUserSuccess(id));
    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(activeUserError());
  }
};

const blockUserRequest = () => ({
  type: USER_ACTIONS.BLOCK_USER_REQUEST,
});

const blockUserSuccess = (userId: string) => ({
  type: USER_ACTIONS.BLOCK_USER_SUCCESS,
  payload: { userId },
});

const blockUserError = () => ({
  type: USER_ACTIONS.BLOCK_USER_ERROR,
});

export const blockUserAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(blockUserRequest());

  try {
    const res = await UserService.blockUser(id);
    dispatch(blockUserSuccess(id));
    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(blockUserError());
  }
};

const getUserCalendarDataRequest = () => ({
  type: USER_ACTIONS.USER_CALENDAR_DATA_REQUEST,
});

const getUserCalendarDataSuccess = (calendarData: UserCalendarData[]) => ({
  type: USER_ACTIONS.USER_CALENDAR_DATA_SUCCESS,
  payload: { calendarData },
});

const getUserCalendarDataError = () => ({
  type: USER_ACTIONS.USER_CALENDAR_DATA_ERROR,
});

export const getUserCalendarDataAction =
  (date: Dayjs) => async (dispatch: Dispatch) => {
    dispatch(getUserCalendarDataRequest());

    try {
      const res = await UserService.getUserCalendarDataAction(date);
      dispatch(getUserCalendarDataSuccess(res));
      return res;
    } catch (err) {
      dispatch(getUserCalendarDataError());
    }
  };
