import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import PostCompany from '@/parts/PostCompany';

const AddCompany = () => {
  const [t] = useTranslation('common');

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{color: theme => theme.colors.text_secondary}}
      >
        {t('companies.add_new_company')}
      </Typography>

      <PostCompany />
    </div>
  );
};

export default AddCompany;
