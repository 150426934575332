import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatisticBoxStyled } from './styles';
import { AccountCircle } from '@mui/icons-material';
import colors from '@/themes/colors';
import { useAdminState } from '@/hooks/redux';

const AdminStatistics = () => {
  const [t] = useTranslation('common');

  const { usersStatistics, loadingAdminStatistics } = useAdminState();

  return (
    <Box
      sx={{
        mt: '100px',
      }}
    >
      <Typography variant="body1">{t('common.all_time')}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          contentJustify: 'center',
        }}
      >
        <StatisticBoxStyled>
          <AccountCircle
            style={{ color: colors.btn_blue_bg, fontSize: '66px' }}
          />
          <div className="counter">
            <div className="counter-value">
              {loadingAdminStatistics ? (
                <CircularProgress />
              ) : (
                usersStatistics.totalCount
              )}
            </div>
            <div className="counter-label">{t('app.total_users')}</div>
          </div>
        </StatisticBoxStyled>
        <StatisticBoxStyled>
          <AccountCircle
            style={{ color: colors.btn_blue_bg, fontSize: '66px' }}
          />
          <div className="counter">
            <div className="counter-value">
              {loadingAdminStatistics ? (
                <CircularProgress />
              ) : (
                usersStatistics.activeCount
              )}
            </div>
            <div className="counter-label">{t('app.active_users')}</div>
          </div>
        </StatisticBoxStyled>
      </Box>
      <Typography sx={{ mt: 5 }} variant="body1">
        {t('common.last_days', { count: 30 })}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          contentJustify: 'center',
        }}
      >
        <StatisticBoxStyled>
          <AccountCircle
            style={{ color: colors.btn_blue_bg, fontSize: '66px' }}
          />
          <div className="counter">
            <div className="counter-value">
              {loadingAdminStatistics ? (
                <CircularProgress />
              ) : (
                usersStatistics.newCount
              )}
            </div>
            <div className="counter-label">{t('app.new_users')}</div>
          </div>
        </StatisticBoxStyled>
      </Box>
    </Box>
  );
};

export default AdminStatistics;
