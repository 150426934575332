import { HttpService } from '@/service/http.service';
import { CompanyModel, SessionModel, UserModel } from '@/resources/models';
import { SearchApiResult } from '@/resources/interfaces';
import { ObjectHelper } from '@/utils';

export class CompanyService {
  static search(query: any = {}): Promise<SearchApiResult<CompanyModel>> {
    return HttpService.get('/companies', query).then(
      (res: SearchApiResult<any>) => {
        return {
          totalCount: res.totalCount,
          data: res.data.map((item) => new CompanyModel(item)),
        };
      },
    );
  }

  static find(id: string) {
    return HttpService.get(`/companies/${id}`).then(
      (res) => new CompanyModel(res),
    );
  }

  static create(data: any) {
    return HttpService.post('/companies', data);
  }

  static update(id: string, data: any) {
    return HttpService.put(`/companies/${id}`, data);
  }

  static remove(id: string) {
    return HttpService.delete(`/companies/${id}`);
  }

  static createAdmin(id: string, data: any) {
    return HttpService.post(`/companies/${id}/admin`, data);
  }

  static searchUsers(query: any = {}): Promise<SearchApiResult<UserModel>> {
    return HttpService.get(
      '/companies/users',
      ObjectHelper.trimQuery(query),
    ).then((res: SearchApiResult<any>) => {
      return {
        totalCount: res.totalCount,
        data: res.data.map((item) => new UserModel(item)),
      };
    });
  }

  static getCompanyUsers(
    id: string,
    query: any = {},
  ): Promise<SearchApiResult<UserModel>> {
    return HttpService.get(
      `/companies/${id}/users`,
      ObjectHelper.trimQuery(query),
    ).then((res: SearchApiResult<any>) => {
      return {
        totalCount: res.totalCount,
        data: res.data.map((item) => new UserModel(item)),
      };
    });
  }

  static searchCompanySessions(filter: any = {}) {
    return HttpService.get('/companies/sessions', filter).then((res) => {
      const { data = [], totalCount } = res;

      return {
        data: data.map((item) => new SessionModel(item)),
        totalCount,
      };
    });
  }

  static disable(id: string, disabled: boolean) {
    return HttpService.put(`/companies/${id}/switch-disabled`, { disabled });
  }
}
