import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import moment from 'moment-timezone';
import {
  useAccountState,
  useCreateDemandAction,
  useGetPendingOfferCount,
  useShowToastAction,
} from '@/hooks/redux';
import { useCountryOptions, usePrivacyOptions } from '@/hooks/app';
import { SessionPeriod } from '@/resources/interfaces';
import { ObjectHelper } from '@/utils';
import RequestSpace, {
  ADDRESS_OPTION,
} from '@/pages/User/RequestSession/RequestSpace';
import RequestTime from '@/pages/User/RequestSession/RequestTime';
import RequestHosts from '@/pages/User/RequestSession/RequestHosts';
import { ROUTES, USER_ACTIVITY_TABS } from '@/constants';
import { useHistory } from 'react-router';

export enum REQUEST_SESSION_STEPS {
  SPACE,
  TIME,
  HOSTS,
}

const steps = [
  { title: 'app.space', step: REQUEST_SESSION_STEPS.SPACE },
  { title: 'app.time', step: REQUEST_SESSION_STEPS.TIME },
  { title: 'app.hosts', step: REQUEST_SESSION_STEPS.HOSTS },
];

const RequestSession = () => {
  const [t] = useTranslation('common');
  const history = useHistory();

  const getPendingOfferCount = useGetPendingOfferCount();
  const showToast = useShowToastAction();

  const createDemand = useCreateDemandAction();
  const { defaultCountryId } = useCountryOptions();
  const { defaultPrivacy } = usePrivacyOptions();
  const { companyUrbanAreas } = useAccountState();

  const [activeStep, setActiveStep] = useState(REQUEST_SESSION_STEPS.SPACE);
  const [requestData, setRequestData] = useState({
    addressOption: ADDRESS_OPTION.MY_ADDRESS,
    address: {
      country: defaultCountryId,
      postalCode: '',
      city: '',
      street: '',
      numberOfStreet: '',
      description: '',
      lat: null,
      lng: null,
    },
    spaceOptions: {
      maximumDistance: { value: '', mandatory: false },
      privacy: { value: defaultPrivacy, mandatory: false },
      accessibility: { value: false, mandatory: false },
      parking: { value: false, mandatory: false },
      childFriendly: { value: false, mandatory: false },
      petFriendly: { value: false, mandatory: false },
    },
    periods: [
      {
        id: '1',
        date: moment(),
        startTime: moment(),
        endTime: moment(),
      },
    ],
    hosts: {
      names: { value: '', mandatory: false },
      units: { value: '', mandatory: false },
      gender: { value: false, mandatory: false },
      aroundAge: { value: false, mandatory: false },
    },
  });

  const onRequestSession = async (data: any) => {
    ObjectHelper.convertSessionPeriod(data.periods as SessionPeriod[]);

    // for now use company area
    data.address.urbanArea = companyUrbanAreas[0].id;

    const res = (await createDemand(ObjectHelper.trimQuery(data))) as any;

    if (res) {
      if (res.scheduled) {
        showToast(t('session.session_created_by_demand'));
        history.push(
          `${ROUTES.USER.ACTIVITY.PREFIX}/${USER_ACTIVITY_TABS.WORKING}`,
        );
      } else {
        showToast(t('session.demand_created_message'));
        history.push(
          `${ROUTES.USER.ACTIVITY.PREFIX}/${USER_ACTIVITY_TABS.DEMANDS}`,
        );
      }
    }
  };

  useEffect(() => {
    getPendingOfferCount();
  }, []);

  return (
    <div>
      <Typography
        variant={'h1'}
        sx={{ color: (theme) => theme.colors.text_secondary }}
      >
        {t('app.request_sessions')}
      </Typography>

      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 10 }}>
        {steps.map((item) => (
          <Step key={item.step}>
            <StepLabel>{t(item.title)}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box>
        {activeStep === REQUEST_SESSION_STEPS.SPACE && (
          <RequestSpace
            requestData={requestData}
            setRequestData={setRequestData}
            onNext={() => setActiveStep(REQUEST_SESSION_STEPS.TIME)}
          />
        )}

        {activeStep === REQUEST_SESSION_STEPS.TIME && (
          <RequestTime
            requestData={requestData}
            setRequestData={setRequestData}
            onBack={() => setActiveStep(REQUEST_SESSION_STEPS.SPACE)}
            onNext={() => setActiveStep(REQUEST_SESSION_STEPS.HOSTS)}
          />
        )}

        {activeStep === REQUEST_SESSION_STEPS.HOSTS && (
          <RequestHosts
            requestData={requestData}
            setRequestData={setRequestData}
            onBack={() => setActiveStep(REQUEST_SESSION_STEPS.TIME)}
            onRequestSession={onRequestSession}
          />
        )}
      </Box>
    </div>
  );
};

export default RequestSession;
