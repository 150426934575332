import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Box, Typography } from '@mui/material';
import { useCountryOptions } from '@/hooks/app';
import { BackButton, Button } from '@/components';
import {
  useAccountState,
  useAuthState,
  useCreateSpaceAction,
  useGetAccountCompanyInfo,
  useShowToastAction,
  useSpaceState,
} from '@/hooks/redux';
import { FormHelper, ObjectHelper } from '@/utils';
import { ROUTES, USER_ACCOUNT_TABS } from '@/constants';
import { SPACE_PRIVACY } from '@/resources/enums';
import SpaceForm from '@/parts/SpaceForm';
import { AddressModel, CompanyModel } from '@/resources/models';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('validation.required_field'),
  country: Yup.string().trim().required('validation.required_field'),
  // urbanArea: Yup.string().trim().required('validation.required_field'),
  privacy: Yup.string().trim().required('validation.required_field'),
  postalCode: Yup.string().trim().required('validation.required_field'),
  city: Yup.string().trim().required('validation.required_field'),
  street: Yup.string().trim().required('validation.required_field'),
  numberOfStreet: Yup.string().trim().required('validation.required_field'),
});

const CreateSpace = () => {
  const [t] = useTranslation('common');
  const { defaultCountryId } = useCountryOptions();
  const createSpace = useCreateSpaceAction();
  const { creating } = useSpaceState();
  const showToast = useShowToastAction();
  const history = useHistory();
  const { account } = useAuthState();
  const { companyUrbanAreas } = useAccountState();
  const [location, setLocation] = useState({ lat: '', lng: '' });

  const getAccountCompanyInfo = useGetAccountCompanyInfo();

  useEffect(() => {
    getAccountCompanyInfo();
  }, []);

  useEffect(() => {
    if (form && companyUrbanAreas.length) {
      form.setFieldValue('urbanArea', companyUrbanAreas[0].name);
    }
  }, [companyUrbanAreas]);

  const onSubmit = async (values: any) => {
    const {
      country,
      postalCode,
      city,
      // urbanArea,
      street,
      numberOfStreet,
      description,
      ...rest
    } = values;

    if (
      country !==
      ((account.company as CompanyModel).address as AddressModel).country
    ) {
      showToast(
        t('common.please_use_country_corresponding_to_urban_area'),
        'error',
      );
      return;
    }

    const urbanArea = companyUrbanAreas[0].id;

    const data = {
      ...rest,
      address: {
        country,
        postalCode,
        city,
        urbanArea,
        street,
        numberOfStreet,
        description,
        lat: location.lat,
        lng: location.lng,
      },
    };
    const res = await createSpace(ObjectHelper.trimQuery(data));

    if (res) {
      showToast(t('space.space_created_message'));
      history.push(`${ROUTES.USER.ACCOUNT.PREFIX}/${USER_ACCOUNT_TABS.SPACES}`);
    }
  };

  const form = useFormik({
    validationSchema,
    onSubmit,
    initialValues: {
      name: '',
      country: defaultCountryId,
      postalCode: '',
      city: '',
      street: '',
      numberOfStreet: '',
      description: '',
      privacy: SPACE_PRIVACY.OWNED_DESK,
      accessibility: false,
      parking: false,
      childFriendly: false,
      petFriendly: false,
    },
  });

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 15,
        }}
      >
        <Typography
          variant={'h1'}
          sx={{ color: (theme) => theme.colors.text_secondary }}
        >
          {t('space.create_new_space')}
        </Typography>

        <BackButton />
      </Box>

      <form
        onSubmit={form.handleSubmit}
        onKeyDown={FormHelper.onKeyDownPreventEnter}
      >
        <Box sx={{ mx: 'auto', maxWidth: '600px' }}>
          <SpaceForm
            form={form}
            location={location}
            setLocation={setLocation}
            isNew={true}
          />

          <div className="d-flex justify-content-center mt-20">
            <Button variant="blue" type="submit" loading={creating}>
              {t('common.submit')}
            </Button>
          </div>
        </Box>
      </form>
    </div>
  );
};

export default CreateSpace;
