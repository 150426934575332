import { HttpService } from '@/service/http.service';
import { OfferModel } from '@/resources/models';

export class OfferService {
  static create(data: any) {
    return HttpService.post('/offers', data);
  }

  static search(filter: any = {}) {
    return HttpService.get('/offers', filter).then((res) => {
      const { data = [], totalCount } = res;

      return {
        data: data.map((item) => new OfferModel(item)),
        totalCount,
      };
    });
  }

  static getHostPendingCount() {
    return HttpService.get('/offers/pending-hosts');
  }

  static remove(id: string) {
    return HttpService.delete(`/offers/${id}`);
  }
}
