import React, { FC, useState } from 'react';
import { SpaceModel } from '@/resources/models';
import { Visibility } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import SpaceDetailDialog from '@/pages/User/Account/Spaces/SpaceDetailDialog';

interface SpaceDetailButtonProps {
  space: SpaceModel;
}

const SpaceDetailButton: FC<SpaceDetailButtonProps> = ({
  space
}) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <IconButton color="primary" onClick={() => setShowDialog(true)}>
        <Visibility />
      </IconButton>

      <SpaceDetailDialog
        space={space}
        open={showDialog}
        handleClose={() => setShowDialog(false)}
      />
    </>
  )
};

export default SpaceDetailButton;
