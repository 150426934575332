import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BackButton } from '@/components';
import AddCalendar from '@/pages/Admin/Companies/CompanyCalendar/AddCalendar';
import CalendarList from '@/pages/Admin/Companies/CompanyCalendar/CalendarList';

const CompanyCalendar = () => {
  const [t] = useTranslation('common');

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 15
        }}
      >
        <Typography
          variant={'h1'}
          sx={{color: theme => theme.colors.text_secondary}}
        >
          {t('companies.company_calendar')}
        </Typography>

        <BackButton />
      </Box>

      <AddCalendar />

      <CalendarList />
    </div>
  );
};

export default CompanyCalendar;
