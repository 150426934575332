import { Dispatch } from 'redux';
import { UrbanAreaService } from '@/service/urban-area.service';
import { showToastAction } from '@/redux/actions/toast.actions';
import { URBAN_AREA_ACTIONS } from '@/redux/action-types/urban-areas.types';

export const getUrbanAreasAction = () => async (dispatch: Dispatch) => {
  try {
    const res = await UrbanAreaService.getUrbanAreas();
    dispatch({
      type: URBAN_AREA_ACTIONS.GET_URBAN_AREAS,
      payload: { urbanAreas: res },
    });
    return res;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
  }
};
