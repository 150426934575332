import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from '@mui/material';
import { FormikContextType } from 'formik';
import { Select } from '@/components';
import { useSpaceState } from '@/hooks/redux';
import { SpaceModel } from '@/resources/models';
import { ROUTES } from '@/constants';
import { useHistory } from 'react-router';
import SpacePreview from '@/parts/SpacePreviewForm/SpacePreview';

interface SpacePreviewFormProps {
  form: FormikContextType<any>;
}

const SpacePreviewForm: FC<SpacePreviewFormProps> = ({ form }) => {
  const [t] = useTranslation('common');
  const { spaces } = useSpaceState();
  const [space, setSpace] = useState<SpaceModel | undefined>(undefined);

  const history = useHistory();

  const spaceSuggestions = useMemo(() => {
    return spaces.map((item) => ({
      value: item.id,
      label: item.name,
    }));
  }, [spaces]);

  useEffect(() => {
    if (form.values.spaceId) {
      onSpaceChange(form.values.spaceId);
    }
  }, []);

  const onSpaceChange = (value) => {
    const space = spaces.find((item) => item.id === value);

    if (space) {
      setSpace(space);
      form.setFieldValue('spaceId', space.id);
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Select
            fullWidth
            options={spaceSuggestions}
            {...form.getFieldProps('spaceId')}
            onChange={(e) => onSpaceChange(e.target.value)}
            errorText={
              form.errors.spaceId && form.touched.spaceId
                ? t(form.errors.spaceId as string)
                : ''
            }
            label={t('space.space_name')}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="link"
              onClick={() => history.push(ROUTES.USER.SPACES.CREATE)}
            >
              {t('space.create_new_space')}
            </Button>
          </Box>
        </Grid>
      </Grid>
      {space ? (
        <SpacePreview space={space} />
      ) : (
        <Box
          sx={{
            textAlign: 'center',
            fontSize: '24px',
            color: (theme) => theme.colors.text,
            my: 10,
          }}
        >
          {t('space.choose_space')}
        </Box>
      )}
    </>
  );
};

export default SpacePreviewForm;
