import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { Button, Dialog } from '@/components';

interface ConfirmDialogProps {
  open: boolean;
  handleClose: () => void;
  confirmTitle?: string;
  confirmText: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  open,
  handleClose,
  confirmTitle= 'common.confirmation',
  confirmText,
  onConfirm,
  onCancel,
}) => {
  const [t] = useTranslation('common');

  const cancel = () => {
    if (onCancel) {
      onCancel();
    }

    handleClose();
  }

  const confirm = () => {
    onConfirm();
    handleClose();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title={t(confirmTitle)}
    >
      <DialogContent sx={{ py: 7.5 }}>
        <DialogContentText
          sx={{ color: 'primary.main', fontSize: '1.125rem' }}
        >
          {t(confirmText)}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={cancel}
          variant="text"
        >
          {t('common.no')}
        </Button>
        <Button
          onClick={confirm}
        >
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
