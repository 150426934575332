import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Grid } from '@mui/material';
import { FormikContextType } from 'formik';
import { GOOGLE_MAP_KEY } from '@/constants';
import { Input, Select } from '@/components';
import { useCountryOptions } from '@/hooks/app';
import GoogleMapReact from 'google-map-react';
import Geocode from 'react-geocode';
import { useAuthState } from '@/hooks/redux';
import { AddressModel } from '@/resources/models';
import { FormHelper } from '@/utils';
import colors from '@/themes/colors';

export const Marker = ({ lat, lng }) => (
  <div
    style={{
      color: 'white',
      background: colors.btn_red_bg,
      padding: '8px 8px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
    }}
  ></div>
);

export const handleGoogleCountryName = (countryName) => {
  switch (countryName) {
    case 'Czechia':
      return 'Czech Republic';
    case 'Moldova':
      return 'Moldova, Republic of';
    case 'Türkiye':
      return 'Turkey';
    default:
      return countryName;
  }
};

const DefaultZoom = 14;

interface SpaceAddressFormProps {
  form: FormikContextType<any>;
  isNew: boolean;
  isCompany?: boolean;
  location?: any;
  setLocation?: (value: any) => void;
}

const SpaceAddressForm: FC<SpaceAddressFormProps> = ({
  form,
  isNew,
  isCompany = false,
  location = {},
  setLocation = () => ({}),
}) => {
  const [t] = useTranslation('common');
  const { countryOptions } = useCountryOptions();
  const { account } = useAuthState();
  const [zoom, setZoom] = useState(DefaultZoom);
  const [mapRequestInProgress, setMapRequestInProgress] = useState(false);
  const [glocation, setGlocation] = useState(location);
  const resetAddressFields = (withDescription = true) => {
    form.setFieldValue('city', '');
    form.setFieldValue('numberOfStreet', '');
    form.setFieldValue('postalCode', '');
    form.setFieldValue('street', '');
    if (withDescription) {
      form.setFieldValue('description', '');
    }
  };

  const handleAccountAddress = (address: AddressModel) => {
    setLocation({ lat: address.lat, lng: address.lng });
    setZoom(14);
    form.setFieldValue('country', address.country);
    form.setFieldValue('city', address.city);
    form.setFieldValue('numberOfStreet', address.numberOfStreet);
    form.setFieldValue('postalCode', address.postalCode);
    form.setFieldValue('street', address.street);
    form.setFieldValue('description', address.description);
  };

  const getAddressByLocation = (
    newLocation: { lat: number; lng: number },
    force = false,
  ) => {
    setLocation(newLocation);
    if (
      force ||
      (!form.values.city &&
        !form.values.street &&
        !form.values.numberOfStreet &&
        !form.values.postalCode)
    ) {
      setMapRequestInProgress(true);
      let city,
        street_number,
        postal_code,
        route,
        country = '';
      Geocode.setApiKey(GOOGLE_MAP_KEY);
      Geocode.fromLatLng(newLocation.lat, newLocation.lng).then(
        (response) => {
          resetAddressFields(false);
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case 'street_number':
                  street_number =
                    response.results[0].address_components[i].long_name;
                  break;
                case 'route':
                  route = response.results[0].address_components[i].long_name;
                  break;
                case 'postal_code':
                  postal_code =
                    response.results[0].address_components[i].long_name;
                  break;
                case 'locality':
                  city = response.results[0].address_components[i].long_name;
                  break;
                case 'country':
                  country = handleGoogleCountryName(
                    response.results[0].address_components[i].long_name,
                  );
                  break;
              }
            }
          }
          const getCountryValue = countryOptions.find(
            (item) => item.label === country,
          )?.value;

          form.setFieldValue('country', getCountryValue || '');
          form.setFieldValue('city', city || '');
          form.setFieldValue('numberOfStreet', street_number || '');
          form.setFieldValue('postalCode', postal_code || '');
          form.setFieldValue('street', route || '');
          setMapRequestInProgress(false);
        },
        (error) => {
          console.error(error);
          setMapRequestInProgress(false);
        },
      );
    }
  };

  const getLocationByAddress = () => {
    if (
      form.values.city &&
      form.values.street &&
      form.values.numberOfStreet &&
      form.values.postalCode
    ) {
      setMapRequestInProgress(true);
      Geocode.setApiKey(GOOGLE_MAP_KEY);
      const { city, street, numberOfStreet, postalCode, country } = form.values;
      Geocode.fromAddress(
        `${numberOfStreet}, ${street}, ${city}, ${postalCode}, ${country}`,
      ).then(
        (response) => {
          if (response.results[0] && response.results[0].geometry.location) {
            setLocation(response.results[0].geometry.location);
            setZoom(17);
          }
          setMapRequestInProgress(false);
        },
        (error) => {
          console.error(error);
          setMapRequestInProgress(false);
        },
      );
    }
  };

  const getByNavigator = () => {
    if (isNew && !location.lat) {
      setMapRequestInProgress(true);
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          getAddressByLocation({ lat, lng }, true);
        },
        () => {
          if (account?.address) {
            handleAccountAddress(account?.address as AddressModel);
          } else {
            setMapRequestInProgress(false);
          }
        },
      );
    }
  };

  useEffect(() => {
    getByNavigator();
  }, []);

  useEffect(() => {
    if (glocation.lat) {
      getAddressByLocation(glocation);
    }
  }, [glocation]);

  return (
    <Grid container spacing={2} sx={{ position: 'relative' }}>
      <Grid item xs={12} md={6}>
        <Grid container>
          <Grid container spacing={2}>
            {!isCompany && (
              <Grid item xs={12}>
                <Input
                  id="urbanarea"
                  label={t('common.urban_area')}
                  fullWidth
                  disabled={true}
                  value={form.values.urbanArea}
                  errorText={
                    form.errors.description && form.touched.description
                      ? t(form.errors.description as string)
                      : ''
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Select
                id="country"
                label={t('companies.country')}
                fullWidth
                // disabled={!isCompany}
                options={countryOptions}
                {...form.getFieldProps('country')}
                errorText={
                  form.errors.country && form.touched.country
                    ? t(form.errors.country as string)
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Input
                id="postalCode"
                label={t('companies.postal_code')}
                fullWidth
                {...form.getFieldProps('postalCode')}
                errorText={
                  form.errors.postalCode && form.touched.postalCode
                    ? t(form.errors.postalCode as string)
                    : ''
                }
                onBlur={getLocationByAddress}
                onKeyDown={FormHelper.callOnEnter(getLocationByAddress)}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Input
                id="city"
                label={t('companies.city')}
                fullWidth
                {...form.getFieldProps('city')}
                errorText={
                  form.errors.city && form.touched.city
                    ? t(form.errors.city as string)
                    : ''
                }
                onBlur={getLocationByAddress}
                onKeyDown={FormHelper.callOnEnter(getLocationByAddress)}
              />
            </Grid>

            <Grid item xs={9}>
              <Input
                id="street"
                label={t('companies.street')}
                fullWidth
                {...form.getFieldProps('street')}
                errorText={
                  form.errors.street && form.touched.street
                    ? t(form.errors.street as string)
                    : ''
                }
                onBlur={getLocationByAddress}
                onKeyDown={FormHelper.callOnEnter(getLocationByAddress)}
              />
            </Grid>
            <Grid item xs={3}>
              <Input
                id="numberOfStreet"
                label={t('companies.number_street')}
                fullWidth
                {...form.getFieldProps('numberOfStreet')}
                errorText={
                  form.errors.numberOfStreet && form.touched.numberOfStreet
                    ? t(form.errors.numberOfStreet as string)
                    : ''
                }
                onBlur={getLocationByAddress}
                onKeyDown={FormHelper.callOnEnter(getLocationByAddress)}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                id="description"
                label={t('common.description')}
                fullWidth
                multiline
                onChange={(e) =>
                  form.setFieldValue('description', e.target.value)
                }
                rows={4}
                {...form.getFieldProps('description')}
                errorText={
                  form.errors.description && form.touched.description
                    ? t(form.errors.description as string)
                    : ''
                }
              />
            </Grid>
            <Grid item xs={12}>
              <div className="d-flex justify-content-start">
                <Button variant="link" onClick={() => resetAddressFields()}>
                  {t('common.reset_address')}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <div style={{ height: isCompany ? '265px' : '300px', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
            center={{
              lat: location.lat || 32.7502,
              lng: location.lng || 114.7655,
            }}
            zoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              map.addListener('click', (e) => {
                const lat = e.latLng.lat();
                const lng = e.latLng.lng();
                setGlocation({ lat, lng });
              });
            }}
          >
            <Marker lat={location.lat} lng={location.lng} />
          </GoogleMapReact>
          <div className="d-flex justify-content-end">
            {mapRequestInProgress && (
              <CircularProgress size={20} sx={{ mt: '6px' }} />
            )}
            <Button
              variant="link"
              sx={{
                color: (theme) => theme.colors.btn_red_bg,
                '&:hover': {
                  color: (theme) => theme.colors.btn_red_bg,
                },
              }}
              onClick={() => {
                getAddressByLocation(location, true);
              }}
            >
              {t('common.get_address_from_map')}
            </Button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default SpaceAddressForm;
