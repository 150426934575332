import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  AddressModel,
  CompanyModel,
  UrbanAreaModel,
  UnitModel,
} from '@/resources/models';
import { addressFormValidation } from '@/validations';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Button, Input } from '@/components';
import {
  useCommonState,
  useCompanyState,
  useUpdateCompanyAction,
} from '@/hooks/redux';
import { FormHelper, ObjectHelper } from '@/utils';
import { ROUTES } from '@/constants';
import { urbanAreaUnitValidation } from 'src/parts/PostCompany/UrbanAreaUnits';
import UrbanAreasForm from 'src/parts/PostCompany/UrbanAreaUnits/UrbanAreasForm';
import UnitsForm from '@/parts/PostCompany/UrbanAreaUnits/UnitsForm';
import SpaceAddressForm from '@/parts/SpaceAddressForm';
import { useUrbanAreasByCountry } from '@/hooks/app/useUrbanAreasByCountry';
import { useUrbanAreasOptions } from '@/hooks/app/useUrbanAreasOptions';

const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('validation.required_field'),
  ...addressFormValidation,
  ...urbanAreaUnitValidation,
});

interface EditCompanyFormProps {
  company: CompanyModel;
}

const EditCompanyForm: FC<EditCompanyFormProps> = ({ company }) => {
  const [t] = useTranslation('common');

  const history = useHistory();
  const { updating } = useCompanyState();
  const updateCompany = useUpdateCompanyAction();

  const address = company?.address as AddressModel;
  const urbanAreas = company?.urbanAreas as UrbanAreaModel[];
  const units = company?.units as UnitModel[];

  const { countries } = useCommonState();

  const countryId =
    typeof address.country === 'string' ? address.country : address.country.id;
  const country = countries.find((c) => c.id === countryId);

  const { urbanAreasByCountry } = useUrbanAreasByCountry(country.code);
  const { urbanAreasOptions } = useUrbanAreasOptions(urbanAreasByCountry);

  const [location, setLocation] = useState({
    lat: address.lat,
    lng: address.lng,
  });

  const handleSubmit = async (values) => {
    const {
      name,
      country,
      postalCode,
      city,
      street,
      numberOfStreet,
      description,
    } = values;
    const urbanAreas = values.urbanAreas.map((item) => item.id);
    const units = values.units.map((item) => {
      return {
        name: item.name,
        urbanAreas: [],
        // TODO: to urban Areas - item.urbanAreas.map((item) => item.id),
      };
    });

    const { lat, lng } = location;

    const data = {
      name,
      address: {
        country,
        postalCode,
        city,
        street,
        numberOfStreet,
        description,
        lat,
        lng,
      },
      urbanAreas,
      units,
    };

    const res = await updateCompany(company.id, ObjectHelper.trimQuery(data));

    if (res) {
      history.push(ROUTES.ADMIN.COMPANIES.INDEX);
    }
  };

  const form = useFormik({
    validationSchema,
    initialValues: {
      name: company.name,
      country: address.country,
      postalCode: address.postalCode,
      city: address.city,
      street: address.street,
      numberOfStreet: address.numberOfStreet,
      description: address.description,
      urbanAreas,
      units,
    },
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <form
        onSubmit={form.handleSubmit}
        onKeyDown={FormHelper.onKeyDownPreventEnter}
      >
        <Paper sx={{ p: 8, mb: 10 }}>
          <Typography variant="h5" mb={8}>
            {t('companies.company_information')}
          </Typography>

          <Grid container rowSpacing={8} columnSpacing={5}>
            <Grid item xs={12} sm={6}>
              <Input
                label={t('companies.company_name')}
                fullWidth
                {...form.getFieldProps('name')}
                errorText={
                  form.errors.name && form.touched.name
                    ? t(form.errors.name as string)
                    : ''
                }
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper sx={{ p: 8, mb: 10 }}>
          <Typography variant="h5" mb={8}>
            {t('companies.company_address')}
          </Typography>

          <SpaceAddressForm
            form={form}
            location={location}
            setLocation={setLocation}
            isNew={false}
            isCompany={true}
          />
        </Paper>

        <UrbanAreasForm form={form} urbanAreasOptions={urbanAreasOptions} />

        <UnitsForm form={form} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button sx={{ minWidth: '150px' }} type="submit" loading={updating}>
            {t('common.update')}
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default EditCompanyForm;
