import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useAuthState, useDeleteUserAction, useShowToastAction } from '@/hooks/redux';
import { UserModel } from '@/resources/models';
import ConfirmDialog from '@/components/ConfimDialog';
import { ROLE } from '@/resources/enums';

interface RemoveUserButtonProps {
  user: UserModel;
  onRemoveUser: () => void;
}

const RemoveUserButton: FC<RemoveUserButtonProps> = ({
  user,
  onRemoveUser,
}) => {
  const [t] = useTranslation('common');
  const deleteUser = useDeleteUserAction();
  const showToast = useShowToastAction();
  const { currentRole } = useAuthState();

  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    const res = await deleteUser(user.id);
    setLoading(false);

    if (res) {
      showToast(t('user.user_deleted_message'));
      onRemoveUser();
    }
  };

  return (
    <>
      <Tooltip title={t('user.delete_user')} >
        <IconButton
          color="error"
          onClick={() => setShowConfirm(true)}
          disabled={
            loading
            || (currentRole === ROLE.COMPANY && user.roles.includes(ROLE.COMPANY))
          }
        >
          <Delete />
        </IconButton>
      </Tooltip>

      <ConfirmDialog
        open={showConfirm}
        handleClose={() => setShowConfirm(false)}
        onConfirm={onDelete}
        confirmTitle="user.delete_user"
        confirmText="user.delete_user_confirm"
      />
    </>
  );
};

export default RemoveUserButton;
