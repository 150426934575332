import { UrbanAreaModel } from '@/resources/models/urban-area.model';
import { URBAN_AREA_ACTIONS } from '@/redux/action-types/urban-areas.types';

export interface UrbanAreasState {
  urbanAreas: UrbanAreaModel[];
}

const initialState: UrbanAreasState = {
  urbanAreas: [],
};

const urbanAreasReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case URBAN_AREA_ACTIONS.GET_URBAN_AREAS: {
      return {
        ...state,
        urbanAreas: payload.urbanAreas,
      };
    }

    default:
      return state;
  }
};

export default urbanAreasReducer;
