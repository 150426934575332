import React from 'react';
import { Box } from '@mui/material';

const ComeInLater = () => (
  <Box
    sx={{
      backgroundImage: 'url(/assets/images/come_in_soon.png)',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      width: '404px',
      height: '404px',
      maxWidth: 'calc(100vw - 25%)',
      maxHeight: 'calc(100vw - 25%)',
      mt: 12,
      mx: 'auto',
    }}
  ></Box>
);

export default ComeInLater;
