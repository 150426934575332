import { Dispatch } from 'redux';
import { COMPANY_ACTIONS } from '@/redux/action-types';
import { CompanyService } from '@/service';
import { showToastAction } from '@/redux/actions/toast.actions';
import { CompanyModel, SessionModel, UserModel } from '@/resources/models';

const createCompanyRequest = () => ({
  type: COMPANY_ACTIONS.CREATE_COMPANY_REQUEST,
});

const createCompanySuccess = () => ({
  type: COMPANY_ACTIONS.CREATE_COMPANY_SUCCESS,
});

const createCompanyError = () => ({
  type: COMPANY_ACTIONS.CREATE_COMPANY_ERROR,
});

export const createCompanyAction =
  (data: any) => async (dispatch: Dispatch) => {
    dispatch(createCompanyRequest());
    // for default company should be active
    data.disabled = false;
    data.deleted = false;

    try {
      const res = await CompanyService.create(data);
      showToastAction('Company is created successfully.')(dispatch);
      dispatch(createCompanySuccess());
      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(createCompanyError());
    }
  };

const getCompaniesRequest = () => ({
  type: COMPANY_ACTIONS.GET_COMPANIES_REQUEST,
});

const getCompaniesSuccess = (
  totalCount: number,
  companies: CompanyModel[],
) => ({
  type: COMPANY_ACTIONS.GET_COMPANIES_SUCCESS,
  payload: { companies, totalCount },
});

const getCompaniesError = () => ({
  type: COMPANY_ACTIONS.GET_COMPANIES_ERROR,
});

export const getCompaniesAction =
  (query: any = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(getCompaniesRequest());

    try {
      const { data, totalCount } = await CompanyService.search(query);

      dispatch(getCompaniesSuccess(totalCount, data));

      return { data, totalCount };
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(getCompaniesError());
    }
  };

const removeCompanyRequest = () => ({
  type: COMPANY_ACTIONS.DELETE_COMPANY_REQUEST,
});

const removeCompanySuccess = () => ({
  type: COMPANY_ACTIONS.DELETE_COMPANY_SUCCESS,
});

const removeCompanyError = () => ({
  type: COMPANY_ACTIONS.DELETE_COMPANY_ERROR,
});

export const removeCompanyAction =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(removeCompanyRequest());

    try {
      await CompanyService.remove(id);
      showToastAction('Company is removed successfully.')(dispatch);
      dispatch(removeCompanySuccess());
      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(removeCompanyError());
    }
  };

const searchCompanyUsersRequest = () => ({
  type: COMPANY_ACTIONS.GET_COMPANY_USERS_REQUEST,
});

const searchCompanyUsersSuccess = (
  companyUsers: UserModel[],
  userTotalCount: number,
) => ({
  type: COMPANY_ACTIONS.GET_COMPANY_USERS_SUCCESS,
  payload: { companyUsers, userTotalCount },
});

const searchCompanyUsersError = () => ({
  type: COMPANY_ACTIONS.GET_COMPANY_USERS_ERROR,
});

export const getCompanyUsersAction =
  (query: any = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(searchCompanyUsersRequest());

    try {
      const res = await CompanyService.searchUsers(query);
      dispatch(searchCompanyUsersSuccess(res.data, res.totalCount));

      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(searchCompanyUsersError());
    }
  };

const searchCompanySessionsRequest = () => ({
  type: COMPANY_ACTIONS.GET_COMPANY_SESSIONS_REQUEST,
});

const searchCompanySessionsSuccess = (
  companySessions: SessionModel[],
  sessionTotalCount: number,
) => ({
  type: COMPANY_ACTIONS.GET_COMPANY_SESSIONS_SUCCESS,
  payload: { companySessions, sessionTotalCount },
});

const searchCompanySessionsError = () => ({
  type: COMPANY_ACTIONS.GET_COMPANY_SESSIONS_ERROR,
});

export const getCompanySessionsAction =
  (query: any = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(searchCompanySessionsRequest());

    try {
      const res = await CompanyService.searchCompanySessions(query);
      dispatch(searchCompanySessionsSuccess(res.data, res.totalCount));

      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(searchCompanySessionsError());
    }
  };

const findCompanyRequest = () => ({
  type: COMPANY_ACTIONS.FIND_COMPANY_REQUEST,
});

const findCompanySuccess = (company: CompanyModel) => ({
  type: COMPANY_ACTIONS.FIND_COMPANY_SUCCESS,
  payload: { company },
});

const findCompanyError = () => ({
  type: COMPANY_ACTIONS.FIND_COMPANY_ERROR,
});

export const findCompanyAction = (id: string) => async (dispatch: Dispatch) => {
  dispatch(findCompanyRequest());

  try {
    const company = await CompanyService.find(id);
    dispatch(findCompanySuccess(company));
    return company;
  } catch (err) {
    showToastAction(err.message, 'error')(dispatch);
    dispatch(findCompanyError());
  }
};

const updateCompanyRequest = () => ({
  type: COMPANY_ACTIONS.UPDATE_COMPANY_REQUEST,
});

const updateCompanySuccess = () => ({
  type: COMPANY_ACTIONS.UPDATE_COMPANY_SUCCESS,
});

const updateCompanyError = () => ({
  type: COMPANY_ACTIONS.UPDATE_COMPANY_ERROR,
});

export const updateCompanyAction =
  (id: string, data: any) => async (dispatch: Dispatch) => {
    dispatch(updateCompanyRequest());

    try {
      await CompanyService.update(id, data);
      showToastAction('Company is updated successfully.')(dispatch);
      dispatch(updateCompanySuccess());
      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(updateCompanyError());
      return false;
    }
  };

const getCompanyDetailUsersRequest = () => ({
  type: COMPANY_ACTIONS.GET_COMPANY_DETAIL_USERS_REQUEST,
});

const getCompanyDetailUsersSuccess = (
  companyUsers: UserModel[],
  userTotalCount: number,
) => ({
  type: COMPANY_ACTIONS.GET_COMPANY_DETAIL_USERS_SUCCESS,
  payload: { companyUsers, userTotalCount },
});

const getCompanyDetailUsersError = () => ({
  type: COMPANY_ACTIONS.GET_COMPANY_DETAIL_USERS_ERROR,
});

export const getCompanyDetailUsersAction =
  (id: string, query: any = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(getCompanyDetailUsersRequest());

    try {
      const res = await CompanyService.getCompanyUsers(id, query);
      dispatch(getCompanyDetailUsersSuccess(res.data, res.totalCount));

      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(getCompanyDetailUsersError());
    }
  };

const disableCompanyRequest = () => ({
  type: COMPANY_ACTIONS.DISABLE_COMPANY_REQUEST,
});

const disableCompanySuccess = () => ({
  type: COMPANY_ACTIONS.DISABLE_COMPANY_SUCCESS,
});

const disableCompanyError = () => ({
  type: COMPANY_ACTIONS.DISABLE_COMPANY_ERROR,
});

export const disableCompanyAction =
  (id: string, disabled: boolean) => async (dispatch: Dispatch) => {
    dispatch(disableCompanyRequest());

    try {
      await CompanyService.disable(id, disabled);
      showToastAction(
        `Company is ${disabled ? 'disabled' : 'enabled'} successfully.`,
      )(dispatch);
      dispatch(disableCompanySuccess());
      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(disableCompanyError());
    }
  };

const createCompanyAdminRequest = () => ({
  type: COMPANY_ACTIONS.CREATE_COMPANY_ADMIN_SUCCESS,
});

const createCompanyAdminSuccess = () => ({
  type: COMPANY_ACTIONS.CREATE_COMPANY_ADMIN_SUCCESS,
});

const createCompanyAdminError = () => ({
  type: COMPANY_ACTIONS.CREATE_COMPANY_ADMIN_ERROR,
});

export const createCompanyAdminAction =
  (id: string, data: any) => async (dispatch: Dispatch) => {
    dispatch(createCompanyAdminRequest());

    try {
      const res = await CompanyService.createAdmin(id, data);
      showToastAction('Company admin is created successfully.')(dispatch);
      dispatch(createCompanyAdminSuccess());

      return res;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(createCompanyAdminError());
    }
  };
