import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NewLogo from '@/parts/NewLogo';
import colors from '@/themes/colors';
import CookiePopup from '@/components/CookiePopup';

export type AuthLayoutProps = {
  className?: string;
};

const AuthLayout: FC<AuthLayoutProps> = ({ className = '', children }) => {
  const [t] = useTranslation('common');

  return (
    <Box
      sx={{
        width: { sm: '100vw', md: '720px' },
        minWidth: '750px',
        height: '100vh',
        display: 'flex',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          backgroundImage: 'url(/assets/images/auth_bg.png)',
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          width: { xs: 0, md: '50%' },
          maxWidth: { xs: '0', md: '50%' },
          mr: { md: -2 },
          p: '60px 40px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <NewLogo size={50} />
          <Typography
            sx={{
              textTransform: 'uppercase',
              color: colors.white,
              fontFamily: 'Stolzl',
              fontSize: '1em',
            }}
            variant="h5"
          >
            coremoting
          </Typography>
          {/*<Logo fill="#fff" />*/}
        </Box>
        <Typography
          sx={{
            mt: '75px',
            color: (theme) => theme.colors.bg_grey,
            fontSize: '48px',
            fontFamily: 'Montserrat',
            fontWeight: 700,
          }}
        >
          {t('app.want_coremote_with_colleague')}
        </Typography>
      </Box>

      <Box
        sx={{
          background: '#fff',
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: 12,
          width: { xs: '100%', md: '50%' },
        }}
      >
        <Box>{children}</Box>
      </Box>
      <CookiePopup />
    </Box>
  );
};

export default AuthLayout;
