import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Box, Paper, Skeleton, Typography } from '@mui/material';
import { useCompanyState, useFindCompanyAction } from '@/hooks/redux';
import { BackButton } from '@/components';
import EditCompanyForm from '@/pages/Admin/Companies/EditCompany/EditCompanyForm';

const EditCompany = () => {
  const [t] = useTranslation('common');
  const { id } = useParams<{ id: string; }>();
  const { company, finding } = useCompanyState();
  const findCompany = useFindCompanyAction();

  useEffect(() => {
    findCompany(id);
  }, [id]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 15
        }}
      >
        <Typography
          variant={'h1'}
          sx={{color: theme => theme.colors.text_secondary}}
        >
          {t('companies.edit_company')}
        </Typography>

        <BackButton />
      </Box>

      {
        finding ? (
          <Box>
            {
              new Array(10).fill(0).map((_, index) => (
                <Skeleton key={index} />
              ))
            }
          </Box>
        ) : (
          company ? (
            <EditCompanyForm company={company} />
          ) : (
            <Paper sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '500px'
            }}>
              <Typography variant="body1" color="error">
                {t('companies.not_found_company')}
              </Typography>
            </Paper>
          )
        )
      }

    </div>
  );
};

export default EditCompany;
