import { Dispatch } from 'redux';
import { SESSION_ACTIONS } from '@/redux/action-types';
import { SessionModel } from '@/resources/models';
import { SessionService } from '@/service';
import { showToastAction } from '@/redux/actions';

const getSessionsRequest = () => ({
  type: SESSION_ACTIONS.GET_SESSIONS_REQUEST,
});

const getSessionsSuccess = (sessions: SessionModel[], totalCount: number) => ({
  type: SESSION_ACTIONS.GET_SESSIONS_SUCCESS,
  payload: { sessions, totalCount },
});

const getSessionsError = () => ({
  type: SESSION_ACTIONS.GET_SESSIONS_ERROR,
});

export const getSessionsAction =
  (query: any = {}) =>
  async (dispatch: Dispatch) => {
    dispatch(getSessionsRequest());

    try {
      const { data, totalCount } = await SessionService.search(query);
      dispatch(getSessionsSuccess(data, totalCount));
      return { data, totalCount };
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(getSessionsError());
    }
  };

const companyScheduledSessionsCountRequest = () => ({
  type: SESSION_ACTIONS.COMPANY_SCHEDULED_SESSIONS_COUNT_REQUEST,
});

const companyScheduledSessionsCountSuccess = (count: number) => ({
  type: SESSION_ACTIONS.COMPANY_SCHEDULED_SESSIONS_COUNT_SUCCESS,
  payload: { count },
});

const companyScheduledSessionsCountError = () => ({
  type: SESSION_ACTIONS.COMPANY_SCHEDULED_SESSIONS_COUNT_ERROR,
});

export const getCompanyScheduledSessionsCountAction =
  () => async (dispatch: Dispatch) => {
    dispatch(companyScheduledSessionsCountRequest());

    try {
      const { count } = await SessionService.getCompanyScheduledSessionsCount();
      dispatch(companyScheduledSessionsCountSuccess(count));
      return count;
    } catch (err) {
      dispatch(companyScheduledSessionsCountError());
    }
  };

const removeSessionRequest = () => ({
  type: SESSION_ACTIONS.REMOVE_SESSION_REQUEST,
});

const removeSessionSuccess = () => ({
  type: SESSION_ACTIONS.REMOVE_SESSION_SUCCESS,
});

const removeSessionError = () => ({
  type: SESSION_ACTIONS.REMOVE_SESSION_ERROR,
});

export const removeSessionAction =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(removeSessionRequest());

    try {
      await SessionService.remove(id);
      showToastAction('Session is removed successfully.')(dispatch);
      dispatch(removeSessionSuccess());
      return true;
    } catch (err) {
      showToastAction(err.message, 'error')(dispatch);
      dispatch(removeSessionError());
    }
  };
