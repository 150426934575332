import { CompanyModel } from '@/resources/models/company.model';
import { UserModel } from '@/resources/models/user.model';
import { SESSION_STATUS } from '@/resources/enums';
import { SpaceModel } from '@/resources/models/space.model';
import {
  DistanceMatrixRowElement,
  TravelMode,
} from '@/resources/interfaces/google-distance-matrix.interface';

export type SessionDistanceInfo = {
  [key in TravelMode]?: DistanceMatrixRowElement;
};

export class SessionModel {
  id: string;
  company: string | CompanyModel;
  host: string | UserModel;
  guest: string | UserModel;
  space: string | SpaceModel;
  status: SESSION_STATUS;
  score: number;
  startTime: string;
  endTime: string;
  createdAt: string;
  distance: number;
  distanceInfo: SessionDistanceInfo;

  constructor(init: any = {}) {
    const data = {
      id: '',
      company: '',
      host: '',
      guest: '',
      status: SESSION_STATUS.SCHEDULED,
      score: 0,
      startTime: '',
      endTime: '',
      createdAt: '',
      distance: 0,
      ...init,
    };

    this.id = data.id || data._id;
    this.status = data.status;
    this.score = data.score;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.createdAt = data.createdAt;
    this.distance = data.distance;
    this.distanceInfo = data.distanceInfo;

    if (typeof data.company === 'string') {
      this.company = data.company;
    } else {
      this.company = new CompanyModel(data.company);
    }

    if (typeof data.host === 'string') {
      this.host = data.host;
    } else {
      this.host = new UserModel(data.host);
    }

    if (typeof data.guest === 'string') {
      this.guest = data.guest;
    } else {
      this.guest = new UserModel(data.guest);
    }

    if (typeof data.space === 'string') {
      this.space = data.space;
    } else {
      this.space = new SpaceModel(data.space);
    }
  }
}
