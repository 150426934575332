import { NotificationModel } from '@/resources/models';
import { NOTIFICATION_ACTIONS } from '@/redux/action-types';

export interface NotificationState {
  loading: boolean;
  notifications: NotificationModel[];
  totalCount: number;

  unreadCount: number;
}

const initialState: NotificationState = {
  loading: false,
  notifications: [],
  totalCount: 0,

  unreadCount: 0,
};

const notificationReducer = (state = initialState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        totalCount: payload.totalCount,
        notifications: payload.notifications,
      };
    }

    case NOTIFICATION_ACTIONS.GET_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case NOTIFICATION_ACTIONS.SET_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: payload.unreadCount,
      };
    }

    case NOTIFICATION_ACTIONS.ADD_UNREAD_COUNT: {
      return {
        ...state,
        unreadCount: state.unreadCount + 1,
      };
    }

    case NOTIFICATION_ACTIONS.MARK_NOTIFICATION_READ: {
      return {
        ...state,
        unreadCount: Math.max(state.unreadCount - 1, 0),
        notifications: state.notifications.map((item) => {
          if (item.id === payload.id) {
            return new NotificationModel({ ...item, read: true });
          }

          return item;
        }),
      };
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
