import React, { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import {
  useAuthState,
  useGetAccountAction,
  useLogout,
  useSetAccountAction,
  useSetAccountLoading,
  useSetTokenAction,
} from '@/hooks/redux/auth';
import {
  ACCESS_TOKEN_KEY,
  COOKIE_CONSENT_NAME,
  REFRESH_TOKEN_KEY,
} from '@/constants';
import { LoadingBox } from '@/components';

const AuthProvider: FC = ({ children }) => {
  const [cookies, setCookie, deleteCookie] = useCookies([]);

  const { tokens, account, loadingAccount } = useAuthState();
  const setTokens = useSetTokenAction();
  const getAccount = useGetAccountAction();
  const setAccount = useSetAccountAction();
  const setAccountLoading = useSetAccountLoading();
  const logout = useLogout();

  useEffect(() => {
    setTokens({
      accessToken: cookies[ACCESS_TOKEN_KEY],
      refreshToken: cookies[REFRESH_TOKEN_KEY],
    });

    if (cookies[COOKIE_CONSENT_NAME] !== true) {
      logout();
    }
  }, []);

  useEffect(() => {
    if (tokens === undefined) {
      return;
    }

    if (!tokens.accessToken) {
      setAccount(null);
      setAccountLoading(false);
      deleteCookie(ACCESS_TOKEN_KEY);
      deleteCookie(REFRESH_TOKEN_KEY);
      return;
    }
    const cookieOptions = {
      secure: true,
      path: '/',
    };
    setCookie(ACCESS_TOKEN_KEY, tokens.accessToken, cookieOptions);
    setCookie(REFRESH_TOKEN_KEY, tokens.refreshToken, cookieOptions);

    if (!account) {
      (async () => {
        const res = await getAccount();

        if (!res) {
          deleteCookie(ACCESS_TOKEN_KEY);
          deleteCookie(REFRESH_TOKEN_KEY);
          setTokens({
            accessToken: '',
            refreshToken: '',
          });
        }
      })();
    }
  }, [tokens, account]);

  if (loadingAccount) {
    return <LoadingBox />;
  }

  return <>{children}</>;
};

export default AuthProvider;
