import { combineReducers } from 'redux';
import authReducer, { AuthState } from '@/redux/reducers/auth.reducer';
import spinnerReducer, { SpinnerState } from '@/redux/reducers/spinner.reducer';
import commonReducer, { CommonState } from '@/redux/reducers/common.reducer';
import toastReducer, { ToastState } from '@/redux/reducers/toast.reducer';
import companyReducer, {
  CompaniesState,
} from '@/redux/reducers/company.reducer';
import userReducer, { UsersState } from '@/redux/reducers/user.reducer';
import offerReducer, { OfferState } from '@/redux/reducers/offer.reducer';
import demandReducer, { DemandState } from '@/redux/reducers/demand.reducer';
import accountReducer, { AccountState } from '@/redux/reducers/account.reducer';
import sessionReducer, { SessionState } from '@/redux/reducers/session.reducer';
import notificationReducer, {
  NotificationState,
} from '@/redux/reducers/notification.reducer';
import spaceReducer, { SpaceState } from '@/redux/reducers/space.reducer';
import adminReducer, { AdminState } from '@/redux/reducers/admin.reducer';
import urbanAreasReducer, {
  UrbanAreasState,
} from '@/redux/reducers/urban-areas.reducer';
import { DESTROY_SESSION } from '@/redux/action-types';

export interface RootState {
  authReducer: AuthState;
  spinnerReducer: SpinnerState;
  commonReducer: CommonState;
  toastReducer: ToastState;
  companyReducer: CompaniesState;
  userReducer: UsersState;
  offerReducer: OfferState;
  demandReducer: DemandState;
  accountReducer: AccountState;
  sessionReducer: SessionState;
  notificationReducer: NotificationState;
  spaceReducer: SpaceState;
  adminReducer: AdminState;
  urbanAreasReducer: UrbanAreasState;
}

const appReducer = combineReducers<RootState>({
  authReducer,
  spinnerReducer,
  commonReducer,
  toastReducer,
  companyReducer,
  userReducer,
  offerReducer,
  demandReducer,
  accountReducer,
  sessionReducer,
  notificationReducer,
  spaceReducer,
  adminReducer,
  urbanAreasReducer,
});

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
