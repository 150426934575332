import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/reducers';
import {
  getAccountCompanyInfoAction,
  setAccount,
  showToastAction,
  updateProfileAction,
} from '@/redux/actions';
import { AccountService } from '@/service';

export const useAccountState = () =>
  useSelector(({ accountReducer }: RootState) => accountReducer);

export const useGetAccountCompanyInfo = () => {
  const dispatch = useDispatch();
  return () => dispatch(getAccountCompanyInfoAction());
};

export const useUpdateProfile = () => {
  const dispatch = useDispatch();
  return (data: any) => dispatch(updateProfileAction(data));
};

export const useUpdateCompanyAccount = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const updateCompanyAccount = (data: any) => {
    setLoading(true);
    return AccountService.updateCompanyProfile(data)
      .then((user) => {
        dispatch(setAccount(user));
        setLoading(false);
        return user;
      })
      .catch((err) => {
        setLoading(false);
        showToastAction(err.message, 'error')(dispatch);
      });
  };

  return { updateCompanyAccount, loading };
};
